/**
 * 个股公告
 */

import React,{useState, useEffect} from "react"
import {getNotice, type_notice} from "../../../modules/notice/web"
import "../news_box/index.less"
import { sendStat } from "../../../modules/bigdata_stat"

/** 个股公告 */
export function QuoteNoticeList(props:{
  market: string,
  code: string,
  size?: number,
  short?:boolean
}){

  const size = props.size || 5
  const [init, SetInit] = useState(true)
  const [showdata, SetShowData] = useState([] as Array<type_notice>)

  useEffect(()=>{
    ;(async()=>{
      let back = await getNotice(props.market, props.code, 5, 300)
      SetInit(false)
      if(back.length > 0){
        SetShowData(back)
      }
    })();
  }, [])


  let short = true
  if(props.short === false){
    short = false
  }

  return (
    <div className="qnb_list" onClick={()=>{sendStat('A', 'Click', 'webjdb.gg.qynr')}}>
      <ul>
        {
          showdata.map((v,index)=>
            <li key={index}>
              <a className={short?'short':''} href={`//data.eastmoney.com/notices/detail/${props.code}/${v.code}.html`} title={v.title} target="_blank">{v.title}</a><span className="time">{v.time && v.time.substring(5, 10)}</span>
            </li>
          )
        }
        {
          showdata.length < size && Array.from(new Array(size - showdata.length)).map((v,index)=>
            <li key={index} className="nodot"></li>            
          )
        }
        {
          showdata.length == 0 && init &&
          <div className="nodata">数据加载中...</div>
        }
        {
          showdata.length == 0 && !init &&
          <div className="nodata">暂无数据</div>
        }
      </ul>
    </div>
  )
}