/**
 * 跳转wap网站
 */

import url from "../url";

function isMobile() {
  var ua = navigator.userAgent;
  var res=false;
  var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
  isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
  isAndroid = ua.match(/(Android)\s+([\d.]+)/) && ua.match(/(Mobile)\s+/),
  isMobile = isIphone || isAndroid;
  if(isMobile) {
      res=true;
  }else{
      res=false;
  }
  return res;
}

/**
 * 跳转wap
 * @param code 代码
 * @param market 市场
 */
function gotowap(code:string, market:string){
  if(url.getQuery('jump_to_web') == 'true'){
    return false
  }
  if (isMobile()) {
    self.location.href = `https://wap.eastmoney.com/quote/stock/${market}.${code}.html`
  }

}

export default gotowap