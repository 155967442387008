/**
 * 公告数据接口
 */
import web_config from "../../config/web";

export interface type_notice{
  title:string,
  code: string,
  time: string
}

let datacache:any = {

}

/**
 * 根据市场代码获取公告
 * @param market 
 * @param code 
 * @param page_size 
 * @param cachetime 数据缓存时间 单位:秒
 * @returns 
 */
export async function getNotice(market:string, code:string, page_size=5, cachetime = 0):Promise<Array<type_notice>>{

  if(cachetime && datacache['notice' + market + code]){
    return datacache['notice' + market + code]
  }

  let backdata = await $.ajax({
    url: `${web_config.getUrl('new_notice_api')}api/security/ann?cb=?`,
    type: 'GET',
    dataType: 'jsonp',
    data: {
      page_size,
      page_index: 1,
      market_code: market,
      stock_list: code,
      client_source: 'web'
    }
  }).then(function(back){
    if(back?.data?.list instanceof Array){
      return back.data.list.map((v:any)=>{
        return {
          title: v.title,
          code: v.art_code,
          time: v.notice_date
        }
      })
    }
    else{
      return []
    }
  }, function(){
    return []
  })

  if(cachetime){
    datacache['notice' + market + code] = backdata
    setTimeout(() => {
      delete datacache['notice' + market + code]
    }, cachetime * 1000);
  }

  return backdata
}