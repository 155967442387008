/**
 * 静态K线图
 */

import React,{useState, useEffect} from "react"
import config from "../../../config/web";
import { KType, KFQType } from './index'
import { FullScreenLink } from "../fullscreen_chart";

/** 主要指标 */
enum MainZB {
  均线 = '',
  EXPMA = 'EXTENDED_MA',
  BOLL = 'EXTENDED_BOLL',
  SAR = 'EXTENDED_SAR',
  BBI = 'EXTENDED_BBI',
}

/** 底部指标 */
enum BottomZB {
  RSI = 'RSI',
  KDJ = 'KDJ',
  MACD = 'MACD',
  'W&R' = 'WR',
  DMI = 'DMI',
  BIAS = 'BIAS',
  OBV = 'OBV',
  CCI = 'CCI',
  ROC = 'ROC',
  CR = 'CR',
  BOLL = 'BOLL'
}

export function StaticKChart(props:{
  market: string,
  quotecode: string,
  ktype: KType,
  kfqtype: KFQType,
  zoom_number: number,
  fullscreen: boolean,
  pagetype?: string
}){
  
  let imageType = 'r'
  let type = ''
  switch (props.ktype) {
    case KType.日K:
      imageType = 'r'
      type = ''
      break
    case KType.周K:
      imageType = 't'
      type = 'W'   
      break
    case KType.月K:
      imageType = 't'
      type = 'M'
      break
    case KType['5分钟']:
      imageType = 't'
      type = 'M5'
      break
    case KType['15分钟']:
      imageType = 't'
      type = 'M15'
      break
    case KType['30分钟']:
      imageType = 'rc'
      type = 'M30'
      break
    case KType['60分钟']:
      imageType = 'rp'
      type = 'M60'
      break
  }  

  let at = 1 //除复权
  switch (props.kfqtype) {
    case KFQType.前复权:
      at = 1
      break
    case KFQType.后复权:
      at = 2   
      break
    case KFQType.不复权:
      at = 0
      break
  }    

  /** 时间戳 刷新图片用 */
  const [timespan, SetTimeSpan] = useState((new Date()).getTime().toString().substring(0, 10))
  /** 是否是浏览器 */
  const [is_browser, SetIsBrowser] = useState(false)

  /** K线图长短 */
  const [klength, SetKLength] = useState(-6)

  /** 当前主要指标 */
  const [mainzb, SetMainZB] = useState(MainZB.均线)

  /** 当前底部指标 */
  const [bottomzb, SetBottomZB] = useState(BottomZB.RSI)


  useEffect(()=>{
    SetIsBrowser(true)
    
    let cd = setInterval(function(){
      SetTimeSpan((new Date()).getTime().toString().substring(0, 10))
    }, 20 * 1000)

    return ()=>{
      try {
        clearInterval(cd)
      } catch (error) {
        
      }
    }
  }, [props.quotecode])

  useEffect(()=>{
    if(props.zoom_number > 0){
      SetKLength((thislength)=>{
        thislength ++
        if(thislength > 0) thislength = 0
        return thislength
      })
    }
    if(props.zoom_number < 0){
      SetKLength((thislength)=>{
        thislength --
        if(thislength < -8) thislength = -8
        return thislength
      })
    }
  }, [props.zoom_number])

    /** 切换主要指标 */
  const switchMainZB = (tozb: MainZB) => {
    SetMainZB(tozb)
  }

  /** 切换底部指标 */
  const switchBottomZB = (tozb: BottomZB) => {
    SetBottomZB(tozb)
  }  

  return (
    <>
      {
        is_browser &&
        <div className="static_k">
          <div className="qsc_k_zb1">
            <div className="qsc_k_zb1t">主要指标</div>
            <ul>
              {
                Object.keys(MainZB).map(v=>
                  <li key={v} className={mainzb == MainZB[v as keyof typeof MainZB] ? 'active': ''} onClick={()=>{switchMainZB(MainZB[v as keyof typeof MainZB])}}>{v}</li>
                )
              }
            </ul>          
          </div>   

        <div className="qsc_k_zb_b">
          <ul>
            {
              Object.keys(BottomZB).map(v=>
                <li key={v} className={bottomzb == BottomZB[v as keyof typeof BottomZB] ? 'active': ''} onClick={()=>{switchBottomZB(BottomZB[v as keyof typeof BottomZB])}}>{v}</li>
              )
            }
          </ul>          
        </div>
        <div className="qsc_k_img">
          <FullScreenLink fullscreen={props.fullscreen}>
            <img src={`${(props.pagetype == 'gzqh') || (props.pagetype == 'option' && (props.market == '11' || props.market == '221'))  ? config.getUrl('chart_kline_delay') : config.getUrl('chart_kline')}GetPic.aspx?nid=${props.quotecode}&type=${type}&unitWidth=${klength}&ef=${mainzb}&formula=${bottomzb}&AT=${at}&imageType=KXL&timespan=${timespan}`} id="hkpic" width={520} height={365} />             
          </FullScreenLink>
         
        </div>
        <div className="qsc_k_zb2 self_clearfix">
          <ul>
            {
              Object.keys(BottomZB).map(v=>
                <li key={v} className={bottomzb == BottomZB[v as keyof typeof BottomZB] ? 'active': ''} onClick={()=>{switchBottomZB(BottomZB[v as keyof typeof BottomZB])}}>{v}</li>
              )
            }
          </ul>          
        </div>          
        </div>
      }
    </>
  )
}


