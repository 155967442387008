/**
 * 行情字段配置
 */

import React from "react"
// import tools, {type_quote_item} from "./tools";

/** 行情字段的值类型 */
export interface type_quote_item{
  /** 输出文本 */
  txt: string,
  /** 颜色class */
  color: 'price_up'|'price_down'|'price_draw'|''|'price_up2'|'price_down2',
  /** 输出html */
  html: JSX.Element,
  /** 输出带闪烁的html */
  blink_html: JSX.Element,
  /** 原值 */
  source: any
}

/**
 * 行情字段
 */
export enum QuoteItem{
  "名称",
  "市场",
  "代码",
  "行情代码",
  "最新价",
  "最新价带颜色反转判断",
  "最后价格",
  "最新价人民币",
  "涨跌幅",
  "涨跌幅带颜色反转判断",
  "涨跌幅BP",
  "涨跌额",
  "行情统一链接",
  "行情统一概念版链接",
  "今开",
  "昨收",
  "最高价",
  "最低价",
  "成交量",
  "成交量带手",
  "成交额",
  "买入价或买一价",
  "卖出价或卖一价",
  "买一量",
  "买一差量",
  "买二价",
  "买二量",
  "买二差量",
  "买三价",
  "买三量",
  "买三差量",
  "买四价",
  "买四量",  
  "买四差量",  
  "买五价",
  "买五量", 
  "买五差量", 
  "卖一量",
  "卖一差量",
  "卖二价",
  "卖二量",
  "卖二差量",
  "卖三价",
  "卖三量",
  "卖三差量",
  "卖四价",
  "卖四量",  
  "卖四差量",  
  "卖五价",
  "卖五量", 
  "卖五差量", 

  "卖十价",
  "卖十量",
  "卖十席位数",
  "卖九价",
  "卖九量",
  "卖九席位数",
  "卖八价",
  "卖八量",
  "卖八席位数",
  "卖七价",
  "卖七量",
  "卖七席位数",
  "卖六价",
  "卖六量",
  "卖六席位数",
  "卖五席位数",
  "卖四席位数",
  "卖三席位数",
  "卖二席位数",
  "卖一席位数",

  "买十价",
  "买十量",
  "买十席位数",
  "买九价",
  "买九量",
  "买九席位数",
  "买八价",
  "买八量",
  "买八席位数",
  "买七价",
  "买七量",
  "买七席位数",
  "买六价",
  "买六量",
  "买六席位数",
  "买五席位数",
  "买四席位数",
  "买三席位数",
  "买二席位数",
  "买一席位数",  

  "内盘",
  "外盘",
  "振幅",
  "量比",
  "交易时间",
  "交易时间不带括号",
  "交易时间带星期",
  "交易币种",
  "交易币种_汇率",
  "总股本",
  "流通股本",
  "换手率",
  "每股收益TTM",
  "总市值",
  "总市值_短",
  "市净率",
  "市盈率动态",
  "市盈率静态",
  "市盈率TTM",
  "每股净资产",
  "净资产",
  "均价",
  "流通市值",
  "流通市值_短",
  "上涨家数",
  "下跌家数",
  "平盘家数",
  "交易状态",
  "JYS",
  "委比",
  "委差",
  "可转债名称或正股名称",
  "可转债市场或正股市场",
  "可转债代码或正股代码",
  "可转债最新价或正股最新价",
  "可转债涨跌额或正股涨跌额",
  "可转债涨跌幅或正股涨跌幅",
  "可转债转股日期",
  "可转债转股溢价率",
  "可转债申购代码",
  "可转债申购日期",
  "可转债转股价",
  "可转债转股价值",
  "可转债回售触发价",
  "可转债强赎触发价",
  "可转债到期赎回价",
  "纯债价值",
  "扩展类型",
  "债券最近成交方式",
  "债券最新匹配成交价",
  "债券当日匹配成交量",
  "债券最新YTM",
  "债券涨跌BP",
  "质押式回购债券涨跌BP",
  "债券当日匹配成交额",
  "债券加权平均涨跌BP",
  "债券加权平均",
  "债券昨加权平均",
  "板块领涨股",
  "板块领涨股概念版",
  "板块领涨股涨跌幅",
  "板块领跌股",
  "主力净额",
  "板块资金流入最大股",
  "涨跌幅_5分钟",
  "涨跌幅_3日",
  "涨跌幅_5日",
  "涨跌幅_6日",
  "涨跌幅_10日",
  "涨跌幅_20日",
  "涨跌幅_60日",
  "涨跌幅_今年以来",
  "营业总收入",
  "营业总收入同比",
  "净利润",
  "净利润TTM",
  "净利润同比",
  "毛利率",
  "净利率",
  "净资产收益率ROE",
  "资产负债率",
  "每股未分配利润",
  "上市日期",
  "财报季度",
  "每股收益",
  "涨停价",
  "跌停价",
  "主力流入",
  "主力流出",
  "主力净流入",
  "超大单流入",
  "超大单流出",
  "超大单净流入",
  "超大单净占比",
  "大单流入",
  "大单流出",
  "大单净流入",
  "大单净占比",
  "中单流入",
  "中单流出", 
  "中单净流入", 
  "中单净占比",
  "小单流入",
  "小单流出", 
  "小单净流入", 
  "小单净占比",
  "主力净占比",
  "5日主力净额",
  "5日主力净占比",
  "5日超大单净额",
  "5日超大单净占比",
  "5日大单净额",
  "5日大单净占比",
  "5日中单净额",
  "5日中单净占比",
  "5日小单净额",
  "5日小单净占比",
  "10日主力净额",
  "10日主力净占比",
  "10日超大单净额",
  "10日超大单净占比",
  "10日大单净额",
  "10日大单净占比",
  "10日中单净额",
  "10日中单净占比",
  "10日小单净额",
  "10日小单净占比",
  "52周最高价",
  "52周最低价",
  "AB股对应的市场",
  "AB股对应的代码",
  "AB股对应的名称",
  "AB股对应的最新价",
  "AB股对应的涨跌幅",
  "AB股比价",
  "现手",
  "股票标识",
  "二级分类",
  "相对大盘指数1月涨跌幅",
  "相对大盘指数3月涨跌幅",
  "相对大盘指数52周涨跌幅",
  "股息率",
  "AH股对应的市场",
  "AH股对应的代码",
  "AH股对应的名称",
  "AH股对应的最新价",
  "AH股对应的涨跌幅",
  "AH股对应的比价",
  "AH股对应的溢价率",
  "所属行业板块市场",
  "所属行业板块代码",
  "行业板块的成分股数",
  "股东权益",
  "总营业收入TTM",
  "市销率TTM",
  "市现率TTM",
  "净资产收益率TTM",

  "A股对应GDR或GDR对应A股的市场",
  "A股对应GDR或GDR对应A股的代码",
  "A股对应GDR或GDR对应A股的名称",

  "总市值行业排名",
  "净资产行业排名",
  "净利润行业排名",
  "净利润TTM行业排名",
  "市盈率动态行业排名",
  "市盈率TTM行业排名",
  "市净率行业排名",
  "毛利率行业排名",
  "净利率行业排名",
  "ROE行业排名",
  "ROETTM行业排名",
  "股东权益行业排名",
  "总营业收入TTM行业排名",
  "市销率TTM行业排名",
  "市现率TTM行业排名",
  "股息率行业排名",

  "总市值行业排名四分位",
  "净资产行业排名四分位",
  "净利润行业排名四分位",
  "净利润TTM行业排名四分位",
  "市盈率动态行业排名四分位",
  "市盈率TTM行业排名四分位",
  "市净率行业排名四分位",
  "毛利率行业排名四分位",
  "净利率行业排名四分位",
  "ROE行业排名四分位",
  "ROETTM行业排名四分位",
  "股东权益行业排名四分位",
  "总营业收入TTM行业排名四分位",
  "市销率TTM行业排名四分位",
  "市现率TTM行业排名四分位",
  "股息率行业排名四分位",

  "总市值行业平均",
  "净资产行业平均",
  "净利润行业平均",
  "市盈率动态行业平均",
  "市净率行业平均",
  "毛利率行业平均",
  "净利率行业平均",
  "ROE行业平均",


  "昨结算",
  "今结算",
  "今持仓",
  "昨持仓",
  "仓差",

  "期权隐含波动率",
  "期权折溢价率",  
  "期权剩余日期",
  "期权内在价值",
  "期权购沽对应的最新价",
  "期权购沽对应的涨跌幅",
  "期权购沽对应的代码",
  "期权购沽对应的市场",
  "期权购沽对应的证券名称",
  "期权合约单位",
  "期权行权价",
  "期权到期日",
  "期权Delta",
  "期权Gamma",
  "期权Vega",
  "期权Theta",
  "期权Rho",
  "股票期权今开",
  "股票期权最高价",
  "股票期权最低价",
  "股票期权均价",

  "30日波动率",
  "60日波动率",
  "120日波动率",
  "240日波动率",

  "盘后成交量",
  "盘后成交额",
  "是否同股同权",
  "是否表决权差异",
  "是否盈利",
  "是否尚未盈利",
  "注册股本",
  "发行股本",

  "异常交易状态",


  "是否注册制",
  "是否具有协议控制架构",

  "成交笔数",

  "扩位简称",

  "做市商数",


  "A瑞士对应的市场",
  "A瑞士对应的代码",
  "A瑞士对应的名称",
  "A瑞士对应的最新价",
  "A瑞士对应的涨跌幅",
  "A瑞士比价",
  "A瑞士溢价率",

  "港股对应人民币计价或者反过来对应的市场",
  "港股对应人民币计价或者反过来对应的代码",

  "关键期国债价差",
  "关键期国债期限",
  "关键期国债国家",
  "是否颜色反转"
}

/**
 * 列表行情的字段配置
 */
export const ListItemConfig = {
  [QuoteItem.名称]: {
    fid: ['f14'], //依赖字段
    value: function(data:any):type_quote_item{ //返回数据
      return {
        txt: data.f14,
        html: <span>{data.f14}</span>,
        color: '',
        blink_html: <></>,
        source: data.f14
      }
    } 
  },
  [QuoteItem.代码]: {
    fid: ['f12'],
    yl: [''],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f12,
        html: <span>{data.f12}</span>,
        color: '',
        blink_html: <span>{data.f12}</span>,
        source: data.f12
      }
    } 
  },
  [QuoteItem.市场]: {
    fid: ['f13'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f13,
        html: <span>{data.f13}</span>,
        color: '',
        blink_html: <span>{data.f13}</span>,
        source: data.f13
      }
    } 
  },
  [QuoteItem.行情代码]: {
    fid: ['f12', 'f13'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f13 + '.' + data.f12,
        html: <span>{data.f13 + '.' + data.f12}</span>,
        color: '',
        blink_html: <span>{data.f13 + '.' + data.f12}</span>,
        source: data.f13 + '.' + data.f12
      }
    } 
  },
  [QuoteItem.行情统一链接]: {
    fid: ['f12', 'f13', 'f14'],
    value: function(data:any):type_quote_item{
      return {
        source: '',
        txt: `//quote.eastmoney.com/unify/r/${data.f13}.${data.f12}`,
        html: <a href={`//quote.eastmoney.com/unify/r/${data.f13}.${data.f12}`} target="_blank">{data.f14}</a>,
        color: '',
        blink_html: <a href={`//quote.eastmoney.com/unify/r/${data.f13}.${data.f12}`} target="_blank">{data.f14}</a>
      }
    } 
  },
  [QuoteItem.行情统一概念版链接]: {
    fid: ['f12', 'f13', 'f14'],
    value: function(data:any):type_quote_item{
      return {
        source: '',
        txt: `//quote.eastmoney.com/unify/cr/${data.f13}.${data.f12}`,
        html: <a href={`//quote.eastmoney.com/unify/cr/${data.f13}.${data.f12}`} target="_blank">{data.f14}</a>,
        color: '',
        blink_html: <a href={`//quote.eastmoney.com/unify/cr/${data.f13}.${data.f12}`} target="_blank">${data.f14}</a>
      }
    } 
  },
  [QuoteItem.最新价]: {
    fid: ['f1', 'f2', 'f4'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f2, data.f1, data.f4, -1)
    } 
  },
  [QuoteItem.最新价带颜色反转判断]: {
    fid: ['f1', 'f2', 'f4', 'f13', 'f19'],
    value: function(data:any):type_quote_item{
        
      //关键期国债，颜色反转
      if(data.f13 == '171' && (data.f19 == '1' || data.f19 == '2' || data.f19 == '4')){
        return quoteItem(data.f2, data.f1, -data.f4, -1)
      }

      return quoteItem(data.f2, data.f1, data.f4, -1)
    } 
  },  
  [QuoteItem.是否颜色反转]: {
    fid: ['f13', 'f19'],
    value: function(data:any):type_quote_item{
        
      //关键期国债，颜色反转
      if(data.f13 == '171' && (data.f19 == '1' || data.f19 == '2' || data.f19 == '4')){
        return {
          txt: 'true',
          html: <span></span>,
          color: '',
          blink_html: <span></span>,
          source: true
        }
      }

      return {
          txt: 'false',
          html: <span></span>,
          color: '',
          blink_html: <span></span>,
          source: false
      }
    } 
  },  
  
  [QuoteItem.最新价人民币]: {
    fid: ['f251', 'f1', 'f4'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f251, data.f1, data.f4, -1)
    } 
  },
  [QuoteItem.涨跌幅]: {
    fid: ['f3', 'f152', 'f4'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3, data.f152, data.f4, -1, '%')
    } 
  },
  [QuoteItem.涨跌幅带颜色反转判断]: {
    fid: ['f3', 'f152', 'f4', 'f13', 'f19'],
    value: function(data:any):type_quote_item{

      //关键期国债，颜色反转
      if(data.f13 == '171' && (data.f19 == '1' || data.f19 == '2' || data.f19 == '4')){
        return quoteItem(data.f3, data.f152, -data.f4, -1, '%')
      }

      return quoteItem(data.f3, data.f152, data.f4, -1, '%')
    } 
  },  
  [QuoteItem.涨跌幅BP]: {
    fid: ['f3', 'f152', 'f4'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3, 0, data.f4, -1)
    } 
  },
  [QuoteItem.涨跌幅_5分钟]: {
    fid: ['f11', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f11, data.f152, data.f11, -1, '%')
    } 
  },   
  [QuoteItem.涨跌幅_3日]: {
    fid: ['f127', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f127, data.f152, data.f127, -1, '%')
    } 
  },  
  [QuoteItem.涨跌幅_6日]: {
    fid: ['f149', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f149, data.f152, data.f149, -1, '%')
    } 
  },    
  [QuoteItem.涨跌幅_5日]: {
    fid: ['f109', 'f152', 'f4'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f109, data.f152, data.f109, -1, '%')
    } 
  },
  [QuoteItem.涨跌幅_10日]: {
    fid: ['f160', 'f152', 'f4'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f160, data.f152, data.f160, -1, '%')
    } 
  },
  [QuoteItem.涨跌额]: {
    fid: ['f3', 'f152', 'f4'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f4, data.f1, data.f4, -1)
    } 
  },
  [QuoteItem.成交额]: {
    fid: ['f6'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f6, -1, 0, 4)
    } 
  },
  [QuoteItem.上涨家数]: {
    fid: ['f104'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f104, -1, 1, -1)
    } 
  },
  [QuoteItem.下跌家数]: {
    fid: ['f105'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f105, -1, -1, -1)
    } 
  },
  [QuoteItem.平盘家数]: {
    fid: ['f106'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f106, -1, 0, -1)
    } 
  },
  [QuoteItem.可转债申购代码]: {
    fid: ['f348'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f348, -1, 0, -1)
    }
  },
  [QuoteItem.可转债申购日期]: {
    fid: ['f243'],
    value: function(data:any):type_quote_item{
      data.f243 = data.f243.toString()
      let color = 0
      if(data.f243.toString().length >= 8){
        data.f243 = data.f243.substring(0, 4) + '/' + data.f243.substring(4, 6) + '/' + data.f243.substring(6, 8)
        let nowdate = new Date()
        if(nowdate.getFullYear() == data.f243.substring(0, 4) && (nowdate.getMonth() + 1) == data.f243.substring(5, 7) && nowdate.getDate() == data.f243.substring(8, 10)){ //如果是今天 标红
          color = 1
        }
      }

      return quoteItem(data.f243, -1, color, -1)
    }
  },
  [QuoteItem.可转债转股溢价率]: {
    fid: ['f237', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f237, data.f152, data.f237, -1, '%')
    }
  },
  [QuoteItem.板块领涨股]: {
    fid: ['f128', 'f140', 'f141'],
    value: function(data:any):type_quote_item{
      return {
        source: data.f128,
        txt: data.f128,
        html: <a href={`//quote.eastmoney.com/unify/r/${data.f141}.${data.f140}`} target="_blank">{data.f128}</a>,
        color: '',
        blink_html: <span dangerouslySetInnerHTML={{__html:`<a href="//quote.eastmoney.com/unify/r/${data.f141}.${data.f140}" class="blinkblue">${data.f128}</a>`}}></span>
      }
    } 
  },
  [QuoteItem.板块领涨股概念版]: {
    fid: ['f128', 'f140', 'f141'],
    value: function(data:any):type_quote_item{
      return {
        source: data.f128,
        txt: data.f128,
        html: <a href={`//quote.eastmoney.com/unify/cr/${data.f141}.${data.f140}`} target="_blank">{data.f128}</a>,
        color: '',
        blink_html: <span dangerouslySetInnerHTML={{__html:`<a href="//quote.eastmoney.com/unify/cr/${data.f141}.${data.f140}" class="blinkblue">${data.f128}</a>`}}></span>
      }
    } 
  },  
  [QuoteItem.板块领涨股涨跌幅]: {
    fid: ['f136'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f136, 2, data.f136, -1, '%')
    } 
  },
  [QuoteItem.板块领跌股]: {
    fid: ['f207', 'f208', 'f209'],
    value: function(data:any):type_quote_item{
      return {
        source: data.f207,
        txt: data.f207,
        html: <a href={`//quote.eastmoney.com/unify/r/${data.f209}.${data.f208}`} target="_blank">{data.f207}</a>,
        color: '',
        blink_html: <span dangerouslySetInnerHTML={{__html:`<a href="//quote.eastmoney.com/unify/r/${data.f209}.${data.f208}" class="blinkblue">${data.f207}</a>`}}></span>
      }
    } 
  }, 
  [QuoteItem.板块资金流入最大股]: {
    fid: ['f204', 'f205', 'f206'],
    value: function(data:any):type_quote_item{
      return {
        source: data.f204,
        txt: data.f204,
        html: <a href={`//quote.eastmoney.com/unify/r/${data.f206}.${data.f205}`} target="_blank">{data.f204}</a>,
        color: '',
        blink_html: <span dangerouslySetInnerHTML={{__html:`<a href="//quote.eastmoney.com/unify/r/${data.f206}.${data.f205}" class="blinkblue">${data.f204}</a>`}}></span>
      }
    } 
  }, 
  [QuoteItem.主力净额]: {
    fid: ['f62'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f62, -1, data.f62, 4)
    }
  },
  [QuoteItem.买入价或买一价]: {
    fid: ['f31', 'f1'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f31, data.f1, 0, -1)
    }
  },
  [QuoteItem.卖出价或卖一价]: {
    fid: ['f32', 'f1'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f32, data.f1, 0, -1)
    }
  },
  [QuoteItem.交易时间]: {
    fid: ['f124'],
    value: function(data:any):type_quote_item{
      return {
        txt: transTradeTime(data.f124),
        html: <span>{transTradeTime(data.f124)}</span>,
        blink_html: <span>{transTradeTime(data.f124)}</span>,
        color: '',
        source: data.f124
      }
    } 
  },
  [QuoteItem.市盈率动态]: {
    fid: ['f9', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f9, data.f152, data.f9, -1)
    }
  },  
  [QuoteItem.总市值]: {
    fid: ['f20'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f20, -1, 0, 4)
    }
  },
  [QuoteItem.净资产]: {
    fid: ['f135'],
    value: function(data:any):type_quote_item{
      if(data.f135 === 0){
        data.f135 = '-'
      }
      return quoteItem(data.f135, -1, 0, 4)
    }
  },  
  [QuoteItem.净利润]: {
    fid: ['f45'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f45, -1, 0, 4)
    } 
  },
  [QuoteItem.净利率]: {
    fid: ['f129'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f129, -1, 0, -1, '%', 2)
    } 
  },
  [QuoteItem.净利润TTM]: {
    fid: ['f138'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f138, -1, 0, 4)
    } 
  },  
  [QuoteItem.总营业收入TTM]: {
    fid: ['f132'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f132, -1, 0, 4)
    } 
  },
  [QuoteItem.市销率TTM]: {
    fid: ['f130'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f130, -1, 0, -1, undefined, 2)
    } 
  },
  [QuoteItem.市现率TTM]: {
    fid: ['f131'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f131, -1, 0, -1, undefined, 2)
    } 
  },  
  [QuoteItem.净资产收益率TTM]: {
    fid: ['f137'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f137, -1, 0, -1, '%', 2)
    } 
  },   
  [QuoteItem.股息率]: {
    fid: ['f133'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f133, -1, 0, -1, '%', 2)
    } 
  },     
  [QuoteItem.每股净资产]: {
    fid: ['f113'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f113, -1, 0, 4, undefined, 2)
    }
  },
  [QuoteItem.市净率]: {
    fid: ['f23', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f23, data.f152, 0, -1)
    }
  },
  [QuoteItem.毛利率]: {
    fid: ['f49'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f49, -1, 0, -1, '%', 2)
    } 
  },
  [QuoteItem.主力净流入]: {
    fid: ['f62'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f62, -1, data.f62, 4)
    } 
  },
  [QuoteItem.主力净占比]: {
    fid: ['f184', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f184, data.f152, data.f184, -1, '%')
    }
  },
  [QuoteItem.超大单净流入]: {
    fid: ['f66'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f66, -1, data.f66, 4)
    } 
  },  
  [QuoteItem.超大单净占比]: {
    fid: ['f69', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f69, data.f152, data.f69, -1, '%')
    }
  },
  [QuoteItem.大单净流入]: {
    fid: ['f72'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f72, -1, data.f72, 4)
    } 
  },  
  [QuoteItem.大单净占比]: {
    fid: ['f75', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f75, data.f152, data.f75, -1, '%')
    }
  },
  [QuoteItem.中单净流入]: {
    fid: ['f78'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f78, -1, data.f78, 4)
    } 
  },  
  [QuoteItem.中单净占比]: {
    fid: ['f81', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f81, data.f152, data.f81, -1, '%')
    }
  },
  [QuoteItem.小单净流入]: {
    fid: ['f84'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f84, -1, data.f84, 4)
    } 
  },  
  [QuoteItem.小单净占比]: {
    fid: ['f87', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f87, data.f152, data.f87, -1, '%')
    }
  },
  [QuoteItem['5日主力净额']]: {
    fid: ['f164'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f164, -1, data.f164, 4)
    } 
  },  
  [QuoteItem['5日主力净占比']]: {
    fid: ['f165', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f165, data.f152, data.f165, -1, '%')
    }
  },
  [QuoteItem['5日超大单净额']]: {
    fid: ['f166'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f166, -1, data.f166, 4)
    } 
  },  
  [QuoteItem['5日超大单净占比']]: {
    fid: ['f167', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f167, data.f152, data.f167, -1, '%')
    }
  },

  [QuoteItem['5日大单净额']]: {
    fid: ['f168'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f168, -1, data.f168, 4)
    } 
  },  
  [QuoteItem['5日大单净占比']]: {
    fid: ['f169', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f169, data.f152, data.f169, -1, '%')
    }
  },

  [QuoteItem['5日中单净额']]: {
    fid: ['f170'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f170, -1, data.f170, 4)
    } 
  },  
  [QuoteItem['5日中单净占比']]: {
    fid: ['f171', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f171, data.f152, data.f171, -1, '%')
    }
  },

  [QuoteItem['5日小单净额']]: {
    fid: ['f172'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f172, -1, data.f172, 4)
    } 
  },  
  [QuoteItem['5日小单净占比']]: {
    fid: ['f173', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f173, data.f152, data.f173, -1, '%')
    }
  },
  [QuoteItem['10日主力净额']]: {
    fid: ['f174'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f174, -1, data.f174, 4)
    } 
  },  
  [QuoteItem['10日主力净占比']]: {
    fid: ['f175', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f175, data.f152, data.f175, -1, '%')
    }
  },
  [QuoteItem['10日超大单净额']]: {
    fid: ['f176'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f176, -1, data.f176, 4)
    } 
  },  
  [QuoteItem['10日超大单净占比']]: {
    fid: ['f177', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f177, data.f152, data.f177, -1, '%')
    }
  },
  [QuoteItem['10日大单净额']]: {
    fid: ['f178'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f178, -1, data.f178, 4)
    } 
  },  
  [QuoteItem['10日大单净占比']]: {
    fid: ['f179', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f179, data.f152, data.f179, -1, '%')
    }
  },
  [QuoteItem['10日中单净额']]: {
    fid: ['f180'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f180, -1, data.f180, 4)
    } 
  },  
  [QuoteItem['10日中单净占比']]: {
    fid: ['f181', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f181, data.f152, data.f181, -1, '%')
    }
  },

  [QuoteItem['10日小单净额']]: {
    fid: ['f182'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f182, -1, data.f182, 4)
    } 
  },  
  [QuoteItem['10日小单净占比']]: {
    fid: ['f183', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f183, data.f152, data.f183, -1, '%')
    }
  },
  [QuoteItem.AB股对应的名称]: {
    fid: ['f203'], //依赖字段
    value: function(data:any):type_quote_item{
      return {
        txt: data.f203,
        html: <span>{data.f203}</span>,
        color: '',
        blink_html: <span>{data.f203}</span>,
        source: data.f203
      }
    } 
  },
  [QuoteItem.AB股对应的市场]: {
    fid: ['f202'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f202,
        source: data.f202,
        html: <span>{data.f202}</span>,
        blink_html: <span>{data.f202}</span>,
        color: ''
      }
    } 
  },
  [QuoteItem.AB股对应的代码]: {
    fid: ['f201'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f201,
        html: <span>{data.f201}</span>,
        blink_html: <span>{data.f201}</span>,
        color: '',
        source: data.f201
      }
    } 
  },
  [QuoteItem.AB股对应的最新价]: {
    fid: ['f196', 'f200', 'f197'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f196, data.f200, data.f197, -1)
    } 
  },
  [QuoteItem.AB股对应的涨跌幅]: {
    fid: ['f197', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f197, data.f152, data.f197, -1, '%')
    } 
  },
  [QuoteItem.AB股比价]: {
    fid: ['f199', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f199, data.f152, 0, -1)
    } 
  },
  [QuoteItem.成交量]: {
    fid: ['f5'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f5, -1, 0, 4)
    }
  },
  [QuoteItem.最高价]: {
    fid: ['f15', 'f1', 'f18'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f15, data.f1, data.f15 - data.f18, -1)
    } 
  },
  [QuoteItem.最低价]: {
    fid: ['f16', 'f1', 'f18'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f16, data.f1, data.f16 - data.f18, -1)
    } 
  },
  [QuoteItem.换手率]: {
    fid: ['f8', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f8, data.f152, 0, -1, '%')
    }
  },
  [QuoteItem.市盈率TTM]: {
    fid: ['f9', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f9, data.f152, 0, -1)
    }
  },
  [QuoteItem.股东权益]: {
    fid: ['f58'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f58, -1, 0, 4)
    }
  },  
  [QuoteItem.行业板块的成分股数]: {
    fid: ['f134'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f134, -1, 0, -1)
    }
  },  
  [QuoteItem.总市值行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1020, -1, 0, -1)
    }
  },
  [QuoteItem.净资产行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1135, -1, 0, -1)
    }
  },
  [QuoteItem.净利润行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1045, -1, 0, -1)
    }
  },
  [QuoteItem.净利润TTM行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1138, -1, 0, -1)
    }
  },
  [QuoteItem.市盈率动态行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1009, -1, 0, -1)
    }
  },
  [QuoteItem.市盈率TTM行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1115, -1, 0, -1)
    }
  },
  [QuoteItem.市净率行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1023, -1, 0, -1)
    }
  },
  [QuoteItem.毛利率行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1049, -1, 0, -1)
    }
  },

  [QuoteItem.净利率行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1129, -1, 0, -1)
    }
  },
  [QuoteItem.ROE行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1037, -1, 0, -1)
    }
  },
  [QuoteItem.ROETTM行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1137, -1, 0, -1)
    }
  },
  [QuoteItem.股东权益行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1058, -1, 0, -1)
    }
  },
  [QuoteItem.总营业收入TTM行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1132, -1, 0, -1)
    }
  },
  [QuoteItem.市销率TTM行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1130, -1, 0, -1)
    }
  },
  [QuoteItem.市现率TTM行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1131, -1, 0, -1)
    }
  },
  [QuoteItem.股息率行业排名]: {
    fid: ['f1000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1133, -1, 0, -1)
    }
  },
  [QuoteItem.总市值行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3020, -1, 0, -1)
    }
  },
  [QuoteItem.净资产行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3135, -1, 0, -1)
    }
  },
  [QuoteItem.净利润行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3045, -1, 0, -1)
    }
  },
  [QuoteItem.净利润TTM行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3138, -1, 0, -1)
    }
  },
  [QuoteItem.市盈率动态行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3009, -1, 0, -1)
    }
  },
  [QuoteItem.市盈率TTM行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3115, -1, 0, -1)
    }
  },
  [QuoteItem.市净率行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3023, -1, 0, -1)
    }
  },
  [QuoteItem.毛利率行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3049, -1, 0, -1)
    }
  },
  [QuoteItem.净利率行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3129, -1, 0, -1)
    }
  },
  [QuoteItem.ROE行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3037, -1, 0, -1)
    }
  },
  [QuoteItem.ROETTM行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3137, -1, 0, -1)
    }
  },
  [QuoteItem.股东权益行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3058, -1, 0, -1)
    }
  },
  [QuoteItem.总营业收入TTM行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3132, -1, 0, -1)
    }
  },
  [QuoteItem.市销率TTM行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3130, -1, 0, -1)
    }
  },
  [QuoteItem.市现率TTM行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3131, -1, 0, -1)
    }
  },
  [QuoteItem.股息率行业排名四分位]: {
    fid: ['f3000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3133, -1, 0, -1)
    }
  },
  [QuoteItem.期权行权价]: {
    fid: ['f161', 'f330'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f161, data.f330, 0, -1)
    } 
  }, 
  [QuoteItem.今持仓]: {
    fid: ['f108'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f108, -1, 0, 4)
    } 
  }, 
  [QuoteItem.期权隐含波动率]: {
    fid: ['f249', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f249, data.f152, 0, -1, '%')
    } 
  },
  [QuoteItem.期权折溢价率]: {
    fid: ['f250', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f250, data.f152, 0, -1, '%')
    } 
  },  
  [QuoteItem.量比]: {
    fid: ['f10', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f10, data.f152, 0, -1)
    }
  },
  [QuoteItem.净资产收益率ROE]: {
    fid: ['f37'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f37, -1, 0, -1, '%', 2)
    } 
  },
  [QuoteItem.总市值行业平均]: {
    fid: ['f2000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f2020, -1, 0, 4)
    }
  },
  [QuoteItem.净资产行业平均]: {
    fid: ['f2000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f2135, -1, 0, 4)
    }
  },
  [QuoteItem.净利润行业平均]: {
    fid: ['f2000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f2045, -1, 0, 4)
    }
  },
  [QuoteItem.市盈率动态行业平均]: {
    fid: ['f2000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f2009, -1, 0, 4)
    }
  },
  [QuoteItem.市净率行业平均]: {
    fid: ['f2000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f2023, -1, 0, 4)
    }
  },  
  [QuoteItem.毛利率行业平均]: {
    fid: ['f2000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f2049, -1, 0, -1, '%', 2)
    } 
  },
  [QuoteItem.净利率行业平均]: {
    fid: ['f2000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f2129, -1, 0, -1, '%', 2)
    } 
  },  
  [QuoteItem.ROE行业平均]: {
    fid: ['f2000'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f2037, -1, 0, -1, '%', 2)
    } 
  },
  [QuoteItem.二级分类]: {
    fid: ['f19'],
    value: function(data:any):type_quote_item{ 
      return {
        txt: data.f19,
        html: <span>{data.f19}</span>,
        color: '',
        blink_html: <span>{data.f19}</span>,
        source: data.f19
      }
    } 
  }
}





/**
 * 单个行情的字段配置
 */
export const OneItemConfig = {
  [QuoteItem.名称]: {
    fid: ['f58'], //依赖字段
    value: function(data:any):type_quote_item{ //返回数据
      return {
        txt: data.f58,
        html: <span>{data.f58}</span>,
        color: '',
        blink_html: <span>{data.f58}</span>,
        source: data.f58
      }
    } 
  },
  [QuoteItem.扩位简称]: {
    fid: ['f734'],
    value: function(data:any):type_quote_item{
      // data.f734 = 'sdfsdfsdfsfdsdfsdf'
      return {
        txt: data.f734,
        html: <span>{data.f734}</span>,
        color: '',
        blink_html: <span>{data.f734}</span>,
        source: data.f734
      }
    } 
  },  
  [QuoteItem.市场]: {
    fid: ['f107'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f107,
        source: data.f107,
        html: <span>{data.f107}</span>,
        blink_html: <span>{data.f107}</span>,
        color: ''
      }
    } 
  },
  [QuoteItem.代码]: {
    fid: ['f57'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f57,
        html: <span>{data.f57}</span>,
        blink_html: <span>{data.f57}</span>,
        color: '',
        source: data.f57
      }
    } 
  },
  [QuoteItem.行情代码]: {
    fid: ['f57', 'f107'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f57 + '.' + data.f107,
        source: data.f57 + '.' + data.f107,
        html: <span>{data.f57 + '.' + data.f107}</span>,
        blink_html: <span>{data.f57 + '.' + data.f107}</span>,
        color: ''
      }
    } 
  },
  [QuoteItem.行情统一链接]: {
    fid: ['f57', 'f58', 'f107'],
    value: function(data:any):type_quote_item{
      return {
        txt: `//quote.eastmoney.com/unify/r/${data.f107}.${data.f57}`,
        html: <a href={`//quote.eastmoney.com/unify/r/${data.f107}.${data.f57}`} target="_blank">{data.f58}</a>,
        blink_html: <a href={`//quote.eastmoney.com/unify/r/${data.f107}.${data.f57}`} target="_blank">{data.f58}</a>,
        color: '',
        source: ''
      }
    } 
  },
  [QuoteItem.行情统一概念版链接]: {
    fid: ['f57', 'f58', 'f107'],
    value: function(data:any):type_quote_item{
      return {
        txt: `//quote.eastmoney.com/unify/cr/${data.f107}.${data.f57}`,
        html: <a href={`//quote.eastmoney.com/unify/cr/${data.f107}.${data.f57}`} target="_blank">{data.f58}</a>,
        blink_html: <a href={`//quote.eastmoney.com/unify/cr/${data.f107}.${data.f57}`} target="_blank">${data.f58}</a>,
        color: '',
        source: ''
      }
    } 
  },  
  [QuoteItem.最新价]: {
    fid: ['f43', 'f59', 'f169'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f43, data.f59, data.f169, -1)
    } 
  },
  [QuoteItem.最后价格]: {
    fid: ['f301', 'f60', 'f43', 'f59'],
    value: function(data:any):type_quote_item{
      if(data.f301 != 0){
        return quoteItem(data.f301, data.f59, 0, -1)
      }
      else if(data.f43 != 0){
        return quoteItem(data.f43, data.f59, 0, -1)
      }      
      else{
        return quoteItem(data.f60, data.f59, 0, -1)
      }
    } 
  },  
  [QuoteItem.涨跌幅]: {
    fid: ['f170', 'f152', 'f169'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f170, data.f152, data.f169, -1, '%')
    } 
  },
  [QuoteItem.涨跌额]: {
    fid: ['f169', 'f59'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f169, data.f59, data.f169, -1)
    } 
  },
  [QuoteItem.今开]: {
    fid: ['f46', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f46, data.f59, data.f46 - data.f60, -1)
    } 
  },
  [QuoteItem.股票期权今开]: {
    fid: ['f46', 'f59', 'f130'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f46, data.f59, data.f46 - data.f130, -1)
    } 
  },  
  [QuoteItem.昨收]: {
    fid: ['f60', 'f59'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f60, data.f59, 0, -1)
    } 
  },
  [QuoteItem.最高价]: {
    fid: ['f44', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f44, data.f59, data.f44 - data.f60, -1)
    } 
  },
  [QuoteItem.股票期权最高价]: {
    fid: ['f44', 'f59', 'f130'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f44, data.f59, data.f44 - data.f130, -1)
    } 
  },  
  [QuoteItem.最低价]: {
    fid: ['f45', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f45, data.f59, data.f45 - data.f60, -1)
    } 
  },
  [QuoteItem.股票期权最低价]: {
    fid: ['f45', 'f59', 'f130'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f45, data.f59, data.f45 - data.f130, -1)
    } 
  },  
  [QuoteItem.成交量]: {
    fid: ['f47'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f47, -1, 0, 4)
    }
  },
  [QuoteItem.盘后成交量]: {
    fid: ['f260'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f260, -1, 0, 4)
    }
  },  
  [QuoteItem.成交量带手]: {
    fid: ['f47'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f47, -1, 0, 4, '手')
    }
  },
  [QuoteItem.成交额]: {
    fid: ['f48'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f48, -1, 0, 4)
    }
  },
  [QuoteItem.盘后成交额]: {
    fid: ['f261'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f261, -1, 0, 4)
    }
  },  
  [QuoteItem.买入价或买一价]: {
    fid: ['f19', 'f59', 'f60', 'f532'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f19, data.f59, data.f19 - data.f60, -1)
    }
  },
  [QuoteItem.卖出价或卖一价]: {
    fid: ['f39', 'f59', 'f60', 'f532'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f39, data.f59, data.f39 - data.f60, -1)
    }
  },
  [QuoteItem.买一量]: {
    fid: ['f20', 'f532'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f20, -1, 0, 4)
    }
  },
  [QuoteItem.卖一量]: {
    fid: ['f40', 'f532'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f40, -1, 0, 4)
    }
  },
  [QuoteItem.买二价]: {
    fid: ['f17', 'f59', 'f60', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f17, data.f59, data.f17 - data.f60, -1)
    }
  },
  [QuoteItem.买二量]: {
    fid: ['f18', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f18, -1, 0, 4)
    }
  },
  [QuoteItem.买三价]: {
    fid: ['f15', 'f59', 'f60', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f15, data.f59, data.f15 - data.f60, -1)
    }
  },
  [QuoteItem.买三量]: {
    fid: ['f16', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f16, -1, 0, 4)
    }
  },
  [QuoteItem.买四价]: {
    fid: ['f13', 'f59', 'f60', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f13, data.f59, data.f13 - data.f60, -1)
    }
  },
  [QuoteItem.买四量]: {
    fid: ['f14', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f14, -1, 0, 4)
    }
  },
  [QuoteItem.买五价]: {
    fid: ['f11', 'f59', 'f60', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f11, data.f59, data.f11 - data.f60, -1)
    }
  },
  [QuoteItem.买五量]: {
    fid: ['f12', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f12, -1, 0, 4)
    }
  },
  [QuoteItem.卖二价]: {
    fid: ['f37', 'f59', 'f60', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f37, data.f59, data.f37 - data.f60, -1)
    }
  },
  [QuoteItem.卖二量]: {
    fid: ['f38', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f38, -1, 0, 4)
    }
  },
  [QuoteItem.卖三价]: {
    fid: ['f35', 'f59', 'f60', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f35, data.f59, data.f35 - data.f60, -1)
    }
  },
  [QuoteItem.卖三量]: {
    fid: ['f36', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f36, -1, 0, 4)
    }
  },  
  [QuoteItem.卖四价]: {
    fid: ['f33', 'f59', 'f60', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f33, data.f59, data.f33 - data.f60, -1)
    }
  },
  [QuoteItem.卖四量]: {
    fid: ['f34', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f34, -1, 0, 4)
    }
  },   
  [QuoteItem.卖五价]: {
    fid: ['f31', 'f59', 'f60', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f31, data.f59, data.f31 - data.f60, -1)
    }
  },
  [QuoteItem.卖五量]: {
    fid: ['f32', 'f531'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f32, -1, 0, 4)
    }
  },
  [QuoteItem.买一差量]: {
    fid: ['f211'],
    value: function(data:any):type_quote_item{
      if(data.f211 == '-' || data.f211 == 0 || data.f211 == undefined){
        data.f211 = ''
      }
      return quoteItem(data.f211, -1, data.f211, 4)
    }
  },
  [QuoteItem.买二差量]: {
    fid: ['f212'],
    value: function(data:any):type_quote_item{
      if(data.f212 == '-' || data.f212 == 0 || data.f212 == undefined){
        data.f212 = ''
      }
      return quoteItem(data.f212, -1, data.f212, 4)
    }
  },
  [QuoteItem.买三差量]: {
    fid: ['f213'],
    value: function(data:any):type_quote_item{
      if(data.f213 == '-' || data.f213 == 0 || data.f213 == undefined){
        data.f213 = ''
      }
      return quoteItem(data.f213, -1, data.f213, 4)
    }
  },
  [QuoteItem.买四差量]: {
    fid: ['f214'],
    value: function(data:any):type_quote_item{
      if(data.f214 == '-' || data.f214 == 0 || data.f214 == undefined){
        data.f214 = ''
      }
      return quoteItem(data.f214, -1, data.f214, 4)
    }
  },
  [QuoteItem.买五差量]: {
    fid: ['f215'],
    value: function(data:any):type_quote_item{
      if(data.f215 == '-' || data.f215 == 0 || data.f215 == undefined){
        data.f215 = ''
      }
      return quoteItem(data.f215, -1, data.f215, 4)
    }
  },
  [QuoteItem.卖一差量]: {
    fid: ['f210'],
    value: function(data:any):type_quote_item{
      if(data.f210 == '-' || data.f210 == 0 || data.f210 == undefined){
        data.f210 = ''
      }
      return quoteItem(data.f210, -1, data.f210, 4)
    }
  },
  [QuoteItem.卖二差量]: {
    fid: ['f209'],
    value: function(data:any):type_quote_item{
      if(data.f209 == '-' || data.f209 == 0 || data.f209 == undefined){
        data.f209 = ''
      }
      return quoteItem(data.f209, -1, data.f209, 4)
    }
  },
  [QuoteItem.卖三差量]: {
    fid: ['f208'],
    value: function(data:any):type_quote_item{
      if(data.f208 == '-' || data.f208 == 0 || data.f208 == undefined){
        data.f208 = ''
      }
      return quoteItem(data.f208, -1, data.f208, 4)
    }
  },
  [QuoteItem.卖四差量]: {
    fid: ['f207'],
    value: function(data:any):type_quote_item{
      if(data.f207 == '-' || data.f207 == 0 || data.f207 == undefined){
        data.f207 = ''
      }
      return quoteItem(data.f207, -1, data.f207, 4)
    }
  },
  [QuoteItem.卖五差量]: {
    fid: ['f206'],
    value: function(data:any):type_quote_item{
      if(data.f206 == '-' || data.f206 == 0 || data.f206 == undefined){
        data.f206 = ''
      }
      return quoteItem(data.f206, -1, data.f206, 4)
    }
  },
  [QuoteItem.内盘]: {
    fid: ['f161'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f161, -1, -1, 4)
    }
  },
  [QuoteItem.外盘]: {
    fid: ['f49'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f49, -1, 1, 4)
    }
  },
  [QuoteItem.振幅]: {
    fid: ['f171', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f171, data.f152, 0, -1, '%')
    }
  },
  [QuoteItem.量比]: {
    fid: ['f50', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f50, data.f152, 0, -1)
    }
  },
  [QuoteItem.交易时间]: {
    fid: ['f86'],
    value: function(data:any):type_quote_item{      
      return {
        txt: transTradeTime(data.f86),
        html: <span>{transTradeTime(data.f86)}</span>,
        blink_html: <span>{transTradeTime(data.f86)}</span>,
        color: '',
        source: data.f86
      }
    } 
  },
  [QuoteItem.交易时间不带括号]: {
    fid: ['f86'],
    value: function(data:any):type_quote_item{      
      return {
        txt: transTradeTime(data.f86, false, false),
        html: <span>{transTradeTime(data.f86, false, false)}</span>,
        blink_html: <span>{transTradeTime(data.f86, false, false)}</span>,
        color: '',
        source: data.f86
      }
    } 
  },  
  [QuoteItem.交易时间带星期]: {
    fid: ['f86'],
    value: function(data:any):type_quote_item{
      return {
        txt: transTradeTime(data.f86, true),
        html: <span>{transTradeTime(data.f86, true)}</span>,
        blink_html: <span>{transTradeTime(data.f86, true)}</span>,
        color: '',
        source: data.f86
      }
    } 
  },
  [QuoteItem.交易币种]: {
    fid: ['f600'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f600,
        source: data.f600,
        html: <span>{data.f600}</span>,
        color: '',
        blink_html: <span>{data.f600}</span>
      }
    }
  },
  [QuoteItem.交易币种_汇率]: {
    fid: ['f601', 'f154'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f601, data.f154, 0, -1)
    }
  },
  [QuoteItem.总股本]: {
    fid: ['f84'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f84, -1, 0, 4)
    }
  },
  [QuoteItem.流通股本]: {
    fid: ['f85'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f85, -1, 0, 4)
    }
  },
  [QuoteItem.换手率]: {
    fid: ['f168', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f168, data.f152, 0, -1, '%')
    }
  },
  [QuoteItem.每股收益TTM]: {
    fid: ['f108'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f108, -1, 0, 4, undefined, 2)
    }
  },
  [QuoteItem.总市值]: {
    fid: ['f116'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f116, -1, 0, 4)
    }
  },
  [QuoteItem.总市值_短]: {
    fid: ['f116'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f116, -1, 0, 2)
    }
  },
  [QuoteItem.流通市值]: {
    fid: ['f117'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f117, -1, 0, 4)
    }
  },
  [QuoteItem.流通市值_短]: {
    fid: ['f117'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f117, -1, 0, 2)
    }
  },
  [QuoteItem.市净率]: {
    fid: ['f167', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f167, data.f152, 0, -1)
    }
  },
  [QuoteItem.市盈率TTM]: {
    fid: ['f164', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f164, data.f152, 0, -1)
    }
  },
  [QuoteItem.市盈率动态]: {
    fid: ['f162', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f162, data.f152, 0, -1)
    }
  },
  [QuoteItem.市盈率静态]: {
    fid: ['f163', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f163, data.f152, 0, -1)
    }
  },  
  [QuoteItem.均价]: {
    fid: ['f71', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f71, data.f59, data.f71 - data.f60, -1)
    }
  },
   [QuoteItem.股票期权均价]: {
    fid: ['f71', 'f59', 'f130'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f71, data.f59, data.f71 - data.f130, -1)
    }
  }, 
  [QuoteItem.每股净资产]: {
    fid: ['f92', 'f59'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f92, -1, 0, -1, undefined, data.f59) //4, undefined, data.f152
    }
  },
  [QuoteItem.交易状态]: {
    fid: ['f292'],
    value: function(data:any):type_quote_item{
      return {
        txt: dealTradeStae(data.f292).txt,
        html: <span>{dealTradeStae(data.f292).txt}</span>,
        blink_html: <span>{dealTradeStae(data.f292).txt}</span>,
        color: dealTradeStae(data.f292).isopen ? 'price_up' : 'price_down',
        source: transTradeTime(data.f292)
      }
    }
  },
  [QuoteItem.异常交易状态]: {
    fid: ['f292'],
    value: function(data:any):type_quote_item{
      return {
        txt: errorTradeState(data.f292),
        html: <span>{errorTradeState(data.f292)}</span>,
        blink_html: <span>{errorTradeState(data.f292)}</span>,
        color: errorTradeState(data.f292) ? 'price_up' : 'price_down',
        source: errorTradeState(data.f292)
      }
    }
  },  
  [QuoteItem.JYS]: {
    fid: ['f112'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f112,
        html: <span>{data.f112}</span>,
        blink_html: <span>{data.f112}</span>,
        color: '',
        source: data.f112
      }
    }
  },
  [QuoteItem.委比]: {
    fid: ['f191', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f191, data.f152, data.f191, -1, '%')
    }
  },
  [QuoteItem.委差]: {
    fid: ['f192'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f192, -1, data.f192, 4)
    }
  },
  [QuoteItem.可转债名称或正股名称]: {
    fid: ['f264'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f264, -1, 0, -1)
    }
  },
  [QuoteItem.可转债市场或正股市场]: {
    fid: ['f263'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f263, -1, 0, -1)
    }
  },
  [QuoteItem.可转债代码或正股代码]: {
    fid: ['f262'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f262, -1, 0, -1)
    }
  },
  [QuoteItem.可转债最新价或正股最新价]: {
    fid: ['f267', 'f265', 'f268'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f267, data.f265, data.f268, -1)
    }
  },
  [QuoteItem.可转债涨跌额或正股涨跌额]: {
    fid: ['f267', 'f268', 'f152'],
    value: function(data:any):type_quote_item{
      let zde = '-' as any
      if(data.f268 != '-' && data.f267 != '-'){
        let zdf = data.f268 / Math.pow(10, data.f152 + 2)
        zde = (zdf*data.f267) / (1+zdf) 
      }
      return quoteItem(zde, data.f152, data.f268, -1)
    }
  },
  [QuoteItem.可转债涨跌幅或正股涨跌幅]: {
    fid: ['f268', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f268, data.f152, data.f268, -1, '%')
    }
  },
  [QuoteItem.可转债转股价]: {
    fid: ['f426', 'f265'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f426, data.f265, 0, -1)
    }
  },  
  [QuoteItem.可转债转股价值]: {
    fid: ['f427', 'f154'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f427, data.f154, 0, -1)
    }
  },    
  [QuoteItem.可转债申购代码]: {
    fid: ['f478'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f478, -1, 0, -1)
    }
  },
  [QuoteItem.可转债申购日期]: {
    fid: ['f425'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f425, -1, 0, -1)
    }
  },  
  [QuoteItem.可转债转股日期]: {
    fid: ['f433'],
    value: function(data:any):type_quote_item{
      return dealDateNumber(data.f433)
    }
  },  
  [QuoteItem.可转债转股溢价率]: {
    fid: ['f428', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f428, data.f152, data.f428, -1, '%')
    }
  },    
  [QuoteItem.可转债回售触发价]: {
    fid: ['f430', 'f265'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f430, data.f265, 0, -1)
    }
  },  
  [QuoteItem.可转债强赎触发价]: {
    fid: ['f431', 'f265'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f431, data.f265, 0, -1)
    }
  },
  [QuoteItem.可转债到期赎回价]: {
    fid: ['f432', 'f265'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f432, data.f265, 0, -1)
    }
  },
  [QuoteItem.纯债价值]: {
    fid: ['f424', 'f154'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f424, data.f154, 0, -1)
    }
  },  
  [QuoteItem.扩展类型]: {
    fid: ['f182'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f182, -1, 0, -1)
    }
  },
  [QuoteItem.债券最近成交方式]: {
    fid: ['f706'],
    value: function(data:any):type_quote_item{
      return quoteItem(BondCJ(data.f706), -1, 0, -1)
    }
  },
  [QuoteItem.债券最新匹配成交价]: {
    fid: ['f700', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f700, data.f59, data.f700 - data.f60, -1)
    }
  },
  [QuoteItem.债券当日匹配成交量]: {
    fid: ['f701'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f701, -1, 0, 4)
    }
  },
  [QuoteItem.债券最新YTM]: {
    fid: ['f703', 'f154'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f703, data.f154, 0, -1, '%', 0 , 2)
    }
  },
  [QuoteItem.质押式回购债券涨跌BP]: {
    fid: ['f169', 'f59'],
    value: function(data:any):type_quote_item{
      let f169 = data.f169
      if(typeof f169 == 'number'){
        f169 = f169 * 100
      }
      // console.info(f169, data.f59)
      
      return quoteItem(f169, data.f59, 0, -1, '', 0, 2)
    }
  },  
  [QuoteItem.债券涨跌BP]: {
    fid: ['f704', 'f152'],
    value: function(data:any):type_quote_item{
      let f704 = data.f704
      if(typeof f704 == 'number'){
        f704 = f704 / 100
      }      
      return quoteItem(f704, data.f152, 0, -1)
    }
  },    
  [QuoteItem.债券当日匹配成交额]: {
    fid: ['f702'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f702, -1, 0, 4)
    }
  },  
  [QuoteItem.债券加权平均涨跌BP]: {
    fid: ['f705', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f705, data.f152, 0, -1)
    }
  },    
  [QuoteItem.债券加权平均]: {
    fid: ['f71', 'f59'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f71, data.f59, 0, -1)
    }
  },    
  [QuoteItem.债券昨加权平均]: {
    fid: ['f721', 'f59'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f721, data.f59, 0, -1)
    }
  },    
  [QuoteItem.涨跌幅_5日]: {
    fid: ['f119', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f119, data.f152, data.f119, -1, '%')
    } 
  },
  [QuoteItem.涨跌幅_10日]: {
    fid: ['f291', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f291, data.f152, data.f291, -1, '%')
    } 
  },
  [QuoteItem.涨跌幅_20日]: {
    fid: ['f120', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f120, data.f152, data.f120, -1, '%')
    } 
  },
  [QuoteItem.涨跌幅_60日]: {
    fid: ['f121', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f121, data.f152, data.f121, -1, '%')
    } 
  },
  [QuoteItem.涨跌幅_今年以来]: {
    fid: ['f122', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f122, data.f152, data.f122, -1, '%')
    } 
  },
  [QuoteItem.营业总收入]: {
    fid: ['f183'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f183, -1, 0, 4)
    } 
  },
  [QuoteItem.营业总收入同比]: {
    fid: ['f184'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f184, -1, 0, -1, '%', 2)
    } 
  },
  [QuoteItem.净利润]: {
    fid: ['f105'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f105, -1, 0, 4)
    } 
  },
  [QuoteItem.净利润同比]: {
    fid: ['f185'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f185, -1, 0, -1, '%', 2)
    } 
  },
  [QuoteItem.毛利率]: {
    fid: ['f186'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f186, -1, 0, -1, '%', 2)
    } 
  },
  [QuoteItem.净利率]: {
    fid: ['f187'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f187, -1, 0, -1, '%', 2)
    } 
  },
  [QuoteItem.净资产收益率ROE]: {
    fid: ['f173'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f173, -1, 0, -1, '%', 2)
    } 
  },
  [QuoteItem.资产负债率]: {
    fid: ['f188'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f188, -1, 0, -1, '%', 2)
    } 
  },
  [QuoteItem.每股未分配利润]: {
    fid: ['f190'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f190, -1, 0, 4)
    } 
  },
  [QuoteItem.上市日期]: {
    fid: ['f189'],
    value: function(data:any):type_quote_item{
      return {
        txt: dealSSRQ(data.f189),
        html: <span>{dealSSRQ(data.f189)}</span>,
        blink_html: <span>{dealSSRQ(data.f189)}</span>,
        color: '',
        source: data.f189
      }
    } 
  },
  [QuoteItem.财报季度]: {
    fid: ['f62'],
    value: function(data:any):type_quote_item{
      return {
        txt: dealCBJD(data.f62),
        html: <span>{dealCBJD(data.f62)}</span>,
        blink_html: <span>{dealCBJD(data.f62)}</span>,
        color: '',
        source: data.f62
      }
    } 
  },
  [QuoteItem.每股收益]: {
    fid: ['f55'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f55, -1, 0, 4)
    } 
  },
  [QuoteItem.涨停价]: {
    fid: ['f51', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f51, data.f59, data.f51 - data.f60, -1)
    } 
  },
  [QuoteItem.跌停价]: {
    fid: ['f52', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f52, data.f59, data.f52 - data.f60, -1)
    } 
  },
  [QuoteItem.主力流入]: {
    fid: ['f135'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f135, -1, 1, 4)
    } 
  },
  [QuoteItem.主力流出]: {
    fid: ['f136'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f136, -1, -1, 4)
    } 
  },
  [QuoteItem.主力净流入]: {
    fid: ['f137'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f137, -1, data.f137, 4)
    } 
  },
  [QuoteItem.主力净占比]: {
    fid: ['f193', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f193, data.f152, data.f193, -1, '%')
    }
  },
  [QuoteItem.超大单流入]: {
    fid: ['f138'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f138, -1, 1, 4)
    } 
  },
  [QuoteItem.超大单流出]: {
    fid: ['f139'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f139, -1, -1, 4)
    } 
  },
  [QuoteItem.超大单净占比]: {
    fid: ['f194', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f194, data.f152, data.f194, -1, '%')
    }
  },  
  [QuoteItem.超大单净流入]: {
    fid: ['f140'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f140, -1, data.f140, 4)
    } 
  },  
  [QuoteItem.大单流入]: {
    fid: ['f141'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f141, -1, 1, 4)
    } 
  },
  [QuoteItem.大单流出]: {
    fid: ['f142'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f142, -1, -1, 4)
    } 
  },
  [QuoteItem.大单净流入]: {
    fid: ['f143'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f143, -1, data.f143, 4)
    } 
  }, 
  [QuoteItem.大单净占比]: {
    fid: ['f195', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f195, data.f152, data.f195, -1, '%')
    }
  }, 
  [QuoteItem.中单流入]: {
    fid: ['f144'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f144, -1, 1, 4)
    } 
  },
  [QuoteItem.中单流出]: {
    fid: ['f145'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f145, -1, -1, 4)
    } 
  },
  [QuoteItem.中单净流入]: {
    fid: ['f146'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f146, -1, data.f146, 4)
    } 
  },   
  [QuoteItem.中单净占比]: {
    fid: ['f196', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f196, data.f152, data.f196, -1, '%')
    }
  }, 
  [QuoteItem.小单流入]: {
    fid: ['f147'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f147, -1, 1, 4)
    } 
  },
  [QuoteItem.小单流出]: {
    fid: ['f148'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f148, -1, -1, 4)
    } 
  },  
  [QuoteItem.小单净流入]: {
    fid: ['f149'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f149, -1, data.f149, 4)
    } 
  },    
  [QuoteItem.小单净占比]: {
    fid: ['f197', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f197, data.f152, data.f197, -1, '%')
    }
  }, 
  [QuoteItem.上涨家数]: {
    fid: ['f113'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f113, -1, 1, -1)
    } 
  },
  [QuoteItem.下跌家数]: {
    fid: ['f114'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f114, -1, -1, -1)
    } 
  },
  [QuoteItem.平盘家数]: {
    fid: ['f115'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f115, -1, 0, -1)
    } 
  },
  [QuoteItem['52周最高价']]: {
    fid: ['f174', 'f59', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f174, data.f59, data.f174 - data.f60, -1)
    } 
  },
  [QuoteItem['52周最低价']]: {
    fid: ['f175', 'f59', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f175, data.f59, data.f175 - data.f60, -1)
    } 
  },  
  [QuoteItem.AB股对应的名称]: {
    fid: ['f271'], //依赖字段
    value: function(data:any):type_quote_item{ //返回数据
      return {
        txt: data.f271,
        html: <span>{data.f271}</span>,
        color: '',
        blink_html: <span>{data.f271}</span>,
        source: data.f271
      }
    } 
  },
  [QuoteItem.AB股对应的市场]: {
    fid: ['f270'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f270,
        source: data.f270,
        html: <span>{data.f270}</span>,
        blink_html: <span>{data.f270}</span>,
        color: ''
      }
    } 
  },
  [QuoteItem.AB股对应的代码]: {
    fid: ['f269'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f269,
        html: <span>{data.f269}</span>,
        blink_html: <span>{data.f269}</span>,
        color: '',
        source: data.f269
      }
    } 
  },
  [QuoteItem.AB股比价]: {
    fid: ['f276', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f276, data.f152, 0, -1)
    } 
  },
  [QuoteItem.A瑞士对应的名称]: {
    fid: ['f749'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f749,
        html: <span>{data.f749}</span>,
        color: '',
        blink_html: <span>{data.f749}</span>,
        source: data.f749
      }
    } 
  },
  [QuoteItem.A瑞士对应的市场]: {
    fid: ['f748'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f748,
        source: data.f748,
        html: <span>{data.f748}</span>,
        blink_html: <span>{data.f748}</span>,
        color: ''
      }
    } 
  },
  [QuoteItem.A瑞士对应的代码]: {
    fid: ['f747'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f747,
        html: <span>{data.f747}</span>,
        blink_html: <span>{data.f747}</span>,
        color: '',
        source: data.f747
      }
    } 
  },
  [QuoteItem.A瑞士比价]: {
    fid: ['f750', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f750, data.f152, 0, -1)
    } 
  },
  [QuoteItem.A瑞士溢价率]: {
    fid: ['f745', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f745, data.f152, 0, -1)
    } 
  },
  [QuoteItem.A瑞士对应的最新价]: {
    fid: ['f743', 'f746', 'f744'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f743, data.f746, data.f744, -1)
    } 
  },
  [QuoteItem.A瑞士对应的涨跌幅]: {
    fid: ['f744', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f744, data.f152, data.f744, -1, '%')
    } 
  },
  [QuoteItem.现手]: {
    fid: ['f452'],
    value: function(data:any):type_quote_item{
      if(isNaN(data.f452)){
        return quoteItem('-', -1, 0, 4)
      }
      return quoteItem(Math.abs(data.f452), -1, data.f452, 4)
    } 
  },  
  [QuoteItem.股票标识]: {
    fid: ['f177'],
    value: function(data:any):type_quote_item{ 
      return {
        txt: data.f177,
        html: <span>{data.f177}</span>,
        color: '',
        blink_html: <span>{data.f177}</span>,
        source: data.f177
      }
    } 
  },
  [QuoteItem.二级分类]: {
    fid: ['f111'],
    value: function(data:any):type_quote_item{ 
      return {
        txt: data.f111,
        html: <span>{data.f111}</span>,
        color: '',
        blink_html: <span>{data.f111}</span>,
        source: data.f111
      }
    } 
  },  
  [QuoteItem.相对大盘指数1月涨跌幅]: {
    fid: ['f123', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f123, data.f152, 0, -1, '%')
    } 
  }, 
  [QuoteItem.相对大盘指数3月涨跌幅]: {
    fid: ['f124', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f124, data.f152, 0, -1, '%')
    } 
  },
  [QuoteItem.相对大盘指数52周涨跌幅]: {
    fid: ['f125', 'f152'],
    value: function(data:any):type_quote_item{ 
      return quoteItem(data.f125, data.f152, 0, -1, '%')
    } 
  },
  [QuoteItem.股息率]: {
    fid: ['f126'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f126, -1, 0, -1, '%')
    } 
  },
  [QuoteItem.AH股对应的市场]: {
    fid: ['f257'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f257,
        source: data.f257,
        html: <span>{data.f257}</span>,
        blink_html: <span>{data.f257}</span>,
        color: ''
      }
    } 
  },
  [QuoteItem.AH股对应的代码]: {
    fid: ['f256'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f256,
        html: <span>{data.f256}</span>,
        blink_html: <span>{data.f256}</span>,
        color: '',
        source: data.f256
      }
    } 
  },
  [QuoteItem.AH股对应的名称]: {
    fid: ['f258'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f258,
        html: <span>{data.f258}</span>,
        blink_html: <span>{data.f258}</span>,
        color: '',
        source: data.f258
      }
    } 
  },  
  [QuoteItem.AH股对应的最新价]: {
    fid: ['f251', 'f255', 'f252'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f251, data.f255, data.f252, -1)
    } 
  },
  [QuoteItem.AH股对应的涨跌幅]: {
    fid: ['f252', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f252, data.f152, data.f252, -1, '%')
    } 
  },
  [QuoteItem.AH股对应的比价]: {
    fid: ['f254', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f254, data.f152, 0, -1)
    } 
  },
  [QuoteItem.AH股对应的溢价率]: {
    fid: ['f253', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f253, data.f152, data.f253, -1, '%')
    } 
  },
  [QuoteItem.A股对应GDR或GDR对应A股的市场]: {
    fid: ['f286'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f286,
        source: data.f286,
        html: <span>{data.f286}</span>,
        blink_html: <span>{data.f286}</span>,
        color: ''
      }
    } 
  },
  [QuoteItem.A股对应GDR或GDR对应A股的代码]: {
    fid: ['f285'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f285,
        html: <span>{data.f285}</span>,
        blink_html: <span>{data.f285}</span>,
        color: '',
        source: data.f285
      }
    } 
  },
  [QuoteItem.A股对应GDR或GDR对应A股的名称]: {
    fid: ['f287'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f287,
        html: <span>{data.f287}</span>,
        blink_html: <span>{data.f287}</span>,
        color: '',
        source: data.f287
      }
    } 
  }, 
  [QuoteItem.所属行业板块市场]: {
    fid: ['f199'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f199,
        source: data.f199,
        html: <span>{data.f199}</span>,
        blink_html: <span>{data.f199}</span>,
        color: ''
      }
    } 
  },
  [QuoteItem.所属行业板块代码]: {
    fid: ['f198'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f198,
        html: <span>{data.f198}</span>,
        blink_html: <span>{data.f198}</span>,
        color: '',
        source: data.f198
      }
    } 
  },
  [QuoteItem.卖十价]: {
    fid: ['f530', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f21, data.f59, data.f21 - data.f60, -1)
    }
  },
  [QuoteItem.卖九价]: {
    fid: ['f530', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f23, data.f59, data.f23 - data.f60, -1)
    }
  },
  [QuoteItem.卖八价]: {
    fid: ['f530', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f25, data.f59, data.f25 - data.f60, -1)
    }
  },
  [QuoteItem.卖七价]: {
    fid: ['f530', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f27, data.f59, data.f27 - data.f60, -1)
    }
  },
  [QuoteItem.卖六价]: {
    fid: ['f530', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f29, data.f59, data.f29 - data.f60, -1)
    }
  },  
  [QuoteItem.买十价]: {
    fid: ['f530', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f1, data.f59, data.f1 - data.f60, -1)
    }
  },
  [QuoteItem.买九价]: {
    fid: ['f530', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f3, data.f59, data.f3 - data.f60, -1)
    }
  },
  [QuoteItem.买八价]: {
    fid: ['f530', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f5, data.f59, data.f5 - data.f60, -1)
    }
  },
  [QuoteItem.买七价]: {
    fid: ['f530', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f7, data.f59, data.f7 - data.f60, -1)
    }
  },
  [QuoteItem.买六价]: {
    fid: ['f530', 'f59', 'f60'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f9, data.f59, data.f9 - data.f60, -1)
    }
  },  
  [QuoteItem.卖十量]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f22, -1, 0, 4)
    }
  },
  [QuoteItem.卖九量]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f24, -1, 0, 4)
    }
  },
  [QuoteItem.卖八量]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f26, -1, 0, 4)
    }
  },
  [QuoteItem.卖七量]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f28, -1, 0, 4)
    }
  },
  [QuoteItem.卖六量]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f30, -1, 0, 4)
    }
  }, 
  [QuoteItem.买十量]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f2, -1, 0, 4)
    }
  },
  [QuoteItem.买九量]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f4, -1, 0, 4)
    }
  },
  [QuoteItem.买八量]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f6, -1, 0, 4)
    }
  },
  [QuoteItem.买七量]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f8, -1, 0, 4)
    }
  },
  [QuoteItem.买六量]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f10, -1, 0, 4)
    }
  },   
  [QuoteItem.卖十席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      // console.info(data.f221)      
      return quoteItem(data.f221, -1, 0, 4)
    }
  },
  [QuoteItem.卖九席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f222, -1, 0, 4)
    }
  },
  [QuoteItem.卖八席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f223, -1, 0, 4)
    }
  },
  [QuoteItem.卖七席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f224, -1, 0, 4)
    }
  },  
  [QuoteItem.卖六席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f225, -1, 0, 4)
    }
  },
  [QuoteItem.卖五席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f226, -1, 0, 4)
    }
  },
  [QuoteItem.卖四席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f227, -1, 0, 4)
    }
  },
  [QuoteItem.卖三席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f228, -1, 0, 4)
    }
  }, 
  [QuoteItem.卖二席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f229, -1, 0, 4)
    }
  },
  [QuoteItem.卖一席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f230, -1, 0, 4)
    }
  }, 
  [QuoteItem.买十席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f240, -1, 0, 4)
    }
  },
  [QuoteItem.买九席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f239, -1, 0, 4)
    }
  },
  [QuoteItem.买八席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f238, -1, 0, 4)
    }
  },
  [QuoteItem.买七席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f237, -1, 0, 4)
    }
  },  
  [QuoteItem.买六席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f236, -1, 0, 4)
    }
  },
  [QuoteItem.买五席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f235, -1, 0, 4)
    }
  },
  [QuoteItem.买四席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f234, -1, 0, 4)
    }
  },
  [QuoteItem.买三席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f233, -1, 0, 4)
    }
  }, 
  [QuoteItem.买二席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f232, -1, 0, 4)
    }
  },
  [QuoteItem.买一席位数]: {
    fid: ['f530'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f231, -1, 0, 4)
    }
  }, 
  [QuoteItem.期权隐含波动率]: {
    fid: ['f412', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f412, data.f152, 0, -1, '%')
    } 
  },
  [QuoteItem.期权折溢价率]: {
    fid: ['f413', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f413, data.f152, 0, -1, '%')
    } 
  },  
  [QuoteItem.期权剩余日期]: {
    fid: ['f407'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f407, -1, 0, -1)
    } 
  },
  [QuoteItem.期权内在价值]: {
    fid: ['f411', 'f59'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f411, data.f59, 0, -1)
    } 
  },  
  [QuoteItem.期权购沽对应的证券名称]: {
    fid: ['f406'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f406, -1, 0, -1)
    } 
  }, 
  [QuoteItem.期权购沽对应的市场]: {
    fid: ['f405'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f405, -1, 0, -1)
    } 
  },
  [QuoteItem.期权购沽对应的代码]: {
    fid: ['f404'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f404, -1, 0, -1)
    } 
  },

  
  [QuoteItem.昨结算]: {
    fid: ['f130', 'f59'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f130, data.f59, 0, -1)
    } 
  }, 
  [QuoteItem.今结算]: {
    fid: ['f131', 'f59'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f131, data.f59, 0, -1)
    } 
  },     
  [QuoteItem.今持仓]: {
    fid: ['f133'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f133, -1, 0, 4)
    } 
  },  
  [QuoteItem.昨持仓]: {
    fid: ['f132'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f132, -1, 0, 4)
    } 
  },    
  [QuoteItem.期权购沽对应的最新价]: {
    fid: ['f401', 'f403', 'f402'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f401, data.f403, data.f402, -1)
    } 
  },
  [QuoteItem.期权购沽对应的涨跌幅]: {
    fid: ['f402', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f402, data.f152, data.f402, -1, '%')
    } 
  },
  [QuoteItem.期权合约单位]: {
    fid: ['f408'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f408, -1, 0, -1)
    } 
  },
  [QuoteItem.期权行权价]: {
    fid: ['f410', 'f481'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f410, data.f481, 0, -1)
    } 
  },  
  [QuoteItem.期权到期日]: {
    fid: ['f409'],
    value: function(data:any):type_quote_item{
      // return quoteItem(data.f409, -1, 0, -1)
      let showtxt = data.f409.toString()
      if(showtxt.length > 7){
        let year = showtxt.substring(0, 4)
        let thisyear = (new Date()).getFullYear()
        if(year == thisyear.toString()){
          showtxt = showtxt.substring(4, 6) + '-' + showtxt.substring(6, 8)
        }
        else{
          showtxt = showtxt.substring(0, 4) + '-' +  showtxt.substring(4, 6) + '-' + showtxt.substring(6, 8)
        }
      }

      return {
        txt: showtxt,
        html: <span>{showtxt}</span>,
        color: '',
        blink_html: <></>,
        source: data.f409
      }
    } 
  },
  [QuoteItem.期权Delta]: {
    fid: ['f414', 'f154'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f414, data.f154, 0, -1)
    } 
  }, 
  [QuoteItem.期权Gamma]: {
    fid: ['f415', 'f154'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f415, data.f154, 0, -1)
    } 
  },   
  [QuoteItem.期权Vega]: {
    fid: ['f416', 'f154'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f416, data.f154, 0, -1)
    } 
  }, 
  [QuoteItem.期权Theta]: {
    fid: ['f417', 'f154'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f417, data.f154, 0, -1)
    } 
  }, 
  [QuoteItem.期权Rho]: {
    fid: ['f418', 'f154'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f418, data.f154, 0, -1)
    } 
  },   
  [QuoteItem['30日波动率']]: {
    fid: ['f419', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f419, data.f152, 0, -1, '%')
    } 
  },
  [QuoteItem['60日波动率']]: {
    fid: ['f420', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f420, data.f152, 0, -1, '%')
    } 
  },
  [QuoteItem['120日波动率']]: {
    fid: ['f421', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f421, data.f152, 0, -1, '%')
    } 
  },
  [QuoteItem['240日波动率']]: {
    fid: ['f422', 'f152'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f422, data.f152, 0, -1, '%')
    } 
  },  
  [QuoteItem.仓差]: {
    fid: ['f134'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f134, -1, 0, 4)
    } 
  },    
  [QuoteItem.注册股本]: {
    fid: ['f277'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f277, -1, 0, 4)
    } 
  },  
  [QuoteItem.发行股本]: {
    fid: ['f278'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f278, -1, 0, 4)
    } 
  },
  [QuoteItem.成交笔数]: {
    fid: ['f296'],
    value: function(data:any):type_quote_item{
      return quoteItem(data.f296, -1, 0, 4)
    } 
  },  
  [QuoteItem.是否同股同权]: {
    fid: ['f279'],
    value: function(data:any):type_quote_item{
      let txt = '-'
      if(data.f279 == 1){
        txt = '是'
      }
      else if(data.f279 == 0){
        txt = '否'
      }

      return {
        txt,
        html: <span>{txt}</span>,
        color: '',
        blink_html: <span>{txt}</span>,
        source: data.f279
      }
    } 
  },
  [QuoteItem.是否表决权差异]: {
    fid: ['f279'],
    value: function(data:any):type_quote_item{
      let txt = '-'
      if(data.f279 == 0){
        txt = '是'
      }
      else if(data.f279 == 1){
        txt = '否'
      }

      return {
        txt,
        html: <span>{txt}</span>,
        color: '',
        blink_html: <span>{txt}</span>,
        source: data.f279
      }
    } 
  },  
  [QuoteItem.是否尚未盈利]: {
    fid: ['f288'],
    value: function(data:any):type_quote_item{
      let txt = '-'
      if(data.f288 == 1){
        txt = '是'
      }
      else if(data.f288 == 0){
        txt = '否'
      }

      return {
        txt,
        html: <span>{txt}</span>,
        color: '',
        blink_html: <span>{txt}</span>,
        source: data.f288
      }
    } 
  }, 
  [QuoteItem.是否盈利]: {
    fid: ['f288'],
    value: function(data:any):type_quote_item{
      let txt = '-'
      if(data.f288 == 0){
        txt = '是'
      }
      else if(data.f288 == 1){
        txt = '否'
      }

      return {
        txt,
        html: <span>{txt}</span>,
        color: '',
        blink_html: <span>{txt}</span>,
        source: data.f288
      }
    } 
  },  
  [QuoteItem.是否注册制]: {
    fid: ['f294'],
    value: function(data:any):type_quote_item{      
      let txt = '-'
      if(data.f294 == 1){
        txt = '是'
      }
      else if(data.f294 == 0){
        txt = '否'
      }

      return {
        txt,
        html: <span>{txt}</span>,
        color: '',
        blink_html: <span>{txt}</span>,
        source: data.f294
      }
    } 
  }, 
  [QuoteItem.是否具有协议控制架构]: {
    fid: ['f295'],
    value: function(data:any):type_quote_item{
      let txt = '-'
      if(data.f295 == 1){
        txt = '是'
      }
      else if(data.f295 == 0){
        txt = '否'
      }

      return {
        txt,
        html: <span>{txt}</span>,
        color: '',
        blink_html: <span>{txt}</span>,
        source: data.f295
      }
    } 
  },
  [QuoteItem.做市商数]: {
    fid: ['f740'],
    value: function(data:any):type_quote_item{
      let txt = '-'
      if(data.f740 != undefined){
        txt = data.f740.toString()
      }

      return {
        txt,
        html: <span>{txt}</span>,
        color: '',
        blink_html: <span>{txt}</span>,
        source: data.f740
      }
    } 
  }, 
  [QuoteItem.港股对应人民币计价或者反过来对应的市场]: {
    fid: ['f752'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f752,
        source: data.f752,
        html: <span>{data.f752}</span>,
        blink_html: <span>{data.f752}</span>,
        color: ''
      }
    } 
  },
  [QuoteItem.港股对应人民币计价或者反过来对应的代码]: {
    fid: ['f751'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f751,
        html: <span>{data.f751}</span>,
        blink_html: <span>{data.f751}</span>,
        color: '',
        source: data.f751
      }
    } 
  },
  [QuoteItem.关键期国债价差]: {
    fid: ['f19', 'f39', 'f59'],
    value: function(data:any):type_quote_item{

      if(typeof data.f19 != 'number' || typeof data.f39 != 'number'){
        return {
          txt: '-',
          html: <>-</>,
          blink_html: <>-</>,
          color: '',
          source: ''
        } 
      }

      let jc = (data.f19 - data.f39) / Math.pow(10, data.f59)
      let jc_str = (jc * 100).toFixed(data.f59 - 2) + 'BP'

      return {
        txt: jc_str,
        html: <span>{jc_str}</span>,
        blink_html: <span>{jc_str}</span>,
        color: '',
        source: jc
      }
    } 
  },  
  [QuoteItem.关键期国债期限]: {
    fid: ['f754'],
    value: function(data:any):type_quote_item{
      return {
        txt: data.f754,
        html: <span>{data.f754}</span>,
        color: '',
        blink_html: <span>{data.f754}</span>,
        source: data.f754
      }
    } 
  },
  [QuoteItem.关键期国债国家]: {
    fid: ['f753', 'f759'],
    value: function(data:any):type_quote_item{
      
      let txt = data.f759

      if(txt == undefined){
        txt = data.f753
        if(txt == undefined){
          txt = '-'
        }
        else if(txt == 'US'){
          txt = '美国'
        }
        else if(txt == 'CN'){
          txt = '中国'
        }        
        else{
          txt = '-'
        }
      }


      return {
        txt: txt,
        html: <span>{txt}</span>,
        color: '',
        blink_html: <span>{txt}</span>,
        source: txt
      }
    } 
  },  

}

/** 债券-成交方式 */
export function BondCJ(state:number){
  let text = '-'
  switch (state) {
    case 1:
      text = '匹配成交'
      break;
    case 2:
      text = '协商成交'
      break;
    case 4:
      text = '点击成交'
      break;
    case 8:
      text = '意向申报'
      break;
    case 16:
      text = '询价成交'
      break;
    case 32:
      text = '竞买成交'
      break;  
    case 64:
      text = '匹配成交大额'
      break;                  
    default:
      break;
  }
  return text
}


/** 交易状态 */
function dealTradeStae(input:number) {
  let returnojb = {
    txt: '',
    isopen: false
  }
  switch (input) {
    case 1:
      returnojb.txt = '开盘竞价'
      returnojb.isopen = true
      break;
    case 2:
      returnojb.txt = '交易中'
      returnojb.isopen = true
      break;
    case 3:
      returnojb.txt = '盘中休市'
      break;
    case 4:
      returnojb.txt = '收盘竞价'
      break; 
    case 5:
      returnojb.txt = '已收盘'
      break;
    case 6:
      returnojb.txt = '停牌'
      break;
    case 7:
      returnojb.txt = '退市'
      break;
    case 8:
      returnojb.txt = '暂停上市'
      break;
    case 9:
      returnojb.txt = '未上市'
      break;
    case 10:
      returnojb.txt = '未开盘'
      break;
    case 11:
      returnojb.txt = '盘前' //美股
      returnojb.isopen = true
      break;
    case 12:
      returnojb.txt = '盘后' //美股
      returnojb.isopen = true
      break; 
    case 13:
      returnojb.txt = '休市'
      break;
    case 14:
      returnojb.txt = '盘中停牌'
      break;
    case 15:
      returnojb.txt = '非交易代码'
      break;
    case 16:
      returnojb.txt = '波动性中断'
      break;
    case 17:
      returnojb.txt = '盘后交易启动' //沪深
      returnojb.isopen = true
      break; 
    case 18:
      returnojb.txt = '盘后集中撮合交易' //沪深
      returnojb.isopen = true
      break;
    case 19:
      returnojb.txt = '盘后固定价格交易' //沪深
      returnojb.isopen = true
      break;          
    default:
      break;
  }

  return returnojb
}

/**
 * 异常交易状态
 * @param input 
 */
function errorTradeState(input:number){
  let txt = ''

  switch (input) {
    case 6:
      txt = '停牌'
      break;
    case 7:
      txt = '已退市'
      break;
    case 8:
      txt = '暂停上市'
      break;
    case 9:
      txt = '未上市'
      break;         
    default:
      break;
  }

  return txt
}

/**
 * 转化行情数据为需要的格式
 * @param input 输入数据
 * @param fixed 缩小倍数并保留小数位数
 * @param colornumber 颜色 大于0红色 小于0 绿色 等于0 灰色 null不设置颜色
 * @param format_number 格式化数据 万 亿 万亿 并设置最大宽度
 * @param hz 后缀
 * @param tofixed 保留小数位数
 * @returns 
 */
export function quoteItem(input:number|string|undefined, fixed:number, colornumber: number | null, format_number: number, hz?:string, tofixed?: number, fixed_tofixed?:number):type_quote_item{

    if(input == undefined){
      return {
        txt: '',
        color: '',
        html: <>-</>,
        blink_html : <>-</>,
        source: input
      }
    }

    if(input == '-'){
      return {
        txt: '-',
        color: '',
        html: <>-</>,
        blink_html: <>-</>,
        source: input
      }
    }
    
    let return_obj: type_quote_item = {
      txt: input.toString(),
      color: 'price_draw',
      html: <></>,
      blink_html: <></>,
      source: input
    }

    let blink_color = ''

    //处理放大倍数
    if(typeof input == 'number' && fixed > 0){
      return_obj.txt = (input / (Math.pow(10, fixed))).toFixed(fixed)
    }
    if(typeof input == 'number' && tofixed != undefined && tofixed > 0){
      return_obj.txt = input.toFixed(tofixed)
    }
    if(typeof input == 'number' && fixed > 0 && fixed_tofixed != undefined && fixed_tofixed > 0){
      return_obj.txt = (input / (Math.pow(10, fixed))).toFixed(fixed_tofixed)
    }    
    
    //格式化
    if(typeof input == 'number' && format_number > 0){
      return_obj.txt = formatNumMaxWidth(input, format_number)
    }    

    //颜色
    if(colornumber != null && colornumber > 0){
      return_obj.color = 'price_up'
      blink_color = 'blinkred'
    }
    else if(colornumber != null && colornumber < 0){
      return_obj.color = 'price_down'
      blink_color = 'blinkgreen'
    }
    else if(colornumber != null && colornumber == 0){
      return_obj.color = 'price_draw'
      blink_color = 'blinkblue'
    }
    else{
      return_obj.color = ''
      blink_color = ''      
    }

    //后缀
    if(hz != undefined){
      return_obj.txt += hz
    }

    return_obj.html = <span className={return_obj.color}>{return_obj.txt}</span>
    return_obj.blink_html = <span dangerouslySetInnerHTML={{__html:`<span class="${return_obj.color} ${blink_color}">${return_obj.txt}</span>`}}></span>

    return return_obj
  }

/**
 * 处理行情接口出的数字型日期
 * @param inputnum 
 */
export function dealDateNumber(inputnum:number | string){

  if(typeof inputnum == 'string' && inputnum.length >= 8){
    return {
      txt: inputnum,
      color: '',
      html: <></>,
      blink_html : <></>,
      source: inputnum
    } as type_quote_item    
  }

  inputnum = inputnum.toString()

  return {
    txt: `${inputnum.substring(0, 4)}-${inputnum.substring(4, 6)}-${inputnum.substring(6, 8)}`,
    color: '',
    html: <></>,
    blink_html : <></>,
    source: inputnum
  } as type_quote_item
}

/**
 * 控制宽度的格式化数字
 * @param num 
*/
export function formatNumMaxWidth(num:any, maxwidth:number){

  if(maxwidth < 0){
    return num
  }

  if(isNaN(num) || num === ''){
    return num
  }

  var hz = '';
  if(num >= 1000000000000||num <= -1000000000000){
    num = num / 1000000000000;
    hz = '万亿';
  }    
  if(num >= 100000000||num <= -100000000){
    num = num / 100000000;
    hz = '亿';
  }
  else if(num >= 10000||num <= -10000){
    num = num / 10000;
    hz = '万';
  }

  //整数部分长度
  let zs_length = num.toString().indexOf('.') 
  if(num.toString().indexOf('-') == 0) zs_length--  //如果是负数，整数部分长度减1
  

  if(zs_length < 0){
    return num + hz
  }

  let fixed = maxwidth - zs_length
  if(fixed < 0) fixed = 0

  
  let tonum = num.toFixed(fixed)

  
  // if(tonum.endsWith('000')){
  //   tonum  = num.toFixed(fixed + 3)
  // }
  // else if(tonum.endsWith('00')){
  //   tonum  = num.toFixed(fixed + 2)
  // }
  // else if(tonum.endsWith('0')){
  //   tonum  = num.toFixed(fixed + 1)
  // }


  return tonum + hz;
}

/**
 * 处理交易时间
 * @param trade_time 输入行情时间
 * @param showxq 是否显示星期
 * @param showkh 是否显示括号
 * @returns 
 */
export function transTradeTime(trade_time:number, showxq = false, showkh = true):string{
  
  if(isNaN(trade_time)){
    return '-'
  }

  try {
    var d = new Date(trade_time * 1000); 

    let xq = ''
    if(showxq){
      switch (d.getDay()) {
        case 0:
          xq = ' 星期日'
          break;
        case 1:
          xq = ' 星期一'
          break;      
        case 2:
          xq = ' 星期二'
          break;
        case 3:
          xq = ' 星期三'
          break;  
        case 4:
          xq = ' 星期四'
          break;      
        case 5:
          xq = ' 星期五'
          break;
        case 6:
          xq = ' 星期六'
          break;           
      }
    }

    var jysj = d.getFullYear() + '-' + (("0" + (d.getMonth() + 1)).slice(-2)) + '-' + (("0" + (d.getDate())).slice(-2)) + xq + ' ' + ("0" + (d.getHours())).slice(-2) + ':' + ("0" + (d.getMinutes())).slice(-2) + ':' + ("0" + (d.getSeconds())).slice(-2);
    
    if( showkh ) return '（' + jysj + '）'
    return jysj

  } catch (e) {
      return '-'
  }
}

/**
 * 处理上市日期
 */
function dealSSRQ(ssrq: any){
  if(ssrq > 0 && ssrq.toString().length > 7){
    ssrq = ssrq.toString()
    return ssrq.substring(0, 4) + '-' + ssrq.substring(4, 6) + '-' + ssrq.substring(6, 8)
  }

  return ssrq
}

/**
 * 处理财报季度
 */
function dealCBJD(cbjd: any){
  if(cbjd > 0){
    if(cbjd == 1){
      return '一'
    }
    if(cbjd == 2){
      return '二'
    }
    if(cbjd == 3){
      return '三'
    }
    if(cbjd == 4){
      return '四'
    }
  }

  return cbjd
}