/**
 * 侧边 - 分时成交
 */

import React, { useState, useEffect } from "react"
import quote_web,{type_fscj} from "../../../modules/quote_info/web";
import { SiderTabBox } from "../sider_tab_box";
import './index.less'
import { sendStat } from "../../../modules/bigdata_stat";

/** 分时成交 */
function SiderTrade(props:{
  title?: string,
  quotecode:string,
  size?: number,
  showbig?: boolean,
  tableclassname?:string,
  showmore?: boolean,
  show_title_more?: boolean
}) {

  const [showdata, SetShowData] = useState([] as Array<type_fscj>)

  const size = props.size || 13

  const getdata = async()=>{
    let back = await quote_web.fscj(props.quotecode, size)
    if(back){
      // console.info(back)
      
      SetShowData(back)
    }
    quote_web.fscj_sse(props.quotecode, function(data: any){
      SetShowData(data)
    }, size)    
  }

  useEffect(()=>{
    getdata()
  }, [])


    return (
      <SiderTabBox mt={10} boxdata={[{
        tab_html: props.show_title_more ? <a href={`//quote.eastmoney.com/f1.html?newcode=${props.quotecode}`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.fscj.fscj')}}>{props.title || '分时成交'}</a>:<>{props.title || '分时成交'}</>,
        more_html: props.show_title_more ? <a href={`//quote.eastmoney.com/f1.html?newcode=${props.quotecode}`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.fscj.fscjgd')}}>更多</a> : <></>
      }]}>
        <>
          <table className={`table1 pl8 ${props.tableclassname}`}>
            {
              !props.showbig &&
              <thead>
                <tr>
                  <th className="tdl">时间</th>
                  <th className="tdl">价格</th>
                  <th className="tdl">成交量</th>
                </tr>
              </thead>            
            }
            {
              props.showbig &&
              <thead>
                <tr>
                  <td colSpan={3} style={{textAlign: 'center'}}><span className="price_down2">■</span>大于20万内盘 <span className="price_up2">■</span>大于20万外盘</td>
                </tr>
              </thead>            
            }
            <tbody>
              {showdata.map((v,index)=>
                <tr key={index}>
                  <td>{v.cjsj}</td>
                  <td>
                    <span dangerouslySetInnerHTML={{__html: v.cjj.html}}></span>
                    <span dangerouslySetInnerHTML={{__html: v.price_zd.html}}></span>
                  </td>
                  <td>
                    {
                      !props.showbig &&
                      <span dangerouslySetInnerHTML={{__html: v.cjl.html}}></span>
                    }
                    {
                      props.showbig &&
                      <span dangerouslySetInnerHTML={{__html: v.cjl2.html}}></span>
                    }
                  </td>
                </tr>              
              )}
            {
              showdata.length < size && Array.from(new Array(size - showdata.length)).map((v, index)=>
                <tr key={index}>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>              
              )              
            }
            {
              props.showmore &&
              <tr>
                <td className="tdbmore" colSpan={3}><a href={`//quote.eastmoney.com/f1.html?newcode=${props.quotecode}`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.fscj.ckgd')}}>点击查看更多分时成交</a></td>
              </tr>              
            }
            </tbody>
          </table>      
        </>
       
      </SiderTabBox>
    )
  
}

export default SiderTrade



/** 概念版分时成交 */
export function ConceptSiderTrade(props:{
  quotecode: string,
  size?: number,
}) {

  const [showdata, SetShowData] = useState([] as Array<type_fscj>)

  const size = props.size || 13

  const getdata = async()=>{
    let back = await quote_web.fscj(props.quotecode, size)
    if(back){
      // console.info(back)
      
      SetShowData(back)
    }
    quote_web.fscj_sse(props.quotecode, function(data: any){
      SetShowData(data)
    }, size)    
  }

  useEffect(()=>{
    getdata()
  }, [])

    return (

        <>
          <table>
            <thead>
              <tr>
                <td colSpan={3}><span className="price_block_down2"></span>大于20万内盘 <span className="price_block_up2"></span>大于20万外盘</td>
              </tr>
            </thead>            
            <tbody>
              {showdata.map((v,index)=>
                <tr key={index}>
                  <td className="time">{v.cjsj}</td>
                  <td className="price">
                    <span dangerouslySetInnerHTML={{__html: v.cjj.html}}></span>
                    <span>
                      {
                        v.jtcolor.txt == '1' &&
                        <span className="fscj_jt_up"></span>
                      }
                      {
                        v.jtcolor.txt == '-1' &&
                        <span className="fscj_jt_down"></span>
                      }
                    </span>
                  </td>
                  <td className="zcl">
                    <span dangerouslySetInnerHTML={{__html: v.cjl2.html}}></span>
                  </td>
                </tr>              
              )}
            {
              showdata.length < size && Array.from(new Array(size - showdata.length)).map((v, index)=>
                <tr key={index}>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>              
              )              
            }
            </tbody>
          </table>      
        </>
    )
  
}


/** 期权分时成交 */
export function QiquanSiderTrade(props:{
  title?: string,
  quotecode:string,
  size?: number,
  tableclassname?:string,
  showmore?: boolean,
  show_title_more?: boolean
}) {

  const [showdata, SetShowData] = useState([] as Array<type_fscj>)

  const size = props.size || 13

  const getdata = async()=>{
    let back = await quote_web.fscj(props.quotecode, size)
    if(back){
      // console.info(back)
      
      SetShowData(back)
    }
    quote_web.fscj_sse(props.quotecode, function(data: any){
      SetShowData(data)
    }, size)    
  }

  useEffect(()=>{
    getdata()
  }, [])


    return (
      <SiderTabBox mt={10} boxdata={[{
        tab_html: props.show_title_more ? <a href={`//quote.eastmoney.com/f1.html?newcode=${props.quotecode}`} target="_blank">{props.title || '分时成交'}</a>:<>{props.title || '分时成交'}</>,
        more_html: props.show_title_more ? <a href={`//quote.eastmoney.com/f1.html?newcode=${props.quotecode}`} target="_blank">更多</a> : <></>
      }]}>
        <>
          <table className={`table1 pl2 ${props.tableclassname}`}>
            <thead>
              <tr>
                <th className="tdl">时间</th>
                <th className="tdl">价格</th>
                <th className="tdl">现量</th>
                <th className="tdl">仓差</th>
                <th className="tdl">性质</th>
              </tr>
            </thead>            
            <tbody>
              {showdata.map((v,index)=>
                <tr key={index}>
                  <td>{v.cjsj}</td>
                  <td>
                    <span dangerouslySetInnerHTML={{__html: v.cjj.html}}></span>
                    <span dangerouslySetInnerHTML={{__html: v.price_zd.html}}></span>
                  </td>
                  <td><span dangerouslySetInnerHTML={{__html: v.qq.xl.html}}></span></td>
                  <td><span dangerouslySetInnerHTML={{__html: v.qq.cc.html}}></span></td>
                  <td><span dangerouslySetInnerHTML={{__html: v.qq.xz.html}}></span></td>
                </tr>              
              )}
            {
              showdata.length < size && Array.from(new Array(size - showdata.length)).map((v, index)=>
                <tr key={index}>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>              
              )              
            }
            {
              props.showmore &&
              <tr>
                <td className="tdbmore" colSpan={3}><a href={`//quote.eastmoney.com/f1.html?newcode=${props.quotecode}`} target="_blank">点击查看更多分时成交</a></td>
              </tr>              
            }
            </tbody>
          </table>      
        </>
       
      </SiderTabBox>
    )
  
}