/**
 * 404JS过滤
 */
export async function filter404(){
  try {
    let thisurl = location.href.substring(location.href.indexOf('//') + 2).toLowerCase();

    $.ajax({
      url: '//emres.dfcfw.com/60/empblist.js?callback=?',
      type: 'GET',
      dataType: 'jsonp',
      jsonpCallback: 'empblist2020',
      cache: true
    }).then(function(data){
      setTimeout(() => {
        if(data?.dataList instanceof Array){      
          if(data.dataList.some((v:string)=>v.substring(v.indexOf('//') + 2).toLowerCase() == thisurl)){ //命中
            //隐藏 资讯 公告 研报 股吧
            $('.qnb_list').hide()
            $('.reporttable').hide()
            $('.quote_guba_frame').hide()
          }
        }        
      }, 1500);
    })
  } catch (error) {
    
  }
}