import React,{useState, useEffect} from "react"
import { makeAutoObservable, runInAction, configure } from "mobx"
import { getOneQuote, type_quote_back, initSingleValue } from "../../modules/quote_info/quote_data";
import { observer } from "mobx-react-lite"
configure({ useProxies: "ifavailable" })

import { QuoteItem as quoteitem } from "../../modules/quote_info/config";
export const QuoteItem = quoteitem

/** 京A页面行情信息 */
class QuoteData {
    hqdata = null as null | type_quote_back

    constructor() {
      makeAutoObservable(this)
      this.getdata()
    }

    async getdata() {
      if(typeof window != 'undefined'){

        let back = await getOneQuote(window.quotedata.quotecode, [
          QuoteItem.名称,
          QuoteItem.扩位简称,
          QuoteItem.市场,
          QuoteItem.代码,
          QuoteItem.行情代码,
          QuoteItem.最新价,
          QuoteItem.最后价格,
          QuoteItem.涨跌幅,
          QuoteItem.涨跌额,
          QuoteItem.股票标识,
          QuoteItem.二级分类,
          QuoteItem.行情统一链接,
          QuoteItem.今开,
          QuoteItem.昨收,
          QuoteItem.最高价,
          QuoteItem.最低价,
          QuoteItem.成交量,
          QuoteItem.盘后成交量,
          QuoteItem.成交量带手,
          QuoteItem.成交额,
          QuoteItem.盘后成交额,
          QuoteItem.是否同股同权,
          QuoteItem.是否表决权差异,
          QuoteItem.注册股本,
          QuoteItem.发行股本,
          QuoteItem.是否盈利,
          QuoteItem.买入价或买一价,
          QuoteItem.买二价,
          QuoteItem.买三价,
          QuoteItem.买四价,
          QuoteItem.买五价,
          QuoteItem.买一量,
          QuoteItem.买二量,
          QuoteItem.买三量,
          QuoteItem.买四量,
          QuoteItem.买五量,             
          QuoteItem.卖出价或卖一价,
          QuoteItem.卖二价,
          QuoteItem.卖三价,
          QuoteItem.卖四价,
          QuoteItem.卖五价,
          QuoteItem.卖一量,
          QuoteItem.卖二量,
          QuoteItem.卖三量,
          QuoteItem.卖四量,
          QuoteItem.卖五量,
          QuoteItem.买一差量,
          QuoteItem.买二差量,
          QuoteItem.买三差量,
          QuoteItem.买四差量,
          QuoteItem.买五差量, 
          QuoteItem.卖一差量,
          QuoteItem.卖二差量,
          QuoteItem.卖三差量,
          QuoteItem.卖四差量,
          QuoteItem.卖五差量,
          QuoteItem.内盘,
          QuoteItem.外盘,
          QuoteItem.振幅,
          QuoteItem.量比,
          QuoteItem.交易时间带星期,
          QuoteItem.总股本,
          QuoteItem.流通股本,
          QuoteItem.换手率,
          QuoteItem.每股收益TTM,
          QuoteItem.总市值,
          QuoteItem.市净率,
          QuoteItem.市盈率TTM,
          QuoteItem.市盈率动态,
          QuoteItem.市盈率静态,
          QuoteItem.每股净资产,
          QuoteItem.均价,
          QuoteItem.流通市值,
          QuoteItem.流通市值_短,
          QuoteItem.交易状态,
          QuoteItem.涨停价,
          QuoteItem.跌停价,
          QuoteItem.总市值,
          QuoteItem.委比,
          QuoteItem.委差,
          QuoteItem.异常交易状态,
          QuoteItem.可转债代码或正股代码,
          QuoteItem.是否注册制,
          QuoteItem.是否具有协议控制架构,
          QuoteItem.AB股对应的代码,
          QuoteItem.AB股对应的市场,
          QuoteItem.AH股对应的代码,
          QuoteItem.AH股对应的市场,
          QuoteItem.A股对应GDR或GDR对应A股的代码,
          QuoteItem.A股对应GDR或GDR对应A股的市场,
          QuoteItem.A瑞士对应的市场,
          QuoteItem.A瑞士对应的代码,


        ], (ssedata:type_quote_back)=>{
          runInAction(()=>{
            this.hqdata = ssedata    
          })
        })
        runInAction(()=>{
          this.hqdata = back    
        })
      }
    }

}

export const quotedata = new QuoteData()

import { QuoteDocumentTitle } from '../shared/quote_document_title'
export let KcbQuoteDocumentTitle = observer(function(){

  if(quotedata.hqdata != null){
    return <QuoteDocumentTitle quotedata={quotedata.hqdata}></QuoteDocumentTitle>
  }

  return <></>
})