/**
 * localstorage封装
 */

export default {
  get(key:string){
    if(typeof localStorage != 'undefined'){
      return localStorage.getItem(key)
    }
    return null
  },
  getObject(key:string){
    if(typeof localStorage != 'undefined' && localStorage.getItem(key) != null){
      try {
        return JSON.parse(localStorage.getItem(key)!)
      } catch (error) {
        console.info('value is not object')
      }
    }
    return null
  },
  set(key: string, value: any){
    if(typeof localStorage != 'undefined'){
      localStorage.setItem(key, value)
    }
  },
  setObject(key: string, value: object){
    if(typeof localStorage != 'undefined'){
      localStorage.setItem(key, JSON.stringify(value))
    }  
  }
}