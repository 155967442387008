/**
 * 研报
 */

import config from "../../config/web";
import dayjs from 'dayjs';

/** 研报类型 */
export interface type_report{
  orgCode: string,
  orgSName: string,
  emRatingName: string,
  encodeUrl: string,
  title: string,
  publishDate: string,
}
/** 获取个股研报 */
export async function getStockReport(code:string, size = 5, beginTime?:string, endTime?:string):Promise<Array<type_report>>{

  if(beginTime == undefined){
    beginTime = dayjs().subtract(2, 'year').format('YYYY-MM-DD')
  }
  if(endTime == undefined){
    endTime = dayjs().format('YYYY-MM-DD')
  }

  let back = await $.ajax({
    url: config.getUrl('report_api') + 'report/list?cb=?',
    type: 'GET',
    dataType: 'json',
    jsonpCallback:'callback' + Math.floor(Math.random() * 10000000 + 1),
    data: {
      beginTime,
      endTime,
      pageNo: 1,
      pageSize: size,
      qType: 0,
      code,
      fields: 'orgCode,orgSName,emRatingName,encodeUrl,title,publishDate'
    }
  })

  if(back?.data instanceof Array){
    return back.data
  }

  return []
}


/** 获取行业研报 */
export async function getReport(industryCode:string, size = 5, beginTime?:string, endTime?:string):Promise<Array<type_report>>{

  if(beginTime == undefined){
    beginTime = dayjs().subtract(2, 'year').format('YYYY-MM-DD')
  }
  if(endTime == undefined){
    endTime = dayjs().format('YYYY-MM-DD')
  }

  let back = await $.ajax({
    url: config.getUrl('report_api') + 'report/list?cb=?',
    type: 'GET',
    dataType: 'json',
    jsonpCallback:'callback' + Math.floor(Math.random() * 10000000 + 1),
    data: {
      beginTime,
      endTime,
      pageNo: 1,
      pageSize: size,
      qType: 1,
      industryCode,
      fields: 'orgCode,orgSName,emRatingName,encodeUrl,title,publishDate'
    }
  })

  if(back?.data instanceof Array){
    return back.data
  }

  return []
}

