/** 
 * 获取财富号推广接口
 */
export async function getCFHTG():Promise<Array<{
  title: string,
  webUrl: string,
  stockCode: string
}>>{
  try {
    let back = await $.ajax({
      url: '/newapi/cfh_tg',
      type: 'GET',
      dataType: 'json'
    })

    return back    
  } catch (error) {
    
  }
  return []
}