import React,{useState, useEffect, useRef} from "react"
import { SiderTabBox } from "../../shared/sider_tab_box"
import { observer } from "mobx-react-lite"
import {initSingleValue} from '../../../modules/quote_info/quote_data'
import { quotedata, QuoteItem } from '../quote_data'
import { useOneQuote } from '../../../modules/quote_info/quote_hook'
import './index.less'
import txt from "../../../modules/txt"
import { HelpIcon } from "../../shared/help_icon"
import { sendStat } from "../../../modules/bigdata_stat"
import cookie from "../../../modules/cookie"

/**
 * 
 * @returns 侧边栏-行情-资金流
 */
export function SiderHQZJL(props:{
  market: string,
  code: string,
  quotecode: string,
  /** 是否是创业板 */
  is_cyb: boolean
}){
  return (
    <SiderTabBox {...{
      tab_split: true,
      boxdata:[
        {
          tab_html: <i>行情报价</i>,
          body: <HQBJO is_cyb={props.is_cyb}></HQBJO>,
          stat_code: 'webjdb.hqbj.hqbj'
        },
        {
          tab_html: <a href={`//data.eastmoney.com/zjlx/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.zjlx.zjlx-dj')}}>资金流向</a>,
          body: <ZJLX market={props.market} code={props.code} quotecode={props.quotecode}></ZJLX>,
          stat_code: 'webjdb.zjlx.zjlx-qh'
        }
      ]}}></SiderTabBox>
  )
}

let HQBJO = observer(HQBJ)

/**
 * 行情报价
 */
function HQBJ(props:{
  is_cyb: boolean
}){

  let showdata = initSingleValue()
  if(quotedata.hqdata != null){
    showdata = quotedata.hqdata
  }

  //海外用户 深交所只显示1挡
  let ishw = false
  if(cookie.get('uidal')){ //登录
    ishw = showdata[QuoteItem.市场].source == 0 && cookie.get('mtp') == '2'
  }
  else{ //未登录
    ishw = showdata[QuoteItem.市场].source == 0 && window.lt == 2
  }

  return (
    <div className={`sider_quote_price ${!props.is_cyb?'sider_quote_price2':''}`}>
      <div className="wb">
        委比：{showdata[QuoteItem.委比].blink_html} 委差：{showdata[QuoteItem.委差].blink_html}
      </div>
      <div className="mm">
        <table>
          <tbody>
            {
              props.is_cyb &&
                <tr className="botb">
                  <td colSpan={4}>
                    涨停： {showdata[QuoteItem.涨停价].blink_html} <HelpIcon tip_txt="涨停价:创业板股票在新股上市后的前5个交易日不设涨跌幅限制，之后日涨跌幅限制为20%" tip_width={170} tip_left={-80}></HelpIcon>
                  </td>
                </tr>              
            }
            {
              ishw ?
                <>
                  <tr>
                    <td>卖五</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>卖四</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>卖三</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>卖二</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </>
              :
                <>
                  <tr>
                    <td>卖五</td>
                    <td>{showdata[QuoteItem.卖五价].blink_html}</td>
                    <td>{showdata[QuoteItem.卖五量].blink_html}</td>
                    <td>
                      {showdata[QuoteItem.卖五差量].blink_html}
                      {
                        showdata[QuoteItem.卖五差量].txt == '' &&
                        <>&nbsp;</>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>卖四</td>
                    <td>{showdata[QuoteItem.卖四价].blink_html}</td>
                    <td>{showdata[QuoteItem.卖四量].blink_html}</td>
                    <td>{showdata[QuoteItem.卖四差量].blink_html}</td>
                  </tr>
                  <tr>
                    <td>卖三</td>
                    <td>{showdata[QuoteItem.卖三价].blink_html}</td>
                    <td>{showdata[QuoteItem.卖三量].blink_html}</td>
                    <td>{showdata[QuoteItem.卖三差量].blink_html}</td>
                  </tr>
                  <tr>
                    <td>卖二</td>
                    <td>{showdata[QuoteItem.卖二价].blink_html}</td>
                    <td>{showdata[QuoteItem.卖二量].blink_html}</td>
                    <td>{showdata[QuoteItem.卖二差量].blink_html}</td>
                  </tr>              
                </>
            }
              <tr>
                <td>卖一</td>
                <td>{showdata[QuoteItem.卖出价或卖一价].blink_html}</td>
                <td>{showdata[QuoteItem.卖一量].blink_html}</td>
                <td>{showdata[QuoteItem.卖一差量].blink_html}</td>
              </tr>              

            {
              !props.is_cyb &&
              <tr>
                <td colSpan={4}>
                  <BJTG></BJTG>
                </td>
              </tr>
            }
            <tr className={`${props.is_cyb?'topb':''}`}>
              <td>买一</td>
              <td>{showdata[QuoteItem.买入价或买一价].blink_html}</td>
              <td>{showdata[QuoteItem.买一量].blink_html}</td>
              <td>{showdata[QuoteItem.买一差量].blink_html}</td>
            </tr>
            {
              ishw ?
                <>
                  <tr>
                    <td>买二</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>买三</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>买四</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>买五</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>                 
                </>
              :
                <>
                  <tr>
                    <td>买二</td>
                    <td>{showdata[QuoteItem.买二价].blink_html}</td>
                    <td>{showdata[QuoteItem.买二量].blink_html}</td>
                    <td>{showdata[QuoteItem.买二差量].blink_html}</td>
                  </tr>
                  <tr>
                    <td>买三</td>
                    <td>{showdata[QuoteItem.买三价].blink_html}</td>
                    <td>{showdata[QuoteItem.买三量].blink_html}</td>
                    <td>{showdata[QuoteItem.买三差量].blink_html}</td>
                  </tr>
                  <tr>
                    <td>买四</td>
                    <td>{showdata[QuoteItem.买四价].blink_html}</td>
                    <td>{showdata[QuoteItem.买四量].blink_html}</td>
                    <td>{showdata[QuoteItem.买四差量].blink_html}</td>
                  </tr>
                  <tr>
                    <td>买五</td>
                    <td>{showdata[QuoteItem.买五价].blink_html}</td>
                    <td>{showdata[QuoteItem.买五量].blink_html}</td>
                    <td>{showdata[QuoteItem.买五差量].blink_html}</td>
                  </tr>                 
                </>
            }
      
            {
              props.is_cyb &&
                <tr>
                  <td colSpan={4} className="topb">
                    跌停： {showdata[QuoteItem.跌停价].blink_html} <HelpIcon tip_txt="跌停价:创业板股票在新股上市后的前5个交易日不设涨跌幅限制，之后日涨跌幅限制为20%" tip_width={170} tip_left={-80}></HelpIcon>
                  </td>
                </tr>  
            } 
          </tbody>

        </table>
      </div>
    </div>
  )
}

/** 报价区域推广 */
function BJTG(){

  const div = useRef(null as null | HTMLDivElement);

  useEffect(()=>{
    ;(async function(){
      try {
        let bulletin = await $.ajax({
          url: '/newapi/getbulletin/266',
          type: 'GET',
          dataType: 'html',
          data: {
            
          }
        })

        if(div !== null) {
          div!.current!.innerHTML = bulletin
          div!.current!.querySelector('a')!.target = '_blank'
        }        
      } catch (error) {
        
      }


    })();
  }, [])

  return (
    <div className="bjtg" ref={div}></div>
  )
}

/**
 * 资金流向
 */
function ZJLX(props:{
  market: string,
  code: string,
  quotecode: string
}){

  let showdata = useOneQuote(props.quotecode, [
    QuoteItem.主力流入,
    QuoteItem.主力流出,
    QuoteItem.主力净流入,
    QuoteItem.超大单流入,
    QuoteItem.超大单流出,
    QuoteItem.超大单净流入,
    QuoteItem.大单流入,
    QuoteItem.大单流出,
    QuoteItem.大单净流入,
    QuoteItem.中单流入,
    QuoteItem.中单流出, 
    QuoteItem.中单净流入, 
    QuoteItem.小单流入,
    QuoteItem.小单流出,
    QuoteItem.小单净流入,
  ], 30)

  if(showdata == null){
    showdata = initSingleValue()
  }

  return (
    <div className="zs_zjl">
      <table className="zjl1">
        <tbody>
          <tr>
            <td>主力流入</td>
            <td className="tar">{showdata[QuoteItem.主力流入].html}</td>
          </tr>
          <tr>
            <td>主力流出</td>
            <td className="tar">{showdata[QuoteItem.主力流出].html}</td>
          </tr>
          <tr>
            <td>主力净流入</td>
            <td className="tar">{showdata[QuoteItem.主力净流入].html}</td>
          </tr>          
        </tbody>
      </table>
      <table className="zjl1">
        <thead>
          <tr>
            <th>(元)</th>
            <th>流入</th>
            <th>流出</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>超大单</td>
            <td>{showdata[QuoteItem.超大单流入].html}</td>
            <td>{showdata[QuoteItem.超大单流出].html}</td>
          </tr>
          <tr>
            <td>大单</td>
            <td>{showdata[QuoteItem.大单流入].html}</td>
            <td>{showdata[QuoteItem.大单流出].html}</td>
          </tr>
          <tr>
            <td>中单</td>
            <td>{showdata[QuoteItem.中单流入].html}</td>
            <td>{showdata[QuoteItem.中单流出].html}</td>
          </tr>
          <tr>
            <td>小单</td>
            <td>{showdata[QuoteItem.小单流入].html}</td>
            <td>{showdata[QuoteItem.小单流出].html}</td>
          </tr>
        </tbody>
      </table>
      <div className="zjlchart">
        {
          // showdata[QuoteItem.超大单净流入].source != '-' &&
          <ZJLChart data={{
            cdd_jlr: showdata[QuoteItem.超大单净流入].source,
            dd_jlr: showdata[QuoteItem.大单净流入].source,
            zd_jlr: showdata[QuoteItem.中单净流入].source,
            xd_jlr: showdata[QuoteItem.小单净流入].source,
          }}></ZJLChart>          
        }
      </div>
    </div>
  )
}



/**
 * 资金流图
 * @param props 
 * @returns 
 */
function ZJLChart(props:{
  data: {
    cdd_jlr: number,
    dd_jlr: number,
    zd_jlr: number,
    xd_jlr: number,
  }
}){
  
  let max = Math.max(Math.abs(props.data.cdd_jlr), Math.abs(props.data.dd_jlr), Math.abs(props.data.zd_jlr), Math.abs(props.data.xd_jlr))
  // console.info(props.data.cdd_jlr)
  
  return (
    <>
      <table className="zjlchart" cellSpacing={0} cellPadding={0}>
        <tbody>
          <tr className="uptr">
            <td><ZJLChartBarUp jlr={props.data.cdd_jlr} max={max}></ZJLChartBarUp></td>
            <td><ZJLChartBarUp jlr={props.data.dd_jlr} max={max}></ZJLChartBarUp></td>
            <td><ZJLChartBarUp jlr={props.data.zd_jlr} max={max}></ZJLChartBarUp></td>
            <td><ZJLChartBarUp jlr={props.data.xd_jlr} max={max}></ZJLChartBarUp></td>
          </tr>
          <tr className="downtr">
            <td><ZJLChartBarDown jlr={props.data.cdd_jlr} max={max}></ZJLChartBarDown></td>
            <td><ZJLChartBarDown jlr={props.data.dd_jlr} max={max}></ZJLChartBarDown></td>
            <td><ZJLChartBarDown jlr={props.data.zd_jlr} max={max}></ZJLChartBarDown></td>
            <td><ZJLChartBarDown jlr={props.data.xd_jlr} max={max}></ZJLChartBarDown></td>
          </tr>
          <tr>
            <td>净超大</td>
            <td>净大单</td>
            <td>净中单</td>
            <td>净小单</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

function ZJLChartBarUp(props:{
  jlr: number,
  max: number
}){

  if(props.jlr == 0){
    return <></>
  }

  if(props.jlr > 0){
    return (
      <span className="price_up_bg" style={{width: '70%', height: (props.jlr/props.max)*25 + 'px'}}></span>
    )
  }

  if(isNaN(props.jlr)){
    return <span className="price_down">-</span>
  }

  return (
    <span className="price_down">{txt.formatNumMaxWidth(props.jlr, 4)}</span>
  )
}

function ZJLChartBarDown(props:{
  jlr: number,
  max: number
}){

  if(props.jlr == 0){
    return <></>
  }

  if(props.jlr < 0){
    return (
      <span className="price_down_bg" style={{width: '70%', height: (-props.jlr/props.max)*25 + 'px'}}></span>
    )
  }

  if(isNaN(props.jlr)){
    return <span className="price_up">-</span>
  }

  return (
    <span className="price_up">{txt.formatNumMaxWidth(props.jlr, 4)}</span>
  )
}