/**
 * 郑重声明
 */

import React,{useState, useEffect} from "react"
import './index.less'

/** 郑重声明 */
export function Statement(){
  return (
    <div className="bot_sm">
      郑重声明：本网站所刊载的所有资料及图表仅供参考使用。投资者依据本网站提供的信息、资料及图表进行金融、证券等投资所造成的盈亏与本网站无关。本网站如因系统维护或升级而需暂停服务，或因线路及超出本公司控制范围的硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，本网站不负任何责任。
    </div>
  )
}