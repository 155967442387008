/**
 * 头部广告
 */

import React,{useState, useEffect} from "react"
import { AdIframe } from "../ad_iframe"
import './index.less'

function HeadAD(){
  return (
    <div className="head_adbox self_clearfix">
      <ul>
        <li>
          <AdIframe adid="22" width={175} height={90}></AdIframe>  
        </li>
        <li>
          <AdIframe adid="174" width={636} height={90}></AdIframe>
        </li>
        <li className="last">
          <AdIframe adid="23" width={175} height={90}></AdIframe>
        </li>
      </ul>
    </div>
  ) 
}

export default HeadAD  