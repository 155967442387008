import React,{useState, useEffect} from "react"
import { observer } from "mobx-react-lite"
import { quotedata, QuoteItem } from "../../page_a/quote_data";
import {initSingleValue, type_quote_back} from '../../../modules/quote_info/quote_data'
import AddZxgBtn from '../add_zxg_btn'
import './index.less'
import { TitleLabel } from "./title_label";
import { QuoteTitleQR } from "./quote_title_qr";
import { KWJC } from "./kwjc";

/** a股页面行情信息 */


let QuoteTitle = function(props:{
  quotecode:string,
  market: string,
  code: string,
  name: string
}){
  let showdata = initSingleValue()
  if(quotedata.hqdata != null){
    showdata = quotedata.hqdata
  }

  return <QuoteTitleShow quotedata={showdata} quotecode={props.quotecode} market={props.market} code={props.code} name={props.name}></QuoteTitleShow>
}

function QuoteTitleShow(props:{
  quotecode: string,
  quotedata: type_quote_back,
  market: string,
  code: string,
  name: string
}){

  let quotedata = props.quotedata

  return (
    <div className="quote_title self_clearfix">
      <div className="quote_title_l">
        <span className="quote_title_name quote_title_name_190" title={quotedata[QuoteItem.名称].txt == '-' ? props.name : quotedata[QuoteItem.名称].txt}>{quotedata[QuoteItem.名称].txt == '-' ? props.name : quotedata[QuoteItem.名称].txt}</span>
        <KWJC quotedata={quotedata}></KWJC>
        <span className="quote_title_code">{quotedata[QuoteItem.代码].txt == '-'? props.code : quotedata[QuoteItem.代码].txt}</span>
        <TitleLabel quotedata={quotedata} change_name={true} market={props.market} code={props.code}></TitleLabel>
        <span className="zxgbtn">
          <AddZxgBtn quotecode={props.quotecode}></AddZxgBtn>
        </span>        
      </div>


      <div className="quote_title_r">
        <span className="quote_title_time">{quotedata[QuoteItem.交易时间带星期].txt}</span>
        <span className="quote_title_tradestate_txt">交易状态: <span>{quotedata[QuoteItem.交易状态].txt}</span></span>
        <QuoteTitleQR quotecode={props.quotecode}></QuoteTitleQR>
      </div>          

      
    </div>
  )
}

export default observer(QuoteTitle)