/**
 * 返回顶部组件
 */


import React,{useState, useEffect, useRef} from "react"
import ReactDOM from 'react-dom'
import { useIsBrowser } from "../hooks"
import './index.less'

/** 返回顶部组件 */
export function BackTop(props:{
  /** 页面宽度 */
  main_width: number
}){

    let isBrowser = useIsBrowser()

  const [show, SetShow] = useState(false) //展示还是隐藏
  const [quote_show, SetQuoteShow] = useState(false) //查询行情 展示还是隐藏
  const inputEl = useRef(null);

  useEffect(()=>{
    let cd = setInterval(function () {
        if ((document.documentElement.scrollTop / document.documentElement.clientHeight) < 1.2) {
          SetShow(false)
        }
        else {
          SetShow(true)
        }

        return true;
    }, 500);

    return function(){
      clearInterval(cd)
    }
  }, [])

  const searchQuote = function(){ //查询行情
    SetQuoteShow(function(now){
      
      return !now
    })
  }

  const search = function(){
    let value = (inputEl.current as unknown as HTMLInputElement).value
    if(value != ''){
      window.open('https://so.eastmoney.com/quotation/s?keyword=' + encodeURIComponent(value))
    }
  }

  if(isBrowser){
    return ReactDOM.createPortal(
      <div id="embacktop" style={{marginLeft: (props.main_width / 2 + 8), display:(show?'block':'none')}}>
        <a href="#" target="_self" id="backtophq" className={quote_show?'on':''} onClick={(e)=>{searchQuote();e.preventDefault();}}>行情</a>
        <a href="//corp.eastmoney.com/Lianxi_liuyan.asp" target="_blank" id="backtopyj" title="意见反馈"></a>
        <a href="#" target="_self" id="gotop" title="回到顶部" onClick={(e)=>{window.scroll(0,0);e.preventDefault();}}></a>
        <div id="backtopsearch" style={{display: quote_show?'block':'none'}}>
          <input type="text" id="backtopsearchinput" ref={inputEl} autoComplete="off" /><button id="backtopsearchsbm" onClick={search}>查询</button>
        </div>
      </div>,
      document.body
    )

  }

  return <></>
}