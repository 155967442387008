/**
 * 个股新闻列表
 */

import React,{useState, useEffect} from "react"
import { getBulletin } from "../../../modules/bulletin/web";
import news_data, {type_news} from "../../../modules/new_news/web";
import "./index.less";
import { sendStat } from "../../../modules/bigdata_stat";

/** 个股新闻列表 */
export function QuoteNewsList(props:{
  quotecode: string,
  size?: number,
  short?:boolean,
  /** 东财新闻列表 */
  is_dc?:boolean
}){

  const size = props.size || 5

  const [init, SetInit] = useState(true)
  const [showdata, SetShowData] = useState([] as Array<type_news>)

  useEffect(()=>{
    ;(async()=>{
      if(props.is_dc){//走布告栏
        let back = await getBulletin('1032')
        if(back){
          let data = [] as Array<type_news>
          $(back).find('a').each(function(i, ele){
            data.push({
              Art_ShowTime: '',
              Art_Title: $(ele).text(),
              Art_OriginUrl: $(ele).attr('href') as string,
              Art_Url: $(ele).attr('href') as string
            })
          })
          SetShowData(data.slice(0,5))
        }
        SetInit(false)
      }
      else{
        let back = await news_data.getNews(props.quotecode, 5, 300)
        SetInit(false)
        if(back.length > 0){
          SetShowData(back)
        }        
      }

    })();
  }, [])

  let short = true
  if(props.short === false){
    short = false
  }

  return (
    <div className="qnb_list" onClick={()=>{sendStat('A', 'Click', 'webjdb.zx.qynr')}}>
      <ul>
        {
          showdata.map((v,index)=>
            <li key={index}>
              <a className={short?'short':''} href={v.Art_Url || v.Art_OriginUrl} title={v.Art_Title} target="_blank">{v.Art_Title}</a><span className="time">{v.Art_ShowTime && v.Art_ShowTime.substring(5, 10)}</span>
            </li>
          )
        }
        {
          showdata.length < size && Array.from(new Array(size - showdata.length)).map((v,index)=>
            <li key={index} className="nodot"></li>            
          )
        }
        {
          showdata.length == 0 && init &&
          <div className="nodata">数据加载中...</div>
        }
        {
          showdata.length == 0 && !init &&
          <div className="nodata">暂无数据</div>
        }
      </ul>
    </div>
  )
}


/**
 * 空的表格
 * @returns 
 */
export function BlankTable(props:{
  size?: number,
  title?:string
}){

  let size = props.size ?? 5
  let title = props.title ?? '暂无数据'

  return (
    <div className="qnb_list">
      <ul>
        {
          Array.from(new Array(5)).map((v,index)=>
            <li key={index} className="nodot"></li>            
          )
        } 
        <div className="nodata">{title}</div>
      </ul>
    </div>
  )
}