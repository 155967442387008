/**
 * 前端直接获取行情信息
 */
import tools from "./tools";
import quote_tools from "./tools";
import api_url from "../../config/web";
import cookie from '../cookie'
import { getDelayparams } from "./delayparams";
import { QuoteSSE } from "../sse";

const ut = 'fa5fd1943c7b386f172d6893dbfba10b'

//延迟参数
var delayparams = getDelayparams()

/**
 * 获取分时成交性质名称
 * @param xoi 增仓量
 * @param cbs 交易类型
 * @param xvol 成交量
 * @returns 
 */
function getTypeName(xoi: number, cbs: number, xvol: number){
  if(xoi > 0 && (Math.abs(xoi) == xvol)){
    return '双开'
  }
  else if(xoi < 0 && Math.abs(xoi) == xvol){
    return '双平'
  }
  else if(cbs == 1 && xoi < 0){
    return '多平'
  }
  else if(cbs == 2 && xoi < 0){
    return '空平'
  }
  else if(cbs == 2 && xoi > 0){
    return '多开'
  }
  else if(cbs == 1 && xoi > 0){
    return '空开'
  }
  else if(cbs == 2 && xoi == 0){
    return '多换'
  }
  else if(cbs == 1 && xoi == 0){
    return '空换'
  }

  return ''
}

/**
 * 转换分时成交数据
 * @param list 
 * @param preprice 
 * @returns 
 */
function tradeToData(list:Array<any>, preprice:number){
  return list.map((v:string, index:number)=>{
    let arr:Array<any> = v.split(',')

    let c1 = 0
    if(arr[4] == '1'){
      c1 = -1
    }
    else if(arr[4] == '2'){
      c1 = 1
    }

    //大于20万
    if(arr[1] * parseFloat(arr[2]) * 100 >= 200000){
      c1 = c1 * 2
    }

    let jt = '　'
    let jtcolor = 0
    if(index > 0){
      let preitem = list[index - 1].split(',')
      if(arr[1] - preitem[1] > 0){
        jt = '↑'
        jtcolor = 1
      }
      else if(arr[1] - preitem[1] < 0){
        jt = '↓'
        jtcolor = -1
      }
    }
    // else if(index == 0){
    //   if(arr[1] - preprice > 0){
    //     jt = '↑'
    //     jtcolor = 1
    //   }
    //   else if(arr[1] - preprice < 0){
    //     jt = '↓'
    //     jtcolor = -1
    //   }
    // }

    return {
      cjsj: arr[0],
      cjj: tools.quoteItem(arr[1], -1, parseFloat(arr[1]) - preprice, -1),
      cjl: tools.quoteItem(parseFloat(arr[2]), -1, c1, -1),
      cjl2: tools.quoteItem_fscj(parseFloat(arr[2]), -1, c1, -1),
      price_zd: tools.quoteItem(jt, -1, jtcolor, -1),
      jtcolor: tools.quoteItem(jtcolor, -1, jtcolor, -1),
      qq:{
        xl: tools.quoteItem(parseFloat(arr[2]), -1, c1, -1),
        cc: tools.quoteItem(parseFloat(arr[3]), -1, 0, -1),
        xz: tools.quoteItem(getTypeName(arr[3], arr[4], arr[2]), -1, c1, -1)
      }
    }
  })
}


/** 沪深港通 */
export interface type_hsgt{
  hk2sh: {
    status: number,
    dayNetAmtIn: number,
    dayAmtRemain: number,
    dayAmtThreshold: number,
    amtStatus: number,
    buySellAmt: number,
    date2: string,
    buySellAmtDate: number,
    netBuyAmt:number,
  },
  sh2hk: {
    status: number,
    dayNetAmtIn: number,
    dayAmtRemain: number,
    dayAmtThreshold: number,
    amtStatus: number,
    buySellAmt: number,
    date2: string,
    buySellAmtDate: number
    netBuyAmt:number,
  },
  hk2sz: {
    status: number,
    dayNetAmtIn: number,
    dayAmtRemain: number,
    dayAmtThreshold: number,
    amtStatus: number,
    buySellAmt: number,
    date2: string,
    buySellAmtDate: number
    netBuyAmt:number,
  },
  sz2hk: {
    status: number,
    dayNetAmtIn: number,
    dayAmtRemain: number,
    dayAmtThreshold: number,
    amtStatus: number,
    buySellAmt: number,
    date2: string,
    buySellAmtDate: number
    netBuyAmt:number,
  }
}

export interface type_item_str{
  /** 输出文本 */
  txt: string,
  /** 颜色class */
  color: 'price_up'|'price_down'|'price_draw'|''|'price_up2'|'price_down2',
  /** html */
  html: string,
  /** 带闪烁的html */
  blink_html: string
}

/** 单个股票行情信息 */
export interface type_onestock{
  /** 名称 */
  name: string,
  /** 代码 */
  code: string,
  /** 最新价 */
  price: type_item_str,
  /** 涨跌额 */
  zde: type_item_str,
  /** 涨跌幅 */
  zdf: type_item_str,
  /** 今开 */
  jk: type_item_str,
  /** 昨收 */
  zs: type_item_str,
  /** 最高价 */
  high_price: type_item_str,
  /** 最低价 */
  low_price:  type_item_str,
  /** 成交量 */
  cjl: type_item_str,
  /** 成交额 */
  cje: type_item_str,
  /** 买入价或买一价 */
  mrj: type_item_str,
  /** 卖出价或卖一价 */
  mcj: type_item_str,
  /** 内盘 */
  np: type_item_str,
  /** 外盘 */
  wp: type_item_str,
  /** 振幅 */
  zf: type_item_str,
  /** 量比 */
  lb: type_item_str,
  /** 交易时间 */
  jysj: string,
  /** 交易币种 */
  jybz:string,
  /** 交易币种-汇率 */
  jybz_hy: type_item_str,
  /** 总股本 */
  zgb: type_item_str,
  /** 流通股本 */
  ltgb: type_item_str,
  /** 换手率 */
  hsy: type_item_str,
  /**每股收益TTM  */
  mgsy_ttm: type_item_str,
  /** 总市值 */
  zsz: type_item_str,
  /** 总市值_短的 */
  zsz_short: type_item_str,
  /** 流通市值 */
  ltsz: type_item_str,
  /** 流通市值_短的 */
  ltsz_short: type_item_str,
  /** 市净率 */
  sjl: type_item_str,
  /** 市盈率ttm */
  syl_ttm: type_item_str,
  /** 每股净资产 */
  mgjzc: type_item_str,
  /** 均价 */
  jj: type_item_str,
}

/**
 * 分时成交数据类型
 */
export interface type_fscj{
  /** 成交时间 */
  cjsj: string,
  /** 成交价 */
  cjj: type_item_str,
  /** 成交量或增仓量 */
  cjl: type_item_str,
  /** 成交量或增仓量 带20万的颜色 */
  cjl2: type_item_str,
  /** 价格涨跌 */
  price_zd:type_item_str,
  /* 箭头颜色 */
  jtcolor:type_item_str,
  qq:{ //期权
    xl: type_item_str, //现量
    cc: type_item_str, //仓差
    xz: type_item_str, //性质
  }
}

/** 过滤功能行情类型 */
export interface type_filter_quote {
  name: string,
  quotecode: string,
  market: string,
  code: string,
  price: type_item_str,
  zdf: type_item_str,
  zde: type_item_str
}

export interface type_quote_list{
  /**
   * 名称
   */
  name:string,
  /**
   * 行情代码
   */
  newcode:string,
  /**
   * 行情市场
   */
  market: string,
  /**
   * 代码，不带市场
   */
  code:string,
  /**
   * 最新价
   */
  price:string,
  /** 最新价html */
  price_str:type_item_str,
  /**
   * 最新价放大数和保留小数
   */
  price_fixnumber: number,
  /**
   * 涨跌幅
   */
  zdf:string,
  /** 涨跌幅html */
  zdf_str:type_item_str,
  /** 涨跌额 */
  zde_str:type_item_str,
  /**
   * 涨跌幅放大数和保留小数
   */
  zdf_fixnumber:number,
  /**
   * 是否是沪深A股票
   */
  ishsstock: boolean,
  /** 涨数量 */
  z_number_str:string,
  /** 平数量 */
  p_number_str:string,
  /** 跌数量 */
  d_number_str:string,
  cje:number
}



export default {
  /**
   * 获取简单行情信息
   * @param codes 
   */
  async simpleInfo(codes:string|Array<string>):Promise<Array<type_quote_list>>{
    let secids = ''
    if(typeof codes == "string"){
      secids = codes
    }
    else{
      secids = codes.join(',')
    }
    return $.ajax({
      url: api_url.getUrl('quote_api') + 'api/qt/ulist.np/get?fields=f1,f2,f3,f4,f6,f14,f12,f13,f19,f104,f105,f106,f152&invt=2&cb=?',
      type: 'GET',
      dataType: 'jsonp',
      data: {
        secids,
        ut,
        wbp2u: delayparams
      }
    }).then(back=>{
      if(back?.data?.diff && back.data.diff instanceof Array){        
        return back.data.diff.map((v:any)=>{          
          return {
            name: v.f14,
            newcode: v.f13 + '.' + v.f12,
            market: v.f13,
            code: v.f12,
            price: v.f2,
            price_str: tools.quoteItem(v.f2, v.f1, v.f4, -1),
            price_fixnumber: v.f1,
            zdf: v.f3,
            zdf_str: tools.quoteItem(v.f3, v.f152, v.f4, -1, '%'),
            zde_str: tools.quoteItem(v.f4, v.f1, v.f4, -1),
            zdf_fixnumber: v.f152,
            ishsstock: quote_tools.isHSStock(v.f13, v.f19),
            z_number_str: tools.quoteItem(v.f104, -1, 1, -1),
            p_number_str: tools.quoteItem(v.f106, -1, 1, -1),
            d_number_str: tools.quoteItem(v.f105, -1, 1, -1),
            cje: v.f6
          }
        })
      }
      else{
        return []
      }                                                                                 
    })
  },
  /**
   * 带fs过滤功能的行情列表信息
   * @param fs fs过滤字符串
   * @param fid 排序字符串
   * @param pi 第几页 0开始
   * @param pz 每页多少条 默认10
   * @param po 排序 1 倒叙 0 正序 默认0
   * @returns 
   */
  async filterStockList(fs:string, fid:string,pi = 0, pz = 10 , po = 1 as 0|1):Promise<Array<type_filter_quote>>{
    return $.ajax({
      url: api_url.getUrl('quote_api') + 'api/qt/clist/get?fltt=1&fields=f1,f2,f3,f4,f12,f13,f14,f152&np=1&invt=2&cb=?',
      type: 'GET',
      dataType: 'jsonp',
      data: {
        fs,
        fid,
        pi,
        pz,
        po,
        ut,
        wbp2u: delayparams
      }
    }).then(back=>{
      if(back?.data?.diff && back.data.diff instanceof Array){        
        return back.data.diff.map((v:any)=>{
          return {
            name: v.f14,
            quotecode: v.f13 + '.' + v.f12,
            market: v.f13,
            code: v.f12,
            price: tools.quoteItem(v.f2, v.f1, v.f4, -1),
            zdf: tools.quoteItem(v.f3, v.f152, v.f4, -1, '%'),
            zde: tools.quoteItem(v.f4, v.f1, v.f4, -1)
          }
        })
      }
      else{
        return []
      }                                                                                 
    })
  },

  /**
   * 单个股票行情信息，带格式化
   * @param market_code 市场+代码
   * @returns 
   */
  async stockInfo(market_code:string):Promise<null|type_onestock>{
    return $.ajax({
      url: api_url.getUrl('quote_api') + 'api/qt/stock/get?fields=f19,f39,f43,f44,f45,f46,f47,f48,f49,f50,f57,f58,f59,f60,f71,f84,f85,f86,f92,f108,f116,f117,f152,f154,f161,f164,f167,f168,f169,f170,f171,f532,f600,f601&invt=2&fltt=1&cb=?',
      type: 'GET',
      dataType: 'jsonp',
      data: {
        secid: market_code,
        ut,
        wbp2u: delayparams
      }
    }).then(back=>{
      if(back?.data){

        return {
          name: back.data.f58,
          code: back.data.f57,
          price: tools.quoteItem(back.data.f43, back.data.f59, back.data.f169, -1),
          zde: tools.quoteItem(back.data.f169, back.data.f59, back.data.f169, -1),
          zdf: tools.quoteItem(back.data.f170, back.data.f152, back.data.f169, -1, '%'),
          jk: tools.quoteItem(back.data.f46, back.data.f59, back.data.f46 - back.data.f60, -1),
          zs: tools.quoteItem(back.data.f60, back.data.f59, 0, -1),
          high_price: tools.quoteItem(back.data.f44, back.data.f59, back.data.f44 - back.data.f60, -1),
          low_price: tools.quoteItem(back.data.f45, back.data.f59, back.data.f45 - back.data.f60, -1),
          cjl: tools.quoteItem(back.data.f47, -1, 0, 4),
          cje: tools.quoteItem(back.data.f48, -1, 0, 4),
          mrj: tools.quoteItem(back.data.f19, back.data.f59, back.data.f19 - back.data.f60, -1),
          mcj: tools.quoteItem(back.data.f39, back.data.f59, back.data.f39 - back.data.f60, -1),
          np: tools.quoteItem(back.data.f161, -1, -1, 4),
          wp: tools.quoteItem(back.data.f49, -1, 1, 4),
          zf: tools.quoteItem(back.data.f171, back.data.f152, 0, -1, '%'),
          lb: tools.quoteItem(back.data.f50, back.data.f152, 0, -1),
          jysj: tools.transTradeTime(back.data.f86),
          jybz: back.data.f600,
          jybz_hy: tools.quoteItem(back.data.f601, back.data.f154, 0, -1),
          zgb: tools.quoteItem(back.data.f84, -1, 0, 4),
          ltgb: tools.quoteItem(back.data.f85, -1, 0, 4),
          hsy: tools.quoteItem(back.data.f168, back.data.f152, 0, -1, '%'),
          mgsy_ttm: tools.quoteItem(back.data.f108, -1, 0, -1, undefined, 2),
          zsz: tools.quoteItem(back.data.f116, -1, 0, 4),
          zsz_short: tools.quoteItem(back.data.f116, -1, 0, 2),
          ltsz: tools.quoteItem(back.data.f117, -1, 0, 4),
          ltsz_short: tools.quoteItem(back.data.f117, -1, 0, 2),
          sjl: tools.quoteItem(back.data.f167, back.data.f152, 0, -1),
          syl_ttm: tools.quoteItem(back.data.f164, back.data.f152, 0, -1),
          jj: tools.quoteItem(back.data.f71, back.data.f59, back.data.f71 - back.data.f60, -1),
          mgjzc: tools.quoteItem(back.data.f92, -1, 0, -1, undefined, 2),
        }
      }
      else{
        return null
      }                                                                                 
    })    
  },
  /**
   * 沪深港通
   * @returns 
   */
  async hsgt():Promise<null|type_hsgt>{
    return $.ajax({
      url: api_url.getUrl('quote_api') + 'api/qt/kamt/get?fields1=f1,f2,f3,f4&fields2=f51,f52,f53,f54,f56,f62,f63,f65,f66&cb=?',
      type: 'GET',
      dataType: 'jsonp',
      data:{
        ut,
        wbp2u: delayparams
      }
    }).then(back=>{
      if(back?.data){
        // console.info(back.data)
        
        return back.data
      }
      return null
    }, ()=>{
      return null
    })
  },
  /**
   * 分时成交数据
   * @param quotecode 
   * @returns 
   */
  async fscj(quotecode:string, size=13):Promise<null|Array<type_fscj>>{
    return $.ajax({
      url: api_url.getUrl('quote_api') + 'api/qt/stock/details/get?fields1=f1,f2,f3,f4&fields2=f51,f52,f53,f54,f55&fltt=2&cb=?',
      type: 'GET',
      dataType: 'jsonp',
      data:{
        pos: '-' + (size + 1),
        secid: quotecode,
        ut,
        wbp2u: delayparams
      }
    }).then(back=>{      
      // console.info(back.data)
      
      if(back?.data){
        return tradeToData(back.data.details, back.data.prePrice).reverse().slice(0, size)
      }
      return []
    }, ()=>{
      return []
    })
  }, 
  
  /**
   * 分时成交推送数据
   * @param quotecode 
   * @param size 
   */
  fscj_sse(quotecode:string, callback:Function, size=13){

    let backlist = [] as Array<any>
    let prePrice = 0
    function dealData(data:any){
      if((data.full != 1 && data.full != 0) || !(data.data.details instanceof Array)){
        return false
      }      

      if(data.full == 1){ //全量数据
        backlist = data.data.details
        prePrice = data.data.prePrice
      }

      if(data.full == 0){ //增量
        backlist = backlist.concat(data.data.details).slice(-(size + 1))
      }
      
      callback(tradeToData(backlist, prePrice).reverse().slice(0, size))
    }

    let url = `${api_url.getUrl('quote_push_api')}api/qt/stock/details/sse?fields1=f1,f2,f3,f4&fields2=f51,f52,f53,f54,f55&mpi=1000&dect=1&ut=bd1d9ddb04089700cf9c27f6f7426281&fltt=2&pos=-${size + 1}&secid=${quotecode}&wbp2u=${delayparams}`
    let fscj_sse = QuoteSSE(url)
    fscj_sse.onmessage = function(e){
      let data = JSON.parse(e.data)
      if(data.data != null) dealData(data)
    }
    return fscj_sse
  },

  /**
   * 分时成交推送原始数据
   * @param quotecode 
   * @param size 
   */
  fscj_source_sse(quotecode:string, callback:Function, size=13){

    let backlist = [] as Array<any>
    let prePrice = 0
    function dealData(data:any){
      if((data.full != 1 && data.full != 0) || !(data.data.details instanceof Array)){
        return false
      }      

      if(data.full == 1){ //全量数据
        backlist = data.data.details
        prePrice = data.data.prePrice
      }

      if(data.full == 0){ //增量
        backlist = backlist.concat(data.data.details)
      }
      
      callback(backlist, prePrice)
    }

    let url = `${api_url.getUrl('quote_push_api')}api/qt/stock/details/sse?fields1=f1,f2,f3,f4&fields2=f51,f52,f53,f54,f55&mpi=2000&ut=bd1d9ddb04089700cf9c27f6f7426281&fltt=2&pos=-${size + 1}&secid=${quotecode}&wbp2u=${delayparams}`
    let fscj_sse = QuoteSSE(url)
    fscj_sse.onmessage = function(e){
      let data = JSON.parse(e.data)
      if(data.data != null) dealData(data)
    }
    return fscj_sse
  },

  /**
   * 个股行情信息—推送
   * @param quotecode 
   * @param callback 
   */
  stockInfo_sse(quotecode:string, callback:Function){
    let quote_data:any = {}
    function dealData(data:any){
      
      if(!data.data) return false

      if(data.full == 1){ //全量数据
        quote_data = data.data
      }

      if(data.full == 0){ //增量数据
        quote_data = Object.assign(quote_data, data.data)
      }

      let returndata = {
          name: quote_data.f58,
          code: quote_data.f57,
          price: tools.quoteItem(quote_data.f43, quote_data.f59, quote_data.f169, -1),
          zde: tools.quoteItem(quote_data.f169, quote_data.f59, quote_data.f169, -1),
          zdf: tools.quoteItem(quote_data.f170, quote_data.f152, quote_data.f169, -1, '%'),
          jk: tools.quoteItem(quote_data.f46, quote_data.f59, quote_data.f46 - quote_data.f60, -1),
          zs: tools.quoteItem(quote_data.f60, quote_data.f59, 0, -1),
          high_price: tools.quoteItem(quote_data.f44, quote_data.f59, quote_data.f44 - quote_data.f60, -1),
          low_price: tools.quoteItem(quote_data.f45, quote_data.f59, quote_data.f45 - quote_data.f60, -1),
          cjl: tools.quoteItem(quote_data.f47, -1, 0, 4),
          cje: tools.quoteItem(quote_data.f48, -1, 0, 4),
          mrj: tools.quoteItem(quote_data.f19, quote_data.f59, quote_data.f19 - quote_data.f60, -1),
          mcj: tools.quoteItem(quote_data.f39, quote_data.f59, quote_data.f39 - quote_data.f60, -1),
          np: tools.quoteItem(quote_data.f161, -1, -1, 4),
          wp: tools.quoteItem(quote_data.f49, -1, 1, 4),
          zf: tools.quoteItem(quote_data.f171, quote_data.f152, 0, -1, '%'),
          lb: tools.quoteItem(quote_data.f50, quote_data.f152, 0, -1),
          jysj: tools.transTradeTime(quote_data.f86),
          jybz: quote_data.f600,
          jybz_hy: tools.quoteItem(quote_data.f601, quote_data.f154, 0, -1),
          zgb: tools.quoteItem(quote_data.f84, -1, 0, 4),
          ltgb: tools.quoteItem(quote_data.f85, -1, 0, 4),
          hsy: tools.quoteItem(quote_data.f168, quote_data.f152, 0, -1, '%'),
          mgsy_ttm: tools.quoteItem(quote_data.f108, -1, 0, -1, undefined, 2),
          zsz: tools.quoteItem(quote_data.f116, -1, 0, 4),
          zsz_short: tools.quoteItem(quote_data.f116, -1, 0, 2),
          ltsz: tools.quoteItem(quote_data.f117, -1, 0, 4),
          ltsz_short: tools.quoteItem(quote_data.f117, -1, 0, 2),
          sjl: tools.quoteItem(quote_data.f167, quote_data.f152, 0, -1),
          syl_ttm: tools.quoteItem(quote_data.f164, quote_data.f152, 0, -1),
          jj: tools.quoteItem(quote_data.f71, quote_data.f59, quote_data.f71 - quote_data.f60, -1),
          mgjzc: tools.quoteItem(quote_data.f92, -1, 0, -1, undefined, 2),
        }
      
      callback(returndata)
    }

    let url = `${api_url.getUrl('quote_push_api')}api/qt/stock/sse?fields=f19,f39,f43,f44,f45,f46,f47,f48,f49,f50,f57,f58,f59,f60,f71,f84,f85,f86,f92,f108,f116,f117,f152,f154,f161,f164,f167,f168,f169,f170,f171,f532,f600,f601&invt=2&fltt=1&secid=${quotecode}&ut=fa5fd1943c7b386f172d6893dbfba10b&wbp2u=${delayparams}`
    let sse = new EventSource(url)
    sse.onmessage = function(e){
      let data = JSON.parse(e.data)
      if(data.data != null) dealData(data)
    }    
  }
}

/**
 * 获取外汇汇率
 * @param type1 
 * @param type2 
 */
export async function getHuiLy(type1:string, type2:string) {
  let quotecode = `119.${type1}${type2}`
  return $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/stock/get?cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data:{
      fields: 'f43,f57,f58,f59,f107,f179',
      secid: quotecode,
      ut,
      wbp2u: delayparams
    }
  }).then(back=>{
    if(back?.data){
      return back.data
    }
    return null
  }, ()=>{
    return null
  })
}

/**
 * 获取外汇人民币中间价
 * @param type1 
 */
export async function getCNYC(type1:string) {
  let quotecode = `120.${type1}CNYC`
  return $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/stock/get?cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data:{
      fields: 'f43,f57,f58,f59,f107,f179',
      secid: quotecode,
      ut,
      wbp2u: delayparams
    }
  }).then(back=>{
    if(back?.data){
      return back.data
    }
    return null
  }, ()=>{
    return null
  })
}



