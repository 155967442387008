/**
 * 加自选按钮
 */

import React,{useState, useEffect} from "react"
import './index.less'
import { pagezxgdata } from "../page_zxg_data";
import { observer } from "mobx-react-lite"

/** 加自选按钮 */
function AddZxgBtn(props:{
  quotecode:string
}){
  if(pagezxgdata.zxgdata != null){
    return (
      <>
        {
          pagezxgdata.zxgdata.result.check && <a href="#" className="quote_delete_zxg_btn" onClick={(e)=>{e.preventDefault();pagezxgdata.cancelZXG(props.quotecode);}}>-删自选</a>
        }
        {
          !pagezxgdata.zxgdata.result.check && <a href="#" className="quote_add_zxg_btn" onClick={(e)=>{e.preventDefault();pagezxgdata.addZXG(props.quotecode);}}>+加自选</a>
        }        
      </>
    )
  }
  return (
    <></>
  )
}

export default observer(AddZxgBtn)