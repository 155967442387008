/**
 * 实用工具
 */

export default {
  /**
   * 把新市场代码转换为旧市场代码
   * @param newmarket 新市场代码
   */
  converNewMarketToOldMarket(newmarket:string|number):string{
    if (newmarket == 0) { //深圳
      return '2'
    }
    else if(newmarket == 1){ //上海
      return '1'
    }
    else if(newmarket == 90){ //沪深板块
      return '1'
    }    
    return ''
  },

  /*
   *
   *@Title: 校验是否支持canvas
   *@description:
   *@return: 
   *@author: qiuhongyang
   *@date: 2020-05-09 09:08:13
   *
  */
  checkCanvas() { 
    var back = false;
    var tempcanvs:any = document.createElement('canvas')
    if (tempcanvs.getContext) {
      back = true;
    }
    return back;
  },
  /*
   *
   *@Title:   js
   *@params1: num  原始数据
   *@params2: suffix  后缀：如 %   
   *@params3: tofix 保留的位数
   *@description:
   *@return: 
   *@author: qiuhongyang
   *@date: 2020-06-09 10:37:03
   *
  */
  formatHqData(num: any, suffix: any, tofix: any){
      var back: any = "";
      if (num === '' || num == null || num == undefined) {
        return back;
      }
      if (num == '-') return num;
      back = num;
      if (tofix === 0 || tofix) {
        back = back.toFixed(tofix)
      }
      if (suffix) {
        back = back + '' + suffix;
      }
      return back;
  },

  /**
   * css注入
  */
  addstyle(css: string) {
    var head = document.head || document.getElementsByTagName('head')[0],
      style: any = document.createElement('style');

    style.type = 'text/css';
    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
  }
}