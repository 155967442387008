/**
 * 自选股信息
 * 包含是否加自选和默认分组信息
 */

import { makeAutoObservable, runInAction, configure } from "mobx"
configure({ useProxies: "ifavailable" })

import zxg_data from '../../../modules/newzxg/web'
import hk_rule from "../../../modules/hk_rule"


/** 自选股信息 */
class PageZXGData {
    zxgdata = null as null | {
      /** 是否成功 */
      re: boolean,
      result: {
        /** 是否加自选 */
        check: boolean,
        /** 自选股列表 */
        list: Array<string>
      }
    }

    constructor() {
      makeAutoObservable(this)
      this.getdata()
    }

    async getdata() {
      if(typeof window != 'undefined'){
        let quotecode = ''
        if(window.quotedata && window.quotedata.quotecode){
          quotecode = window.quotedata.quotecode
        }
        else if(window.quotecode){
          quotecode = window.quotecode
        }
        let back = await zxg_data.defaultGroupList(quotecode)
        let hk_delay = await hk_rule.isDelayCache()
        // console.info(back)
        // console.info(hk_delay)
        
        if(hk_delay.re){
          back.result.list = back.result.list.map(function(v){
            if(v.indexOf('116.') == 0) v = v.replace('116.', '128.')
            return v
          })
        }
        runInAction(()=>{
          this.zxgdata = back    
        })
      }
    }

    async addZXG(quotecode:string){
      // let zxg_data = await import('../../../modules/newzxg/web')
      let back = await zxg_data.addDefaultStock(quotecode)
      runInAction(()=>{
        if(this.zxgdata?.result){
          this.zxgdata.result.check = true
        }
      })
      window.open('//quote.eastmoney.com/zixuan/?from=add_button')
    }

    async cancelZXG(quotecode:string){
      // let zxg_data = await import('../../../modules/newzxg/web')
      let back = await zxg_data.deleteDefaultStock(quotecode)
      runInAction(()=>{
        if(this.zxgdata?.result){
          this.zxgdata.result.check = false
        }
      })
    }

}

export const pagezxgdata = new PageZXGData()