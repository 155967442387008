import React,{useState, useEffect} from "react"
import './index.less'
import { sendStat } from "../../../modules/bigdata_stat"

let overcd: NodeJS.Timeout

/**
 * 行情tab框
 * @param props 
 * @returns 
 */
export function SiderTabBox(props:{
  children?: JSX.Element
  /** tab等分 */
  tab_split?: boolean
  /** margin-top */
  mt?:number,
  noborder?: boolean,
  boxdata: Array<{
    /** tab内容 */
    tab_html: JSX.Element,
    /** 是否是假的tab,鼠标移上去不切换 */
    faketab?: boolean
    /** 对应的右上角html */
    more_html?: JSX.Element,
    /** 切换tab引起的body切换内容 */
    body?: JSX.Element,
    /** 大数据统计代码 */
    stat_code?: string
  }>
}){

  let mt = 0
  if(props.mt != undefined){
    mt = props.mt
  }

  let noborder = props.noborder ?? false

  /** 当前显示index */
  const [nowindex, SetNowIndex] = useState(0)

  const mouseover = (index:number) =>{
    if(props.boxdata[index].faketab == true){
      return false
    }
    overcd = setTimeout(() => {
      SetNowIndex(index)
      if(props?.boxdata?.[index]?.stat_code){
        sendStat('Li', 'Click', (props.boxdata[index].stat_code as string))
      }
    }, 200);
  }

  const mouseout = () =>{
    clearTimeout(overcd)
  }

  if(props.boxdata.length == 0) return <></>

  let liclass = ''
  if(props.tab_split){
    liclass = 'df' + props.boxdata.length.toString()
  }

  return (
    <div className="sidertabbox" style={{marginTop: mt}}>
      <div className={`sidertabbox_t ${props.tab_split?'sidertabbox_tsplit':''}`}>
        {
          //多个tab变成ul li
          props.boxdata.length > 1 &&
          <ul>
            {
              props.boxdata.map((v, index)=>
                <li key={index} onMouseOver={(e)=>{mouseover(index)}} onMouseOut={mouseout} className={liclass + ' ' + (index == nowindex ? 'active': '')}>{v.tab_html}</li>
              )
            }
          </ul>          
        }
        {
          props.boxdata.length == 1 &&
          <div className="onet">{props.boxdata[0].tab_html}</div>
        }
        {
          props.boxdata[nowindex]?.more_html &&
          <div className="more">{props.boxdata[nowindex].more_html}</div>
        }
      </div>
      <div className={`sidertabbox_c ${noborder && 'sidertabbox_c_noborder'}`}>
        {props.boxdata[nowindex].body}
        {
          props.children
        }
      </div>
    </div>
  )
}
