/**
 * 静态分时图
 */

import React,{useState, useEffect} from "react"
import config from "../../../config/web";
import { TimeType } from './index'

export function StaticTimeChart(props:{
  market: string,
  quotecode: string,
  timetype: TimeType,
  pagetype?: string,
  /** 自刷时间 */
  refresh_time?: number
}){

  let imageType = 'r'
  let type = ''

  let refresh_time = props.refresh_time == undefined ? 20 : props.refresh_time

  // console.info(refresh_time)
  

  switch (props.timetype) {
    case TimeType.一天:
      imageType = 'r'
      type = ''
      break
    case TimeType.二天:
      imageType = 't'
      type = 'M1'   
      break
    case TimeType.三天:
      imageType = 't'
      type = 'M2'
      break
    case TimeType.四天:
      imageType = 't'
      type = 'M3'
      break
    case TimeType.五天:
      imageType = 't'
      type = 'M4'
      break
    case TimeType.盘前:
      imageType = 'rc'
      type = ''
      break
    case TimeType.盘后:
      imageType = 'rp'
      type = ''
      break
  }  

  /** 时间戳 刷新图片用 */
  const [timespan, SetTimeSpan] = useState((new Date()).getTime().toString().substring(0, 10))
  /** 是否是浏览器 */
  const [is_browser, SetIsBrowser] = useState(false)

  useEffect(()=>{
    SetIsBrowser(true)
    
    if(refresh_time > 0){
      let cd = setInterval(function(){
        SetTimeSpan((new Date()).getTime().toString().substring(0, 10))
      }, refresh_time * 1000)

      return ()=>{
        try {
          clearInterval(cd)
        } catch (error) {
          
        }
      }      
    }


  }, [props.timetype, props.refresh_time])

  return (
    <>
      {
        is_browser &&
          <div className="time_static_img">
            <img src={`${(props.pagetype == 'gzqh') || (props.pagetype == 'option' && (props.market == '11' || props.market == '221')) ? config.getUrl('chart_time_delay') : config.getUrl('chart_time')}GetPic.aspx?imageType=${imageType}&type=${type}&token=44c9d251add88e27b65ed86506f6e5da&nid=${props.quotecode}&timespan=${timespan}`} width={578} height={276} />    
            
          </div>
      }
    </>
  )
}


