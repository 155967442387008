/**
 * 个股研报
 */


import React,{useState, useEffect} from "react"
import { type_report, getStockReport } from "../../../modules/report/web";
import './report_table.less'
import { sendStat } from "../../../modules/bigdata_stat";

/** 研报表格 */
export function StockReportTable(props:{
  code: string,
  size?: number,
  short?: boolean
}){

  let size = props.size || 5;

  const [showdata, SetShowData] = useState([] as Array<type_report>)

  const getData = async()=>{

    let back = await getStockReport(props.code, size)
    
    if(back.length > 0){
      SetShowData(back)
    }
  }

  useEffect(()=>{
    getData()
  },[])

  return (
    <>
      <table className="reporttable" onClick={()=>{sendStat('Table', 'Click', 'webjdb.ggyb.qynr')}}>
        <thead>
          <tr>
            <th>机构</th>
            <th>评级</th>
            <th className="tal">研报</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            showdata.map((v, index)=>
              <tr key={index}>
                <td className="orgname"><a href={`//data.eastmoney.com/report/orgpublish.jshtml?orgcode=${v.orgCode}`} target="_blank"><span title={v.orgSName}>{v.orgSName}</span></a></td>
                <td className="rating">{v.emRatingName}</td>
                <td className="title"><a href={`http://data.eastmoney.com/report/zw_stock.jshtml?encodeUrl=${v.encodeUrl}`} target="_blank"><span title={v.title}>{v.title}</span></a></td>
                <td className="time">{v.publishDate.length >= 10 ? v.publishDate.substring(5,10) : ''}</td>
              </tr>              
            )
          }
          {
            showdata.length > 0 &&showdata.length < size && Array.from(new Array(size - showdata.length)).map((v,index)=>
              <tr key={index}>
                <td className="orgname"></td>
                <td className="rating"></td>
                <td className="title"></td>
                <td className="time"></td>
              </tr>              
            )
          }
          {
            showdata.length == 0 &&
              <>
                <tr>
                  <td className="orgname"></td>
                  <td className="rating"></td>
                  <td className="title"></td>
                  <td className="time"></td>
                </tr>
                <tr>
                  <td className="orgname"></td>
                  <td className="rating"></td>
                  <td className="title"></td>
                  <td className="time"></td>
                </tr>  
                <tr>
                  <td className="nodata" colSpan={4} style={{textAlign: 'center', fontSize: 12}}>暂无数据</td>
                </tr>
              <tr>
                  <td className="orgname"></td>
                  <td className="rating"></td>
                  <td className="title"></td>
                  <td className="time"></td>
                </tr>
                <tr>
                  <td className="orgname"></td>
                  <td className="rating"></td>
                  <td className="title"></td>
                  <td className="time"></td>
                </tr>            
              </>
          }


        </tbody>
      </table>
    </>
  )
}