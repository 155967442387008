/**
 * 浏览器指纹
 */

var fingerprint = require('./fingerprint2');
import bid from "./bid";

/**
 * 是否支持canvas
 * 
 * @returns 
 */
function isSupportCanvas(){
  var elem = document.createElement('canvas');
  return !!(elem.getContext && elem.getContext('2d'));
}

export default {
  get: async function():Promise<string>{

    let bid_str = bid.get()

    if (bid_str != null) { //cookie中已经有了
      return bid_str
    }

    if(isSupportCanvas()){ //支持canvas
      return new Promise(function(resolve, reject){
        new fingerprint({
          dontUseFakeFontInCanvas: true,
          swfContainerId : true,
          swfPath : true,
          // userDefinedFonts : true,
          excludeUserAgent : true,
          // excludeLanguage : true,
          // excludeColorDepth : true,
          excludeScreenResolution : true,
          excludeAvailableScreenResolution: true,
          // excludeTimezoneOffset : true,
          // excludeSessionStorage : true,
          // excludeIndexedDB : true,
          // excludeAddBehavior : true,
          // excludeOpenDatabase : true,
          // excludeCpuClass : true,
          // excludePlatform : true,
          // excludeDoNotTrack : true,
          // excludeCanvas : true,
          // excludeWebGL : true,
          excludeAdBlock : true,
          // excludeHasLiedLanguages : true,
          // excludeHasLiedResolution : true,
          // excludeHasLiedOs : true,
          // excludeHasLiedBrowser : true,
          // excludeJsFonts : true,
          excludeFlashFonts : true,
          excludePlugins : true,
          excludeIEPlugins : true
          // excludeTouchSupport : true,
          // excludePixelRatio : true,
          // excludeHardwareConcurrency : true,
        }).get(function(result:string, components:object){
          bid.save(result)
          resolve(result);
          return;
        });        
      })
    }
    else{
      return bid.make() // 不支持canvas 生成一个随机数
    }
  }
}