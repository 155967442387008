/**
 * 数据全景图
 */

import React,{useState, useEffect} from "react"
import './index.less'

export function DataImg(props: {
  name: string,
  code: string
}){
  return (
    <a href={`//data.eastmoney.com/stockdata/${props.code}.html`} target="_blank">
      <div className="quotedataimg">
        {props.name}数据全景图
      </div>
    </a>
  )
}