/**
 * 新版资讯
 */

import web_config from "../../config/web";
import _ from 'lodash'
import { getBulletin } from "../bulletin/web";

export interface type_news{
  Art_ShowTime: string,
  Art_Title: string,
  Art_OriginUrl: string,
  Art_Url: string,
}

export interface type_old_news{
  Art_Title: string,
  Art_Showtime: string,
  Art_Url: string
}

/**
 * 通过行情代码获取资讯-老接口
 * @param market 
 * @param code 
 * @param name 
 * @returns 
 */
export async function oldGetNewsByCodeName(market: string, code:string, name:string):Promise<Array<type_old_news>>{
  return $.ajax({
    url: `${web_config.getUrl('old_zixun_api')}api/StockNews?returnFields=Art_Title,Art_Showtime,Art_Url&cb=?`,
    type: 'GET',
    dataType: 'jsonp',
    data: {
      mktNum: market,
      stockCode: code,
      stockName: name
    }
  }).then(function(back){
    if(back.Data instanceof Array){
      return back.Data.map((v:any)=>{
        return {
          Art_ShowTime: v.Art_Showtime,
          ...v
        }
      })
    }
    else{
      return []
    }
  }, function(error){
    console.error(error)
    return []
  })
}

/**
 * 通过频道id获取资讯-老接口
 * @param channels 
 * @param pageindex 
 * @param pageSize 
 * @param exclude_cols 
 * @returns 
 */
export async function oldGetNewsByChannel(channels: string, pageindex = 1, pageSize = 5, exclude_cols = ''):Promise<Array<type_old_news>>{
  return $.ajax({
    url: `${web_config.getUrl('old_zixun_api')}api/CmsNews?cb=?`,
    type: 'GET',
    dataType: 'jsonp',
    data: {
      channels,
      exclude_cols,
      pageindex,
      pageSize
    }
  }).then(function(back){
    if(back.Result instanceof Array){
      return back.Result.map((v:any)=>{
        return {
          Art_ShowTime: v.Art_Showtime,
          ...v
        }
      })
    }
    else{
      return []
    }
  }, function(error){
    console.error(error)
    return []
  })
}

/**
 * 获取机构名家文章-老接口
 */
export async function getJigouMingjia(jigou_count = 5, mingjia_count = 20):Promise<Array<{
  title: string,
  url: string
}>> {
  try {
    let [jigou, mingjia] = await Promise.all<any>([
      $.ajax({
        url: `${web_config.getUrl('old_zixun_api')}api/organization/GetOrganizationArticleByIds?cb=?`,
        type: 'GET',
        dataType: 'jsonp',
        data: {
          channelIds: 9,
          pageIndex: 1,
          pageSize: jigou_count
        }
      }),
      $.ajax({
        url: `${web_config.getUrl('old_zixun_api')}api/author/GetAuthorArticleByIds?cb=?`,
        type: 'GET',
        dataType: 'jsonp',
        data: {
          channelIds: 9,
          pageIndex: 1,
          pageSize: mingjia_count
        }
      })
    ]) 

    if(jigou?.Result instanceof Array && mingjia?.Result instanceof Array){
      let list = (jigou.Result as Array<any>).concat(mingjia.Result)
      list = list.map(v=>{
        return {
          title: v.Art_Title,
          url: v.Art_UniqueUrl || v.Art_Url
        }
      })
      list = _.uniq(list)
      return list
    }    
  } catch (error) {
    console.error(error)
    return []
  }


  return []
}

/**
 * 根据scColumns获取资讯
 * @param column_id 
 * @param pagesize 
 * @param cachetime 数据缓存时间 单位:秒 0不缓存
 */
export const getNewsBySccolumns = async (sccolumns: string, pagesize=5):Promise<Array<type_news>> =>{

  let backdata = await $.ajax({
    url: `${web_config.getUrl('new_zixun_api')}comm/adpt/getlist?callback=?`,
    type: 'GET',
    dataType: 'jsonp',
    data: {
      client: 'web_col',
      biz: 'web_quote_other',
      fcolType: 1,
      type: 1,
      fcolName: 'scColumns',
      fcolValue: sccolumns,
      order: 2,
      pageSize: pagesize
    }
  }).then(function(back){
    if(back?.data?.list instanceof Array){
      return back.data.list.slice(0, pagesize)
    }
    else{
      return []
    }
  }, function(error){
    console.error(error)
    return []
  })

  return backdata
}

let datacache:any = {

}

export default {
  /**
   * 根据市场代码获取资讯
   * @param code 代码 比如 1.600000
   * @param pagesize 
   * @param cachetime 数据缓存时间 单位:秒
   */
  async getNews(code:string, pagesize=5, cachetime = 0):Promise<Array<type_news>>{
    if(cachetime && datacache['stocknews' + code]){
      return datacache['stocknews' + code]
    }
    let backdata = await $.ajax({
      url: `${web_config.getUrl('new_zixun_api')}comm/web/getListInfo?cfh=1&client=web&mTypeAndCode=${code}&type=1&pageSize=${pagesize}&callback=?`,
      type: 'GET',
      dataType: 'jsonp',
      data: {
        traceId: Math.floor(Math.random()*10000000000+1)
      }
    }).then(function(back){
      if(back.code == 1 && back.data && back.data.list && back.data.list instanceof Array){
        return back.data.list.slice(0, pagesize)
      }
      else{
        return []
      }
    }, function(){
      return []
    })

    if(cachetime){
      datacache['stocknews' + code] = backdata
      setTimeout(() => {
        delete datacache['stocknews' + code]
      }, cachetime * 1000);
    }

    return backdata
  },
  /**
   * 获取东财资讯
   * @param pagesize 
   * @returns 
   */
  async getDCNews(pagesize=5):Promise<Array<type_news>>{
    try {
      let back = await getBulletin('1032')

      if(back){
        let data = [] as Array<type_news>
        $(back).find('a').each(function(i, ele){
          data.push({
            Art_ShowTime: '',
            Art_Title: $(ele).text(),
            Art_OriginUrl: $(ele).attr('href') as string,
            Art_Url: $(ele).attr('href') as string
          })
        })
        return data.slice(0, pagesize)
      }

      return []
    } catch (error) {
      
    }

    return []
  },  

  /**
   * 根据栏目id获取资讯
   * @param column_id 
   * @param pagesize 
   * @param cachetime 数据缓存时间 单位:秒 0不缓存
   */
  async getNewsByColumn(column_id: string, pagesize=5, cachetime=0):Promise<Array<type_news>>{


    if(cachetime && datacache['columnnews' + column_id]){
      return datacache['columnnews' + column_id]
    }

    let backdata = await $.ajax({
      url: `${web_config.getUrl('new_zixun_api')}comm/adpt/getlist?client=web_col&biz=web_quote_other&fcolType=1&type=1&fcolName=columns&fcolValue=${column_id}&order=1&pageSize=${pagesize}`,
      type: 'GET',
      dataType: 'jsonp',
      data: {
        
      }
    }).then(function(back){
      if(back.code == 1 && back.data && back.data.list && back.data.list instanceof Array){
        return back.data.list.slice(0, pagesize)
      }
      else{
        return []
      }
    }, function(error){
      console.error(error)
      return []
    })

    if(cachetime){
      datacache['columnnews' + column_id] = backdata
      setTimeout(() => {
        delete datacache['columnnews' + column_id]
      }, cachetime * 1000);
    }

    return backdata
  }
}