/**
 * a股
 */

import React,{useState, useEffect} from "react"
import ReactDOM from "react-dom";
import gotowap from "../modules/gotowap";

//跳转手机版
gotowap(window.quotedata.code, window.quotedata.market);

//跳转到概念版页面
if(window.localStorage && window.localStorage.getItem('rememberme') == '0'){
  if(window.quotedata.market == '1'){
    location.replace("/concept/sh" + window.quotedata.code + ".html")
  }
  else{
    location.replace("/concept/sz" + window.quotedata.code + ".html")
  }
}

//跳转到科创板
if(window.quotedata.market == '1' && window.quotedata.code.substring(0, 3) == '688'){
  location.replace("/kcb/" + window.quotedata.code + ".html")
}

/** 404过滤 */
import { filter404 } from "../modules/404";
filter404()

import { Index } from "../components/page_a";
ReactDOM.hydrate(
  <Index quotedata={window.quotedata}></Index>,
  document.getElementById('app')
)