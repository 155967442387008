
/**
 * 行情市场+代码，转换成股吧的代码
 * @param market 行情市场
 * @param code 行情代码
 * @param area 地区
 */
export function getGubaCode(market:string, code: string, pagetype?:string, area?:string){
  if(typeof window != 'undefined' && window?.GubaCode){
    return window.GubaCode
  }
  
  if(pagetype == 'fund'){ //基金
    return 'of' + code
  }

  if(pagetype == 'bond'){ //债券
    if(market == '0') return 'sz' + code
    if(market == '1') return 'sh' + code
  }

  if(pagetype == 'zs'){ //指数
    if(market == '0') return 'zssz' + code
    if(market == '1') return 'zssh' + code
  }  

  
  if (market == '2') { //中证指数
      return 'is' + code;
  }
  if (market == '155') { //英股
      return 'lse' + code;
  }
  if (market == '156') { //英股
      return 'lseiob' + code;
  }
  if (market == '252') { //瑞士GDR
      return 'six' + code;
  }  
  if (market == '116') { //港股
      return 'hk' + code;
  }
  if (market == '105' || market == '106' || market == '107') { //美股
      return 'us' + code.replace('_', '.'); //美股下划线，股吧特殊逻辑，改成点
  }
  if (market == '153') { //粉单
    return 'otcbb' + code;
  }
  if (market == '171') { //粉单
    return 'ub' + code;
  }  

  return code
}