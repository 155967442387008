/**
 * 港股合规
 */
import user from "../user";

let thisuser = user.get()

export default {
  /**
   * 是否需要延迟数据
   */
  async isDelay(){
    let back = await $.ajax({
      url: '//push2.eastmoney.com/api/qt/stock/get?cb=?',
      type: 'GET',
      dataType: 'jsonp',
      data: {
        ut:'bd1d9ddb04089700cf9c27f6f7426281'
      }
    })
    if(back.lt == 2){ //非大陆用户
      return {
        re: true,
        message: `非中国大陆用户仅可查看港股延时15分钟行情`
      }
    }
    if(thisuser == null){ //未登录
      return {
        re: true,
        message: `未登录用户仅可查看港股延时15分钟行情，<a href="https://passport2.eastmoney.com/pub/login?backurl=${encodeURIComponent(self.location.href)}" target="_blank">去登录</a>`
      }
    }    
    return {
      re: false,
      message: ''
    }
  },
  isdelaycache: <null|{
      re: boolean,
      message: string
    }>null,
  /**
   * 是否需要延迟数据带缓存
   */
  async isDelayCache(){
    if(this.isdelaycache != null){
      return this.isdelaycache
    }
    let result = await this.isDelay()
    this.isdelaycache = result
    return result
  }
}