import React,{useState, useEffect} from "react"
import { sendStat } from "../../../modules/bigdata_stat"
import {F10Type, getF10LinkNew} from '../../../modules/links/f10'
import './index.less'

/**
 * 行情F10 沪深
 * @returns 
 */
export function QuoteF10(props:{
  market: string,
  code: string,
  pagetype?: string
}){

  let f10code = 'SZ' + props.code
  if(props.market == '1'){
    f10code = 'SH' + props.code
  }

  if(props.pagetype == 'bj'){
    f10code = 'BJ' + props.code
  }

  return (
    <div className="quotef10 self_clearfix mt10">
      <div className="hqadls">
        <a href="https://zqhd.eastmoney.com/Html/aghd/pc/20170918/html/activity1.html" target="_blank" className="lt" title="东方财富,看实时资金流。"></a>
        <a href="https://acttg.eastmoney.com/pub/pctg_hskh_act_wysfpcb_01_01_01_0" target="_blank" className="la" title="东方财富电脑版"></a>
        <a href="http://acttg.eastmoney.com/pub/web_app_hqy_bjl_01_01_01_0" target="_blank" title="东方财富手机版" className="lb"></a>
      </div>

      <div className="hqrls">
          <div className="cells cells_hs"> 
              <span style={{marginLeft: 5}}>F10档案：</span>
              <a href={getF10LinkNew(f10code, F10Type.操盘必读)} target="_blank" className="red" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.cpbd')}}>操盘必读</a>
              <a href={getF10LinkNew(f10code, F10Type.公司概况)} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.gsgk')}}>公司概况</a>
              <a href={getF10LinkNew(f10code, F10Type.经营分析)} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.jyfx')}}>经营分析</a>
              <a href={getF10LinkNew(f10code, F10Type.核心题材)} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.hxtc')}}>核心题材</a>
              <a href={getF10LinkNew(f10code, F10Type.股东研究)} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.gdyj')}}>股东研究</a>
              <a href={getF10LinkNew(f10code, F10Type.公司大事)} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.gsds')}}>公司大事</a>
              <a href={getF10LinkNew(f10code, F10Type.股本结构)} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.gbjg')}}>股本结构</a>
              <a href={getF10LinkNew(f10code, F10Type.财务分析)} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.cwfx')}}>财务分析</a>
              <a href={getF10LinkNew(f10code, F10Type.分红融资)} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.fhrz')}}>分红融资</a>
              <a href={getF10LinkNew(f10code, F10Type.资本运作)} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.zbyz')}}>资本运作</a>
              <a href={getF10LinkNew(f10code, F10Type.行业分析)} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.hyfx')}}>行业分析</a>
              <a href={getF10LinkNew(f10code, F10Type.关联个股)} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.glgg')}}>关联个股</a>
              <a href={getF10LinkNew(f10code, F10Type.操盘必读)} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hqdy.f10.gd')}}>更多<b></b></a>
          </div>

          <div className="cells cells_hs">
              <span><a href={`//data.eastmoney.com/stockdata/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.tssj')}}>特色数据：</a></span>
              <a href={`//data.eastmoney.com/zjlx/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.zjlx')}}>资金流向</a>
              <a href={`//data.eastmoney.com/stockcomment/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.zlkp')}}>主力控盘</a>
              <a href={`//data.eastmoney.com/stockcomment/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.jgsh')}}>机构散户</a>
              <a href={`//data.eastmoney.com/stock/lhb/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.lhbd')}}>龙虎榜单</a>
              <a href={`//data.eastmoney.com/stockdata/${props.code}.html`} target="_blank" className="red" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.sdsj')}}>深度数据</a>
              <a href={`//data.eastmoney.com/dzjy/detail/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.dzjy')}}>大宗交易</a>
              <a href={`//data.eastmoney.com/stockcomment/stock/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.zndp')}}>智能点评</a>
              <a href={`//data.eastmoney.com/rzrq/detail/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.rzrq')}}>融资融券</a>
              <a href={`//data.eastmoney.com/bbsj/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.zxyj')}}>最新业绩</a>
              <a href={`//data.eastmoney.com/zlsj/detail/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.jgcc')}}>机构持仓</a>
              <a href={`//data.eastmoney.com/dxf/q/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.xsjj')}}>限售解禁</a>
              <a href={`//data.eastmoney.com/notices/stock/${props.code}.html?f_node=5`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.zdsx')}}>重大事项</a>
              <a href={`//data.eastmoney.com/stockdata/${props.code}.html`} className="red" target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tssj.gd')}}>更多<b></b></a>
          </div>
      </div>
    </div>
  )
}


/**
 * 行情F10 北交所
 * @returns 
 */
export function QuoteF10BJ(props:{
  code: string
}){
  return (
    <div className="quotef10 self_clearfix mt10">
      <div className="hqadls">
        <a href="https://zqhd.eastmoney.com/Html/aghd/pc/20170918/html/activity1.html" target="_blank" className="lt" title="东方财富,看实时资金流。"></a>
        <a href="https://acttg.eastmoney.com/pub/pctg_hskh_act_wysfpcb_01_01_01_0" target="_blank" className="la" title="东方财富电脑版"></a>
        <a href="http://acttg.eastmoney.com/pub/web_app_hqy_bjl_01_01_01_0" target="_blank" title="东方财富手机版" className="lb"></a>
      </div>

      <div className="hqrls">
          <div className="cells" style={{marginTop: '12px'}}> 
              <span>F10档案：</span>
              <a href={`//emweb.eastmoney.com/PC_SanBanF10/CoreReading/index?color=web&code=${props.code}.BJ`} target="_blank" className="red">核心必读</a>
              <a href={`//emweb.eastmoney.com/PC_SanBanF10/CompanyInfo/index?color=web&code=${props.code}.BJ`} target="_blank">公司概况</a>
              <a href={`//emweb.eastmoney.com/PC_SanBanF10/FinancialAnalysis/index?color=web&code=${props.code}.BJ`} target="_blank">财务分析</a>
              <a href={`//emweb.eastmoney.com/PC_SanBanF10/Shareholders/index?color=web&code=${props.code}.BJ`} target="_blank">股本股东</a>
              <a href={`//emweb.eastmoney.com/PC_SanBanF10/ShareBonus/index?color=web&code=${props.code}.BJ`} target="_blank">分红融资</a>
          </div>

          {/* <div className="cells">
              <span><a href="http://data.eastmoney.com/stockdata/<%=Stock.code %>.html" target="_blank">特色数据：</a></span>
              <a href="http://data.eastmoney.com/zjlx/<%=Stock.code %>.html" target="_blank">资金流向</a>
              <a href="http://data.eastmoney.com/stockcomment/<%=Stock.code %>.html" target="_blank">主力控盘</a>
              <a href="http://data.eastmoney.com/stockcomment/<%=Stock.code %>.html" target="_blank">机构散户</a>
              <a href="http://data.eastmoney.com/stock/lhb/<%=Stock.code %>.html" target="_blank">龙虎榜单</a>
              <a href="http://data.eastmoney.com/stockdata/<%=Stock.code %>.html" target="_blank" className="red">深度数据</a>
              <a href="http://data.eastmoney.com/dzjy/detail/<%=Stock.code %>.html" target="_blank">大宗交易</a>
              <a href="http://data.eastmoney.com/stockcomment/stock/<%=Stock.code %>.html" target="_blank">智能点评</a>
              <a href="http://data.eastmoney.com/rzrq/detail/<%=Stock.code %>.html" target="_blank">融资融券</a>
              <a href="http://data.eastmoney.com/bbsj/<%=Stock.code %>.html" target="_blank">最新业绩</a>
              <a href="http://data.eastmoney.com/zlsj/detail/<%=Stock.code %>.html" target="_blank">机构持仓</a>
              <a href="http://data.eastmoney.com/dxf/q/<%=Stock.code %>.html" target="_blank">限售解禁</a>
              <a href="//data.eastmoney.com/notices/stock/<%=Stock.code %>.html?f_node=5" target="_blank">重大事项</a>
              <a href="http://data.eastmoney.com/stockdata/<%=Stock.code %>.html" className="more red" target="_blank">更多<b></b></a>
          </div> */}
      </div>
    </div>
  )
}