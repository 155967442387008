
/*
 * 
 *@description:二维码生成模块二次封装
 *@modifyContent:
 *@author: qiuhongyang
 *@date: 2020-06-10 09:28:49
 *
*/

// import AraleQRCode from "./qrcode.js";
import AraleQRCode from './qrcode'
import utils from "../utils"; 


var qrcodenew = function(opts:any){
    var rendertype = "canvas";
    // console.log(utils.checkCanvas())
    if (utils.checkCanvas()){

    }else{
        rendertype = "table"
    };

    var defaultopt = {
        render: rendertype,
        correctLevel: 1,
        pdground: '#000',
        text: "https://wap.eastmoney.com/quote/stock/0.300059.html",
        size: 100,
        imageSize: 26,
        image: '/newstatic/css/old/img/qrem3.png' //./newstatic/css/old/img/qrem.png
    };

    var Opt = $.extend({}, defaultopt, opts);

    var qrnode = new AraleQRCode(Opt);

    // $("#"+ opts.domId).append(qrnode);
    return qrnode;
}



export default qrcodenew;





