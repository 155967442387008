import React,{useState, useEffect} from "react"
import { type_quote_back } from "../../../modules/quote_info/quote_data";
import { QuoteItem } from '../../../modules/quote_info/config'


/**
 * 行情数据浏览器标题栏通知
 * @returns 
 */
export function QuoteDocumentTitle(props:{
  quotedata: type_quote_back
}){

  

  useEffect(()=>{    
    if(props.quotedata[QuoteItem.最新价].txt != '-'){
      document.title = `${props.quotedata[QuoteItem.名称].txt} ${props.quotedata[QuoteItem.最新价].txt} ${props.quotedata[QuoteItem.涨跌额].txt}(${props.quotedata[QuoteItem.涨跌幅].txt})股票价格_行情_走势图—东方财富网`      
    }
    else{
      // console.info(props.quotedata[QuoteItem.名称].txt)
      document.title = `${props.quotedata[QuoteItem.名称].txt}(${props.quotedata[QuoteItem.代码].txt})股票价格_行情_走势图—东方财富网`         
    }

  }, [props.quotedata[QuoteItem.名称].txt, props.quotedata[QuoteItem.最新价].txt, props.quotedata[QuoteItem.涨跌额].txt, props.quotedata[QuoteItem.涨跌幅].txt])

  return (
    <></>
  )
}