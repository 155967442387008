/**
 * 文本相关组件
 */

import React,{useState, useEffect} from "react"
import './index.less'

/**
 * 截取字符串长度
 * @param props 
 * @returns 
 */
export function TextCut(props:{
  text?: string,
  width: number,
  tip?: boolean,
  children?: any
}){
  let tip = props.tip ?? true
  return <span className="text_cut" title={tip?props.text:''} style={{maxWidth: props.width}}>{props.children ?? props.text}</span>
}

/** 切断字符长度 */
export function TextCutLength(props:{
  text: string,
  size: number
}){

  let thislength = 0
  let txt = props.text

  if(!txt){
    return <span></span>
  }

  

  for (var i = 0; i < txt.length; i++) {
    if (txt.charCodeAt(i) > 255) {
      thislength += 2;
    }
    else {
      thislength++;
    }
    if (thislength > props.size) {
      return <span title={txt}>{txt?.substring(0, i)}...</span>
    }
  }

  return <span>{txt}</span>
}