/**
 * 新盘口异动接口 push2接口
 */
import api_url from "../../config/web";
import { getDelayparams } from "../quote_info/delayparams";

const ut = 'fa5fd1943c7b386f172d6893dbfba10b'

var delayparams = getDelayparams()

var types = {
  "1": "有大买盘",
  "101": "有大卖盘",
  "2": "大笔买入",
  "102": "大笔卖出",
  "201": "封涨停板",
  "301": "封跌停板",
  "202": "打开涨停",
  "302": "打开跌停",
  "203": "高开5日线",
  "303": "低开5日线",
  "204": "60日新高",
  "304": "60日新低",
  "401": "向上缺口",
  "501": "向下缺口",
  "402": "火箭发射",
  "502": "高台跳水",
  "403": "快速反弹",
  "503": "快速下跌",
  "404": "竞价上涨",
  "504": "竞价下跌",
  "405": "60日大幅上涨",
  "505": "60日大幅下跌"
}

/** 盘口异动数据 */
export interface PKYD{
  /** 时间 */
  time: string,
  /** 股票代码 */
  code: string,
  /** 股票市场 */
  market: string,
  /** 股票名称 */
  name: string,
  /** 盘口移动代码 */
  pkydcode: string,
  /** 盘口移动标题 */
  title:string,
  /** 盘口异动说明 */
  intro: string,
  /** 盘口移动颜色 1为红色，2为绿色 */
  color: string
}

/**
 * 获取单个股票盘口异动
 * @param quotecode 
 * @param maxsize 
 * @returns 
 */
export async function getOneStockPKYD(quotecode: string, maxsize=20):Promise<Array<PKYD>>{
  try {
    let back = await $.ajax({
      url: api_url.getUrl('quote_api') + 'api/qt/pkyd/get?&cb=?',
      type: 'GET',
      dataType: 'jsonp',
      data: {
        fields: 'f1,f2,f3,f4,f5,f6,f7',
        secids: quotecode,
        lmt: maxsize,
        ut,
        wbp2u: delayparams
      }
    })
    
    if(back && back.data && back.data.pkyd && back.data.pkyd instanceof Array){
      return back.data.pkyd.map((v:string)=>{
        //"09:32:00,688003,1,天准科技,304,25.15元,2"
        let temparray = v.split(',')
        return {
          time: temparray[0],
          code: temparray[1],
          market: temparray[2],
          name: temparray[3],
          pkydcode: temparray[4],
          title: types[temparray[4] as keyof typeof types],
          intro: temparray[5],
          color: temparray[6],
        }
      })
    }
    else{
      return []
    }

  } catch (error) {
    console.error(error)
    return []
  }
}

/**
 * 获取所有盘口异动
 * @param maxsize 
 * @returns 
 */
export async function getAllStockPKYD(maxsize=5):Promise<Array<PKYD>>{
  try {
    let back = await $.ajax({
      url: api_url.getUrl('quote_api') + 'api/qt/pkyd/get?&cb=?',
      type: 'GET',
      dataType: 'jsonp',
      data: {
        fields: 'f1,f2,f3,f4,f5,f6,f7',
        lmt: maxsize,
        ut,
        wbp2u: delayparams
      }
    })
    
    if(back && back.data && back.data.pkyd && back.data.pkyd instanceof Array){
      return back.data.pkyd.map((v:string)=>{
        //"09:32:00,688003,1,天准科技,304,25.15元,2"
        let temparray = v.split(',')
        return {
          time: temparray[0],
          code: temparray[1],
          market: temparray[2],
          name: temparray[3],
          pkydcode: temparray[4],
          title: types[temparray[4] as keyof typeof types],
          intro: temparray[5],
          color: temparray[6],
        }
      })
    }
    else{
      return []
    }

  } catch (error) {
    console.error(error)
    return []
  }
}