/**
 * 页面主JS图
 */

/**
 * TODO
 * K线图除复权标志
 */

import React,{useState, useEffect} from "react"
import './index.less'
import { KlineChart } from "./kline_chart";
import { StaticKChart } from "./k_static";
import { TimeSSEChart } from "./time_sse_chart";
import { StaticTimeChart } from "./time_static";
import { FullScreenBtn, FullScreenChart, FullScreenLink } from "../fullscreen_chart";
import localstorage from "../../../modules/localstorage";
import dayjs from 'dayjs'
import hk_rule from "../../../modules/hk_rule";
import { sendStat } from "../../../modules/bigdata_stat";

/** 图片类型 */
enum ChartType {
  "图片版",
  "动图版",
  "极速版"
}

/** 分时图类型 */
export enum TimeType{
  "盘前",
  "盘后",
  "一天",
  "二天",
  "三天",
  "四天",
  "五天",
}

/** K线图类型 */
export enum KType{
  "日K",
  "周K",
  "月K",
  "5分钟",
  "15分钟",
  "30分钟",
  "60分钟",
}

/** K线图复权类型 */
export enum KFQType{
  "前复权",
  "后复权",
  "不复权"
}

function getMemoryType(){
  let memory_type = localstorage.get('charts_type')
  if(memory_type == '1'){
    return ChartType.动图版
  }
  else if(memory_type == '0'){
    return ChartType.图片版
  }  
  return ChartType.动图版
}

function setMemoryType(inputtype: ChartType){
  if(inputtype == ChartType.动图版){
    localstorage.set('charts_type', '1')
  }
  else if(inputtype == ChartType.图片版){
    localstorage.set('charts_type', '0')
  }
}

/** 页面主JS图 */
export function MainQuoteCharts(props:{
  market: string,
  code: string,
  quotecode: string,
  /** 显示全屏按钮 默认不显示 */
  fullscreen?: boolean,
  /** 显示除复权 默认显示 */
  show_fq?: boolean,
  /** 是否显示盘前 */
  show_pq?: boolean,
   /** 是否显示盘后 */
  show_ph?: boolean,
  /** 显示动态图 */
  show_dtt?: boolean,
  /** 页面类型 */
  pagetype?: string,
  /** 股价提醒广告 */
  gjtx_ad?: boolean,
  /** 显示概念版链接 */
  show_concept?: boolean,
  /** 是否需要盘口移动打点 */
  pkyd_dot?:boolean,
  /** 概念版页面推广图片 */
  concept_img?: boolean,
  /** 显示筹码分布图图片 */
  cmfb_img?: boolean
}){

  let fullscreen = props.fullscreen || false
  let show_fq = props.show_fq !== undefined ? props.show_fq : true
  let show_pq = props.show_pq !== undefined ? props.show_pq : false
  let show_ph = props.show_ph !== undefined ? props.show_ph : false
  let show_dtt = props.show_dtt !== undefined ? props.show_dtt : true

  let default_timetype = TimeType.一天 //默认分时图类型
  if(show_pq){ 
    if(dayjs().isBefore(dayjs().hour(9).minute(30)) && dayjs().isAfter(dayjs().hour(9).minute(0))){
      default_timetype = TimeType.盘前
    }
  }
  if(show_ph){ 
    if(dayjs().isBefore(dayjs().hour(16).minute(0)) && dayjs().isAfter(dayjs().hour(15).minute(0))){
      default_timetype = TimeType.盘后
    }
  }


  const [this_chart_type, SetChartType] = useState(show_dtt ? getMemoryType() : ChartType.图片版)
  const [this_time_type, SetTimeType] = useState(default_timetype as TimeType)
  const [this_k_type, SetKType] = useState(KType.日K)
  const [this_kfq_type, SetKFQType] = useState(KFQType.前复权)
  const [this_zoom_number, SetZoomNumber] = useState(0)
  const [isBrowser, SetIsBrowser] = useState(false)

  const [hk_nofresh, SetHKNoFresh] = useState(false)

  const changeChartType = function(totype:ChartType){
    SetChartType(totype)
    setMemoryType(totype)
  }

  const changeTimeType = function(totype:TimeType){
    SetTimeType(totype)
  }

  const changeKType = function(totype:KType){
    SetKType(totype)
  }

  const changeKFQType = function(totype:KFQType){
    SetKFQType(totype)
  }

  const changeZoomNumber = function(zoom:number){
    let rnd = Math.floor(Math.random()*10000000+1)
    // SetZoomNumber((num)=>{
    //   return num + zoom
    // })
    SetZoomNumber(zoom * rnd)
  }

  useEffect(()=>{
    SetIsBrowser(true)

    if(props.pagetype == 'hk'){
      hk_rule.isDelayCache().then(function(back){
        if(back.re){
          SetHKNoFresh(true)
        }
      })
    }
    
  }, []) 


  // console.info(this_chart_type)

  let timetypelist = [TimeType.一天, TimeType.二天,TimeType.三天,TimeType.四天,TimeType.五天]

  if(show_ph){ 
    timetypelist.unshift(TimeType.盘后)
  }
  if(show_pq){ 
    timetypelist.unshift(TimeType.盘前)
  }

  if(props.pagetype == 'qihuo' || props.pagetype == 'hk' || props.pagetype == 'gzqh' || props.pagetype == 'globalfuture' || props.pagetype == 'option'){
    timetypelist = []
  }

  let ktypelist = [KType.日K, KType.周K, KType.月K, KType['5分钟'], KType['15分钟'], KType['30分钟'], KType['60分钟']]

  if(props.pagetype == 'fund' || props.pagetype == 'qihuo' || props.pagetype == 'b' || props.pagetype == 'hk' || props.pagetype == 'gzqh' || props.pagetype == 'globalfuture' || props.pagetype == 'option'){ //基金 期货
    ktypelist = [KType.日K, KType.周K, KType.月K]
  }

  let ChartTypeList = [ChartType.图片版, ChartType.动图版]
  if(props.show_concept){
    ChartTypeList.push(ChartType.极速版)
  }

  let ChartTypeList_Stat = ['webjdb.bbqh.tpb', 'webjdb.bbqh.dtb', 'webjdb.bbqh.jsb']

  return (
    <div className="mainquotecharts">
      {
        fullscreen &&
        <FullScreenChart quotecode={props.quotecode} pagetype={props.pagetype} market={props.market} code={props.code}></FullScreenChart>
      }
      {
        timetypelist.length > 0 &&
          <div className="mqc_time_header">
            <ul className="time_tab mqc_tab">
              {
                isBrowser && timetypelist.map(v=>
                  <li key={v}>
                    <a href="#" className={this_time_type == v ? 'active' : ''} onClick={(e)=>{e.preventDefault();changeTimeType(v)}}>{TimeType[v]}</a>
                  </li>
                )
              }
            </ul>

            {
              props.gjtx_ad &&
                <a href="https://acttg.eastmoney.com/pub/web_act_ggds_hqtsf_01_01_03_0" target="_blank" className="mcharts_txtg" onClick={()=>{try {window.sendTrackLog('div','click','webjdb.dygjtx.dygjtx','','https://acttg.eastmoney.com/pub/web_act_ggds_hqtsf_01_01_03_0')} catch (error) {}}}>
                  <em></em>订阅股价提醒
                </a>              
            }

            <div className="typechoice mqc_tab2">
              {
                isBrowser && show_dtt &&
                  <ul>
                    {
                      ChartTypeList.map((v,index)=>
                        <li key={index}>
                          {
                            (v == ChartType.图片版 || v == ChartType.动图版) &&
                                <a href="#" className={this_chart_type === v ? 'active' : 'noactive'} onClick={(e)=>{e.preventDefault();changeChartType(v); sendStat('DIV', 'Click', ChartTypeList_Stat[v])}}>{ChartType[v]}</a>                            
                          }
                          {
                            v == ChartType.极速版 &&
                                <a href={`//quote.eastmoney.com/unify/cr/${props.market}.${props.code}`} target="_blank" onClick={()=>{sendStat('DIV', 'Click', ChartTypeList_Stat[v])}}>{ChartType[v]}</a>                            
                          }
                        </li>
                      )
                    }
                  </ul>         
              }
              {
                fullscreen &&
                <div className={`mqc_fullscreen ${props.gjtx_ad?'mqc_fullscreen_down':''}`}>
                  <FullScreenBtn></FullScreenBtn>
                </div>             
              }
            </div>
          </div>        
      }



      <div className="time_chart">
        <FullScreenLink fullscreen={fullscreen}>
          {
            this_chart_type == ChartType.动图版 &&
            <TimeSSEChart quotecode={props.quotecode} timetype={this_time_type} pkyd_dot={props.pkyd_dot} ></TimeSSEChart> 
          }
          {
            this_chart_type == ChartType.图片版 &&
              <StaticTimeChart quotecode={props.quotecode} timetype={this_time_type} pagetype={props.pagetype} market={props.market} refresh_time={hk_nofresh?0:20}></StaticTimeChart>
          }        
        </FullScreenLink>
        {
          this_chart_type == ChartType.图片版 &&
          <a href="http://acttg.eastmoney.com/pub/web_app_hqy_zst_01_01_01_0" target="_blank"
          className="picrfixad"><img alt="" src="/newstatic/images/chartgg.png" /></a>
        }
        {
          props.concept_img && this_chart_type == ChartType.动图版 &&
          <a href={`//quote.eastmoney.com/unify/cr/${props.quotecode}?from=classic`}  target="_blank"  className="concept_tg_img"><img src="/newstatic/images/ty.png" /></a>
        }
      </div>
      <div className="mqc_k_header">
        <ul className="k_tab mqc_tab">
          {
            ktypelist.map(v=> //K线图类型
              <li key={v}>
                <a href="#" className={this_k_type == v ? 'active' : ''} onClick={(e)=>{e.preventDefault();changeKType(v)}}>{KType[v]}</a>
              </li>
            )
          }
          {
            props.pagetype == 'fund' &&
              <li>
                <a href={`//fund.eastmoney.com/${props.code}.html`} target="_blank">净值</a>
              </li>            
          }
        </ul>
        
        <div className={`kzoom ${show_fq == false && fullscreen == false ? 'kzoomr' : ''}`}>
          <a href="#" onClick={(e)=>{e.preventDefault();changeZoomNumber(1)}}>拉长K线</a>
          <a href="#" onClick={(e)=>{e.preventDefault();changeZoomNumber(-1)}}>缩短K线</a>
        </div>

        {
          show_fq &&
            <div className="kfq">
              <div className="kfq_t">{KFQType[this_kfq_type]}</div>
              <ul>
                {
                  [KFQType.前复权, KFQType.后复权, KFQType.不复权].map(v=>
                    <li key={v}>
                      <a href="#" className={this_kfq_type == v ? 'active' : ''} onClick={(e)=>{e.preventDefault();changeKFQType(v)}}>{KFQType[v]}</a>
                    </li>
                  )
                }            
              </ul>
            </div>
        }


        {
          fullscreen && show_fq &&
          <div className="mqc_fullscreen2">
            <FullScreenBtn></FullScreenBtn>
          </div>             
        }
        {
          fullscreen && !show_fq &&
          <div className="mqc_fullscreen3">
            <FullScreenBtn></FullScreenBtn>
          </div>             
        }        
      </div>
      <div className="k_chart">
 
          {
            this_chart_type == ChartType.动图版 &&
            <KlineChart quotecode={props.quotecode} ktype={this_k_type} kfqtype={this_kfq_type} zoom_number={this_zoom_number} fullscreen={fullscreen}></KlineChart> 
          }
          {
            this_chart_type == ChartType.图片版 &&
            <StaticKChart market={props.market} quotecode={props.quotecode} ktype={this_k_type} kfqtype={this_kfq_type} zoom_number={this_zoom_number} fullscreen={fullscreen} pagetype={props.pagetype}></StaticKChart>
          }            
          {
            props.concept_img && this_chart_type == ChartType.图片版 &&
            <a href={`//quote.eastmoney.com/unify/cr/${props.quotecode}?from=classic`}  target="_blank"  className="concept_tg_img_k"><img src="/newstatic/images/ty.png" /></a>
          }
          {
            props.cmfb_img && this_chart_type == ChartType.动图版 &&
              <CMFB_IMG quotecode={props.quotecode}></CMFB_IMG>
          }
      </div>
    </div>
  )
}

/** 筹码分布图示例和链接 */
function CMFB_IMG(props:{
  quotecode: string
}){

  const [show, SetShow] = useState(true)

  const changeShow = function(){
    SetShow((show)=>!show)
  }

  return (
    <div className="cmfb_img">
      <div className="t" onClick={(e)=>{changeShow();e.preventDefault();}}>均线 <em></em></div>
      <a style={{display: show?'block':'none'}} href={`//quote.eastmoney.com/unify/cr/${props.quotecode}#chart-k-cyq`} target="_blank">
        <img src="/newstatic/images/cyq.png" alt="" />
      </a>
    </div>
  )
}