/**
 * 路演组件
 */



import React,{useState, useEffect} from "react"
import { getCFHTG } from "../../../modules/cfh/web"
import {getLuyanAD} from '../../../modules/luyan/web'
import { AdIframe } from "../ad_iframe"
import './index.less'



/** 路演广告条-指数页面用 */
export function LuYanAD_ZS(props:{
  market: string,
  code: string
}){

  const [showdata, SetShowData] = useState([] as Array<{
    title:string,
    channel_id: string,
    img: string
  }>)

  useEffect(()=>{

    ;(async()=>{
      let data = await getLuyanAD()

      let zs_finddata = data.zs_code.find((v: any)=>{
        return v.market == props.market && v.code == props.code
      })
      //如果有数据，并且图片也有数据
      if(zs_finddata && data.zs_ch instanceof Array && data.zs_ch.length > 0){
        let backdata = data.zs_ch.map(v=>{
          return {
            title: v.name,
            channel_id: v.channel_id,
            img: v.bg.img
          }
        })
        SetShowData(backdata)

        var thisroll = $('.luyangg_title').vTicker({
          speed: 200,
          pause: 5000,
          position: 'absolute',
          showItems: 1,
          down_btn: $('#luyangg_titles_up'),
          up_btn: $('#luyangg_titles_down'),
          onChange: function(index:number){
            $('#luyangg_link').attr('href', 'https://roadshow.eastmoney.com/luyan/' + backdata[index].channel_id)
            $('#luyangg_img').attr('src', backdata[index].img)
          }
        });
        
      }
      
    })();

  },[])

  if(showdata.length > 1){
    return (
      <div className="luyangg">
        <a id="luyangg_link" href={`https://roadshow.eastmoney.com/luyan/${showdata[0].channel_id}`} target="_blank">
          <img id="luyangg_img" src={showdata[0].img} />
          <div className="luyangg_title">
            <ul>
              {
                showdata.map((v,index)=>
                  <li key={index}>{v.title}</li>
                )
              }
            </ul>
          </div>
        </a>
        <a href="#" target="_self" className="up" id="luyangg_titles_up" />
        <a href="#" target="_self" className="down" id="luyangg_titles_down" />
      </div>
    )    
  }

  return <></>

}


/** 路演广告条-A股页面用 */
export function LuYanAD_A(props:{
  market: string,
  code: string
}){

  //路演广告数据
  const [showdata, SetShowData] = useState(null as null | {
    title:string,
    channel_id: string,
    img: string
  })

  //财富号推广数据
  const [cfh_tg, SetCFHTG] = useState(null as null | {
    title: string,
    url: string
  })

  useEffect(()=>{

    ;(async()=>{
      try {
        let data = await getLuyanAD()

        let a_finddata = data.ch.find((v: any)=>{
          return v.quote_code.some((v2:any)=>{
            return v2.market == props.market && v2.code == props.code
          })
        })
        //如果有数据，并且图片也有数据
        if(a_finddata && a_finddata?.bg?.quote?.img ){
          SetShowData({
            title: a_finddata.name,
            channel_id: a_finddata.channel_id,
            img: a_finddata.bg.quote.img
          })
        }
        else{ //加载财富号推广
          let cfh_tg_data = await getCFHTG()
          // console.info(cfh_tg_data)
          if(cfh_tg_data.length > 0){
            let findone = cfh_tg_data.find(v=>v.stockCode == props.market + '_' + props.code)
            if(findone){
              SetCFHTG({
                title: findone.title,
                url: findone.webUrl
              })
            }
          }
        }
      } catch (error) {
        
      }

      
    })();

  },[])

  if(showdata){
    return (
      <div className="luyangg luyangg_a">
        <a id="luyangg_link" href={`https://roadshow.eastmoney.com/luyan/${showdata.channel_id}`} target="_blank">
          <img id="luyangg_img" src={showdata.img} />
          <div className="luyangg_title_a">
            <ul>
              <li title={showdata.title}>{showdata.title}</li>
            </ul>
          </div>
        </a>

      </div>
    )    
  }

  if(cfh_tg){
    return (
      <div className="cfh_tg">
        <a id="luyangg_link" href={`${cfh_tg.url}`} target="_blank">
          <img src="/newstatic/images/cfh_tg.png" alt="" />
          <div className="title">{cfh_tg.title}</div>
        </a>

      </div>
    )      
  }

  return <AdIframe width={790} height={34} adid={'1063'}></AdIframe>

}