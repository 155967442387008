/**
 * 路演接口
 */

import config from "../../config/web";

/** 路演中心广告类型 */
export interface type_luyanad{
  ch: Array<{
    channel_id: string,
    name: string,
    quote_code:Array<{
      market: number,
      code: string
    }>,
    bg: {
      quote:{
        img:string
      }
    }
  }>,
  zs_ch:Array<{
    channel_id: string,
    name: string,
    bg:{
      img: string
    }
  }>,
  zs_code: Array<{
    market: number,
    code: string
  }>
}

/** 获取路演中心广告接口 */
export async function getLuyanAD():Promise<type_luyanad>{
  let back = await $.ajax({
    url: config.getUrl('roadshow_api') + 'EMAV/dist.channel.em',
    type: 'GET',
    dataType: 'jsonp',
    jsonpCallback: 'emroadshow'
  })

  return back
}