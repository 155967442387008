import React from "react"
import api_url from "../../config/web";
import _ from "lodash";
import {getDelayparams} from './delayparams'
import { QuoteItem, ListItemConfig, OneItemConfig } from "./config";
import { QuoteSSE } from "../sse";

const ut = 'fa5fd1943c7b386f172d6893dbfba10b'

//延迟参数
var delayparams = getDelayparams()

export const QuoteConfigItem = QuoteItem

/** 行情字段的值类型 */
export interface type_quote_item{
  /** 输出文本 */
  txt: string,
  /** 颜色class */
  color: 'price_up'|'price_down'|'price_draw'|'',
  /** 输出html */
  html: JSX.Element,
  /** 输出带闪烁的html */
  blink_html: JSX.Element,
  /** 原值 */
  source: any
}

/**
 * 行情返回结果
 */
export type type_quote_back = {
  [key in QuoteItem] : type_quote_item
}

/**
 * 初始化单个股票的各项值
 */
export function initSingleValue():type_quote_back{
  let returnobj:any = {} 
  for(let key in QuoteItem){
    returnobj[key] = {
      txt: '-',
      color: '',
      html: <>-</>,
      blink_html: <>-</>,
      source: '-'
    }
  }
  return returnobj
}

/**
 * 生成调用一组行情的fields字符串
 * @param itemlist 
 * @returns 
 */
function ListItemFields(itemlist: Array<QuoteItem>){
  
  let fids = [] as Array<string>
  
  itemlist.forEach(v=>{    
    fids = fids.concat(ListItemConfig[v as keyof typeof ListItemConfig].fid)
  })

  let fid_list = _.uniq(fids).filter(v=>v!='')



  return fid_list.join(',')
}

/**
 * 生成调用单个行情的fields字符串
 * @param itemlist 
 * @returns 
 */
function OneItemFields(itemlist: Array<QuoteItem>){

  let fids = [] as Array<string>
  
  itemlist.forEach(v=>{
    fids = fids.concat(OneItemConfig[v as keyof typeof OneItemConfig].fid)
  })


  let fid_list = _.uniq(fids).filter(v=>v!='')

  //处理兼容的冗余字段
  //530 > 531 > 532
  if(fid_list.find(v=>v=='f530')){
    fid_list =  fid_list.filter(v=>v != 'f531' && v != 'f532')
  }
  else if(fid_list.find(v=>v=='f531')){
    fid_list =  fid_list.filter(v=>v != 'f532')
  }


  return fid_list.join(',')
}

/**
 * 处理行情接口返回的列表数据
 * @param data 
 * @param itemlist 
 * @returns 
 */
function BackListData(data:any, itemlist: Array<QuoteItem>):Array<type_quote_back>{
  if(!(data?.data?.diff instanceof Array)){
    return []
  }
  return data.data.diff.map((v:any)=>{
    let onereturn = {} as any
    itemlist.forEach((i:QuoteItem)=>{
      onereturn[i] = ListItemConfig[i as keyof typeof ListItemConfig].value(v)
    })
    return onereturn
  })
}

/**
 * 处理行情接口返回的单个股票数据
 * @param data 
 * @param itemlist 
 * @returns 
 */
function BackOneData(data:any, itemlist: Array<QuoteItem>):type_quote_back | null{

  if(data?.lt){
    window.lt = data.lt
  }
  
  if(!(data?.data)){
    return null
  }
  let onereturn = {} as any
  itemlist.forEach((i:QuoteItem)=>{
    onereturn[i] = OneItemConfig[i as keyof typeof OneItemConfig].value(data.data)
  })
  return onereturn
}

/**
 * 获取单个的行情数据
 * @param quotecode 行情代码
 * @param itemlist 配置列表
 * @param sse_callback 推送回调函数
 * @returns 
 */
export async function getOneQuote(quotecode: string, itemlist: Array<QuoteItem>, sse_callback?:Function){
  // console.info(sse_callback)
  
  let quoteback = await $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/stock/get?invt=2&fltt=1&cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data: {
      fields: OneItemFields(itemlist),
      secid: quotecode,
      ut,
      wbp2u: delayparams,
      dect: 1
    }
  })

  if(sse_callback != undefined){
    getOneQuoteSSE(quotecode, itemlist, function(data:type_quote_back){
      sse_callback(data)
    })
  }
  
  return BackOneData(quoteback, itemlist)
}

/**
 * 获取基于reqt单个的行情特殊数据
 * @param quotecode 行情代码
 * @param itemlist 配置列表
 * @param reqt reqt参数
 * @returns 
 */
export async function getOneReqtQuote(quotecode: string, itemlist: Array<QuoteItem>, reqt: number){
  
  let quoteback = await $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/stock/get?invt=2&fltt=1&cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data: {
      reqt,
      fields: OneItemFields(itemlist),
      secid: quotecode,
      ut,
      wbp2u: delayparams
    }
  })
  
  return BackOneData(quoteback, itemlist)
}

/**
 * 获取单个的行情推送数据
 * @param quotecode 行情代码
 * @param itemlist 配置列表
 * @param sse_callback 推送回调函数
 * @returns 
 */
export async function getOneQuoteSSE(quotecode: string, itemlist: Array<QuoteItem>, sse_callback:Function){
  
  //推送地址
  let url = `${api_url.getUrl('quote_push_api')}api/qt/stock/sse?fields=${OneItemFields(itemlist)}&mpi=1000&invt=2&fltt=1&secid=${quotecode}&ut=${ut}&dect=1&wbp2u=${delayparams}`

  let sse = QuoteSSE(url)// { withCredentials: true }
  let quote_data:any = {
    data: {}
  }
  sse.onmessage = function(e){
    
    let data = JSON.parse(e.data)
    if(data.data != null){
      if(data.full == 1){ //全量数据
        quote_data.data = data.data
      }

      if(data.full == 0){ //增量数据
        quote_data.data = Object.assign(quote_data.data, data.data)
      }
      
      sse_callback(BackOneData(quote_data, itemlist))
    }
  } 
}

/**
 * 获取一组行情数据
 * @param quotecode_list 行情代码数组
 * @param itemlist 配置列表
 * @returns 
 */
export async function getListQuote(quotecode_list: Array<string> | string, itemlist: Array<QuoteItem>, fid?:string, po?: 0|1, pz = 20){
  
  let quoteback = await $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/ulist/get?fltt=1&invt=2&cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data: {
      fields: ListItemFields(itemlist),
      secids: typeof quotecode_list == 'string' ? quotecode_list : quotecode_list.join(','),
      ut,
      fid,
      po,
      pn: 1,
      np: 1,
      pz,
      dect: 1,
      wbp2u: delayparams
    }
  })
  
  return BackListData(quoteback, itemlist)
}

/**
 * 获取行情四分位数据
 * @param quotecode_list 行情代码数组
 * @param itemlist 配置列表
 * @returns 
 */
export async function getQuote4fw(quotecode: string, itemlist: Array<QuoteItem>){
  
  let quoteback = await $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/slist/get?fltt=1&invt=2&cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data: {
      fields: ListItemFields(itemlist),
      secid: quotecode,
      ut,
      pn: 1,
      np: 1,
      spt: 1,
      wbp2u: delayparams
    }
  })
  
  return BackListData(quoteback, itemlist)
}

/**
 * 获取股票期权T型报价列表
 * @param quotecode_list 行情代码数组
 * @param itemlist 配置列表
 * @returns 
 */
export async function getQiquanTList(quotecode: string, itemlist: Array<QuoteItem>){
  
  let quoteback = await $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/slist/get?fltt=1&invt=2&cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data: {
      fields: ListItemFields(itemlist),
      secid: quotecode,
      fid: 'f161',
      ut,
      pn: 1,
      np: 3,
      spt: 6,
      wbp2u: delayparams
    }
  })
  
  return BackListData(quoteback, itemlist)
}

/**
 * 股票相关个股
 * @param quotecode 
 * @param itemlist 
 * @returns 
 */
export async function stockXGStock(quotecode: string, itemlist: Array<QuoteItem>, fid = 'f3', size = 6){
  
  let quoteback = await $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/slist/get?fltt=1&invt=2&cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data: {
      fields: ListItemFields(itemlist),
      secid: quotecode,
      // fid: 'f161',
      ut,
      pi: 0,
      po: 1,
      np: 1,
      fid,
      pz: size,
      spt: 4,
      wbp2u: delayparams
    }
  })
  
  return BackListData(quoteback, itemlist)
}

/**
 * 股票相关板块
 * @param quotecode 
 * @param itemlist 
 * @returns 
 */
export async function stockXGBK(quotecode: string, itemlist: Array<QuoteItem>, size = 5){
  
  let quoteback = await $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/slist/get?fltt=1&invt=2&cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data: {
      fields: ListItemFields(itemlist),
      secid: quotecode,
      // fid: 'f161',
      ut,
      pi: 0,
      po: 1,
      np: 1,
      pz: size,
      spt: 3,
      wbp2u: delayparams
    }
  })
  
  return BackListData(quoteback, itemlist)
}

/**
 * 股票相关板块阶段涨幅
 * @param quotecode 
 * @param itemlist 
 * @returns 
 */
export async function stockXGBKZDZF(quotecode: string, itemlist: Array<QuoteItem>){
  
  let quoteback = await $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/slist/get?fltt=1&invt=2&cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data: {
      fields: ListItemFields(itemlist),
      secid: quotecode,
      // fid: 'f161',
      ut,
      pi: 0,
      po: 1,
      np: 1,
      spt: 1,
      wbp2u: delayparams
    }
  })
  
  return BackListData(quoteback, itemlist)
}


/**
 * 指数成分股列表
 * @param quotecode 
 * @param itemlist 
 * @returns 
 */
export async function indexComponentList(quotecode: string, itemlist: Array<QuoteItem>, fid='f3', pagesize=8 , po = 1 as 0|1){
  
  let quoteback = await $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/slist/get?fltt=1&invt=2&cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data: {
      fields: ListItemFields(itemlist),
      secid: quotecode,
      fid: fid,
      pz: pagesize,
      ut,
      // pi: 0,
      po: po,
      np: 1,
      spt: 11,
      pn: 1,
      wbp2u: delayparams
    }
  })
  
  return BackListData(quoteback, itemlist)
}

/**
 * 股票阶段涨幅(股票+三个板块)
 * @param quotecode 
 * @param itemlist 
 * @returns 
 */
export async function stockBKListZF(quotecode: string, itemlist: Array<QuoteItem>){
  
  let quoteback = await $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/slist/get?fltt=1&invt=2&cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data: {
      spt: 7,
      fields: ListItemFields(itemlist),
      secid: quotecode,
      np: 3,
      wbp2u: delayparams
    }
  })
  
  return BackListData(quoteback, itemlist)
}


/**
 * 获取港股经纪队列
 * @param quotecode 
 * @returns 
 */
export async function getHKJJDL(quotecode: string):Promise<Array<string>>{
  try {
    let quoteback = await $.ajax({
      url: api_url.getUrl('quote_api') + 'api/qt/stock/brkq/get?fltt=1&invt=2&cb=?',
      type: 'GET',
      dataType: 'jsonp',
      data: {
        fields: 'f1',
        secid: quotecode,
        ut,
        wbp2u: delayparams
      }
    })
    
    if(quoteback?.data?.brkq instanceof Array){
      return quoteback.data.brkq
    }

    return []   
  } catch (error) {
    return []
  }
}


/**
 * 带fs过滤功能的行情列表信息
 * @param fs fs过滤字符串
 * @param itemlist 配置列表
 * @param fid 排序字符串
 * @param pn 第几页 1开始
 * @param pz 每页多少条 默认10
 * @param po 排序 1 倒叙 0 正序 默认0
 * @returns 
 */
export async function searchListQuote(fs:string, itemlist: Array<QuoteItem>, fid:string, pn = 1, pz = 10 , po = 1 as 0|1){

  let quoteback = await $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/clist/get?np=1&fltt=1&invt=2&cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data: {
      fs,
      fields: ListItemFields(itemlist),
      fid,
      pn,
      pz,
      po,
      ut,
      dect: 1,
      wbp2u: delayparams
    }
  })
  
  return BackListData(quoteback, itemlist)
}


/**
 * 获取行情分时图数据推送
 * @param quotecode 行情代码
 * @param sse_callback 推送回调
 * @param ndays 默认为1，返回当天的分时数据，最大为5。如果ndays大于1，则iscr参数无效。
 * @param iscr 1 带盘前 0 不带盘前 仅在days为1时有效
 * @param iscca 默认为0，1表示带收盘集合竞价(或科创板盘后)数据，0表示不带，仅在days为1时有效
 */
export function getTimeSSE(quotecode: string, sse_callback:Function, ndays = 1, iscr = 0, iscca = 0, onerror?:Function){
  
  let api = api_url.getUrl('quote_push_api')
  if(ndays > 1){
    api = api_url.getUrl('quote_history_push_api')
  }

  let url = `${api}api/qt/stock/trends2/sse?fields1=f1,f2,f3,f4,f5,f6,f7,f8,f9,f10,f11,f12,f13,f17&fields2=f51,f52,f53,f54,f55,f56,f57,f58&mpi=1000&ut=${ut}&secid=${quotecode}&ndays=${ndays}&iscr=${iscr}&iscca=${iscca}&wbp2u=${delayparams}`

  let sse = new EventSource(url)
  let quote_data:any = {
    fulldata: {}
  }

  sse.onerror = function(e){
    if(onerror){
      onerror(e)
    }
  }
  sse.onmessage = function(e){
    
    let data = JSON.parse(e.data)
    if(data.data != null){
      if(data.full == 1){ //全量数据
        quote_data.fulldata = data
      }

      if(data.full == 0 && data.data.trends && data.data.trends.length > 0 && quote_data.fulldata?.data?.trends){ //增量数据
        // quote_data.fulldata = Object.assign(quote_data.data, data.data)
        data.data.trends.forEach((v:string)=>{
          //尝试搜索有没有相同的时间
          quote_data.fulldata.data.trends = quote_data.fulldata.data.trends.filter((sv:string)=>{
            return v.substring(0,17) != sv.substring(0,17)
          })
        })
        quote_data.fulldata.data.trends = quote_data.fulldata.data.trends.concat(data.data.trends)
      }
      
      sse_callback(quote_data)
    }
  } 
  return sse
}


/**
 * 获取行情分时图推送
 * @param quotecode 行情代码
 * @param ndays 默认为1，返回当天的分时数据，最大为5。如果ndays大于1，则iscr参数无效。
 * @param iscr 1 带盘前 0 不带盘前 仅在days为1时有效
 * @param iscca 默认为0，1表示带收盘集合竞价(或科创板盘后)数据，0表示不带，仅在days为1时有效
 */
export async function getTimeData(quotecode: string, ndays = 1, iscr = 0, iscca = 0){
  
  let api = api_url.getUrl('quote_push_api')
  if(ndays > 1){
    api = api_url.getUrl('quote_history_push_api')
  }

  let back = await $.ajax({
    url: `${api}api/qt/stock/trends2/get?cb=?`,
    type: 'GET',
    dataType: 'jsonp',
    data: {
      fields1: "f1,f2,f3,f4,f5,f6,f7,f8,f9,f10,f11,f12,f13,f17",
      fields2: "f51,f52,f53,f54,f55,f56,f57,f58",
      ut,
      ndays,
      iscr,
      iscca,
      secid: quotecode
    }
  })
  
  return back
}

/**
 * 根据代码数据获取行情数据，并且存储在以quotecode为key的object中
 * @param quotecode_list 
 * @param itemlist 
 */
export async function getStocksListObject(quotecode_list: Array<string>, itemlist: Array<QuoteItem>) {

  let config_item_list = [QuoteItem.行情代码].concat(itemlist)

  let quoteback = await $.ajax({
    url: api_url.getUrl('quote_api') + 'api/qt/ulist/get?fltt=1&invt=2&cb=?',
    type: 'GET',
    dataType: 'jsonp',
    data: {
      fields: ListItemFields(config_item_list),
      secids: quotecode_list.join(','),
      ut,
      pn: 1,
      np: 1,
      pz: quotecode_list.length,
      wbp2u: delayparams
    }
  })

  let quotelist = BackListData(quoteback, config_item_list)

  let returnobj:{
    [key:string]: type_quote_back
  } = {}

  quotelist.forEach(v=>{
    returnobj[v[QuoteItem.行情代码].txt] = v
  })

  return returnobj
}

/** 获取K线图收盘价和涨跌幅 */
export async function getStockKlineData_PriceZD(quotecode: string, size = 30):Promise<Array<{
  date: string,
  price: string,
  zdf: string
}>> {
  try {
    let back = await $.ajax({
      url: api_url.getUrl('quote_history_api') + 'api/qt/stock/kline/get?cb=?',
      type: 'GET',
      dataType: 'jsonp',
      data: {
        fields1: 'f1,f2,f3,f4,f5',
        fields2: 'f51,f53,f59',
        secid: quotecode,
        ut,
        lmt: size,
        klt: 101,
        fqt: 1,
        end: 20500000
      }
    })    
    if(back?.data.klines instanceof Array){
      return back.data.klines.map((v:string)=>{
        let array = v.split(',')
        return {
          date: array[0],
          price: array[1],
          zdf: array[2]
        }
      })
    }
  } catch (error) {
    
  }

  return []

}
