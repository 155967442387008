/**
 * 页面布局
 */
import React,{useState, useEffect} from "react"
import './index.less'

/**
 * 不限宽度的左右布局
 * @returns 
 */
export let Layout_LeftRight = {
  Container(props:{
    children: any
  }){
    return (
      <div className="layout_lr">
        {props.children}
      </div>
    )
  },
  Left(props:{
    children: JSX.Element
  }){
    return (
      <div className="left">
        {props.children}
      </div>
    )
  },
  Right(props:{
    children: JSX.Element
  }){
    return (
      <div className="right">
        {props.children}
      </div>
    )
  }
}



/**
 * 两列-侧-主
 * @returns 
 */
export const Layout_SiderMain : {
  (props:{
    children: any,
    mt?: number
  }): JSX.Element;
  Sider(props:{
    children: React.ReactNode
  }): JSX.Element;
  Main(props:{
    children: React.ReactNode
  }): JSX.Element;
} = function(props){
  return (
    <div className="layout_sm" style={{marginTop: props.mt}}>
      {props.children}
    </div>
  )
}

Layout_SiderMain.Sider = function(props){
  return (
    <div className="layout_sm_sider">
      {props.children}
    </div>
  )
}

Layout_SiderMain.Main = function(props){
  return (
    <div className="layout_sm_main">
      {props.children}
    </div>
  )
}



/**
 * 三列-侧-主-主
 * @returns 
 */
export const Layout_SiderMainMain : {
  (props:{
    children: any,
    mt?: number
  }): JSX.Element;
  Sider(options:any): JSX.Element;
  Main(options:any): JSX.Element;
} = function(props){
  return (
    <div className="layout_smm" style={{marginTop: props.mt}}>
      {props.children}
    </div>
  )
}

Layout_SiderMainMain.Sider = function(props:{
  children: JSX.Element
}){
  return (
    <div className="layout_smm_sider">
      {props.children}
    </div>
  )
}

Layout_SiderMainMain.Main = function(props:{
  children: JSX.Element
}){
  return (
    <div className="layout_smm_main">
      {props.children}
    </div>
  )
}


/** 一级大标题 */
export const PageTitle = function(props:{
  children: JSX.Element
}){
  return (
    <div className="pagetitle">
      <div className="t">
        {props.children}
      </div>
    </div>
  )
}

/**
 * 两列 主均分
 * @returns 
 */
export const Layout_Main2Split : {
  (props:{
    children: any,
    mt?: number
  }): JSX.Element;
  Sider(options:any): JSX.Element;
} = function(props){
  return (
    <div className="layout_m2split" style={{marginTop: props.mt}}>
      {props.children}
    </div>
  )
}

Layout_Main2Split.Sider = function(props:{
  children: JSX.Element
}){
  return (
    <div className="layout_m2split_sider">
      {props.children}
    </div>
  )
}

/**
 * 两列 主分成主-侧
 * @returns 
 */
export const Layout_Main_MainSider : {
  (props:{
    children: any,
    mt?: number
  }): JSX.Element;
  Main(options:any): JSX.Element;
  Sider(options:any): JSX.Element;
} = function(props){
  return (
    <div className="layout_m_ms" style={{marginTop: props.mt}}>
      {props.children}
    </div>
  )
}

Layout_Main_MainSider.Main = function(props:{
  children: JSX.Element
}){
  return (
    <div className="layout_m_ms_m">
      {props.children}
    </div>
  )
}

Layout_Main_MainSider.Sider = function(props:{
  children: JSX.Element
}){
  return (
    <div className="layout_m_ms_s">
      {props.children}
    </div>
  )
}