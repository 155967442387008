import React from "react"
import './index.less'

/**
 * 面包屑 
 * @returns 
 */
export function Breadcrumb(props:{
  children: Array<JSX.Element | "">,
  mt?:number
}){
  return (
    <div className="breadcrumb" style={{marginTop: props.mt}}>
      {props.children.map((item,index)=>
        <React.Fragment key={index}>
          {item}
        </React.Fragment>
      )}
    </div>
  )
}

Breadcrumb.Item = function(props:{
  children: any
}){
  return (
    <span className="breadcrumb_item">
      {props.children}
    </span>
  )
}