/**
 * 行情-最近访问
 */

import React,{useState, useEffect} from "react"
import './index.less'
import { getHistory,history_template, setHistory } from "../../../modules/browser_history/old";
import { TextCut } from "../text";

/** 老的行情最近浏览记录 */
export function OldBrowserHistory(props:{
  /** 最大显示数量 */
  size?:number,
  type?: keyof typeof history_template,
  market?:string,
  code?:string,
  name?:string,
  maxwidth?: number
}){

  let size = props.size || 8

  const [showdata, SetShowData] = useState([] as Array<{
    name: string,
    link: string
  }>)

  useEffect(()=>{
    //写入记录
    if(props.type != undefined && props.market != undefined && props.code != undefined && props.name != undefined){
      setHistory(props.type, props.market, props.code, props.name)
    }
    //读取记录
    SetShowData(getHistory(size))

  }, [])

  let cssobj = {}
  if(props.maxwidth){
    cssobj = {maxWidth: props.maxwidth + 'px'}
  }

  return (
    <div className="quote_history" style={cssobj}>
      <span>最近访问：</span>
      {
        showdata.map((v, index)=>
          <a key={index} href={v.link} target="_blank" title={v.name}><TextCut width={110}>{v.name}</TextCut></a>
        )
      }
    </div>
  )
}