import React,{useState, useEffect} from "react"
import { SiderTabBox } from "../sider_tab_box"
import { useOneQuote, QuoteItem } from '../../../modules/quote_info/quote_hook'
import { type_quote_back, initSingleValue } from '../../../modules/quote_info/quote_data'
import * as Layout from '../../shared/layout';
import './index.less'
import { OneBulletin } from "../bulletin";

/**
 * 资金流图表
 * @returns 
 */
export function ZJLCharts(props:{
  quotecode: string,
  market: string,
  code: string,
  type: 'astock' | 'bstock' | 'bk'
}){

  let zjl_data = useOneQuote(props.quotecode, [
    QuoteItem.超大单流入,
    QuoteItem.超大单流出,
    QuoteItem.大单流入,
    QuoteItem.大单流出,
    QuoteItem.中单流入,
    QuoteItem.中单流出,
    QuoteItem.小单流入,
    QuoteItem.小单流出,
    QuoteItem.主力净流入,
    QuoteItem.主力净占比,
    QuoteItem.超大单净流入,
    QuoteItem.超大单净占比,
    QuoteItem.大单净流入,
    QuoteItem.大单净占比,
    QuoteItem.中单净流入,
    QuoteItem.中单净占比,
    QuoteItem.小单净流入,
    QuoteItem.小单净占比,
  ], 60)

  if(zjl_data == null){
    zjl_data = initSingleValue()
  }

  let datalink = `//data.eastmoney.com/zjlx/${props.code}.html`
  if(props.type == 'bk'){
    datalink = `//data.eastmoney.com/bkzj/${props.code}.html`
  }

  let f10code = (props.market == '0'? 'sz' : 'sh') + props.code

  return (
    <>
      {
        (props.type == 'bk' || props.type == 'bstock') &&
          <Layout.PageTitle>
            <a href={datalink} target="_blank">实时资金流</a>
          </Layout.PageTitle>        
      }
      {
        (props.type == 'astock') &&
          <div className="astockzjltab self_clearfix">
            <ul>
              <li className="active"><a href={datalink} target="_blank">实时资金流</a></li>
              <li><a href={`//emweb.securities.eastmoney.com/PC_HSF10/NewFinanceAnalysis/Index?type=web&code=${f10code}`} target="_blank">财务指标</a></li>
              <li><a href={`//data.eastmoney.com/report/${props.code}.html`} target="_blank">机构观点</a></li>
              <li><a href={`//emweb.securities.eastmoney.com/PC_HSF10/IndustryAnalysis/Index?type=web&code=${f10code}#hyzx-0`} target="_blank">行业排名</a></li>
            </ul>
            <div className="tg"><OneBulletin id="704"></OneBulletin></div>
          </div>        
      }
      

      <div className="zjl_charts self_clearfix">
        <div className="lbt">
          <SiderTabBox {...{
            boxdata:[
              {
                tab_html: <i>实时成交分布图</i>
              }
            ]}}>
              <ZJL_BT datalink={datalink} quotecode={props.quotecode} code={props.code} zjl_data={zjl_data}></ZJL_BT>
            </SiderTabBox>
        </div>
        <div className="rxt">
          <SiderTabBox {...{
            boxdata:[
              {
                tab_html: <a href={datalink} target="_blank">实时资金流向图</a>,
                body: <ZJL_SS datalink={datalink} quotecode={props.quotecode} code={props.code} zjl_data={zjl_data}></ZJL_SS>
              },
              {
                tab_html: <a href={datalink} target="_blank">盘后资金流向趋势</a>,
                body: <ZJL_PH datalink={datalink} quotecode={props.quotecode} code={props.code}></ZJL_PH>
              }
            ]}}></SiderTabBox>        
        </div>
        
      </div>    
    </>

  )
}

declare var emchartPiechartNew:any

/**
 * 资金流饼图
 */
function ZJL_BT(props:{
  quotecode: string,
  zjl_data: type_quote_back,
  code: string,
  datalink: string
}){

  useEffect(()=>{
    if (document.createElement('canvas').getContext != undefined && typeof emchartPiechartNew != 'undefined') {
      // 资金流饼图初始化
      var pieChart = new emchartPiechartNew({
          boxid: 'zjlbchart',
          width: 325,
          height: 250,
          radius: 80,
          secid: props.quotecode
      }).init();
    }
  }, [])


  return (
    <div className="zjl_bt">
      <a href={props.datalink} target="_blank"><div id="zjlbchart"></div></a>
      <div className="zjl_bt_nums self_clearfix">
        <ul>
          <li className="lic">流入</li>
          <li className="lic">流出</li>
          <li><span className="tl cdd_lr"></span>超大单(<span className="price_up">{props.zjl_data[QuoteItem.超大单流入].txt}</span>)</li>
          <li><span className="tl cdd_lc"></span>超大单(<span className="price_down">{props.zjl_data[QuoteItem.超大单流出].txt}</span>)</li>
          <li><span className="tl dd_lr"></span>大单(<span className="price_up">{props.zjl_data[QuoteItem.大单流入].txt}</span>)</li>
          <li><span className="tl dd_lc"></span>大单(<span className="price_down">{props.zjl_data[QuoteItem.大单流出].txt}</span>)</li>
          <li><span className="tl zd_lr"></span>中单(<span className="price_up">{props.zjl_data[QuoteItem.中单流入].txt}</span>)</li>
          <li><span className="tl zd_lc"></span>中单(<span className="price_down">{props.zjl_data[QuoteItem.中单流出].txt}</span>)</li>
          <li><span className="tl xd_lr"></span>小单(<span className="price_up">{props.zjl_data[QuoteItem.小单流入].txt}</span>)</li>
          <li><span className="tl xd_lc"></span>小单(<span className="price_down">{props.zjl_data[QuoteItem.小单流出].txt}</span>)</li>
        </ul>
      </div>
    </div>
  )
}

declare var emchartLinechartNew:any

/**
 * 实时资金流向图
 */
function ZJL_SS(props:{
  quotecode: string,
  zjl_data: type_quote_back,
  code: string,
  datalink: string
}){

  useEffect(()=>{
    if (document.createElement('canvas').getContext != undefined && typeof emchartPiechartNew != 'undefined') {
      // 资金流饼图初始化
      var kline = new emchartLinechartNew({
          boxid: 'pq_zjl_chart',
          width: 620,
          height: 240,
          panhou: false,
          sceid: props.quotecode,
          showdanwei: 'true'
      })

      setTimeout(() => {
        $('#pq_zjl_chart').off().on('click', function(){
          window.open(props.datalink)
          return false
        })
    }, 500);
    }
  }, [])

  return (
    <div className="zjl_ss">
      <div id="pq_zjl_chart"></div>
      <div className="zjl_ss_tl">
        <span className="tl zl"></span> 主力净流入
        <span className="tl cdd"></span> 超大单净流入
        <span className="tl dd"></span> 大单净流入
        <span className="tl zd"></span> 中单净流入
        <span className="tl xd"></span> 小单净流入
      </div>
      <div className="zjl_ss_nums">
        <table>
          <tbody>
            <tr>
              <td className="t">今日主力净流入：</td>
              <td className="v">{props.zjl_data[QuoteItem.主力净流入].html}</td>
              <td className="ts">主力净占比：</td>
              <td className="v">{props.zjl_data[QuoteItem.主力净占比].html}</td>
              <td className="t">今日超大单净流入：</td>
              <td className="v">{props.zjl_data[QuoteItem.超大单净流入].html}</td>
              <td className="ts">超大单净占比：</td>
              <td className="v">{props.zjl_data[QuoteItem.超大单净占比].html}</td>              
            </tr>
            <tr>
              <td className="t">今日大单净流入：</td>
              <td className="v">{props.zjl_data[QuoteItem.大单净流入].html}</td>
              <td className="ts">大单净占比：</td>
              <td className="v">{props.zjl_data[QuoteItem.大单净占比].html}</td>
              <td className="t">今日中单净流入：</td>
              <td className="v">{props.zjl_data[QuoteItem.中单净流入].html}</td>
              <td className="ts">中单净占比：</td>
              <td className="v">{props.zjl_data[QuoteItem.中单净占比].html}</td>
            </tr>
            <tr>
              <td className="t">今日小单净流入：</td>
              <td className="v">{props.zjl_data[QuoteItem.小单净流入].html}</td>
              <td className="ts">小单净占比：</td>
              <td className="v">{props.zjl_data[QuoteItem.小单净占比].html}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

/**
 * 盘后资金流向趋势
 */
function ZJL_PH(props:{
  quotecode: string,
  code: string,
  datalink: string
}){

  useEffect(()=>{
    if (document.createElement('canvas').getContext != undefined && typeof emchartPiechartNew != 'undefined') {
      /**
       * 盘后资金流
       */
      var panhou = new emchartLinechartNew({
          boxid: 'ph_zjl_chart',
          width: 620,
          height: 240,
          panhou: true,
          sceid: props.quotecode,
          showdanwei: 'true'
      }) 

      setTimeout(() => {
          $('#ph_zjl_chart').off().on('click', function(){
            window.open(props.datalink)
            return false
          })
      }, 500);
    }
  }, [])

  return (
    <div className="zjl_ph">
      <div id="ph_zjl_chart"></div>
      <div className="zjl_ss_tl">
        <span className="tl zl"></span> 主力净流入
        <span className="tl cdd"></span> 超大单净流入
        <span className="tl dd"></span> 大单净流入
        <span className="tl zd"></span> 中单净流入
        <span className="tl xd"></span> 小单净流入
      </div>
    </div>
  )
}