
/**
 * 新版自选股接口
 * 
 */

import fingerprint from '../browser_fingerprint'
import user from '../user'


let apiurl = '//myfavor.eastmoney.com/v4/' //正式环境
let appkey = 'e9166c7e9cdfad3aa3fd7d93b757e9b1'


if(user.get() == null){
  apiurl += 'anonymwebouter/'
}
else{
  apiurl += 'webouter/'
} 

export default {
  /**
   * 获取自选股接口通用参数
   */
  async getParams():Promise<string>{
    if(user.get() == null){
      let fp = await fingerprint.get()
      return `appkey=${appkey}&bid=${fp}`
    }
    else{
      return `appkey=${appkey}`
    }
  },
  /**
   * 获取默认分组股票，检测是否加了自选
   * @param {*} code 股票代码 0.300059
   */
  async defaultGroupList(code:string = ''):Promise<{
    re: boolean,
    result: {
      check: boolean,
      list: Array<string>
    }
  }>{
    let params = await this.getParams()
    code = code.replace('.', '$')

    return $.ajax({
      url: `${apiurl}gsaandcheck?${params}&cb=?`,
      type: 'GET',
      dataType: 'jsonp',
      data: {
        sc:code
      }
    })
    .then(function(json) {   
      if(json.state == 0 && json.data && json.data.stkstarlist instanceof Array){
        return {
          re: true,
          result: {
            check: json.data.check,
            list: json.data.stkstarlist.map((v:any)=>{
              return v.security.substring(0, v.security.lastIndexOf('$')).replace('$', '.')
            })
          }
        }
      }
      else{
        return {
          re: true,
          result: {
            check: false,
            list:[]
          }
        }
        
      }
    })
  },
  /**
   * 加自选到默认分组
   * @param {*} code 股票代码
   */
  async addDefaultStock(code:string){
    let params = await this.getParams()
    code = code.replace('.', '$')
    return $.ajax({
      url: `${apiurl}asz?${params}&cb=?`,
      type: 'GET',
      dataType: 'jsonp',
      data: {
        sc: code
      }
    }).then(function(back){
      if(back.state == 0){
        return {
          re: true
        }
      }
      else{
        return {
          re: false,
          message: back.message
        }
      }
    })
  },  
  /**
   * 删除默认分组的自选
   * @param {*} code 股票代码
   */
  async deleteDefaultStock(code:string){
    let params = await this.getParams()
    code = code.replace('.', '$')
    return $.ajax({
      url: `${apiurl}dsz?${params}&cb=?`,
      type: 'GET',
      dataType: 'jsonp',
      data: {
        sc: code
      }
    }).then(function(back){
      if(back.state == 0){
        return {
          re: true
        }
      }
      else{
        return {
          re: false,
          message: back.message
        }
      }
    })
  }
}