/**
 * 个股日历
 */


import React,{useState, useEffect} from "react"
import { SiderTabBox } from "../sider_tab_box"
import { rltype, GGRL } from '../../../modules/datacenter/web'
import useSWR from "swr"
import './index.less'
import { hrefFun } from "../ggrl_bar"
import dayjs from 'dayjs'
import { sendStat } from "../../../modules/bigdata_stat"

/**
 * 侧边栏 大事提醒
 * @returns 
 */
export function SiderStockCalendar(props:{
  market: string,
  code: string,
  height?: number
}){

  let linkcode = 'sz' + props.code
  if(props.market == '1') linkcode = 'sh' + props.code

  return (
    <SiderTabBox {...{
      mt: 10,
      tab_split: true,
      boxdata:[
        {
          tab_html: <a href={`//data.eastmoney.com/stockcalendar/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.dstx.dstx')}}>大事提醒</a>,
          body: <SiderStockCalendarContent market={props.market} code={props.code} height={props.height}></SiderStockCalendarContent>
        },
        {
          tab_html: <a href={`//quote.eastmoney.com/concept/${linkcode}.html#chart-k`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.xxdl.xxdl')}}>信息地雷</a>,
          faketab: true
        }
      ]}}></SiderTabBox>
  )
}

function SiderStockCalendarContent(props:{
  market: string,
  code: string,
  height?: number
}){

  const { data, error } = useSWR(`/ggrl/4/${props.code}`, () => GGRL(props.code, 4), {
    revalidateOnFocus: false
  })
  // console.info(data)
  
  
  return (
    <div className="siderstockcalendarcontent newscroll" style={{height: props.height?'auto':props.height}} onClick={()=>{sendStat('DIV', 'Click', 'webjdb.dstx.qynr')}}>
      {
        data != undefined && data.length > 1 &&
          <table>
            <tbody>
              {
                data.map((v, index)=>
                  <tr key={index}>
                    <td className="time">
                      <div>{v.NOTICE_DATE && v.NOTICE_DATE.substring(5, 10)}</div>
                      <div className="year">{v.NOTICE_DATE && v.NOTICE_DATE.substring(0, 4)}</div>
                    </td>
                    <td className="event">
                      <b className={`circle ${dayjs().format('YYYY-MM-DD') == v.NOTICE_DATE?.substring(0, 10)? 'redcircle' : ''}`}></b>
                      <div className="title">{v.EVENT_TYPE}</div>
                      <div className="description" title={v.LEVEL1_CONTENT}><a href={hrefFun(v.EVENT_TYPE_CODE, props.code, props.market)} target="_blank">{v.LEVEL1_CONTENT}</a></div>
                    </td>
                  </tr>                
                )
              }

            </tbody>
          </table>        
      }
      {
        ((data != undefined && data.length == 0) || error)  &&
          <div className="nodata">暂无数据</div>
      }
      {
        !data && !error &&
          <div className="nodata">加载中</div>
      } 
    </div>
  )
}