import React,{useState, useEffect} from "react"
import {type_quote_back} from '../../../modules/quote_info/quote_data'
import { QuoteItem } from '../../../modules/quote_info/config'
import useSWR from 'swr'
import { StockChangeName } from "../../../modules/datacenter/web"

/**
 * 股票标识
 * 两融 沪主板 深主板 沪股通 深股通 科创版 创业板 注册制 可转债 H股 GDR B股
 * @returns 
 */
export function TitleLabel(props:{
  quotedata: type_quote_back,
  change_name?:boolean,
  market?: string,
  code?: string
}){

  let quotedata = props.quotedata

  // if(quotedata[QuoteItem.A瑞士对应的代码]){
  //   console.info(quotedata[QuoteItem.A瑞士对应的代码] == undefined, quotedata[QuoteItem.A瑞士对应的代码].txt)
    
  // }
  

  return (
    <>
      {
        props.change_name && props.market != undefined && props.code != undefined &&
        <ChangeName code={props.code} market={props.market}></ChangeName>
      }     
      {
        (quotedata[QuoteItem.股票标识].source & 64) > 0 &&
          <a href={`//data.eastmoney.com/rzrq/detail/${quotedata[QuoteItem.代码].txt}.html`} className="quote_title_label" title="该股票为融资融券标的" target="_blank">两融</a>
      } 
      {
        (quotedata[QuoteItem.市场].source == 1 && quotedata[QuoteItem.二级分类]?.source == 2) &&
          <a href="//quote.eastmoney.com/center/gridlist.html#sh_a_board" className="quote_title_label" title="该股票在沪主板上市" target="_blank">沪主板</a>
      }
      {
        (quotedata[QuoteItem.市场].source == 0 && quotedata[QuoteItem.二级分类]?.source == 6) &&
          <a href="//quote.eastmoney.com/center/gridlist.html#sz_a_board" className="quote_title_label" title="该股票在深主板上市" target="_blank">深主板</a>
      } 
      {
        (quotedata[QuoteItem.股票标识].source & 512) > 0 &&
          <a href="//quote.eastmoney.com/center/gridlist.html#sh_hk_board" className="quote_title_label" title="该股票为沪股通标的" target="_blank">沪股通</a>
      }
      {
        (quotedata[QuoteItem.股票标识].source & 1024) > 0 &&
          <a href="//quote.eastmoney.com/center/gridlist.html#sz_hk_board" className="quote_title_label" title="该股票为深股通标的" target="_blank">深股通</a>
      }
     
      {
        (quotedata[QuoteItem.市场].source == 1 && quotedata[QuoteItem.二级分类]?.source == 23) &&
          <a href="//quote.eastmoney.com/center/gridlist.html#kcb_board" className="quote_title_label" title="该股票为科创板" target="_blank">科创板</a>
      }    
      {
        (quotedata[QuoteItem.市场].source == 0 && quotedata[QuoteItem.二级分类]?.source == 80) &&
          <a href="//quote.eastmoney.com/center/gridlist.html#gem_board" className="quote_title_label" title="该股票为创业板" target="_blank">创业板</a>
      }
      {
        (quotedata[QuoteItem.市场].source == 0 && quotedata[QuoteItem.是否注册制]?.source == 1) &&
          <a href="//quote.eastmoney.com/center/gridlist.html#sz_a_board_zcz" className="quote_title_label" title="该股票为注册制" target="_blank">注册制</a>
      }
      {
        (quotedata[QuoteItem.市场].source == 1 && quotedata[QuoteItem.是否注册制]?.source == 1) &&
          <a href="//quote.eastmoney.com/center/gridlist.html#sh_a_board_zcz" className="quote_title_label" title="该股票为注册制" target="_blank">注册制</a>
      }      
      {
        quotedata[QuoteItem.可转债代码或正股代码]?.txt != undefined && quotedata[QuoteItem.可转债代码或正股代码].txt != '-' &&
          <a href={`//quote.eastmoney.com/unify/r/${quotedata[QuoteItem.市场].source}.${quotedata[QuoteItem.可转债代码或正股代码].source}`} className="quote_title_label" title="点击查看关联可转债行情" target="_blank">可转债</a>
      }  
      
      
      {
        quotedata[QuoteItem.AH股对应的代码]?.txt != undefined && quotedata[QuoteItem.AH股对应的代码].txt != '-' &&
          <a href={`//quote.eastmoney.com/unify/r/${quotedata[QuoteItem.AH股对应的市场].source}.${quotedata[QuoteItem.AH股对应的代码].source}`} className="quote_title_label" title="点击查看关联H股行情" target="_blank">H股</a>
      } 

      {
        quotedata[QuoteItem.A股对应GDR或GDR对应A股的代码]?.txt != undefined && quotedata[QuoteItem.A股对应GDR或GDR对应A股的代码].txt != '-' &&
          <a href={`//quote.eastmoney.com/unify/r/${quotedata[QuoteItem.A股对应GDR或GDR对应A股的市场].source}.${quotedata[QuoteItem.A股对应GDR或GDR对应A股的代码].source}`} className="quote_title_label" title="该股票存在关联的GDR（全球存托凭证）" target="_blank">GDR</a>
      }  

      {
        quotedata?.[QuoteItem.A瑞士对应的代码]?.txt != undefined && quotedata?.[QuoteItem.A瑞士对应的代码].txt != '-' &&
          <a href={`//quote.eastmoney.com/unify/r/${quotedata[QuoteItem.A瑞士对应的市场].source}.${quotedata[QuoteItem.A瑞士对应的代码].source}`} className="quote_title_label" title="该股票存在关联的瑞交所GDR" target="_blank">瑞交所GDR</a>
      }
      
      {
        quotedata[QuoteItem.AB股对应的代码]?.txt != undefined && quotedata[QuoteItem.AB股对应的代码].txt != '-' &&
          <a href={`//quote.eastmoney.com/unify/r/${quotedata[QuoteItem.AB股对应的市场].source}.${quotedata[QuoteItem.AB股对应的代码].source}`} className="quote_title_label" title="点击查看关联B股行情" target="_blank">B股</a>
      }  

    </>
  )
}

/**
 * 更名信息
 */
function ChangeName(props:{
  market: string,
  code: string
}){
  
  const { data, error } = useSWR(`/quote_changename/`, ()=>StockChangeName(props.market, props.code), {
    revalidateOnFocus: false
  })

  if(data && data.length > 1){    
    return (
      <span className="quote_title_label_rename">
        <span className="title">更名</span>
        <div className="renameb">
          <div className="renamebb arrow_center_top">
            <div className="tt">公司名称变更信息</div>
            <div className="ret">
              简称变更：
            </div>
            <div className="relist">
              {
                data.map((v, index)=>
                  <React.Fragment key={index}>
                    <span>
                      {v.name} <br /> ({v.change_date?.substring(0, 10)})
                    </span>
                    {
                      index < data.length - 1 &&
                      <span>&gt;&gt;&gt;</span>
                    }
                  </React.Fragment>

                )
              }

            </div>  
            <div className="clear_fix"></div>          
          </div>

        </div>
      </span>
    )
  }


  return <></>
}