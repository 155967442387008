/**
 * 股吧 标题
 */

import React, { useState, useEffect } from "react"
import { getGubaCode } from "../../../modules/guba/gubacode";
import { TextCut } from "../text";
import useSWR from 'swr'
import './index.less'
import { getBulletin } from "../../../modules/bulletin/web";

export function GubaTitle(props:{
  market:string,
  code:string,
  pagetype?:string,
  gubaname:string,
  gubacode?:string,
  /** 显示推广 */
  show_tg?:boolean
}) {

  let guba_code = props.gubacode || getGubaCode(props.market, props.code, props.pagetype)


    return (
      <div className="guba_title">
        <div className="guba_title_t">
          <a href={`//guba.eastmoney.com/list,${guba_code}.html`} target="_blank"><TextCut width={300} text={`${props.gubaname}吧`}></TextCut></a>

        </div>
        {
          props.show_tg &&
          <GubaTitleTG></GubaTitleTG>
        }        
        <div className="guba_title_r">
          <a target="_blank" href="http://guba.eastmoney.com/"><span className="guba_icon"></span> 股吧首页</a>
          &nbsp;|&nbsp;
          <a target="_blank" href="http://guba.eastmoney.com/remenba.aspx?type=1">热门个股吧</a>
          &nbsp;|&nbsp;
          <a target="_blank" href="http://guba.eastmoney.com/remenba.aspx?type=2">热门主题吧</a>
          &nbsp;|&nbsp;
          <a target="_blank" href={`//guba.eastmoney.com/list,${guba_code}.html`}>更多</a>
        </div>
      </div>
    )
}

/** 推广 */
function GubaTitleTG() {
  const { data, error } = useSWR(`/bulletin/979`, ()=>getBulletin('979'), {
    revalidateOnFocus: false
  })

  if(data){
    let linkhtml = $(data).find('a:first').attr('target', '_blank').parent().html()
    if(linkhtml){
      return (
        <div className="gubatitletg" dangerouslySetInnerHTML={{__html: linkhtml}}></div>
      )       
    }
  }
  return <></>
}

export function GubaTitleKCB(props:{
  market:string,
  code:string,
  pagetype?:string,
  gubaname:string,
  gubacode?:string
}) {

  let guba_code = props.gubacode || getGubaCode(props.market, props.code, props.pagetype)


    return (
      <div className="guba_title guba_kcb_title">
        <div className="guba_title_kcb">
          <a href={`//guba.eastmoney.com/list,${guba_code}.html`} target="_blank"><span>{props.gubaname}</span>吧</a>
        </div>
        <div className="guba_title_kcb guba_title_kcb2">
          <a href={`//guba.eastmoney.com/list,kcb.html`} target="_blank">科创板吧</a>
        </div>        
        <div className="guba_title_r">
          <a target="_blank" href="http://guba.eastmoney.com/"><span className="guba_icon"></span> 股吧首页</a>
          &nbsp;|&nbsp;
          <a target="_blank" href="http://guba.eastmoney.com/remenba.aspx?type=1">热门个股吧</a>
          &nbsp;|&nbsp;
          <a target="_blank" href="http://guba.eastmoney.com/remenba.aspx?type=2">热门主题吧</a>
          &nbsp;|&nbsp;
          <a target="_blank" href={`//guba.eastmoney.com/list,${guba_code}.html`}>更多</a>
        </div>
      </div>
    )
}
