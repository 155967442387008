/**
 * 行情相关工具
 */

import txt_tools from "../txt";



/** 行情字段 */
export interface quote_item{
  /** 输出文本 */
  txt: string,
  /** 颜色class */
  color: 'price_up'|'price_down'|'price_draw'|''|'price_up2'|'price_down2',
  /** 输出html */
  html: string,
  /** 输出带闪烁的html */
  blink_html: string
}

export default {
  /**
   * 是否是沪深A股票
   * @param {*} f13 
   * @param {*} f19 
   */
  isHSStock: function(f13:number, f19:number){
    return (f13 == 1 && f19 == 2) || (f13 == 1 && f19 == 23) || ((f13 == 0) && (f19 == 6 || f19 == 13 || f19 == 80))
  },
  /**
   * 转化行情数据为需要的格式
   * @param input 输入数据
   * @param fixed 缩小倍数并保留小数位数
   * @param colornumber 颜色 大于0红色 小于0 绿色 等于0 灰色 null不设置颜色
   * @param format_number 格式化数据 万 亿 万亿 并设置最大宽度
   * @param hz 后缀
   * @param tofixed 保留小数位数
   * @returns 
   */
  quoteItem: function(input:number|string|undefined, fixed:number, colornumber: number | null, format_number: number, hz?:string, tofixed?: number):quote_item{

    // if(typeof input == 'string'){
    //   if(isNaN(parseFloat(input)) ){
    //     input = undefined
    //   } 
    //   else{
    //     input = parseFloat(input)
    //   }
    // }

    if(input == undefined){
      return {
        txt: '',
        color: '',
        html: '',
        blink_html : ''
      }
    }

    if(input == '-'){
      return {
        txt: '-',
        color: '',
        html: '-',
        blink_html: '-'
      }
    }
    
    let return_obj: quote_item = {
      txt: input.toString(),
      color: 'price_draw',
      html: '',
      blink_html: ''
    }

    let blink_color = ''

    //处理放大倍数
    if(typeof input == 'number' && fixed > 0){
      return_obj.txt = (input / (Math.pow(10, fixed))).toFixed(fixed)
    }
    if(typeof input == 'number' && tofixed != undefined && tofixed > 0){
      return_obj.txt = input.toFixed(tofixed)
    }
    
    //格式化
    if(typeof input == 'number' && format_number > 0){
      return_obj.txt = txt_tools.formatNumMaxWidth(input, format_number)
    }    

    //颜色
    if(colornumber != null && colornumber > 0){
      return_obj.color = 'price_up'
      blink_color = 'blinkred'
    }
    else if(colornumber != null && colornumber < 0){
      return_obj.color = 'price_down'
      blink_color = 'blinkgreen'
    }
    else if(colornumber != null && colornumber == 0){
      return_obj.color = 'price_draw'
      blink_color = 'blinkblue'
    }
    else{
      return_obj.color = ''
      blink_color = ''      
    }

    //后缀
    if(hz != undefined){
      return_obj.txt += hz
    }

    return_obj.html = `<span class="${return_obj.color}">${return_obj.txt}</span>`
    return_obj.blink_html = `<span class="${return_obj.color} ${blink_color}">${return_obj.txt}</span>`

    return return_obj
  },

  /**
   * 分时成交，带另外两种转化行情数据为需要的格式
   * @param input 输入数据
   * @param fixed 缩小倍数并保留小数位数
   * @param colornumber 颜色 大于0红色 小于0 绿色 等于0 灰色 null不设置颜色
   * @param format_number 格式化数据 万 亿 万亿 并设置最大宽度
   * @param hz 后缀
   * @param tofixed 保留小数位数
   * @returns 
   */
  quoteItem_fscj: function(input:number|string|undefined, fixed:number, colornumber: number | null, format_number: number, hz?:string, tofixed?: number):quote_item{

    // if(typeof input == 'string'){
    //   if(isNaN(parseFloat(input)) ){
    //     input = undefined
    //   } 
    //   else{
    //     input = parseFloat(input)
    //   }
    // }

    if(input == undefined){
      return {
        txt: '',
        color: '',
        html: '',
        blink_html : ''
      }
    }

    if(input == '-'){
      return {
        txt: '-',
        color: '',
        html: '-',
        blink_html: '-'
      }
    }
    
    let return_obj: quote_item = {
      txt: input.toString(),
      color: 'price_draw',
      html: '',
      blink_html: ''
    }

    let blink_color = ''

    //处理放大倍数
    if(typeof input == 'number' && fixed > 0){
      return_obj.txt = (input / (Math.pow(10, fixed))).toFixed(fixed)
    }
    if(typeof input == 'number' && tofixed != undefined && tofixed > 0){
      return_obj.txt = input.toFixed(tofixed)
    }
    
    //格式化
    if(typeof input == 'number' && format_number > 0){
      return_obj.txt = txt_tools.formatNumMaxWidth(input, format_number)
    }    

    //颜色
    if(colornumber != null && colornumber > 0){
      return_obj.color = 'price_up'
      blink_color = 'blinkred'
      if(colornumber > 1){
        return_obj.color = 'price_up2'
      }
      
    }
    else if(colornumber != null && colornumber < 0){
      return_obj.color = 'price_down'
      blink_color = 'blinkgreen'
      if(colornumber < -1){
        return_obj.color = 'price_down2'
      }      
    }
    else if(colornumber != null && colornumber == 0){
      return_obj.color = 'price_draw'
      blink_color = 'blinkblue'
    }
    else{
      return_obj.color = ''
      blink_color = ''      
    }

    //后缀
    if(hz != undefined){
      return_obj.txt += hz
    }

    return_obj.html = `<span class="${return_obj.color}">${return_obj.txt}</span>`
    return_obj.blink_html = `<span class="${return_obj.color} ${blink_color}">${return_obj.txt}</span>`

    return return_obj
  },

  /** 处理交易时间 */
  transTradeTime(trade_time:number):string{
    if(isNaN(trade_time)){
      return '-'
    }

    try {
      var d = new Date(trade_time * 1000); 
      var jysj = d.getFullYear() + '-' + (("0" + (d.getMonth() + 1)).slice(-2)) + '-' + (("0" + (d.getDate())).slice(-2)) + ' ' + ("0" + (d.getHours())).slice(-2) + ':' + ("0" + (d.getMinutes())).slice(-2) + ':' + ("0" + (d.getSeconds())).slice(-2);

      return '（' + jysj + '）';

    } catch (e) {
        return '-'
    }
  }
}