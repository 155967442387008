import React,{useState, useEffect} from "react"
import { observer } from "mobx-react-lite"
import { quotedata, QuoteItem } from "../quote_data";
import {initSingleValue} from '../../../modules/quote_info/quote_data'
import './index.less'

/** 指数 侧边栏 行情 */
export function SiderBrief(){

  let showdata = initSingleValue()
  if(quotedata.hqdata != null){
    showdata = quotedata.hqdata
  }

  return (
    <div className="sider_brief">
      <table className="t1">
        <tbody>
          <tr>
            <td>最新：{showdata[QuoteItem.最新价].blink_html}</td>
            <td>均价：{showdata[QuoteItem.均价].blink_html}</td>
          </tr>
          <tr>
            <td>涨幅：{showdata[QuoteItem.涨跌幅].blink_html}</td>
            <td>涨跌：{showdata[QuoteItem.涨跌额].blink_html}</td>
          </tr>
          <tr>
            <td>总手：{showdata[QuoteItem.成交量].blink_html}</td>
            <td>金额：{showdata[QuoteItem.成交额].blink_html}</td>
          </tr>
          <tr>
            <td>换手：{showdata[QuoteItem.换手率].blink_html}</td>
            <td>量比：{showdata[QuoteItem.量比].blink_html}</td>
          </tr>
          <tr>
            <td>最高：{showdata[QuoteItem.最高价].blink_html}</td>
            <td>最低：{showdata[QuoteItem.最低价].blink_html}</td>
          </tr>
          <tr>
            <td>今开：{showdata[QuoteItem.今开].blink_html}</td>
            <td>昨收：{showdata[QuoteItem.昨收].blink_html}</td>
          </tr>
          <tr>
            <td>涨停：{showdata[QuoteItem.涨停价].blink_html}</td>
            <td>跌停：{showdata[QuoteItem.跌停价].blink_html}</td>
          </tr>
          <tr>
            <td>外盘：{showdata[QuoteItem.外盘].blink_html}</td>
            <td>内盘：{showdata[QuoteItem.内盘].blink_html}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
export default observer(SiderBrief)