/**
 * 沪深a股页面，除了科创版，包含创业板
 */


import React,{useState, useEffect} from "react"
import HeadAD from "../shared/header_ads"
import HeaderLinks from "../shared/header_links"
import HeaderQuoteBox from "../shared/header_quotebox";
import HeadTitle from "../shared/header_title";
import {Statement} from "../shared/statement"
import {OldBrowserHistory} from "../shared/browser_history/old"
import { GubaFrame } from "../shared/guba_frame";
import { PageLibs } from "../page_libs";
import { KcbQuoteDocumentTitle } from './quote_data'
import { GubaTitle } from "../shared/guba_title";
import "./index.less";
import { Breadcrumb } from "../shared/breadcrumb";
import QuoteTitle from "../shared/quote_title/a_quote_title";
import AQuoteBrief from './quote_brief'
import { QuoteF10 } from "../shared/quote_f10";
import { QuoteSiderFavor } from "../shared/sider_favor/quote_sider_favor";
import { SiderTabBox } from "../shared/sider_tab_box";
import { QuoteNewsList } from "../shared/news_box/quote_news_list";
import { QuoteNoticeList } from '../shared/notice_box'
import { MainQuoteCharts } from "../shared/main_quote_charts";

import * as Layout from "../shared/layout"

import { SiderHQZJL } from './sider_hq_zjl'
import SiderTrade from "../shared/sider_trade";
import { SiderStockCalendar } from "../shared/stockcalendar";
import { StockReportTable } from "../shared/report";
import { TabBoxIndustryReport } from "../shared/report/report_table";
import { BKStockRank, BKStocksBar, StockBKJDZF } from "../shared/bankuai";

import dayjs from 'dayjs'
import { AdIframe } from "../shared/ad_iframe";
import { AFinance4Table } from "../shared/finance_4";
import { DataImg } from "./data_img";
import { SiderJigouMingia } from "../shared/news/sider_jigou_mingjia";
import CYBZB from "./cyb_zb";
import { StockSiderDC } from "../shared/stock_sider_dc";
import { ZJLCharts } from "../shared/zjl_charts";
import { QuoteSiderCore } from "../shared/quote_sider_core";
import { ProfitChart } from "../shared/profit_chart";
import { SDLTGD } from "../shared/sdltgd";
import SiderBrief from "./sider_brief";
import { BackTop } from "../shared/backtop";
import { BarD } from "./bard";
import { GGRLBar } from "../shared/ggrl_bar";
import { SiderChanges } from "../shared/sider_changes";
import { LuYanAD_A } from "../shared/luyan";
import { sendStat } from "../../modules/bigdata_stat";

//更名信息
export function Index(props:{
  quotedata: {
    name: string,
    code: string,
    market: string,
    quotecode: string,
    bk_name: string,
    bk_id: string,
    type111: number
  }
}){


  const pagetype = 'a'
  /** 是否是创业板 */
  const is_cyb = props.quotedata.market == '0' && props.quotedata.type111 == 80
  // /** 是否是科创板 */
  // const is_kcb = props.quotedata.market == '1' && props.quotedata.type111 == 23


  return (
    <div style={{ backgroundColor: '#fff' }}>
      <div className="container">
        <BackTop main_width={1000}></BackTop>
        <KcbQuoteDocumentTitle></KcbQuoteDocumentTitle>
        {/* 基金广告 */}
        <div className="jjgg">
          <iframe width="1000" height="60" frameBorder="0" scrolling="no" marginWidth={0} marginHeight={0} id="tbggiframe" src={`//fund.eastmoney.com/trade/hqb_hq.html?spm=100002.sbb&rt=${dayjs().format('YYMMDDHH')}`}></iframe>
        </div>
        <HeaderLinks></HeaderLinks>
        <HeadAD></HeadAD>
        <HeadTitle></HeadTitle>
        <HeaderQuoteBox></HeaderQuoteBox>
        
        <Layout.Layout_LeftRight.Container>
          <Layout.Layout_LeftRight.Left>
            <Breadcrumb mt={5}>
                <Breadcrumb.Item>
                  <a href="http://quote.eastmoney.com/center/" target="_blank">行情首页</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {
                    props.quotedata.market == '1' &&
                      <a href="//quote.eastmoney.com/center/gridlist.html#sh_a_board" target="_blank">上证A股</a>
                  }
                  {
                    props.quotedata.market == '0' &&
                      <a href="//quote.eastmoney.com/center/gridlist.html#sz_a_board" target="_blank">深证A股</a>
                  }
                </Breadcrumb.Item>
                {
                  props.quotedata.bk_name &&
                  <Breadcrumb.Item>
                    <a href={`//quote.eastmoney.com/center/boardlist.html#boards2-90.${props.quotedata.bk_id}`} target="_blank">{props.quotedata.bk_name}</a>
                  </Breadcrumb.Item>                  
                }
                <Breadcrumb.Item>
                  <>{props.quotedata.name}</>
                </Breadcrumb.Item>
              </Breadcrumb>
          </Layout.Layout_LeftRight.Left>
          <Layout.Layout_LeftRight.Right>
            <OldBrowserHistory  maxwidth={600} type={'ty'} name={props.quotedata.name} market={props.quotedata.market} code={props.quotedata.code} size={6}></OldBrowserHistory>
          </Layout.Layout_LeftRight.Right>
        </Layout.Layout_LeftRight.Container>

        <QuoteTitle quotecode={props.quotedata.quotecode} market={props.quotedata.market} code={props.quotedata.code} name={props.quotedata.name}></QuoteTitle>

        <AQuoteBrief market={props.quotedata.market} code={props.quotedata.code} name={props.quotedata.name}></AQuoteBrief>

        
        <CYBZB is_cyb={is_cyb}></CYBZB>
        

        <QuoteF10 code={props.quotedata.code} market={props.quotedata.market}></QuoteF10>

        <div className="quote2l mt10">
          <div className="quote2l_cl2">
            <QuoteSiderFavor></QuoteSiderFavor>
            <SiderStockCalendar market={props.quotedata.market} code={props.quotedata.code}></SiderStockCalendar>
            <DataImg code={props.quotedata.code} name={props.quotedata.name}></DataImg>
            <QuoteSiderCore quotecode={props.quotedata.quotecode} code={props.quotedata.code}></QuoteSiderCore>
            <ProfitChart data_code={props.quotedata.code + '.' + (props.quotedata.market == '0' ? 'SZ' : 'SH')} code={props.quotedata.code} show_link={true} show_more={true}></ProfitChart>
            <SDLTGD code={props.quotedata.code} market={props.quotedata.market}></SDLTGD>
          </div>
          <div className="quote2l_cr2">
            <LuYanAD_A market={props.quotedata.market} code={props.quotedata.code}></LuYanAD_A>
            <div className="quote2l_cr2_m mt10">
              <BarD market={props.quotedata.market} code={props.quotedata.code} name={props.quotedata.name}></BarD>
              <GGRLBar code={props.quotedata.code} market={props.quotedata.market}></GGRLBar>
              <MainQuoteCharts pagetype={pagetype} quotecode={props.quotedata.quotecode} market={props.quotedata.market} code={props.quotedata.code} show_pq={true} show_ph={is_cyb} gjtx_ad={true} show_concept={true} fullscreen={true} pkyd_dot={true} concept_img={true} cmfb_img={true}></MainQuoteCharts>
              <BKStocksBar quotecode={props.quotedata.quotecode} bk_id={props.quotedata.bk_id} bk_name={props.quotedata.bk_name}></BKStocksBar>
              
              <AFinance4Table name={props.quotedata.name} code={props.quotedata.code} quotecode={props.quotedata.quotecode} bk_id={props.quotedata.bk_id} bk_name={props.quotedata.bk_name}></AFinance4Table>
              <AdIframe width={575} height={90} adid={'1182'} mt={10}></AdIframe>
            </div>
            <div className="quote2l_cr2_c mt10">
              <SiderHQZJL market={props.quotedata.market} code={props.quotedata.code} quotecode={props.quotedata.quotecode} is_cyb={is_cyb}></SiderHQZJL>
              <SiderBrief></SiderBrief>
              <SiderTrade quotecode={props.quotedata.quotecode} size={10} showbig={true} tableclassname='lh_1' showmore={true} show_title_more={true}></SiderTrade>
              <SiderChanges market={props.quotedata.market} code={props.quotedata.code}></SiderChanges>
              <AdIframe width={200} height={200} adid={'175'} mt={10}></AdIframe>
            </div>
          </div>
        </div>

        <Layout.Layout_SiderMainMain mt={10}>
          <Layout.Layout_SiderMainMain.Sider>
            <StockBKJDZF quotecode={props.quotedata.quotecode} bk_id={props.quotedata.bk_id}></StockBKJDZF>
          </Layout.Layout_SiderMainMain.Sider>
          <Layout.Layout_SiderMainMain.Main>
            <SiderTabBox {...{
              boxdata:[
                {
                  tab_html: <a href={`//so.eastmoney.com/news/s?keyword=${encodeURIComponent(props.quotedata.name)}`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.zx.ggzx')}}>个股资讯</a>,
                  more_html: <a href={`//so.eastmoney.com/news/s?keyword=${encodeURIComponent(props.quotedata.name)}`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.zx.gd')}}>更多</a>,
                  body: <QuoteNewsList quotecode={props.quotedata.quotecode} short={true} is_dc={props.quotedata.market == '0' && props.quotedata.code == '300059'}></QuoteNewsList>
                },
                {
                  tab_html: <a href={`${props.quotedata.bk_id ? '//stock.eastmoney.com/hangye/hy' + props.quotedata.bk_id.toString().replace(/bk0*/i,'') + '.html' : '//stock.eastmoney.com/'}`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.zx.hyzx')}}>行业资讯</a>,
                  faketab: true             
                }
              ]}}></SiderTabBox>
          </Layout.Layout_SiderMainMain.Main>
          <Layout.Layout_SiderMainMain.Main>
            <SiderTabBox {...{
              boxdata:[
                {
                  tab_html: <a href={`//data.eastmoney.com/notices/stock/${props.quotedata.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.gg.gg')}}>公告</a>,
                  more_html: <a href={`//data.eastmoney.com/notices/stock/${props.quotedata.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.gg.gd')}}>更多</a>,
                  body: <QuoteNoticeList market={props.quotedata.market} code={props.quotedata.code} short={true}></QuoteNoticeList>
                }
              ]}}></SiderTabBox>
          </Layout.Layout_SiderMainMain.Main>
        </Layout.Layout_SiderMainMain>

        
        <Layout.Layout_SiderMainMain mt={10}>
          <Layout.Layout_SiderMainMain.Sider>
            <BKStockRank bk_id={props.quotedata.bk_id} bk_name={props.quotedata.bk_name}></BKStockRank>
          </Layout.Layout_SiderMainMain.Sider>
          <Layout.Layout_SiderMainMain.Main>
            <SiderTabBox {...{
              boxdata:[
                {
                  tab_html: <a href={`//data.eastmoney.com/report/singlestock.jshtml?stockcode=${props.quotedata.code}`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.ggyb.ggyb')}}>个股研报</a>,
                  more_html: <a href={`//data.eastmoney.com/report/singlestock.jshtml?stockcode=${props.quotedata.code}`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.ggyb.gd')}}>更多</a>,
                  body: <StockReportTable code={props.quotedata.code} short={true}></StockReportTable>
                }
              ]}}></SiderTabBox>
          </Layout.Layout_SiderMainMain.Main>
          <Layout.Layout_SiderMainMain.Main>
            <TabBoxIndustryReport bk_id={props.quotedata.bk_id}></TabBoxIndustryReport>
          </Layout.Layout_SiderMainMain.Main>
        </Layout.Layout_SiderMainMain>

       
        <GubaTitle market={props.quotedata.market} code={props.quotedata.code} gubaname={props.quotedata.name} show_tg={true}></GubaTitle>

        <div className="quote2l" style={{marginTop: 10}}>
          <div className="quote2l_ml">
            <GubaFrame {...{market: props.quotedata.market, code: props.quotedata.code, stock_type: true, height: 630, count: 18, newpost: true}}></GubaFrame>
            <AdIframe width={790} height={90} adid={'638'} mt={10}></AdIframe>
          </div>
          <div className="quote2l_cr">
            <StockSiderDC {...{name: props.quotedata.name, market: props.quotedata.market, code: props.quotedata.code}}></StockSiderDC>
            <SiderJigouMingia size={19}></SiderJigouMingia>
          </div>
        </div>

        <ZJLCharts quotecode={props.quotedata.quotecode} code={props.quotedata.code} market={props.quotedata.market} type='astock'></ZJLCharts>
        
        <Statement></Statement>

        

      </div>
    </div>
  )
}

export function PageLayout(props:{
  quotedata:any,
  footer: string,
  thistime: string,
  machine_num: string
}){

  let title = `${props.quotedata.name}(${props.quotedata.code})`

  return (
    <>
      <html>
      <head>
        <meta name="sinfo" data-bz={`STime: ${props.thistime} SNum: ${props.machine_num}`} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge,chrome=1" /><meta name="referrer" content="always" />
        <meta httpEquiv="refresh" content="720" />
        <title>{title}_股票价格_行情_走势图—东方财富网</title>
        <meta name="keywords" content={`${title},东方财富网,股票行情,行情中心,实时行情,五档盘口,逐笔交易,个股档案,公司简介,主力动向,财务数据,特色数据,阶段涨幅,所属板块,股权分析,分红融资,股市备忘,资金流入,主力控盘程度,机构资金与散户资金对比,交易数据排行,市盈率排行,财务指标排行,流通股本,每股收益,主营业务收入,个股研报,盈利预测,研报,资金流向,股吧`} />
        <meta name="description" content={`提供${title}股票的行情走势、五档盘口、逐笔交易等实时行情数据，及${title}的新闻资讯、公司公告、研究报告、行业研报、F10资料、行业资讯、资金流分析、阶段涨幅、所属板块、财务指标、机构观点、行业排名、估值水平、股吧互动等与${title}有关的信息和服务。`} />
        <link href="/newstatic/build/a.css" rel="stylesheet" />
        <link rel="stylesheet" href="//gbfek.dfcfw.com/deploy/fd_quote_editor/work/editor.css" />
      </head>
      <body style={{marginTop: 36}}>
        <div id="app">
          <Index quotedata={props.quotedata}></Index>
        </div>
        <div dangerouslySetInnerHTML={{__html: props.footer}}></div>
      </body>
      </html>
      <script src="//emres.dfcfw.com/public/js/websitecommand.js" charSet="utf-8"></script>
      <PageLibs></PageLibs>
      <script type="text/javascript" dangerouslySetInnerHTML={{__html:`
      var quotedata = ${JSON.stringify(props.quotedata)};
      `}}>
      </script>
      <script src="//emres.eastmoney.com/public/js/topnav.js"></script>
      <script src="//emcharts.dftzj.com/suggest/stocksuggest2017.min.js "></script>
      <script src="//emcharts.dftzj.com/ec/3.18.1/emcharts.min.js"></script>
      <script src="/newstatic/libs/quotekchart/1.0.6.js"></script>      
      <script src="/newstatic/libs/jquery.vticker.b.js"></script>
      <script src="/newstatic/js/libs/quotemoneyflowchart0715.js"></script>
      <script src="//gbfek.dfcfw.com/deploy/fd_quote_editor/work/editor.js"></script>
      <script src="/newstatic/build/a.js"></script>
      <script src="//emcharts.dftzj.com/pr3/prod/personalrecommend3.min.js"></script>
      <script src="//emcharts.dftzj.com/newsts/newsts.min.js" charSet="utf-8"></script>
      <script src="//emcharts.dftzj.com/emsider/prod/emsider.min.js"></script>
      <script dangerouslySetInnerHTML={{__html:`if (window.EMSider) {
          EMSider({ top: 33, zindex: 9000 });
        }`}}></script>
      <script dangerouslySetInnerHTML={{__html:`var emtj_isUpload = 1;
        var emtj_pageId = 113200301201;
        var emtj_logSet = "1111111111";
        var emtj_sampleRate = 20;`}}>
      </script>
      <script src="//emcharts.dftzj.com/usercollect/usercollect.min.js"></script>
      <script src="https://bdstatics.eastmoney.com/web/prd/jump_tracker.js"></script>
    </>
  )
}