import React, { useState, useEffect } from "react"
import './index.css'

function HeaderLinks() {
  return (
    <div className="head_links self_clearfix">
      <ul>
        <li><a target="_blank" href="http://finance.eastmoney.com/">财经</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://finance.eastmoney.com/yaowen.html">焦点</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://stock.eastmoney.com/">股票</a></li>
        <li className="i">|</li>
        <li><span className="red"><a target="_blank" href="http://stock.eastmoney.com/newstock.html">新股</a></span></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://stock.eastmoney.com/gzqh.html">期指</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://option.eastmoney.com/">期权</a></li>
        <li className="i">|</li>
        <li><span className="red"><a target="_blank" href="http://quote.eastmoney.com/flash/sz300059.html">行情</a></span></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://data.eastmoney.com/">数据</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://stock.eastmoney.com/global.html">全球</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://stock.eastmoney.com/america.html">美股</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://hk.eastmoney.com/">港股</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://futures.eastmoney.com/">期货</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://forex.eastmoney.com/">外汇</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://gold.eastmoney.com">黄金</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://bank.eastmoney.com/">银行</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://www.1234567.com.cn/">基金</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://money.eastmoney.com/">理财</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://insurance.eastmoney.com/">保险</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://bond.eastmoney.com/">债券</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://video.eastmoney.com/">视频</a></li>
        <li className="i">|</li>
        <li><span className="red"><a target="_blank" href="http://guba.eastmoney.com/">股吧</a></span></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://fund2.eastmoney.com/">基金吧</a></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://blog.eastmoney.com/">博客</a></li>
        <li className="i">|</li>
        <li><span className="red"><a target="_blank" href="http://caifuhao.eastmoney.com/">财富号</a></span></li>
        <li className="i">|</li>
        <li><a target="_blank" href="http://so.eastmoney.com/">搜索</a></li>
      </ul>
    </div>

  )
}

export default HeaderLinks