import React,{useState, useEffect} from "react"
import { SiderTabBox } from "../sider_tab_box"
import SiderFavorImg from './sider_favor_img'
import { SiderHistoryImg } from './sider_history_img'
import { sendStat } from "../../../modules/bigdata_stat"

/**
 * 行情侧边自选股
 * @returns 
 */
export function QuoteSiderFavor(){
  return (
    <>
    <SiderTabBox {...{
      tab_split: true,
      boxdata:[
        {
          tab_html: <a href='//quote.eastmoney.com/zixuan/?from=zixuanmini' target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.wdzxg.wdzxg-dj')}}>我的自选股</a>,
          body: <SiderFavorImg size={6}></SiderFavorImg>,
          stat_code: 'webjdb.wdzxg.wdzxg-qh'
        },
        {
          tab_html: <i onClick={()=>{sendStat('A', 'Click', 'webjdb.zjfw.zjfw')}}>最近访问</i>,
          body: <SiderHistoryImg size={6}></SiderHistoryImg>
        }
      ]}}></SiderTabBox>  
    </>
  )
}