import React,{useState, useEffect} from "react"
import './kwjc.less'
import {type_quote_back} from '../../../modules/quote_info/quote_data'
import { QuoteItem } from '../../../modules/quote_info/config'

/**
 * 扩位简称
 * @returns 
 */
export function KWJC(props:{
  quotedata: type_quote_back
}){

  if(props.quotedata[QuoteItem.扩位简称] && props.quotedata[QuoteItem.扩位简称].source && props.quotedata[QuoteItem.扩位简称].source != '-'){
    return (
      <em className="kwjc" title={`扩位简称：${props.quotedata[QuoteItem.扩位简称].source}`}></em>
    )
  }

  return <></>

}