/**
 * 研报表格
 */

import React,{useState, useEffect} from "react"
import { type_report, getReport } from "../../../modules/report/web";
import { SiderTabBox } from "../sider_tab_box";
import './report_table.less'
import { sendStat } from "../../../modules/bigdata_stat";

/** 研报表格 */
export function ReportTable(props:{
  industryCode?: string,
  inputdata?: Array<type_report>,
  size?: number
}){

  let size = props.size || 5;

  const [showdata, SetShowData] = useState([] as Array<type_report>)

  const getData = async()=>{

    if(props.inputdata){
      SetShowData(props.inputdata)
      return
    }

    let industryCode = props.industryCode || '*'
    let back = await getReport(industryCode)
    
    if(back.length > 0){
      SetShowData(back)
    }
  }

  useEffect(()=>{
    getData()
  },[])

  return (
    <>
      <table className="reporttable" onClick={()=>{sendStat('A', 'Click', 'webjdb.hyyb.qynr')}}>
        <thead>
          <tr>
            <th>机构</th>
            <th>评级</th>
            <th className="tal">研报</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            showdata.map((v, index)=>
              <tr key={index}>
                <td className="orgname"><a href={`//data.eastmoney.com/report/orgpublish.jshtml?orgcode=${v.orgCode}`} target="_blank"><span title={v.orgSName}>{v.orgSName}</span></a></td>
                <td className="rating">{v.emRatingName}</td>
                <td className="title"><a href={`http://data.eastmoney.com/report/zw_industry.jshtml?encodeUrl=${v.encodeUrl}`} target="_blank"><span title={v.title}>{v.title}</span></a></td>
                <td className="time">{v.publishDate.length >= 10 ? v.publishDate?.substring(5,10) : ''}</td>
              </tr>              
            )
          }
          {
            showdata.length > 0 && showdata.length < size && Array.from(new Array(size - showdata.length)).map((v,index)=>
              <tr key={index}>
                <td className="orgname"></td>
                <td className="rating"></td>
                <td className="title"></td>
                <td className="time"></td>
              </tr>              
            )
          }
          {
            showdata.length == 0 &&
            <>
              <tr>
                <td className="orgname"></td>
                <td className="rating"></td>
                <td className="title"></td>
                <td className="time"></td>
              </tr> 
              <tr>
                <td className="orgname"></td>
                <td className="rating"></td>
                <td className="title"></td>
                <td className="time"></td>
              </tr>
              <tr>
                <td colSpan={4} style={{textAlign: 'center', fontSize: 12}}>暂无数据</td>
              </tr>
              <tr>
                <td className="orgname"></td>
                <td className="rating"></td>
                <td className="title"></td>
                <td className="time"></td>
              </tr>
              <tr>
                <td className="orgname"></td>
                <td className="rating"></td>
                <td className="title"></td>
                <td className="time"></td>
              </tr> 
            </>
          }


        </tbody>
      </table>
    </>
  )
}

/**
 * 行业研报
 * @param props 行业板块id
 */
export function TabBoxIndustryReport(props:{
  bk_id: string
}){

  let hy_id = props.bk_id.replace(/bk(0*)/i, '')

  if(hy_id == ''){
    return (
      <SiderTabBox {...{
        boxdata:[
          {
            tab_html: <a href={`//data.eastmoney.com/report/industry`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hyyb.hyyb')}}>行业研报</a>,
            more_html: <a href={`//data.eastmoney.com/report/industry`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hyyb.gd')}}>更多</a>,
            body:
            <table className="reporttable">
              <thead>
                <tr>
                  <th>机构</th>
                  <th>评级</th>
                  <th className="tal">研报</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td className="orgname"></td>
                    <td className="rating"></td>
                    <td className="title"></td>
                    <td className="time"></td>
                  </tr> 
                  <tr>
                    <td className="orgname"></td>
                    <td className="rating"></td>
                    <td className="title"></td>
                    <td className="time"></td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={{textAlign: 'center', fontSize: 12}}>暂无数据</td>
                  </tr>
                  <tr>
                    <td className="orgname"></td>
                    <td className="rating"></td>
                    <td className="title"></td>
                    <td className="time"></td>
                  </tr>
                  <tr>
                    <td className="orgname"></td>
                    <td className="rating"></td>
                    <td className="title"></td>
                    <td className="time"></td>
                  </tr>             
              </tbody>
            </table>
          }
        ]}}></SiderTabBox>      
    )
  }

  return (
    <SiderTabBox {...{
      boxdata:[
        {
          tab_html: <a href={`//data.eastmoney.com/report/industry.jshtml?hyid=${hy_id}`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hyyb.hyyb')}}>行业研报</a>,
          more_html: <a href={`//data.eastmoney.com/report/industry.jshtml?hyid=${hy_id}`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.hyyb.gd')}}>更多</a>,
          body: <ReportTable industryCode={hy_id}></ReportTable>
        }
      ]}}></SiderTabBox>
  )
}