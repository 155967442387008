/**
 * 头部-行情框
 */

import React,{useState, useEffect} from "react"
import HSGT from "../hsgt"
import QQGS from "../qqgs"
import './index.less'

function HeaderQuoteBox(){

  useEffect(()=>{
    if(window.suggest2017){
      var newsuggest = new window.suggest2017({ //新建实例
          inputid: "hqb_t_search_input", //参数部分
          newapi: true,
          offset: { left: -98, top: 5 },
          shownewtips: true,
          newtipsoffset: { top: -4, left: 17 }
      });      
    }

  }, [])

  return (
    <div className="headquotebox">
      <div className="hqb_t self_clearfix">
          <div className="title"><a href="http://quote.eastmoney.com/center/" target="_blank"><span className="hqb_center_icon"></span>行情中心</a></div>
          <div className="hqb_links">
            <ul>
              <li><a href="http://quote.eastmoney.com/center/hszs.html" target="_blank">指数</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/center/gridlist.html#futures_cffex" target="_blank">期指</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/center/qqsc.html" target="_blank">期权</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/" target="_blank">个股</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/center/hsbk.html" target="_blank">板块</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/center/gridlist.html#hs_a_board" target="_blank">排行</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/center/gridlist.html#newshares" target="_blank">新股</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/center/jjsc.html" target="_blank">基金</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/center/ggsc.html" target="_blank">港股</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/center/mgsc.html" target="_blank">美股</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/center/futures.html" target="_blank">期货</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/center/whsc.html" target="_blank">外汇</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/center/hjsc.html" target="_blank">黄金</a></li><li>|</li>
              <li><a href="http://quote.eastmoney.com/zixuan/" target="_blank">自选股</a></li><li>|</li>
              <li><a href="http://favor.fund.eastmoney.com/" target="_blank">自选基金</a></li>
            </ul>
          </div>
          <div className="hqb_t_search">
            <form className="hqb_t_search_f" method="get" target="_blank">
              <input type="text" name="stockcode" id="hqb_t_search_input" /><input type="submit" value="搜索" className="hqb_t_search_submit" />
            </form>
          </div>
      </div>
      <div className="hqb_qqgs self_clearfix">
        <div className="title">
          <a href="http://quote.eastmoney.com/center/global.html#global_3" target="_blank"><span className="hqb_qqgs_icon"></span>全球股市</a>
        </div>
        <div className="hqb_qqgsd">
          <QQGS></QQGS>          
        </div>
      </div>
      <div className="hqb_sjzx">
        <div className="title">
          <a href="http://js1.eastmoney.com/tg.aspx?ID=517" target="_blank"><span className="hqb_data_icon"></span>数据中心</a>
        </div>
        <div className="hqb_sjzx_d self_clearfix">
          <a href="http://js1.eastmoney.com/tg.aspx?ID=520" target="_blank">新股申购</a><a href="http://js1.eastmoney.com/tg.aspx?ID=522" target="_blank">新股日历</a><a href="http://js1.eastmoney.com/tg.aspx?ID=525" target="_blank">资金流向</a><a href="//quote.eastmoney.com/center/gridlist.html#ah_comparison" target="_blank" className="red">AH股比价</a><a href="http://js1.eastmoney.com/tg.aspx?ID=540" target="_blank">主力排名</a><a href="http://js1.eastmoney.com/tg.aspx?ID=615" target="_blank">板块资金</a><a href="http://data.eastmoney.com/report/stock.jshtml" target="_blank">个股研报</a><a href="http://data.eastmoney.com/report/industry.jshtml" target="_blank">行业研报</a><a href="http://data.eastmoney.com/report/profitforecast.jshtml" target="_blank">盈利预测</a><a href="http://data.eastmoney.com/stockcomment/" target="_blank">千股千评</a><a href="http://data.eastmoney.com/bbsj/" target="_blank">年报季报</a><a href="http://js1.eastmoney.com/tg.aspx?ID=693" target="_blank">龙虎榜单</a><a href="http://js1.eastmoney.com/tg.aspx?ID=695" target="_blank">限售解禁</a><a href="http://js1.eastmoney.com/tg.aspx?ID=731" target="_blank">大宗交易</a><a href="http://js1.eastmoney.com/tg.aspx?ID=748" target="_blank">期指持仓</a><a href="http://js1.eastmoney.com/tg.aspx?ID=609" target="_blank">融资融券</a>
        </div>
      </div>
      <div className="hqb_hsgt">
        <div className="title">
          <a href="http://quote.eastmoney.com/center/hgtstock.html#_12" target="_blank"><span className="hqb_hsgt_icon"></span>沪深港通</a>
        </div>
        <div className="hqb_hsgt_d">
          <HSGT></HSGT>          
        </div>
      </div>
    </div>
  )
}

export default HeaderQuoteBox