import React,{useState, useEffect} from "react"
import './index.less'

/** 帮助小图标 */
export function HelpIcon(props:{
  tip_txt?:string,
  tip_width?:number,
  tip_left?:number,
}){

  let width = props.tip_width != undefined ? props.tip_width : 200
  let left = props.tip_left != undefined ? props.tip_left : -100

  return (
    <div className="helpicon">
      {
        props.tip_txt &&
        <div className="tiptxt" style={{width: width, left: left}}>
          {props.tip_txt}
        </div>
      }
    </div>
  )
}