import React,{useState, useEffect} from "react"



export function useIsBrowser(){
  const [isBrowser, SetIsBrowser] = useState(false)

  useEffect(()=>{
    SetIsBrowser(true)
  }, []) 

  return isBrowser
}