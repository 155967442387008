/**
 * 模拟下拉菜单
 */
import React,{useState, useEffect, useRef} from "react"
import _ from 'lodash'
import './index.less'

/**
 * 模拟下拉菜单
 * @param props 
 * @returns 
 */
export function Select(props:{
  title?: string,
  width?: number,
  list: Array<{
    name: string,
    value: any,
    check?: boolean
  }>,
  onChange?: Function
}){

  let width = props.width ?? 150

  const dropEl = useRef(null);

  const [hide, SetHide] = useState(false)

  const [title, SetTitle] = useState(props.title || (props.list.find(v=>v.check)?.name))

  const onChange = function(index: number){
    SetTitle(props.list[index].name)
    if(props.onChange){
      props.onChange(props.list[index].value);
      // (dropEl.current as unknown as HTMLDivElement).style.display = 'none'
      SetHide(true)
      setTimeout(() => {
        SetHide(false)
      }, 100);
    }
  }


  return (
    <div className={`select ${hide?'select_hide':''}`} style={{width: width}}>
      <div className="select_n">
        <div className="t">{title}</div>
        <div className="select_arrow"></div>
      </div>
      <div className="drop" ref={dropEl}>
        {
          props.list.length > 0 &&
          <ul>
            {
              props.list.map((v, index)=>
                <li key={index} onClick={(e)=>{onChange(index)}}>{v.name}</li>
              )
            }
          </ul>          
        }

      </div>
    </div>
  )
}


/**
 * 模拟下拉菜单
 * @param props 
 * @returns 
 */
export function Select2(props:{
  width?: number,
  list: Array<{
    name: string,
    value: any,
    check?: boolean
  }>,
  onChange?: Function
}){

  let width = props.width ?? 150

  const [list, SetList] = useState(props.list)

  useEffect(()=>{
    SetList(props.list)
  }, [props.list])
  

  const onChange = function(index: number){
    list.forEach(v=>{
      v.check = false
    })
    list[index].check = true
    SetList(_.clone(list))
    if(props.onChange){
      props.onChange(list[index].value)
    }
  }

  let checkitem = list.find(v=>v.check)

  return (
    <div className="select" style={{width: width}}>
      <div className="select_n">
        {
          checkitem ?
            <div className="t">{checkitem.name}</div>
          :
            <div className="t">{list[0]?.name}</div>
        }
        
        <div className="select_arrow"></div>
      </div>
      <div className="drop">
        {
          list.length > 0 &&
          <ul>
            {
              list.map((v, index)=>
                <li key={index} onClick={(e)=>{onChange(index)}}>{v.name}</li>
              )
            }
          </ul>          
        }

      </div>
    </div>
  )
}