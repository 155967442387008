/**
 * 十大流通股东
 */
import React,{useState, useEffect} from "react"
import { LTGDGD, LTGDJS } from "../../../modules/datacenter/web"
import useSWR from 'swr'
import txt from '../../../modules/txt'
import './index.less'
import { sendStat } from "../../../modules/bigdata_stat"

export function SDLTGD(props:{
  code: string,
  market: string
}){

  let marstr = 'sh'
  if(props.market == '0'){
    marstr = 'sz'
  }

  let data_code = props.code
  if(props.market == '0'){
    data_code = `${props.code}.SZ`
  }
  else if(props.market == '1'){
    data_code = `${props.code}.SH`
  }

  // if(type == 'BJ'){
  //   data_code = `${props.code}.BJ`
  // }

  const { data, error } = useSWR(`/LTGDGD/${data_code}`, ()=>LTGDGD(data_code), {
    revalidateOnFocus: false
  })
  const { data: data2, error: error2 } = useSWR(`/LTGDJS/${data_code}`, ()=>LTGDJS(data_code), {
    revalidateOnFocus: false
  })

  return (
    <div className="sdltgd">
      <div className="t">十大流通股东</div>
      <div className="more"><a href={`https://emweb.securities.eastmoney.com/pc_hsf10/pages/index.html?type=web&code=${marstr}${props.code}&color=b#/gdyj`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.ltgd.gd')}}>更多</a></div>
      {
        data2 && data2.length > 0 &&
        <table>
          <tbody>
            <tr>
                <td>公募</td>
                <td>私募</td>
                <td>社保</td>
                <td>QFII</td>
            </tr>          
            <tr>
                <td><ShowNumber input={data2[0].HOLDER_PUBLIC_NUM}></ShowNumber>家(<ShowNumber input={data2[0].HOLDER_PUBLIC_NUMMM}></ShowNumber>)</td>
                <td><ShowNumber input={data2[0].HOLDER_PRIVATE_NUM}></ShowNumber>家(<ShowNumber input={data2[0].HOLDER_PRIVATE_NUMMM}></ShowNumber>)</td>
                <td><ShowNumber input={data2[0].HOLDER_SOCIAL_NUM}></ShowNumber>家(<ShowNumber input={data2[0].HOLDER_SOCIAL_NUMMM}></ShowNumber>)</td>
                <td><ShowNumber input={data2[0].HOLDER_QFII_NUM}></ShowNumber>家(<ShowNumber input={data2[0].HOLDER_QFII_NUMMM}></ShowNumber>)</td>
            </tr>
          </tbody>
        </table>         
      }
      {
        data2 && data2.length == 0 &&
          <table>
            <tbody>
              <tr>
                  <td>公募</td>
                  <td>私募</td>
                  <td>社保</td>
                  <td>QFII</td>
              </tr>          
              <tr>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
              </tr>
            </tbody>
          </table>         
      }
      {
        data && data.length > 1 &&
        <table>
          <tbody>      
            <tr>
                <td>股东情况</td>
                <td className="tac">{data[0].END_DATE?.substring(2, 10)}</td>
                <td className="tac">{data[1].END_DATE?.substring(2, 10)}</td>
            </tr>
            <tr>
                <td>股东户数</td>
                <td className="tac">{txt.formatNum(data[0].HOLDER_TOTAL_NUM)}</td>
                <td className="tac">{txt.formatNum(data[1].HOLDER_TOTAL_NUM)}</td>
            </tr>
            <tr>
                <td>较上期(%)</td>
                <td className="tac">{data[0].TOTAL_NUM_RATIO ? txt.showPercentWithZoomNew(data[0].TOTAL_NUM_RATIO, 2) : '-'}</td>
                <td className="tac">{data[1].TOTAL_NUM_RATIO ? txt.showPercentWithZoomNew(data[1].TOTAL_NUM_RATIO, 2) : '-'}</td>
            </tr>
          </tbody>
        </table>        
      }

    </div>
  )
}

function ShowNumber(props:{
  input:number | null
}){
  if(props.input === null){
    return <>-</>
  }
  return <>{props.input}</>
}