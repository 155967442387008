/**
 * 多空调查接口
 */

import cookie from "../cookie";

/**
 * 获取单个股票的涨跌投票数据
 * @param market 市场
 * @param code 代码
 * @returns 
 */
export async function getStockVote(market:string, code:string):Promise<{
  z: number,
  d: number,
  date: string
}>{
  if(market == '0'){
    code = 'sz' + code
  }
  else{
    code = 'sh' + code
  }
  try {
    let back = await $.ajax({
      method: 'GET',
      url: '/newapi/getstockvote',
      dataType: 'json',
      data: {
        code
      }
    })

    if(back?.Status == 1){
      return {
        z: back.Data.TapeZ,
        d: back.Data.TapeD,
        date: back.Data.Date
      }
    }
  } catch (error) {
    console.error(error)
  }

  return {
    z: 0.5,
    d: 0.5,
    date: ''
  }
}

/**
 * 股票多空调查投票
 * @param market 市场
 * @param code 代码
 * @param direction 涨 1 跌 -1 
 * @returns 
 */
export async function voteStockDZ(market: string, code: string, direction: 1 | -1){

  if(market == '0'){
    code = 'sz' + code
  }
  else{
    code = 'sh' + code
  }

  let uid = cookie.get('uidal') || cookie.get('qgqp_b_id')

  try {
    $.ajax({
      url: '/newapi/votestock',
      type: 'POST',
      dataType: 'json',
      data: {
        code,
        tapetype: direction,
        uid
      }
    })
  } catch (error) {
    console.error(error)
  }

  return {
    re: true
  }
}