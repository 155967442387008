/**
 * Web F10链接
 */

import {StockType} from "../stocktype"

/**
 * F10类型
 */
export enum F10Type{
  "操盘必读",
  "公司概况",
  "经营分析",
  "财务分析",
  "股东研究",
  "核心题材",
  "分红融资",
  "公司大事",
  "股本结构",
  "资本运作",
  "行业分析",
  "关联个股",
  "资讯公告",
  "同行比较",
  "盈利预测",
  "研究报告",
  "公司高管",

  "更多"
}

/**
 * 获取F10链接
 * @param code 代码 
 * @param pagetype 页面类型
 * @param f10type F10类型
 * @returns 
 */
export function getF10Link(code: string, pagetype: StockType, f10type: F10Type){

  let f10code = 'SZ' + code

  if(pagetype == StockType.上证主板A股 || pagetype == StockType.上证科创板) f10code = 'SH' + code
  if(pagetype == StockType.北证主板A股) f10code = 'BJ' + code

  return getF10LinkNew(f10code, f10type)
}

/**
 * F10地址
 * @param f10code F10代码 例如 SZ300059 BJBJ872190 SH600000
 * @param f10type 
 */
export function getF10LinkNew(f10code: string, f10type: F10Type){

  let backurl = ''
  let commonurl = 'https://emweb.securities.eastmoney.com/pc_hsf10/pages/index.html?type=web&code='

  switch (f10type) {
    case F10Type.操盘必读:
      backurl = `${commonurl}${f10code}&color=b#/cpbd`
      break;
    case F10Type.公司概况:
      backurl = `${commonurl}${f10code}&color=b#/gsgk	`
      break;  
    case F10Type.经营分析:
      backurl = `${commonurl}${f10code}&color=b#/jyfx`
      break;
    case F10Type.核心题材:
      backurl = `${commonurl}${f10code}&color=b#/hxtc`
      break; 
    case F10Type.资讯公告:
      backurl = `${commonurl}${f10code}&color=b#/zxgg`
      break;       
    case F10Type.股东研究:
      backurl = `${commonurl}${f10code}&color=b#/gdyj`
      break;
    case F10Type.公司大事:
      backurl = `${commonurl}${f10code}&color=b#/gsds`
      break;  
    case F10Type.股本结构:
      backurl = `${commonurl}${f10code}&color=b#/gbjg`
      break;
    case F10Type.财务分析:
      backurl = `${commonurl}${f10code}&color=b#/cwfx`
      break;       
    case F10Type.分红融资:
      backurl = `${commonurl}${f10code}&color=b#/fhrz`
      break;
    case F10Type.资本运作:
      backurl = `${commonurl}${f10code}&color=b#/zbyz`
      break;  
    case F10Type.行业分析:
      backurl = `${commonurl}${f10code}&color=b#/thbj`
      break;
    case F10Type.关联个股:
      backurl = `${commonurl}${f10code}&color=b#/glgg`
      break; 
    case F10Type.同行比较:
      backurl = `${commonurl}${f10code}&color=b#/thbj`
      break;       
    case F10Type.盈利预测:
      backurl = `${commonurl}${f10code}&color=b#/ylyc`
      break; 
    case F10Type.研究报告:
      backurl = `${commonurl}${f10code}&color=b#/yjbg`
      break;       
    case F10Type.公司高管:
      backurl = `${commonurl}${f10code}&color=b#/gsgg`
      break;  
  
    default:
      backurl = `${commonurl}${f10code}&color=b#/cpbd`
      break;
  }

  return backurl
}