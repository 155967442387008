/**
 * A股行情字段列表
 */

import React,{useState, useEffect} from "react"
import { observer } from "mobx-react-lite"
import { quotedata, QuoteItem } from "../quote_data";
import {initSingleValue} from '../../../modules/quote_info/quote_data'
import { type_quote_back } from "../../../modules/quote_info/quote_data";
import './index.less'
import { QuoteBriefSider } from "./quote_brief_sider";

function AQuoteBrief(props:{
  market: string,
  code: string,
  name: string
}){


  let showdata = initSingleValue()
  if(quotedata.hqdata != null){
    showdata = quotedata.hqdata
  }

  return (
    <QuoteBrief quotedata={showdata} name={props.name} market={props.market} code={props.code}></QuoteBrief>
  )
}


function QuoteBrief(props:{
  market: string,
  code: string,
  name: string,
  quotedata: type_quote_back
}){

  let quotedata = props.quotedata

  return (
    <div className="zsquote3l zs_brief">
      <div className="quote3l_l">
        <div className="quote_quotenums">
          {
            quotedata[QuoteItem.异常交易状态].txt == '停牌' &&
            <div className="errortp">
              <div className="errortp_zhj">
                {quotedata[QuoteItem.最后价格].txt}
              </div>
              <div className="errorstatetp">{ quotedata[QuoteItem.异常交易状态].txt}</div>
            </div>
          }
          {
            quotedata[QuoteItem.异常交易状态].txt && quotedata[QuoteItem.异常交易状态].txt != '-' && quotedata[QuoteItem.异常交易状态].txt != '停牌' &&
              <div className="errorstate">{ quotedata[QuoteItem.异常交易状态].txt}</div>
          }
          {
            (quotedata[QuoteItem.异常交易状态].txt == '' || quotedata[QuoteItem.异常交易状态].txt == '-') &&
              <>
                <div className="zxj">
                  {quotedata[QuoteItem.最新价].blink_html}
                  {quotedata[QuoteItem.最新价].color == 'price_up' &&
                    <span className="price_up_icon"></span>
                  }
                  {quotedata[QuoteItem.最新价].color == 'price_down' &&
                    <span className="price_down_icon"></span>
                  }
                </div>              
                <div className="zd">
                  {quotedata[QuoteItem.涨跌额].blink_html}
                  {quotedata[QuoteItem.涨跌幅].blink_html}
                </div>
              </>            
          }         
        </div>
      </div>
      <div className="quote3l_c">
        <div className="brief_info_c">
          <table>
            <tbody>
              <tr>
                <td className="n">今开：</td>
                <td>{quotedata[QuoteItem.今开].blink_html}</td>
                <td className="n">最高：</td>
                <td>{quotedata[QuoteItem.最高价].blink_html}</td>
                <td className="n">涨停：</td>
                <td>{quotedata[QuoteItem.涨停价].blink_html}</td>                
                <td className="n">换手：</td>
                <td>{quotedata[QuoteItem.换手率].blink_html}</td>
                <td className="n">成交量：</td>
                <td>{quotedata[QuoteItem.成交量].blink_html}</td>       
                <td className="n">
                  市盈(动)<span className="title_help">
                    <div className="ssy">
                      <ul>
                        <li className="ssy_t">·动态市盈率：{quotedata[QuoteItem.市盈率动态].html}</li>
                        <li>总市值除以全年预估净利润，例如当前一季度净利润1000万,则预估全年净利润4000万</li>
                        <li className="ssy_t">·静态市盈率：{quotedata[QuoteItem.市盈率静态].html}</li>
                        <li>总市值除以上一年度净利润</li>
                        <li className="ssy_t">·滚动市盈率：{quotedata[QuoteItem.市盈率TTM].html}</li>
                        <li>最新价除以最近4个季度的每股收益</li>
                      </ul>
                    </div>                    
                  </span>：
                </td>
                <td>{quotedata[QuoteItem.市盈率动态].blink_html}</td>                
                <td className="n">总市值：</td>
                <td>{quotedata[QuoteItem.总市值].blink_html}</td>
              </tr>
              <tr>
                <td className="n">昨收：</td>
                <td>{quotedata[QuoteItem.昨收].blink_html}</td>
                <td className="n">最低：</td>
                <td>{quotedata[QuoteItem.最低价].blink_html}</td>
                <td className="n">跌停：</td>
                <td>{quotedata[QuoteItem.跌停价].blink_html}</td>                
                <td className="n">量比：</td>
                <td>{quotedata[QuoteItem.量比].blink_html}</td>
                <td className="n">成交额：</td>
                <td>{quotedata[QuoteItem.成交额].blink_html}</td>
                <td className="n">市净：</td>
                <td>{quotedata[QuoteItem.市净率].blink_html}</td>                
                <td className="n">流通市值：</td>
                <td>{quotedata[QuoteItem.流通市值].blink_html}</td>
              </tr>
            </tbody>
          </table>         
        </div>
      </div>
      <div className="quote3l_r">
        <QuoteBriefSider name={props.name} code={props.code} market={props.market}></QuoteBriefSider>
      </div>
    </div>
  )    
}



export default observer(AQuoteBrief)
