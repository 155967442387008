/**
 * 侧边栏-我的自选
 */


import React,{useState, useEffect} from "react"
import { pagezxgdata } from "../page_zxg_data";
import { observer } from "mobx-react-lite"
import { useQuoteList, QuoteItem } from "../hooks/quote_hook";
import './sider_favor_img.less'
import config from '../../../config/web'
import { sendStat } from "../../../modules/bigdata_stat";

function ShowSiderFavorImg(props:{
  stock_list: Array<string>,
  size: number,
  mt ?: number
}){

  return (

      <>
        <QuoteTableWithImg codelist={props.stock_list} minsize={props.size}></QuoteTableWithImg>
        <div className="sider_favor_img_bmore">
          <a href="//quote.eastmoney.com/zixuan/?from=zixuanmini" target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.wdzxg.ckgd')}}>查看更多自选股&gt;&gt;&gt;</a>
        </div> 
      </>
  )    
  
}

/** 侧边栏-行情表格-行情代码串获取 */
export function QuoteTableWithImg(props:{
  codelist: Array<string>,
  minsize?:number,
}){



  let showdata = useQuoteList(props.codelist, [
    QuoteItem.名称,
    QuoteItem.代码,
    QuoteItem.行情代码,
    QuoteItem.行情统一链接,
    QuoteItem.最新价,
    QuoteItem.涨跌幅,
    // QuoteItem.是否颜色反转,
    QuoteItem.交易时间
  ], 40)

  let size = props.codelist.length
  if(showdata.length > 0){
    size = showdata.length
  }
  if(props.minsize != undefined && props.minsize > size){
    size = props.minsize
  }

  return (
    <div>
      <table className='sider_favor_img' onClick={()=>{sendStat('Table', 'Click', 'webjdb.wdzxg.qynr')}}>
        <tbody>
          {
            showdata.map(v=>
              <tr key={v[QuoteItem.行情代码].txt}>
                <td><a href={v[QuoteItem.行情统一链接].txt} className="ofd_td_60" title={v[QuoteItem.名称].txt} target="_blank">{v[QuoteItem.名称].txt}<br />{v[QuoteItem.代码].txt}</a></td>
                <td>{v[QuoteItem.最新价].html}<br />{v[QuoteItem.涨跌幅].html}</td>
                <td className="img"><a href={v[QuoteItem.行情统一链接].txt} target="_blank"><img src={`${config.getUrl('chart_time')}GetPic.aspx?nid=${v[QuoteItem.行情代码].txt}&imageType=RJY&token=44c9d251add88e27b65ed86506f6e5da92&rnd=${v[QuoteItem.交易时间].source}`} alt="" /></a></td>
              </tr>          
            )
          }
          {
            (showdata.length < size) && Array.from(new Array(size - showdata.length)).map((v,index)=>
              <tr key={index}>
                <td>-</td>
                <td>-</td>
                <td className="img">-</td>
              </tr>                
            )
          }
        </tbody>
      </table>
    </div>
  )
}


function SiderFavorImg(props:{
  mt?:number,
  size?: number
}){

  let size = props.size ?? 9  

  if(pagezxgdata.zxgdata != null){
    return (
      <ShowSiderFavorImg stock_list={pagezxgdata.zxgdata.result.list.slice(0, size)} size={size} mt={props.mt}></ShowSiderFavorImg>
    )    
  }
  return (
    <ShowSiderFavorImg stock_list={[]} size={size}></ShowSiderFavorImg>
  )   
}

export default observer(SiderFavorImg)