/**
 * 行情hook
 */
import React,{useState, useEffect} from "react"
import { QuoteItem as quoteitem  } from "./config";
import { getListQuote, getOneQuote, searchListQuote, type_quote_back } from "./quote_data";

export const QuoteItem = quoteitem

/**
 * 获取一组行情列表hook
 * @param quotecodelist 行情代码数组
 * @param itemlist 获取字段数组
 * @param refresh_time 自刷时间 单位秒 0不自刷 默认不自刷
 */
export function useQuoteList(quotecodelist: Array<string>, itemlist:Array<quoteitem>, refresh_time = 0, fid?:string, po?: 0 | 1) {

  if(quotecodelist.length == 0){
    return []
  }

  const [backdata, SetBackData] = useState([] as Array<type_quote_back>)

  const getdata = async()=>{
    let quote_back = await getListQuote(quotecodelist, itemlist, fid, po)        
    SetBackData(quote_back)
  }

  useEffect(()=>{
    getdata()
    if(refresh_time > 0){
      let cd = setInterval(function(){
        getdata()
      }, refresh_time * 1000)
      
      return ()=>{
        try {
          clearInterval(cd)
        } catch (error) {
          
        }
      }      
    }
  }, [quotecodelist.join(',')])

  return backdata
}


/**
 * 获取单个行情数据hook
 * @param quotecode 行情代码
 * @param itemlist 获取字段数组
 * @param refresh_time 自刷时间 单位秒 0不自刷 默认不自刷
 */
export function useOneQuote(quotecode: string, itemlist:Array<quoteitem>, refresh_time = 0) {

  const [backdata, SetBackData] = useState(null as null | type_quote_back)

  const getdata = async()=>{
    let quote_back = await getOneQuote(quotecode, itemlist)        
    SetBackData(quote_back)
  }

  useEffect(()=>{
    getdata()
    if(refresh_time > 0){
      let cd = setInterval(function(){
        getdata()
      }, refresh_time * 1000)
      
      return ()=>{
        try {
          clearInterval(cd)
        } catch (error) {
          
        }
      }      
    }
  }, [])

  return backdata
}

/**
 * 获取fs查询字符串行情列表hook
 * @param fs fs过滤字符串
 * @param itemlist 字段数组
 * @param fid 排序字符串
 * @param pn 第几页 1开始
 * @param pz 每页多少条 默认10
 * @param po 排序 1 倒叙 0 正序
 * @param refresh_time 自刷时间 单位:秒 0不自刷 默认不自刷
 */
export function useQuoteSearchList(fs: string, itemlist:Array<quoteitem>, fid:string, pn = 1, pz = 10 , po = 1 as 0|1, refresh_time = 0) {


  const [backdata, SetBackData] = useState([] as Array<type_quote_back>)

  const getdata = async()=>{
    let quote_back = await searchListQuote(fs, itemlist, fid, pn, pz, po)        
    SetBackData(quote_back)
  }

  useEffect(()=>{
    getdata()
    if(refresh_time > 0){
      let cd = setInterval(function(){
        getdata()
      }, refresh_time * 1000)
      
      return ()=>{
        try {
          clearInterval(cd)
        } catch (error) {
          
        }
      }      
    }
  }, [fs, itemlist.toString(), fid, pn, pz, po, refresh_time])

  return backdata
}