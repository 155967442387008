import React,{useState, useEffect} from "react"
import { sendStat } from "../../../modules/bigdata_stat"
import './index.less'


/**
 * A股bar
 * @returns 
 */
export function BarD(props:{
  market: string,
  code: string,
  name: string
}){
  return (
    <div className="abard">
      <a className="icon gb" href={`//guba.eastmoney.com/list,${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tzgn.gb')}}>{props.name}吧</a>
      <a className="icon sh" href={`//data.eastmoney.com/stockcomment/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tzgn.jgsh')}}>机构散户</a>
      <a className="icon red mmd" href="https://acttg.eastmoney.com/pub/web_act_ggds_hqtsf_01_01_01_0" target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tzgn.jzmmd')}}>精准买卖点</a>
      <a className="icon cj" href={`//quote.eastmoney.com/f1.html?code=${props.code}&market=${props.market}`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tzgn.ddcj')}}>大单成交</a>
      <a className="icon yl" href={`//data.eastmoney.com/report/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tzgn.ylyc')}}>盈利预测</a>
      <a className="icon red jg" href="https://acttg.eastmoney.com/pub/web_act_ggds_hqtsf_01_01_02_0" target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tzgn.jgdy')}}>机构调研</a>
      <a className="icon wdm" href={`//guba.eastmoney.com/qa/qa_search.aspx?company=${props.code}&keyword=&questioner=&qatype=1`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.tzgn.wdm')}}>问董秘</a>
      <a className="refresh" href="#" onClick={(e)=>{sendStat('A', 'Click', 'webjdb.tzgn.sx');self.location.reload();e.preventDefault();}}></a>
    </div>
  )
}