/**
 * 操作字符串
 */

const txt = {
  /**
   * 字符串长度 一个汉字算2个
   * 
   * @param {any} txt 
   * @returns 
   */
  txtLength (txt:string) {
    var thislength = 0;
    for (var i = 0; i < txt.length; i++) {
      if (txt.charCodeAt(i) > 255) {
        thislength += 2;
      }
      else {
        thislength++;
      }
    }
    return thislength;
  },
    /**
   * 字符串截取 (一个汉字算2个)
   * 
   * @param {string} txt 输入文本
   * @param {int} n 截取多少个字 一个汉字算2个
   * @param {boolean} needtip 是否需要全文提示
   * @param {string} postfix 自定义截断后缀，默认...
   * @returns 
   */
  txtLeft: function (txt:string, n:number, needtip:boolean = false, postfix:string = '...') {
    if( txt == null || txt == "" ){
      return "";
    }

    var thislength = 0;

    for (var i = 0; i < txt.length; i++) {
      if (txt.charCodeAt(i) > 255) {
        thislength += 2;
      }
      else {
        thislength++;
      }
      if (thislength > n) {
        if(needtip){
          return '<span title="' + txt + '">' + txt.substring(0, i) + postfix + "</span>";
        }
        else{
          return txt.substring(0, i) + postfix;
        }
        break;
      }
    }
    return txt;
  },
  /**
   * 根据数字颜色化文字
   * @param text 文字
   * @param num 数字
   * @param hz 后缀
   */
  textNumColor: function(text:string, num:any, hz?:string){
    if (hz == undefined) {
      hz = ''
    }
    if (num == null || isNaN(num)) {
      return '<span class="quotenone">' + text + '</span>'
    }
    else{
      num = num / 1
    }

    if (num > 0) {
      return '<span class="quoteup red">' + text + hz + '</span>'
    }
    else if (num < 0) {
      return '<span class="quotedown green">' + text + hz + '</span>'
    } 
    else if (num == 0) {
      return '<span class="quotedraw">' + text + hz + '</span>'
    }
    else{
      return '<span class="quotenone">' + text + '</span>'
    }
  },
  /**
   * 对放大的行情数字缩小处理
   * @param number1 
   * @param zoom 
   */
  quoteNumZoomOut: function(number1:any, zoom:any){
    if (number1 == null || number1 === '' || isNaN(number1) || zoom == null || zoom === '' || isNaN(zoom)) {
      return '-'
    }
    return (number1 / (Math.pow(10, zoom))).toFixed(zoom)
  },
  formatNum: function(num:any){
    if(num == 0) {
      return num
    }
    if(num == undefined || num === '' || isNaN(num)){
      return '';
    }

    var hz = '';
    if(num >= 100000000||num <= -100000000){
      num = num / 100000000;
      hz = '亿';
    }
    else if(num >= 10000||num <= -10000){
      num = num / 10000;
      hz = '万';
    }
    else{
      return num;
    }

    var num2 = num.toFixed(2);

    // if(parseInt(num) >= 1000){ //整数部分超过4位
    //   num2 = num.toFixed(1);
    // }

    return num2.toString() + hz;
  },
  /**
   * 显示百分比
   * @param number 输入
   * @param bl 乘于倍率
   * @param fixed 格式化
   */
  showPercentNumber: function(num:any, bl = 1, fixed = 2){
    if(isNaN(num) || num === ''){
      return num
    }     
    return (num * bl).toFixed(fixed) + '%'
  },
  /**
   * 数字保留小数位数
   */
  numberWithFixed: function(num:any,fixnum?:number){
    if(isNaN(num) || num === '' || fixnum == undefined){
      return num
    }  
    if(fixnum != undefined){
      return parseFloat(num).toFixed(fixnum)
    }
  },
  /**
   * 百分比数字显示
   * @param num 数字
   * @param zoom 放大倍数
   * @param needformat 是否需要倍数显示
   */
  showPercentWithZoom: function(num:any, zoom:number, needformat: false){ 
    if(isNaN(num) || num === ''){
      return num
    }
    num = parseFloat(num) / (Math.pow(10, zoom))
    if(needformat){
      if(num >= 1000 || num <= -1000){
        return (num / 100).toFixed(zoom) + '倍'
      }      
    }
    return (num as number).toFixed(zoom) + '%'
  },

  /**
   * 百分比数字显示—新逻辑
   * @param num 数字
   */
  showPercentWithZoomNew: function(num:any, fixed?:number){ 
    if(isNaN(num) || num === ''){
      return num
    }
    num = parseFloat(num)

    let hz = ''

    if(Math.abs(num) >= 1000000){
      hz = '万倍'
      num = (num / 1000000)
    }
    else if(Math.abs(num) >= 10000){
      hz = '倍'
      num = (num / 100)
    }
    
    if(fixed != undefined){
      return num.toFixed(fixed) + hz
    }
    return num + hz
  },  
  /**
   * 显示放大倍数之后的正确数字,行情接口的放大逻辑，同时保留小数
   * @param num 数字
   * @param zoom 放大倍数
   * @param needformat 是否需要格式化
   */
  showNumberWithZoom: function(num:any, zoom:number, needformat?:false){
    if(isNaN(num) || num === ''){
      return num
    }
    if(needformat){ 
      return txt.formatNumWithFixed((parseFloat(num) / (Math.pow(10, zoom))), zoom)
    }
    return (parseFloat(num) / (Math.pow(10, zoom))).toFixed(zoom)
  },
  
  /**
   * 格式化数字
   * @param num 
   */
  formatNumWithFixed: function(num:any, fixnum?:number){
    if(isNaN(num) || num === ''){
      return num
    }

    var hz = '';
    if(num >= 1000000000000||num <= -1000000000000){
      num = num / 1000000000000;
      hz = '万亿';
    }
    else if(num >= 100000000||num <= -100000000){
      num = num / 100000000;
      hz = '亿';
    }
    else if(num >= 10000||num <= -10000){
      num = num / 10000;
      hz = '万';
    }
    else{
      if(fixnum != undefined){
        return parseFloat(num).toFixed(fixnum)
      }
      return num;
    }

    if(fixnum != undefined){
      return parseFloat(num).toFixed(fixnum) + hz
    }
    return num + hz;
  },

  /**
   * 格式化数字
   * @param num 
   */
  formatNumWithFixedReturnData: function(num:any, fixnum?:number){
    if(isNaN(num) || num === ''){
      return {
        num: num,
        hz: ''
      }
    }

    var hz = '';
    if(num >= 100000000||num <= -100000000){
      num = num / 100000000;
      hz = '亿';
    }
    else if(num >= 10000||num <= -10000){
      num = num / 10000;
      hz = '万';
    }
    else{
      if(fixnum != undefined){
        return {
          num: parseFloat(num).toFixed(fixnum),
          hz: ''
        }
      }
      return {
        num: num,
        hz: ''
      }
    }

    if(fixnum != undefined){
      return {
        num: parseFloat(num).toFixed(fixnum),
        hz: hz
      }
    }
    return {
      num: num,
      hz: hz
    }
  },
  
  numToFixed: function (input:any, fixnum: number) {
    try {
      return input.toFixed(fixnum)
    } catch (error) {
      return input
    }
  },
  /**
   * 全角转半角
   * @param str 
   */
  ToCDB(str:string) { 
    var tmp = ""; 
    for(var i=0;i<str.length;i++){ 
        if (str.charCodeAt(i) == 12288){
            tmp += String.fromCharCode(str.charCodeAt(i)-12256);
            continue;
        }
        if(str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375){ 
            tmp += String.fromCharCode(str.charCodeAt(i)-65248); 
        } 
        else{ 
            tmp += String.fromCharCode(str.charCodeAt(i)); 
        } 
    } 
    return tmp 
  },
  /**
   * 全角转半角，并删除空白字符
   * @param str 
   */
  ToCDBRemoveBlank(str:string){
    str = this.ToCDB(str)
    return str.replace(/\s/g, '')
  },
  format(str:string, ...values:string[]):string{
    if (values.length > 0) {

      for (var i = 0; i < values.length; i++) {
          if (values[i] != undefined) {
              var reg= new RegExp("(\\{" + i + "\\})", "g");
              str = str.replace(reg, values[i]);
          }
      }
    }
    return str;
  },
    /**
   * 控制宽度的格式化数字
   * @param num 
   */
  formatNumMaxWidth: function(num:any, maxwidth:number){
    
    if(maxwidth < 0){
      return num
    }

    if(isNaN(num) || num === ''){
      return num
    }

    var hz = '';
    if(num >= 1000000000000||num <= -1000000000000){
      num = num / 1000000000000;
      hz = '万亿';
    }    
    if(num >= 100000000||num <= -100000000){
      num = num / 100000000;
      hz = '亿';
    }
    else if(num >= 10000||num <= -10000){
      num = num / 10000;
      hz = '万';
    }

    //整数部分长度
    let zs_length = num.toString().indexOf('.')  

    if(zs_length == -1){
      return num + hz
    }

    let fixed = maxwidth - zs_length
    if(fixed < 0) fixed = 0
    
    num = num.toFixed(fixed)


    return num + hz;
  },
}

export default txt