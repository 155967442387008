/**
 * 带推送的分时图
 */

import React,{useState, useEffect} from "react"
import txt from "../../../modules/txt"
import {getTimeSSE} from '../../../modules/quote_info/quote_data'
import { TimeType } from './index'
import { getOneStockPKYD } from "../../../modules/changes_data/new"


/** 分时图类型 */
function timeChartType(ndays:number){
  let type = 'r'
  switch (ndays) {
    case 1:
      return 'r'
      break;
    case 2:
      return 't2'
      break;  
    case 3:
      return 't3'
      break;
    case 4:
      return 't4'
      break;  
    case 5:
      return 't5'
      break;        
    default:
      break;
  }
  return type
}


export function TimeSSEChart(props:{
  quotecode: string,
  timetype: TimeType,
  /** 是否需要盘口移动打点 */
  pkyd_dot?: boolean
}){

  let ndays = 1
  let iscr = 0
  let iscca = 0

  switch (props.timetype) {
    case TimeType.一天:
      ndays = 1
      iscr = 0
      iscca = 0
      break;
    case TimeType.二天:
      ndays = 2
      iscr = 0
      iscca = 0
      break;
    case TimeType.三天:
      ndays = 3
      iscr = 0
      iscca = 0
      break;      
    case TimeType.四天:
      ndays = 4
      iscr = 0
      iscca = 0
      break;
    case TimeType.五天:
      ndays = 5
      iscr = 0
      iscca = 0
      break;
    case TimeType.盘前:
      ndays = 1
      iscr = 1
      iscca = 0
      break;      
    case TimeType.盘后:
      ndays = 1
      iscr = 0
      iscca = 1
      break;
      
    default:
      break;
  }

  useEffect(()=>{
    var option = {
      container: "#emchart-0",
      width: 578,
      height: 276,
      type: timeChartType(ndays),
      iscr: iscr,
      iscca: iscca,
      ndays: ndays,
      gridwh: {
        height: 25,
        width: 578
      },
      padding: {
        top: 0,
        bottom: 5
      },
      restline: {
        isshow: true,
        color: "#eee",
        solid: 3,       // 实线长度
        dashed: 0      // 虚线长度
      },
      tip: {
        trading: true
      },
      color: {
        line: "#326fb2",
        fill: ["rgba(101,202,254, 0.2)", "rgba(101,202,254, 0.1)"]
      }
    }

    let pkyd_cd:NodeJS.Timeout; //盘口异动cd

    if(window?.emcharts3?.time){
      var time = new window.emcharts3.time(option);
      var sse = getTimeSSE(props.quotecode, function(data: any){
        data.fulldata.data.name = txt.txtLeft(data.fulldata.data.name, 12)
        time.setData({
          time: data.fulldata
        });
        time.redraw();      
      },ndays, iscr, iscca)

      if(props.pkyd_dot){
        drawPKYD(props.quotecode, time)
        pkyd_cd = setInterval(function(){
          drawPKYD(props.quotecode, time)
        }, 60 * 1000)
      }

      return ()=>{
        try {
          clearInterval(pkyd_cd)
          sse.close()
        } catch (error) {
          
        }
      }          
    }




  }, [props.timetype])

  return (
    <>
      <div id="emchart-0" className="timessechart"></div>
    </>
  )
}

/**
 * 绘制盘口移动打点
 * @param quotecode 
 * @param time 
 * @returns 
 */
async function drawPKYD(quotecode: string, time:any) {
  return getOneStockPKYD(quotecode, 40).then(function(pkydlist){
    //['688003', '09:35', '天准科技', '高台跳水', '-4.18%', '2']
    let inputlist = pkydlist.map(v=>{
      return [
        v.code,
        v.time.substring(0, 5),
        v.name,
        v.title,
        v.intro,
        v.color
      ].join(',')
    })
    
    time.setData({
        positionChanges: inputlist
    });
    time.redraw();
  })
}