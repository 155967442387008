/**
 * 浏览器id cookie
 */

import cookie from "../cookie";

export default {
  get : function(){
      return cookie.get('qgqp_b_id')      
  },
  make : function(){
      var newid = Math.floor(Math.random()*9+1).toString();
      for (var i = 0; i < 19; i++) {
          newid +=  Math.floor(Math.random()*9).toString();
          
      }
      cookie.set('qgqp_b_id', newid, 10000, '.eastmoney.com');
      return newid;
  },
  save : function(fingerprint:string){
      cookie.set('qgqp_b_id', fingerprint, 10000, '.eastmoney.com');
      return fingerprint;
  },  
  init: function(){
      if (this.get() == null || this.get() == '') {
          return this.make();
      }
      else{
          return this.get();
      }
  }
}