/**
 * 大数据埋点
 */

/**
 * 发送大数据统计
 * @param ElementType 网页元素，A/Button/Div 等
 * @param EventType 事件类型，Click/DoubleClick/MouseOver 等
 * @param EventCode 由大数据部制定，必须项
 * @param ExtInfo 根据需求收集需要的参数，格式为 Json 串形式{"key":"value"} ，多个参数值用英文 ,分隔，不能含有空格，若无特殊产品需求，则可为空
 * @param goToUrl a 元素的 href 属性值，若无，可为空
 */
export function sendStat(ElementType: string,EventType: string,EventCode:string,ExtInfo?:object,goToUrl?:string){
  if(typeof window != 'undefined' && window.sendTrackLog != undefined){
    if(ExtInfo && goToUrl){
      window.sendTrackLog (ElementType,EventType,EventCode,ExtInfo,goToUrl)
    }
    else if(ExtInfo){
      window.sendTrackLog (ElementType,EventType,EventCode,ExtInfo)
    }
    else{
      window.sendTrackLog (ElementType,EventType,EventCode)
    }
  }
}