import React,{useState, useEffect} from "react"

/**
 * same的广告iframe
 * @returns 
 */
export function AdIframe(props:{
  adid:string,
  width: number,
  height: number,
  mt?: number
}){
  return (
    <iframe style={{marginTop:props.mt}} width={props.width} height={props.height} frameBorder="0" scrolling="no" marginWidth={0} marginHeight={0} src={`//same.eastmoney.com/s?z=eastmoney&c=${props.adid}&op=1`}></iframe>
  )
}