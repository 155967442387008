/**
 * 创业板特色指标
 */

import React,{useState, useEffect} from "react"
import { observer } from "mobx-react-lite"
import { quotedata, QuoteItem } from "../quote_data";
import {initSingleValue} from '../../../modules/quote_info/quote_data'
import { type_quote_back } from "../../../modules/quote_info/quote_data";
import './index.less'

function CYBZB(props:{
  is_cyb?: boolean
}){

  let showdata = initSingleValue()
  if(quotedata.hqdata != null){
    showdata = quotedata.hqdata
  }

  if(showdata[QuoteItem.是否注册制].txt != '是' && !props.is_cyb){
    return <></>
  }

  return (
    <div className="cybzb">
        <ul className="zbitems">
            <li className="title">
              {
                props.is_cyb ?
                <>创业板特色指标</>
                :
                <>注册制相关指标</>
              }
              
            </li>
            <li>
              <span>是否注册制
                <span className="icon_tips">
                  <div className="detail">
                    是否注册制:公司是否以注册制方式进行上市
                  </div>
                </span>：<span>{showdata[QuoteItem.是否注册制].txt}</span>
              </span>
            </li>
            <li>
              <span>协议控制架构
                <span className="icon_tips">
                  <div className="detail">
                    协议控制架构:公司是否具有协议控制架构
                  </div>
                </span>：<span>{showdata[QuoteItem.是否具有协议控制架构].txt}</span>
              </span>
            </li>
            <li>
              <span>表决权差异
                <span className="icon_tips">
                  <div className="detail">
                    表决权差异:公司是否具有表决权差异安排
                  </div>
                </span>：<span>{showdata[QuoteItem.是否表决权差异].txt}</span>
              </span>
            </li>
            <li>
              <span>是否盈利
                <span className="icon_tips">
                  <div className="detail">
                    是否盈利:公司当前是否盈利
                  </div>
                </span>：<span>{showdata[QuoteItem.是否盈利].txt}</span>
              </span>
            </li>
            {
              props.is_cyb &&
              <>
                <li>
                  <span>盘后成交量
                    <span className="icon_tips">
                      <div className="detail">
                        盘后成交量:在当日收盘集合竞价结束后，按照时间优先原则，以当日收盘价对盘后定价买卖订单进行逐笔连续撮合的交易所产生的成交量
                      </div>
                    </span>：<span>{showdata[QuoteItem.盘后成交量].txt}</span>
                  </span>
                </li>
                <li>
                  <span>盘后成交额
                    <span className="icon_tips">
                      <div className="detail">
                        盘后成交额:在当日收盘集合竞价结束后，按照时间优先原则，以当日收盘价对盘后定价买卖订单进行逐笔连续撮合的交易所产生的成交额
                      </div>
                    </span>：<span>{showdata[QuoteItem.盘后成交额].txt}</span>
                  </span>
                </li>              
              </>
            }
        </ul>
    </div>
  )
}

export default observer(CYBZB)