/**
 * 码表系统证券品种
 */
export enum StockType {
  "深证主板A股",
  "深证创业板",
  "上证主板A股",
  "上证科创板",
  "北证主板A股",

  "未知"
}

/**
 * 获取码表系统证券品种
 * @param market 市场
 * @param type111 小类
 * @returns 
 */
export function getStockType(market: string, type111: number, type181:number){
  if(market == '0' && type111 == 6){
    return StockType.深证主板A股
  }
  else if(market == '0' && type111 == 80){
    return StockType.深证创业板
  }
  if(market == '1' && type111 == 2){
    return StockType.上证主板A股
  }
  else if(market == '1' && type111 == 23){
    return StockType.上证科创板
  }
  else if(market == '0' && type111 == 81 && type181 & 2048){
    return StockType.北证主板A股
  }  

  return StockType.未知
}