import React,{useState, useEffect, useRef} from "react"

/**
 * 单条链接的布告栏
 * @param props 
 * @returns 
 */
export function OneBulletin(props:{
  /** 布告栏id */
  id: string
}){

  const div = useRef(null as null | HTMLDivElement);

  useEffect(()=>{
    ;(async function(){
      try {
        let bulletin = await $.ajax({
          url: '/newapi/getbulletin/' + props.id,
          type: 'GET',
          dataType: 'html',
          data: {
            
          }
        })

        if(div !== null) {
          div!.current!.innerHTML = bulletin
          div!.current!.querySelector('a')!.target = '_blank'
        }        
      } catch (error) {
        
      }


    })();
  }, [])

  return (
    <div className="onebulletin" ref={div}></div>
  )
}