/**
 * 封装sse,超过规定次数不再重连
 */
export function QuoteSSE(url: string, max_error_count = 200){

  let newsse =  new EventSource(url)
  let error_count = 0

  newsse.onerror = function(){
    console.info('sse connect error: ' + url)
    error_count ++
    if(error_count >= max_error_count){ //断开
      console.info('sse reconnet stop: ' + url)
      newsse.close()
    }
  }
  
  return newsse
}