/**
 * 板块相关
 */


import React,{useState, useEffect} from "react"
import { stockXGBK, stockXGBKZDZF, stockXGStock, searchListQuote } from "../../../modules/quote_info/quote_data"
import { SiderTabBox } from "../sider_tab_box"
import { QuoteItem } from '../../../modules/quote_info/config'
import useSWR from 'swr'
import './index.less'
import { Select } from "../select"
import { sendStat } from "../../../modules/bigdata_stat"

/**
 * 所属板块+阶段涨幅
 * @returns 
 */
export function StockBKJDZF(props:{
  quotecode:string,
  bk_id:string
}){
  return (
    <SiderTabBox {...{
      tab_split: true,
      boxdata:[
        {
          tab_html: <i>所属板块</i>,
          body: <XGBK quotecode={props.quotecode}></XGBK>,
          stat_code: 'webjdb.ssbk.ssbk'
        },
        {
          tab_html: <i>阶段涨幅</i>,
          body: <JDZF quotecode={props.quotecode}></JDZF>,
          stat_code: 'webjdb.jdzf.jdzf'
        }
      ]}}></SiderTabBox>
  )
}

/**
 * 所属板块
 */
export function XGBK(props:{
  quotecode: string
}){

  const { data, error } = useSWR(`/quote/xgbk/${props.quotecode}`, ()=>stockXGBK(props.quotecode, [
    QuoteItem.名称,
    QuoteItem.行情代码,
    QuoteItem.涨跌幅,
    QuoteItem.板块领涨股,
  ]), {
    refreshInterval: 60 * 1000,
    revalidateOnFocus: false
  })
  
  if(data && data.length > 0){
    return (
      <div className="xgbk">
        <table>
          <thead>
            <tr>
              <th>名称</th>
              <th>涨跌幅</th>
              <th>领涨股</th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((v, index)=>
                <tr key={index}>
                  <td><a href={`//quote.eastmoney.com/center/boardlist.html#boards2-${v[QuoteItem.行情代码].txt}`} target="_blank">{v[QuoteItem.名称].txt}</a></td>
                  <td>{v[QuoteItem.涨跌幅].html}</td>
                  <td>{v[QuoteItem.板块领涨股].html}</td>
                </tr>
              )
            }
            {
              data.length > 0 && data.length < 5 && Array.from(new Array(5 - data.length)).map((v, index)=>
                <tr key={index}>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )
            }            
          </tbody>
        </table>
      </div>
    )
  }

  if(error || (data !== undefined && data.length == 0)){
    return (
      <div className="xgbk"><div className="nodata">暂无数据</div></div>
    )
  }

  return (
    <div className="xgbk"><div className="nodata">加载中</div></div>
  )
}


/**
 * 阶段涨幅
 */
export function JDZF(props:{
  quotecode: string
}){

  const { data, error } = useSWR(`/quote/jdzf/${props.quotecode}`, ()=>stockXGBKZDZF(props.quotecode, [
    QuoteItem.名称,
    QuoteItem.涨跌幅,
    QuoteItem.涨跌幅_3日,
    QuoteItem.涨跌幅_5日,
    QuoteItem.涨跌幅_6日,
    QuoteItem.涨跌幅_10日,
    
  ]), {
    refreshInterval: 60 * 1000,
    revalidateOnFocus: false
  })
  
  if(data && data.length > 0){
    return (
      <div className="xgbk">
        <table>
          <thead>
            <tr>
              <th>阶段</th>
              <th>{data[0][QuoteItem.名称].txt}</th>
              <th>{data[1][QuoteItem.名称].txt}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>今日</td>
              <td>{data[0][QuoteItem.涨跌幅].html}</td>
              <td>{data[1][QuoteItem.涨跌幅].html}</td>
            </tr>
            <tr>
              <td>3日</td>
              <td>{data[0][QuoteItem.涨跌幅_3日].html}</td>
              <td>{data[1][QuoteItem.涨跌幅_3日].html}</td>
            </tr>
            <tr>
              <td>5日</td>
              <td>{data[0][QuoteItem.涨跌幅_5日].html}</td>
              <td>{data[1][QuoteItem.涨跌幅_5日].html}</td>
            </tr>
            <tr>
              <td>6日</td>
              <td>{data[0][QuoteItem.涨跌幅_6日].html}</td>
              <td>{data[1][QuoteItem.涨跌幅_6日].html}</td>
            </tr>
            <tr>
              <td>10日</td>
              <td>{data[0][QuoteItem.涨跌幅_10日].html}</td>
              <td>{data[1][QuoteItem.涨跌幅_10日].html}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  if(error || (data !== undefined && data.length == 0)){
    return (
      <div className="xgbk"><div className="nodata">暂无数据</div></div>
    )
  }

  return (
    <div className="xgbk"><div className="nodata">加载中</div></div>
  )
}

/**
 * 行情图下板块股票
 */
export function BKStocksBar(props:{
  bk_id: string,
  bk_name: string,
  quotecode: string
}){

  const { data, error } = useSWR(`/quote/xgstock/${props.quotecode}`, ()=>stockXGStock(props.quotecode, [
    QuoteItem.行情统一链接,
    QuoteItem.涨跌幅,
    QuoteItem.最新价
  ]), {
    refreshInterval: 60 * 1000,
    revalidateOnFocus: false
  })

  if(data && data.length > 0){
    return (
      <div className="bkstocksbar self_clearfix" onClick={()=>{sendStat('A', 'Click', 'webjdb.xggg.xggg')}}>
        <div className="t">
          <a href={`//quote.eastmoney.com/center/boardlist.html#boards2-90.${props.bk_id}`} target="_blank">{props.bk_name}</a>
        </div>
        <div className="list">
          <ul>
            {
              data.map((v,index)=>
                <li key={index}>{v[QuoteItem.行情统一链接].html}({v[QuoteItem.最新价].html} {v[QuoteItem.涨跌幅].html})</li>
              )
            }
          </ul>
        </div>
      </div>
    )
  }

  return <></>
}


/**
 * 板块相关股票排行
 */
export function BKStockRank(props:{
  bk_id: string,
  bk_name: string
}){

  const SelectType = [
    {
      name: '股票涨跌幅排行',
      fid: 'f3'
    },
    {
      name: '股票涨跌排行',
      fid: 'f4'
    },
    {
      name: '股票成交额排行',
      fid: 'f6'
    },
    {
      name: '股票成交量排行',
      fid: 'f5'
    },
    {
      name: '5分钟涨跌幅排行',
      fid: 'f11'
    },
    {
      name: '量比排行',
      fid: 'f10'
    },
  ]

  const [select, SetSelect] = useState(SelectType.map((v, index)=>{
    return {
      name: v.name,
      value: v.fid,
      check: index == 0
    }
  }))

  const [fid, SetFid] = useState(SelectType[0].fid)

  const change = function(tovalue: string){
    SetFid(tovalue)
  }

  if(!props.bk_id){
    return (
      <SiderTabBox {...{
        boxdata:[
          {
            tab_html: <i>相关个股</i>
          }
        ]}}>
          <div className="bkstockrank">
            <div className="nodata">暂无数据</div>
          </div>        
        </SiderTabBox>
    )
  }

  return (
    <SiderTabBox {...{
    boxdata:[
      {
        tab_html: <i>[<a href={`//quote.eastmoney.com/center/boardlist.html#boards2-90.${props.bk_id}`} target="_blank">{props.bk_name}</a>]相关个股</i>,
        more_html: <a href={`//quote.eastmoney.com/center/boardlist.html#boards2-90.${props.bk_id}`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.xggg.gd')}}>更多</a>
      }
    ]}}>
      <div className="bkstockrank">
        <div className="sel" onClick={()=>{sendStat('A', 'Click', 'webjdb.xggg.phb')}}>
          <Select width={150} list={select} onChange={change}></Select>
        </div>
        <div className="c">
          <BKStockRankTable fid={fid} bk_id={props.bk_id}></BKStockRankTable>
        </div>
      </div>        
    </SiderTabBox>


  )
}

function BKStockRankTable(props:{
  fid: string,
  bk_id: string
}){

  let th3dataname = QuoteItem.涨跌幅
  let th3datatitle = '涨跌幅'

  switch (props.fid) {
    case 'f3':
      th3dataname = QuoteItem.涨跌幅
      th3datatitle = '涨跌幅'
      break;
    case 'f4':
      th3dataname = QuoteItem.涨跌额
      th3datatitle = '涨跌'
      break;  
    case 'f6':
      th3dataname = QuoteItem.成交额
      th3datatitle = '成交额'
      break;
    case 'f5':
      th3dataname = QuoteItem.成交量
      th3datatitle = '成交量'
      break; 
    case 'f11':
      th3dataname = QuoteItem.涨跌幅_5分钟
      th3datatitle = '5分钟涨跌幅'
      break;
    case 'f10':
      th3dataname = QuoteItem.量比
      th3datatitle = '量比'
      break;   
    default:
      break;
  }

  const { data, error } = useSWR(`/quote/bkrank/${props.fid}/${props.bk_id}`, ()=>searchListQuote('b:' + props.bk_id + '+f:!18', [
    QuoteItem.行情统一链接,
    QuoteItem.最新价,
    QuoteItem.涨跌幅,
    th3dataname
  ], props.fid, 1, 5), {
    refreshInterval: 60 * 1000,
    revalidateOnFocus: false
  })

  return (
    <table>
      <thead>
        <tr>
          <th>名称</th>
          <th>最新价</th>
          <th>{th3datatitle}</th>
        </tr>
      </thead>
      <tbody>
        {
          data && data.length > 0 && data.map((v, index)=>
            <tr key={index}>
              <td>{v[QuoteItem.行情统一链接].html}</td>
              <td>{v[QuoteItem.最新价].html}</td>
              <td>{v[th3dataname].html}</td>
            </tr>          
          )
        }
      </tbody>
    </table>
  )
}