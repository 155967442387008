import React,{useState, useEffect} from "react"
import ReactDOM from "react-dom";
import { useIsBrowser } from '../hooks'
import './index.less'

/** 图表全屏按钮 */
export function FullScreenBtn(){

  const openFullScreenChart = () => {
    self.location.href = '#fullScreenChart'
  }

  return (
    <a href="#" onClick={(e)=>{e.preventDefault();openFullScreenChart();}} target="_self" className="fullscreenbtn">全屏</a>
  )
}

/** 全屏图元素 */
export function FullScreenChart(props:{
  pagetype?: string,
  market: string,
  code: string,
  quotecode: string
}){

  const [show, SetShow] = useState(false)
  let isBrowser = useIsBrowser()

  useEffect(()=>{
    //监听hash变化
    window.addEventListener('hashchange',function() {
      if(location.hash == '#fullScreenChart'){
        SetShow(true)
      }
      else{
        SetShow(false)
      }
    },false);

    if(location.hash == '#fullScreenChart'){
      SetShow(true)
    }
    else{
      SetShow(false)
    }    

  }, [])

  const close = () =>{
    self.location.href = '#'
  }

  let old_market = '1'
  if(props.market == '0') old_market = '2'

  if(isBrowser){
    return ReactDOM.createPortal(
      <>
        {
          show &&
          <div className="fullscreen_chart">
            <div className="fsc_mask"></div>
            <div className="fsc_close" onClick={close}>×</div>
            <div className="fsc_iframe">
              {/* {
                props.pagetype == 'kcb' &&
                  <iframe src={`/basic/kcbh5chart-iframe.html?code=${props.code}&market=1&type=r`}></iframe>
              } */}
              {
                (props.pagetype == 'a' || props.pagetype == 'kcb') &&
                  <iframe src={`/basic/h5chart-iframe.html?code=${props.code}&market=${old_market}&type=r`}></iframe>
              }              
              {
                props.pagetype != 'kcb' && props.pagetype != 'a' &&
                <iframe src={`/basic/full.html?mcid=${props.quotecode}&type=r`}></iframe>
              }
              
            </div>
          </div>        
        }
      </>,
      document.body
    )    
  }

  return <></>
}

/**
 * 全屏链接
 * @param props 
 * @returns 
 */
export let FullScreenLink = function(props:{
  fullscreen: boolean,
  children:any
}){
  
  if(props.fullscreen){
    return (
      <a href="#fullScreenChart" target="_self">
        {props.children}
      </a>
    )
  }
  
  return <>{props.children}</>
}