/**
 * 行情延迟参数
 */
import cookie from '../cookie'

/**
 * 获取延迟参数
 */
export function getDelayparams(){
  var delayparams = 'UID|ZUZHI|MOBILE|0|web'

  if(cookie.get('uidal')){
    delayparams = delayparams.replace('UID', cookie.get('uidal')!.substring(0, 16))
  }
  else{
    delayparams = delayparams.replace('UID', '')
  }

  if(cookie.get('mtp') != null){
    if(cookie.get('mtp') == '0'){
      delayparams = delayparams.replace('MOBILE', '3')
    }
    else{
      delayparams = delayparams.replace('MOBILE', cookie.get('mtp')!)
    }
  }
  else{
    delayparams = delayparams.replace('MOBILE', '0')
  }

  if(cookie.get('wbp2uorgt') != null){
    delayparams = delayparams.replace('ZUZHI', cookie.get('wbp2uorgt')!)
  }
  else{
    delayparams = delayparams.replace('ZUZHI', '0')
  }  

  return delayparams
}