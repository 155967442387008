import React,{useState, useEffect} from "react"
import { SiderTabBox } from "../../sider_tab_box"
import { getJigouMingjia } from '../../../../modules/new_news/web'
import './index.less'

/**
 * 侧边栏机构名家
 * @returns 
 */
export function SiderJigouMingia(props:{
  size?:number
}){
  return (
    <SiderTabBox {...{
      mt: 10,
      boxdata:[
        {
          tab_html: <a href='//jigou.eastmoney.com/' target="_blank">机构名家</a>
        },
      ]}}>
        <JigouMingjia size={props.size}></JigouMingjia>
      </SiderTabBox>  
  )
}

function JigouMingjia(props:{
  size?: number
}){
  let size = props.size ?? 22

  const [showdata, SetShowData] = useState([] as Array<{
    title: string,
    url: string
  }>)

  useEffect(()=>{
    ;(async()=>{
      let back = await getJigouMingjia()
      if(back.length > 0){
        SetShowData(back)
      }
    })();
  }, [])

  return (
    <div className="sider_jigoumingjia">
      <ul>
        {
          showdata.slice(0, size).map((v, index)=>
            <li key={index}>
              <a href={v.url} target="_blank">{v.title}</a>
            </li>          
          )
        }

      </ul>
    </div>
  )
}