/**
 * 布告栏接口
 */



/** 获取布告栏 */
export async function getBulletin(id:string):Promise<string>{
  try {
    let back = await $.ajax({
        url: '/newapi/getbulletin/' + id,
        type: 'GET',
        dataType: 'html',
        data: {
          
        }
      })

    return back    
  } catch (error) {
    return ''
  }

}