/**
 * 老的浏览记录逻辑
 */
import cookie from "../cookie";
import _ from 'lodash'

export const history_template = { 
  "a": "//quote.eastmoney.com/[#MARKET#][#CODE#].html", 
  "b": "//guba.eastmoney.com/topic,[#CODE#].html", 
  "c": "//fund.eastmoney.com/[#CODE#].html", 
  "d": "//quote.eastmoney.com/hk/[#CODE#].html", 
  "e": "//quote.eastmoney.com/gzqh/[#CODE#].html", 
  "f": "//quote.eastmoney.com/zs[#CODE#].html", 
  "g": "//quote.eastmoney.com/qihuo/[#CODE#].html", 
  "h": "//quote.eastmoney.com/hk/[#CODE#].html", 
  "i": "//quote.eastmoney.com/hk/zs[#CODE#].html", 
  "j": "//quote.eastmoney.com/us/[#CODE#].html", 
  "k": "//quote.eastmoney.com/forex/[#CODE#].html", 
  "l": "//quote.eastmoney.com/[#MARKET#][#CODE#].html", 
  "m": "//quote.eastmoney.com/gb/zs[#CODE#].html", 
  "n": "//quote.eastmoney.com/globalfuture/[#CODE#].html", 
  "o": "//quote.eastmoney.com/qiquan/[#CODE#]_[#MARKET#].html", 
  "p": "//quote.eastmoney.com/3ban/[#MARKET#][#CODE#].html",
  "kcb": "//quote.eastmoney.com/kcb/[#CODE#].html",
  "ty": "//quote.eastmoney.com/unify/r/[#MARKET#].[#CODE#]"
}

/** 最大条数 */
const max_size = 10

/**
 * 获取浏览记录
 * @param size 最多显示多少条
 */
export function getHistory(size:number):Array<{
  name: string,
  link: string,
  quotecode: string
}>{

  let c_value = cookie.get_old('HAList')

  if(c_value){
    let hlist = c_value.split(',')
      .map(v=>v.split('-'))
      .filter(v=>v.length >= 4)
      .map(v=>{
        if(v.length == 6){ //期权特殊情况
          v[2] = v.slice(2, 5).join('-')
          v[3] = v[5] 
          v = v.slice(0,4)
        }
        else if(v.length > 4){
            v[3] = v.slice(3).join('-')
            v = v.slice(0,4)
        }
        return v
      })
      .filter(v=>{        
        return Object.keys(history_template).includes(v[0])
      })
      .filter(v=>{
        return v[3] != 'undefined'
      })
      .map(v=>{
        let market = v[1]
        if(market.toLowerCase() == 'sz'){
          market = '0'
        }
        else if(market.toLowerCase() == 'sh'){
          market = '1'
        }
        let quotecode = `${market}.${v[2]}`
        return {
          name: v[3],
          link: history_template[v[0] as keyof typeof history_template].replace("[#MARKET#]", v[1]).replace("[#CODE#]", v[2]),
          quotecode
        }
      })

      hlist = _.uniqBy(hlist, 'quotecode')

      hlist = hlist.slice(0, size)

      // console.info(hlist)
      
    return(hlist)
  }

  return []
}

/**
 * 设置浏览记录
 * @param type 类型
 * @param market 市场
 * @param code 代码
 * @param name 名称
 */
export function setHistory(type: keyof typeof history_template, market: string, code: string, name: string){

  let c_value = cookie.get_old('HAList')

  let hlist = [] as Array<any>

  if(c_value){
    hlist = c_value.split(',')
      .map(v=>v.split('-'))
      .filter(v=>v.length >= 4)
      .map(v=>{
        if(v.length == 6){ //期权特殊情况
          v[2] = v.slice(2, 5).join('-')
          v[3] = v[5] 
          v = v.slice(0,4)
        }
        else if(v.length > 4){
            v[3] = v.slice(3).join('-')
            v = v.slice(0,4)
        }
        return v
      })
      .filter(v=>{
        return Object.keys(history_template).includes(v[0])
      })
      .filter(v=>{
        return v[0] != type || v[1] != market || v[2].toLocaleLowerCase() != code.toLocaleLowerCase() 
      })
  }

  // code = code.replace(/-/g, '&#45;')

  hlist.unshift([type, market, code, name])
  hlist = hlist.slice(0, max_size)

  let c_string = hlist.map(v=>{
    return v.join('-')
  }).join(',')

  cookie.set_old('HAList', c_string, 90, '.eastmoney.com')
}