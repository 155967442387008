import React,{useState, useEffect} from "react"
import { SiderTabBox } from "../sider_tab_box"
import './index.less'
import useSWR from "swr";
import { getAllStockPKYD, getOneStockPKYD } from "../../../modules/changes_data/new";
import { sendStat } from "../../../modules/bigdata_stat";

/**
 * 侧边栏盘口异动
 * @returns 
 */
export function SiderChanges(props:{
  market: string,
  code: string
}){
  return (
    <div className="siderchanges">
      <SiderTabBox {...{
        mt: 10,
        tab_split: true,
        boxdata:[
          {
            tab_html: <a href="//quote.eastmoney.com/changes" target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.pkyd.pkyd-dj')}}>盘口异动</a>,
            body: <ChangesBody></ChangesBody>,
            stat_code: 'webjdb.pkyd.pkyd-qh'
          },
          {
            tab_html: <a href={`//quote.eastmoney.com/changes/stocks/${props.market=='0'?'sz':'sh'}${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.ggyd.ggyd-dj')}}>该股异动</a>,
            body: <ChangesBodyStock market={props.market} code={props.code}></ChangesBodyStock>,
            stat_code: 'webjdb.ggyd.ggyd-qh'
          }
        ]}}></SiderTabBox>
    </div>
  )
}

function ChangesBody(){

  const { data, error } = useSWR(`/quote/stock/sider_changes/`, ()=>getAllStockPKYD(5),{
    refreshInterval: 60 * 1000,
    revalidateOnFocus: false
  })

  if(data && data.length > 0){
    return (
      <div className="changesbody" onClick={()=>{sendStat('DIV', 'Click', 'webjdb.pkyd.qynr')}}>
        <table className="table1 pl5">
          <tbody>
            {
              data.map((v, index)=>
                <tr key={index}>
                  <td>{v.time}</td>
                  <td><a target="_blank" href={`//quote.eastmoney.com/changes/stocks/${v.market=='0'?'sz':'sh'}${v.code}.html`}>{v.name}</a></td>
                  <td><span className={v.color == '1'?'price_up':v.color="2"?'price_down':''}>{v.title}</span></td>
                </tr>                
              )
            }
            {
              data.length < 5 && Array.from(new Array(5 - data.length)).map((v,index)=>
                <tr key={index}>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>                
              )
            }
            <tr>
              <td colSpan={3} className="tdbmore">
                <a href="http://quote.eastmoney.com/changes" target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.pkyd.ckgd')}}>点击查看更多&gt;&gt;&gt;
                </a>
              </td>
            </tr>          
          </tbody>
        </table>
      </div>
    )    
  }

  return (
    <div className="changesbody">
      <table className="table1 pl5">
        <tbody>
          {
            Array.from(new Array(5)).map((v,index)=>
              <tr key={index}>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>                
            )
          }
          <tr>
            <td colSpan={3} className="tdbmore">
              <a href="http://quote.eastmoney.com/changes" target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.pkyd.ckgd')}}>点击查看更多&gt;&gt;&gt;
              </a>
            </td>
          </tr>          
        </tbody>
      </table>
    </div>
  )
}


function ChangesBodyStock(props:{
  market: string,
  code: string
}){

  const { data, error } = useSWR(`/quote/stock/sider_changes/stock/`, ()=>getOneStockPKYD( props.market + '.' + props.code, 5),{
    refreshInterval: 30 * 1000,
    revalidateOnFocus: false
  })

  if(data && data.length > 0){
    return (
      <div className="changesbody" onClick={()=>{sendStat('A', 'Click', 'webjdb.ggyd.qynr')}}>
        <table className="table1 pl5">
          <tbody>
            {
              data.map((v, index)=>
                <tr key={index}>
                  <td>{v.time}</td>
                  <td><span className={v.color == '1'?'price_up':v.color="2"?'price_down':''}>{v.title}</span></td>
                  <td><span className={v.color == '1'?'price_up':v.color="2"?'price_down':''}>{v.intro}</span></td>
                </tr>                
              )
            }
            {
              data.length < 5 && Array.from(new Array(5 - data.length)).map((v,index)=>
                <tr key={index}>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>                
              )
            }
            <tr>
              <td colSpan={3} className="tdbmore">
                <a href={`//quote.eastmoney.com/changes/stocks/${props.market=='0'?'sz':'sh'}${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.ggyd.ckgd')}}>点击查看更多&gt;&gt;&gt;
                </a>
              </td>
            </tr>          
          </tbody>
        </table>
      </div>
    )    
  }

  return (
    <div className="changesbody">
      <table className="table1 pl5">
        <tbody>
          {
            Array.from(new Array(5)).map((v,index)=>
              <tr key={index}>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>                
            )
          }
          <tr>
            <td colSpan={3} className="tdbmore">
              <a href={`//quote.eastmoney.com/changes/stocks/${props.market=='0'?'sz':'sh'}${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.ggyd.ckgd')}}>点击查看更多&gt;&gt;&gt;
              </a>
            </td>
          </tr>          
        </tbody>
      </table>
    </div>    
  )
}


