/**
 * 全球股市
 */

import React, { useState, useEffect } from "react"
import { useQuoteList, QuoteItem } from "../hooks/quote_hook";
import './index.less'

/**
 * 上证 深证
 */
function SHSZ(){


  var codelist = [
    {
      code: "1.000001",
      name: "上证",
      z_link: '//quote.eastmoney.com/center/gridlist.html?st=ChangePercent&sortType=C&sortRule=-1#sh_a_board',
      d_link: '//quote.eastmoney.com/center/gridlist.html?st=ChangePercent&sr=1&sortType=C&sortRule=1#sh_a_board'
    },
    {
      code: "0.399001",
      name: "深证",
      z_link: '//quote.eastmoney.com/center/gridlist.html?st=ChangePercent&sortType=C&sortRule=-1#sz_a_board',
      d_link: '//quote.eastmoney.com/center/gridlist.html?st=ChangePercent&sr=1&sortType=C&sortRule=1#sz_a_board'
    }
  ]

  const showdata = useQuoteList([codelist[0].code, codelist[1].code], [
    QuoteItem.行情代码,
    QuoteItem.名称,
    QuoteItem.最新价,
    QuoteItem.行情统一链接,
    QuoteItem.涨跌幅,
    QuoteItem.涨跌额,
    QuoteItem.成交额,
    QuoteItem.上涨家数,
    QuoteItem.下跌家数,
    QuoteItem.平盘家数
  ], 30)

  if(showdata.length == 2){
      return (
        <div className="qqzs_shsz">
          {showdata.map((v,index)=>
            <React.Fragment key={v[QuoteItem.行情代码].txt}>
              <a href={v[QuoteItem.行情统一链接].txt} target="_blank"><strong>{codelist.find(i=>i.code == v[QuoteItem.行情代码].txt)?.name}</strong></a>：
              <span className={`nums ${v[QuoteItem.最新价].color}`}>
                {v[QuoteItem.最新价].txt}&ensp;
                <span className="jt">
                  {v[QuoteItem.最新价].color == 'price_up' &&
                    <>↑</>
                  }
                  {v[QuoteItem.最新价].color == 'price_down' &&
                    <>↓</>
                  }              
                </span>
                {v[QuoteItem.涨跌额].txt}&ensp;
                {v[QuoteItem.涨跌幅].txt}&ensp;
                {v[QuoteItem.成交额].txt}
              </span>
              元
              {/* {txt.formatNumWithFixedReturnData(v.cje, 2).hz}元 */}
              (涨:<a href={codelist[index].z_link} target="_blank" className="price_up zdnums"><b className="nums">{v[QuoteItem.上涨家数].txt}</b></a>
              &ensp;平:<b className="nums">{v[QuoteItem.平盘家数].txt}</b>
              &ensp;跌:<a href={codelist[index].d_link} target="_blank" className="price_down zdnums"><b className="nums">{v[QuoteItem.下跌家数].txt}</b></a>)&ensp;
            </React.Fragment>
          )} 
        </div>
      )
  }


  return (
    <div className="qqzs_shsz">
      <a href="//quote.eastmoney.com/unify/r/1.000001" target="_blank"><strong>上证</strong></a>：
      <span className="nums">- - - -</span> (涨:
      <a href={codelist[0].z_link} target="_blank" className="zdnums"><b>-</b></a> 平:
      <b>-</b> 跌:
      <a href={codelist[0].d_link} target="_blank" className="zdnums"><b>-</b></a>) 
      <a href="//quote.eastmoney.com/unify/r/0.399001" target="_blank"><strong>深证</strong></a>：
      <span className="nums">- - - -</span> (涨:
      <a href={codelist[1].z_link} target="_blank" className="zdnums"><b>-</b></a> 平:
      <b>-</b> 跌:
      <a href={codelist[1].d_link} target="_blank" className="zdnums"><b>-</b></a>) 
    </div>
  )

}

/** 国际指数 */
function GJZS(props:{
  type: number
}){

  const [showdata, SetShowData] = useState(null as null | Array<any>)

  var codelist = [
    {
        code: "100.DJIA",
        name:"道琼斯"
    },
    {
        code: "100.NDX",
        name: "纳斯达克"
    },
    {
        code: "100.N225",
        name: "日经"
    },
    {
        code: "100.HSI",
        name: "恒生"
    },  

    {
        code: "100.GDAXI",
        name: "德DAX"
    },
    {
        code: "100.FCHI",
        name: "法CAC"
    },
    {
        code: "100.FTSE",
        name: "英FT"
    },
    {
        code: "100.ATX",
        name: "奥ATX"
    }
  ]

  let showcodelist = codelist.slice(0, 4)
  if(props.type == 2){
    showcodelist = codelist.slice(4, 8)
  }

  return (
    <SHOWGJZS quotecode_list={showcodelist.map(v=>v.code)} codedata={showcodelist}></SHOWGJZS>
  )
}

/** 国际指数 */
function SHOWGJZS(props:{
  quotecode_list: Array<string>,
  codedata: Array<{
    code:string,
    name:string
  }>
}){

  const showdata = useQuoteList(props.quotecode_list, [
    QuoteItem.行情代码,
    QuoteItem.名称,
    QuoteItem.最新价,
    QuoteItem.行情统一链接,
    QuoteItem.涨跌幅,
    QuoteItem.涨跌额,
  ], 30)


  return (
    <div className="qqzs_gjzs">
    {showdata.map(v=>
      <React.Fragment key={v[QuoteItem.行情代码].txt}>
        <a href={v[QuoteItem.行情统一链接].txt} target="_blank"><b>{props.codedata.find(i=>i.code.toLowerCase() == v[QuoteItem.行情代码].txt.toLowerCase())?.name}</b></a>
        <span className={`nums ${v[QuoteItem.最新价].color}`}>
          {v[QuoteItem.最新价].txt}
          <span className="jt">
            {v[QuoteItem.最新价].color == 'price_up' &&
              <>↑</>
            }
            {v[QuoteItem.最新价].color == 'price_down' &&
              <>↓</>
            }
          </span>   
          {v[QuoteItem.涨跌额].txt}
          <span className="jt">
            {v[QuoteItem.最新价].color == 'price_up' &&
              <>↑</>
            }
            {v[QuoteItem.最新价].color == 'price_down' &&
              <>↓</>
            }              
          </span>          
          {v[QuoteItem.涨跌幅].txt}
        </span>
      </React.Fragment>
    )}
    </div>
  )
}



/** 全球股市 */
function QQGS() {

  const [index, SetIndex] = useState(0)

  const UpHandler = () =>{
    SetIndex((index)=>{
      index --
      if(index < 0) index = 2
      return index
    })
  }

  const DownHandler = () =>{
    SetIndex((index)=>{
      index ++
      if(index > 2) index = 0
      return index
    })
  }

  return (
    <div className="topqqgs self_clearfix">
      <div className="topqqgs_btns">
        <span className="down" title="上滚" onClick={UpHandler} />
        <span className="up" title="下滚" onClick={DownHandler} />
      </div>
      <div className="topqqgs_links">
        {index == 0 &&
          <SHSZ></SHSZ>
        }
        {index == 1 &&
          <GJZS type={1}></GJZS>
        }
        {index == 2 &&
          <GJZS type={2}></GJZS>
        }
      </div>

    </div>
  )
}

export default QQGS