/**
 * 头部标题
 */

import React, { useState, useEffect } from "react"
import './index.less'


function HeadTitle() {
  return (
    <div className="head_title self_clearfix">
      <div className="logo">
        <a target="_blank" href="http://www.eastmoney.com" className="logolink"><img src="/newstatic/images/logo.png" alt="东方财富网" />
        </a>
        <a target="_blank" href="http://quote.eastmoney.com/center/" className="logolink2">
          <img src="/newstatic/images/quote_center.png" alt="行情中心" />
        </a>
      </div>
      <div className="ogo">
        <a className="ogo_qq" target="_blank" href="http://kuaixun.eastmoney.com/">全球财经快讯</a>
        <a className="ogo_data" target="_blank" href="http://data.eastmoney.com/center/">数据中心</a>
        <a className="ogo_mobile" target="_blank" href="https://acttg.eastmoney.com/pub/web_act_ggds_dhlsf_01_01_01_0">手机站</a>
        <a className="ogo_app" target="_blank" href="https://acttg.eastmoney.com/pub/web_act_ggds_dhlsf_01_01_02_0">客户端</a>
        <a className="ogo_choice" target="_blank" href="https://acttg.eastmoney.com/pub/web_act_ggds_dhlsf_01_01_03_0">Choice数据</a>
        <a className="ogo_jj" target="_blank" href="http://acttg.eastmoney.com/pub/web_act_ggds_dhlsf_01_01_04_0">手机买基金 牛市赚不停&gt;</a>
      </div>
    </div> 
  )
}

export default HeadTitle