/**
 * 侧边栏 股票 调查
 */

import React,{useState, useEffect} from "react"
import { SiderTabBox } from "../sider_tab_box"
import useSWR from 'swr'
import './index.less'
import { getStockVote } from "../../../modules/stock_vote/web"
import cookie from '../../../modules/cookie'

/**
 * 侧边栏 股票 调查
 */
export function StockSiderDC(props:{
  name: string,
  market: string,
  code: string
}){
  return (
    <SiderTabBox {...{
      mt:-2,
      boxdata:[
        {
          tab_html: <span>{props.name}网友调查</span>,
          body: <StockSiderDCContent {...{
            market: props.market,
            code: props.code
          }}></StockSiderDCContent>
        }
      ]}}></SiderTabBox>
  )
}

function StockSiderDCContent(props:{
  market: string,
  code: string
}){

  const { data, error } = useSWR(`/quote/stock/dkdc/${props.code}`, ()=>getStockVote(props.market, props.code), {
    refreshInterval: 5 * 60 * 1000,
    revalidateOnFocus: false
  })

  const [selectvalue, SetSelectValue] = useState('')
  const [hasvote, SetHasVote] = useState(false)

  const submit = function(){
    if(selectvalue == ''){
      alert('请先选择投票方向！')
      return false
    }

    if(!cookie.get('uidal')){
      alert('请先登录！')
      return false
    }

    $.ajax({
      url: '/newapi/votestock',
      type: 'POST',
      dataType: 'json',
      data: {
        code: `${props.market == '0'?'sz':'sh'}${props.code}`,
        tapetype: selectvalue
      }
    }).then(function(data){
      if(data.re == false){
        alert(data.message)
      }
      else if(data.Status === -1){
        alert(data.Message)
      }
      else{
        SetHasVote(true)
        alert('投票成功！')        
      }

    })
    
  }

  if(data){
    return (
      <div className="stocksiderdc">
        <form onSubmit={(e)=>{submit();e.preventDefault()}}>
          <div className="fitem">
            <label><input type="radio" className="kzkd" name="kzkd" value="1" onClick={()=>{SetSelectValue('1')}} />看涨</label>
            <span className="percentbar price_up_bg" style={{width: 75 * data.z}}></span>
            <span>{(data.z * 100).toFixed(2) + '%'}</span>
          </div>
          <div className="fitem">
            <label><input type="radio" className="kzkd" name="kzkd" value="-1" onClick={()=>{SetSelectValue('-1')}} />看跌</label>
            <span className="percentbar price_down_bg" style={{width: 75 * data.d}}></span>
            <span>{(data.d * 100).toFixed(2) + '%'}</span>
          </div>
          <div className="fitem btn">
            {
              hasvote ?
                <div className="hasvote">已投票</div>
              :
                <input type="submit" value="投票" />
            }
          </div>
          <div className="time">
            统计时间: <span>{data.date}</span>
          </div>
        </form>
      </div>    
    )    
  }

  return (
    <div className="stocksiderdc"></div>
  )
}