import React,{useState, useEffect} from "react"
import url from '../../../modules/url'
import './index.less'
import { sendStat } from "../../../modules/bigdata_stat"

/**
 * 利润趋势图
 * @returns 
 */
export function ProfitChart(props:{
  data_code: string,
  code: string,
  show_more?: boolean,
  show_link?: boolean
}){

  let show_more = props.show_more ?? false
  let show_link = props.show_link ?? false

  const [isBrowser, SetIsBrowser] = useState(false)

  useEffect(()=>{
    SetIsBrowser(true)
  }, []) 

  let env = url.getQuery('env')
  let address = `/newstatic/html/profitchart_new.html?code=${props.data_code}`

  if(env == 'test'){
    address += '&env=test'
  }

  return (
    <div className="profitchart">
      <div className="t">
        {
          show_link &&
          <a href={`//data.eastmoney.com/stockdata/${props.code}.html#cwsj`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.lrqs.lrqs')}}>利润趋势</a>
        }
        {
          !show_link &&
          <>利润趋势</>
        }        
      </div>
      {
        show_more &&
        <div className="more"><a href={`//data.eastmoney.com/stockdata/${props.code}.html#cwsj`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.lrqs.gd')}}>更多</a></div>
      }
      {
        isBrowser &&
          <div className="chart">
            <iframe scrolling="no" frameBorder="0" style={{width: '185px', height: '138px'}} src={address} marginHeight={5} marginWidth={0}></iframe>
          </div>
      }      
    </div>
  )
}