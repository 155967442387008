

import React,{useState, useEffect} from "react"
import { useOneQuote, QuoteItem } from "../../../modules/quote_info/quote_hook"
import useSWR from "swr";
import { getBondKzzInfoNew } from "../../../modules/datacenter/web";
import dayjs from 'dayjs'

/**
 * 显示逻辑：
 * 可转债 
 * 又有H股又是沪伦通
 * 港股
 * 沪伦通
 * 瑞士GDR
 * B股
 * 持有基金
 */

/**
 * 行情数字栏右侧模块
 * @returns 
 */
export function QuoteBriefSider(props:{
  code: string,
  name: string,
  market: string
}){

  const quotedata = useOneQuote(props.market + '.' + props.code, [
    QuoteItem.可转债代码或正股代码,
    QuoteItem.可转债市场或正股市场,
    QuoteItem.A股对应GDR或GDR对应A股的市场,
    QuoteItem.A股对应GDR或GDR对应A股的代码,
    QuoteItem.AH股对应的市场,
    QuoteItem.AH股对应的代码,
    QuoteItem.AB股对应的市场,
    QuoteItem.AB股对应的代码,

    QuoteItem.A瑞士对应的市场,
    QuoteItem.A瑞士对应的代码,
  ], 0)
  

  // if(quotedata){
  //   console.info(quotedata[QuoteItem.A瑞士对应的代码].txt)
    
  // }

  if(quotedata && quotedata[QuoteItem.可转债代码或正股代码].txt != '-'){ //可转债
    return <KZZ market={props.market} code={props.code} kzz_market={quotedata[QuoteItem.可转债市场或正股市场].txt} kzz_code={quotedata[QuoteItem.可转债代码或正股代码].txt} name={props.name}></KZZ>
  }

  if(quotedata && quotedata[QuoteItem.A股对应GDR或GDR对应A股的代码].txt != '-' && quotedata[QuoteItem.AH股对应的代码].txt != '-'){ //有H股又是沪伦通
    return <HKandGDR {...{
      h_market: quotedata[QuoteItem.AH股对应的市场].txt,
      h_code: quotedata[QuoteItem.AH股对应的代码].txt,
      uk_market: quotedata[QuoteItem.A股对应GDR或GDR对应A股的市场].txt,
      uk_code: quotedata[QuoteItem.A股对应GDR或GDR对应A股的代码].txt,
    }}></HKandGDR>
  }

  if(quotedata && quotedata[QuoteItem.AH股对应的代码].txt != '-'){ //有H股
    return <HKQuote {...{
      market: quotedata[QuoteItem.AH股对应的市场].txt,
      code: quotedata[QuoteItem.AH股对应的代码].txt,
    }}></HKQuote>
  }

  if(quotedata && quotedata[QuoteItem.A股对应GDR或GDR对应A股的代码].txt != '-'){ //有英股
    return <UKQuote {...{
      market: quotedata[QuoteItem.A股对应GDR或GDR对应A股的市场].txt,
      code: quotedata[QuoteItem.A股对应GDR或GDR对应A股的代码].txt,
    }}></UKQuote>
  }  
  
  if(quotedata && quotedata[QuoteItem.A瑞士对应的代码].txt != '-' && quotedata[QuoteItem.A瑞士对应的代码].txt != undefined){ //有瑞士GDR
    return <CHQuote {...{
      market: quotedata[QuoteItem.A瑞士对应的市场].txt,
      code: quotedata[QuoteItem.A瑞士对应的代码].txt,
    }}></CHQuote>
  }  
  

  if(quotedata && quotedata[QuoteItem.AB股对应的代码].txt != '-'){ //有B股
    return <BQuote {...{
      market: quotedata[QuoteItem.AB股对应的市场].txt,
      code: quotedata[QuoteItem.AB股对应的代码].txt,
    }}></BQuote>
  }    


  if(quotedata == null) return <></>

  return <CYJJ name={props.name} code={props.code}></CYJJ>
}



/** 可转债 */
function KZZ(props:{
  market: string,
  code: string,
  kzz_market: string,
  kzz_code: string,
  name: string
}){

  const { data, error } = useSWR(`/quote/stock/kzz/`, ()=>getBondKzzInfoNew(props.kzz_market, props.kzz_code),{
    revalidateOnFocus: false
  })

  if(data){
    if(data.PUBLIC_START_DATE == null) return <CYJJ name={props.name} code={props.code}></CYJJ> //没有申购日期
    
    /** 申购日期 */
    let sgdate = dayjs(data.PUBLIC_START_DATE?.substring(0, 10), "YYYY-MM-DD")
    /** 今天 */
    let thisdate = dayjs()
    
    if (thisdate.isBefore(sgdate, 'day')) {//申购日期之前
      return (
        <div className="rblocks">
          <div className="t">
            <a target="_blank" href={`//data.eastmoney.com/kzz/detail/${props.kzz_code}.html`}>{data.SECURITY_NAME_ABBR}</a>
          </div>
          <div>
            申购日期: {data.PUBLIC_START_DATE?.substring(0, 10)}
          </div>
          <div className="price">
            <a target="_blank" href={`//data.eastmoney.com/kzz/detail/${props.kzz_code}.html`}>查看详情&gt;&gt;</a>
          </div>
        </div>        
      )
    }
    else if(thisdate.isSame(sgdate, 'day')){ //申购当天
      console.info(111)
      
      var hh = (new Date()).getHours();
      if(hh < 15){ //申购当天 00:00 -- 15:00:今日申购
        return (
          <div className="rblocks">
            <div className="t">
              <a target="_blank" href={`//data.eastmoney.com/kzz/detail/${props.kzz_code}.html`}>{data.SECURITY_NAME_ABBR}今日申购</a>
            </div>
            <div>
              申购代码: {data.CORRECODE}
            </div>
            <div className="price">
              配售代码: {data.CORRECODEO}
            </div>
        </div>
        )          
      }
      else{ //申购当天15：00至中签公布日:---转债
        return (
          <div className="rblocks">
            <div className="t">
              <a target="_blank" href={`//data.eastmoney.com/kzz/detail/${props.kzz_code}.html`}>{data.SECURITY_NAME_ABBR}</a>
            </div>
            <div>
              债券代码: {props.kzz_code}
            </div>
            <div className="price">
              中签公布: {data.BOND_START_DATE?.substring(0, 10)}
            </div>
          </div>
        )   
      }
    }
    else if(data.LISTING_DATE != null){ //有上市日期
      /** 上市日期 */
        let ssdate = dayjs(data.LISTING_DATE?.substring(0, 10), "YYYY-MM-DD")
        if(thisdate.isBefore(ssdate, 'day')){ //当前日期小于上市日期
          return (
            <div className="rblocks">
              <div className="t">
                <a target="_blank" href={`//data.eastmoney.com/kzz/detail/${props.kzz_code}.html`}>{data.SECURITY_NAME_ABBR}</a>
              </div>
              <div>
                债券代码: {props.kzz_code}
              </div>
              <div className="price">
                上市日期: {data.LISTING_DATE?.substring(0, 10)}
              </div>
            </div>
          ) 
        }
        else{  //上市当天及之后
          return <KZZQuote market={props.market} code={props.kzz_code}></KZZQuote>
        }
    }
    else{
      //申购之后至中签公布日
      if(data.BOND_START_DATE == null || data.BOND_START_DATE == '-'){
        return (
          <div className="rblocks">
            <div className="t">
              <a target="_blank" href={`//data.eastmoney.com/kzz/detail/${props.kzz_code}.html`}>{data.SECURITY_NAME_ABBR}</a>
            </div>
            <div>
              债券代码: {props.kzz_code}
            </div>
            <div className="price">
              中签公布: -
            </div>
          </div>
        )   
      }
      else{
        /** 中签公布日 */
        let zqdate = dayjs(data.BOND_START_DATE?.substring(0, 10), "YYYY-MM-DD")
        //中签公布日当天或之前
        if(zqdate.isAfter(thisdate, 'day') || zqdate.isSame(thisdate, 'day')){
          return (
            <div className="rblocks">
              <div className="t">
                <a target="_blank" href={`//data.eastmoney.com/kzz/detail/${props.kzz_code}.html`}>{data.SECURITY_NAME_ABBR}</a>
              </div>
              <div>
                债券代码: {props.kzz_code}
              </div>
              <div className="price">
                中签公布: {data.BOND_START_DATE?.substring(0, 10)}
              </div>
            </div>
          )            
        }
        else{ //中签公布日之后
          return (
            <div className="rblocks">
              <div className="t">
                <a target="_blank" href={`//data.eastmoney.com/kzz/detail/${props.kzz_code}.html`}>{data.SECURITY_NAME_ABBR}</a>
              </div>
              <div>
                债券代码: {props.kzz_code}
              </div>
              <div className="price">
                <a target="_blank" href={`//data.eastmoney.com/kzz/detail/${props.kzz_code}.html`}>查看详情&gt;&gt;</a>
              </div>
            </div>        
          )
        }
      }
      
    }

  }

  return <></>
}

/** 可转债行情 */
function KZZQuote(props:{
  market: string,
  code: string
}){

  const quotedata = useOneQuote(props.market + '.' + props.code, [
    QuoteItem.名称,
    QuoteItem.行情统一链接,
    QuoteItem.最新价,
    QuoteItem.涨跌幅,
    QuoteItem.涨跌额,
  ], 30)  

  if(quotedata == null) return <></>

  return (
    <div className="rblocks">
      <div className="t">
        <a target="_blank" href={quotedata[QuoteItem.行情统一链接].txt}>{quotedata[QuoteItem.名称].txt}行情</a>
      </div>
      <div>
        {quotedata[QuoteItem.最新价].blink_html}
      </div>
      <div className="price">
        {quotedata[QuoteItem.涨跌额].blink_html}
        &nbsp;
        {quotedata[QuoteItem.涨跌幅].blink_html}
      </div>
    </div>
  )
}

/** 港股 */
function HKQuote(props:{
  market: string,
  code: string
}){

  const quotedata = useOneQuote(props.market + '.' + props.code, [
    QuoteItem.行情统一链接,
    QuoteItem.名称,
    QuoteItem.最新价,
    QuoteItem.涨跌幅,
    QuoteItem.涨跌额,
  ], 30)  

  if(quotedata == null) return <></>

  return (
    <div className="rblocks">
      <div className="t">
        <a target="_blank" href={quotedata[QuoteItem.行情统一链接].txt}>{quotedata[QuoteItem.名称].txt} H股行情</a>
      </div>
      <div>
        {quotedata[QuoteItem.最新价].blink_html}
      </div>
      <div className="price">
        {quotedata[QuoteItem.涨跌额].blink_html}
        &nbsp;
        {quotedata[QuoteItem.涨跌幅].blink_html}
      </div>
    </div>
  )
}

/** 英股 */
function UKQuote(props:{
  market: string,
  code: string
}){

  const quotedata = useOneQuote(props.market + '.' + props.code, [
    QuoteItem.行情统一链接,
    QuoteItem.名称,
    QuoteItem.最新价,
    QuoteItem.涨跌幅,
    QuoteItem.涨跌额,
  ], 30)  

  if(quotedata == null) return <></>

  return (
    <div className="rblocks">
      <div className="t">
        <a target="_blank" href={quotedata[QuoteItem.行情统一链接].txt}>{quotedata[QuoteItem.名称].txt} 英股行情</a>
      </div>
      <div>
        {quotedata[QuoteItem.最新价].blink_html}
      </div>
      <div className="price">
        {quotedata[QuoteItem.涨跌额].blink_html}
        &nbsp;
        {quotedata[QuoteItem.涨跌幅].blink_html}
      </div>
    </div>
  )
}

/** 瑞士GDR */
function CHQuote(props:{
  market: string,
  code: string
}){

  const quotedata = useOneQuote(props.market + '.' + props.code, [
    QuoteItem.行情统一链接,
    QuoteItem.名称,
    QuoteItem.最新价,
    QuoteItem.涨跌幅,
    QuoteItem.涨跌额,
  ], 30)  

  if(quotedata == null) return <></>

  return (
    <div className="rblocks">
      <div className="t">
        <a target="_blank" href={quotedata[QuoteItem.行情统一链接].txt}>{quotedata[QuoteItem.名称].txt} 瑞士GDR行情</a>
      </div>
      <div>
        {quotedata[QuoteItem.最新价].blink_html}
      </div>
      <div className="price">
        {quotedata[QuoteItem.涨跌额].blink_html}
        &nbsp;
        {quotedata[QuoteItem.涨跌幅].blink_html}
      </div>
    </div>
  )
}


/** B股 */
function BQuote(props:{
  market: string,
  code: string
}){

  const quotedata = useOneQuote(props.market + '.' + props.code, [
    QuoteItem.行情统一链接,
    QuoteItem.名称,
    QuoteItem.最新价,
    QuoteItem.涨跌幅,
    QuoteItem.涨跌额,
  ], 30)  

  if(quotedata == null) return <></>

  return (
    <div className="rblocks">
      <div className="t">
        <a target="_blank" href={quotedata[QuoteItem.行情统一链接].txt}>{quotedata[QuoteItem.名称].txt} B股行情</a>
      </div>
      <div>
        {quotedata[QuoteItem.最新价].blink_html}
      </div>
      <div className="price">
        {quotedata[QuoteItem.涨跌额].blink_html}
        &nbsp;
        {quotedata[QuoteItem.涨跌幅].blink_html}
      </div>
    </div>
  )
}

/**
 * 又有港股又有GDR
 */
function HKandGDR(props:{
  h_market: string,
  h_code: string,
  uk_market: string,
  uk_code: string
}){

  const quotedata_h = useOneQuote(props.h_market + '.' + props.h_code, [
    QuoteItem.行情统一链接,
    QuoteItem.名称,
    QuoteItem.最新价,
    QuoteItem.涨跌幅,
    QuoteItem.涨跌额,
  ], 30)

  const quotedata_uk = useOneQuote(props.uk_market + '.' + props.uk_code, [
    QuoteItem.行情统一链接,
    QuoteItem.名称,
    QuoteItem.最新价,
    QuoteItem.涨跌幅,
    QuoteItem.涨跌额,
  ], 30)  

  return (
    <div className="rblocks4">
      {
        quotedata_h &&
          <>
            <div className="t">
              <a href={quotedata_h[QuoteItem.行情统一链接].txt} target="_blank">{quotedata_h[QuoteItem.名称].txt} H股行情</a>
            </div>
            <div className="price">
              <span>{quotedata_h[QuoteItem.最新价].blink_html}</span>
              <span>{quotedata_h[QuoteItem.涨跌额].blink_html}</span>
              <span>{quotedata_h[QuoteItem.涨跌幅].blink_html}</span>
            </div>  
          </>      
      }
      {
        quotedata_uk &&
          <>
            <div className="t">
              <a href={quotedata_uk[QuoteItem.行情统一链接].txt} target="_blank">{quotedata_uk[QuoteItem.名称].txt} 英股行情</a>
            </div>
            <div className="price">
              <span>{quotedata_uk[QuoteItem.最新价].blink_html}</span>
              <span>{quotedata_uk[QuoteItem.涨跌额].blink_html}</span>
              <span>{quotedata_uk[QuoteItem.涨跌幅].blink_html}</span>
            </div>  
          </>      
      }
    </div> 
  )
}



/**
 * 持有基金
 * @param props 
 * @returns 
 */
function CYJJ(props:{
  name: string,
  code: string
}){
  return (
    <div className="rlinks">
      <a className="link1" href={`//data.eastmoney.com/zlsj/detail/${props.code}-1.html`} target="_blank">持有该股的基金</a><br />
      <a className="link2" href="http://acttg.eastmoney.com/pub/web_act_ggds_f10sf_01_01_01_0" target="_blank">查看<span>{props.name}</span>买卖点</a><br />
      <a className="link3" href="https://acttg.eastmoney.com/pub/web_act_ggds_f10sf_01_01_02_0" target="_blank"><span>{props.name}</span>股价预警</a>
    </div>
  )
}