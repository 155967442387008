import React,{useState, useEffect} from "react"
import { SiderTabBox } from "../sider_tab_box"
import { useOneQuote, QuoteItem } from '../../../modules/quote_info/quote_hook'
import { initSingleValue } from "../../../modules/quote_info/quote_data";
import './index.less'
import { sendStat } from "../../../modules/bigdata_stat";

/**
 * 公司核心数据
 * @returns 
 */
export function QuoteSiderCore(props:{
  quotecode: string,
  code: string
}){
  return (
    <SiderTabBox {...{
      mt: 10,
      tab_split: true,
      boxdata:[
        {
          tab_html: <i>公司核心数据</i>
        },
        {
          tab_html: <a href={`//data.eastmoney.com/stockdata/${props.code}.html`} target="_blank" onClick={()=>{sendStat('A', 'Click', 'webjdb.gssjql.gssjql')}}>公司数据全览</a>,
          faketab: true
        }
      ]}}>
        <QuoteSiderCoreInner quotecode={props.quotecode} code={props.code}></QuoteSiderCoreInner>
      </SiderTabBox>
  )
}

function QuoteSiderCoreInner(props:{
  quotecode: string,
  code: string
}){

  let showdata = useOneQuote(props.quotecode, [
    QuoteItem.行情代码,
    QuoteItem.市盈率动态,
    QuoteItem.市净率,
    QuoteItem.每股净资产,
    QuoteItem.营业总收入,
    QuoteItem.营业总收入同比,
    QuoteItem.净利润,
    QuoteItem.净利润同比,
    QuoteItem.毛利率,
    QuoteItem.净利率,
    QuoteItem.净资产收益率ROE,
    QuoteItem.资产负债率,
    QuoteItem.总股本,
    QuoteItem.总市值,
    QuoteItem.流通股本,
    QuoteItem.流通市值,
    QuoteItem.每股未分配利润,
    QuoteItem.上市日期,
    QuoteItem.财报季度,
    QuoteItem.每股收益
  ])

  if(showdata == null){
    showdata = initSingleValue()
  }

  return (
    <div className="quotecore">
      <table>
        <tbody>
          <tr>
            <td><a href={`//data.eastmoney.com/bbsj/${props.code}.html`} target="_blank">收益(<span title={`第${showdata[QuoteItem.财报季度].txt}季度`}>{showdata[QuoteItem.财报季度].txt}</span>)</a>:{showdata[QuoteItem.每股收益].html}</td>
            <td>PE(动):{showdata[QuoteItem.市盈率动态].html}</td>
          </tr>
          <tr>
            <td><a href={`//data.eastmoney.com/bbsj/${props.code}.html`} target="_blank">每股净资产</a>:{showdata[QuoteItem.每股净资产].html}</td>
            <td>市净率:{showdata[QuoteItem.市净率].html}</td>
          </tr>
          <tr>
            <td>总营收:{showdata[QuoteItem.营业总收入].html}</td>
            <td><a href={`//data.eastmoney.com/bbsj/${props.code}.html`} target="_blank">同比</a>:{showdata[QuoteItem.营业总收入同比].html}</td> 
          </tr>
          <tr>
            <td>净利润:{showdata[QuoteItem.净利润].html}</td>
            <td>同比:{showdata[QuoteItem.净利润同比].html}</td>
          </tr>
          <tr>
            <td><a href={`//data.eastmoney.com/bbsj/${props.code}.html`} target="_blank">毛利率</a>:{showdata[QuoteItem.毛利率].html}</td>
            <td>净利率:{showdata[QuoteItem.净利率].html}</td>
          </tr>
          <tr>
            <td><a href={`//data.eastmoney.com/bbsj/${props.code}.html`} target="_blank">ROE<b className="help" title="加权净资产收益率"></b></a>:{showdata[QuoteItem.净资产收益率ROE].html}</td>
            <td>负债率:{showdata[QuoteItem.资产负债率].html}</td>
          </tr>
          <tr>
            <td>总股本: {showdata[QuoteItem.总股本].html}</td>
            <td>总值:{showdata[QuoteItem.总市值].html}</td>
          </tr>
          <tr>
            <td>流通股: {showdata[QuoteItem.流通股本].html}</td>
            <td>流值:{showdata[QuoteItem.流通市值].html}</td>
          </tr>
          <tr>
            <td colSpan={2}>每股未分配利润:{showdata[QuoteItem.每股未分配利润].html}元</td>
          </tr>
          <tr>
            <td colSpan={2}>上市时间:{showdata[QuoteItem.上市日期].html}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}


/**
 * 公司核心数据-京A
 * @returns 
 */
export function QuoteSiderCoreBJ(props:{
  quotecode: string
}){
  return (
    <SiderTabBox {...{
      mt: 10,
      boxdata:[
        {
          tab_html: <span>公司核心数据</span>
        }
      ]}}>
        <QuoteSiderCoreInnerBJ quotecode={props.quotecode}></QuoteSiderCoreInnerBJ>
      </SiderTabBox>
  )
}

function QuoteSiderCoreInnerBJ(props:{
  quotecode: string
}){

  let showdata = useOneQuote(props.quotecode, [
    QuoteItem.行情代码,
    QuoteItem.市盈率动态,
    QuoteItem.市净率,
    QuoteItem.每股净资产,
    QuoteItem.营业总收入,
    QuoteItem.营业总收入同比,
    QuoteItem.净利润,
    QuoteItem.净利润同比,
    QuoteItem.毛利率,
    QuoteItem.净利率,
    QuoteItem.净资产收益率ROE,
    QuoteItem.资产负债率,
    QuoteItem.总股本,
    QuoteItem.总市值,
    QuoteItem.流通股本,
    QuoteItem.流通市值,
    QuoteItem.每股未分配利润,
    QuoteItem.上市日期,
    QuoteItem.财报季度,
    QuoteItem.每股收益
  ])

  if(showdata == null){
    showdata = initSingleValue()
  }

  return (
    <div className="quotecore">
      <table>
        <tbody>
          <tr>
            <td>收益(<span title={`第${showdata[QuoteItem.财报季度].txt}季度`}>{showdata[QuoteItem.财报季度].txt}</span>):{showdata[QuoteItem.每股收益].html}</td>
            <td>PE(动):{showdata[QuoteItem.市盈率动态].html}</td>
          </tr>
          <tr>
            <td>每股净资产:{showdata[QuoteItem.每股净资产].html}</td>
            <td>市净率:{showdata[QuoteItem.市净率].html}</td>
          </tr>
          <tr>
            <td>总营收:{showdata[QuoteItem.营业总收入].html}</td>
            <td>同比:{showdata[QuoteItem.营业总收入同比].html}</td> 
            {/* {showdata[QuoteItem.营业总收入同比].html} 暂无数据 */}
          </tr>
          <tr>
            <td>净利润:{showdata[QuoteItem.净利润].html}</td>
            <td>同比:{showdata[QuoteItem.净利润同比].html}</td>
            {/* {showdata[QuoteItem.净利润同比].html} */}
          </tr>
          <tr>
            <td>毛利率:{showdata[QuoteItem.毛利率].html}</td>
            {/* {showdata[QuoteItem.毛利率].html} */}
            <td>净利率:{showdata[QuoteItem.净利率].html}</td>
            {/* {showdata[QuoteItem.净利率].html} */}
          </tr>
          <tr>
            <td>ROE<b className="help" title="加权净资产收益率"></b>:{showdata[QuoteItem.净资产收益率ROE].html}</td>
            <td>负债率:{showdata[QuoteItem.资产负债率].html}</td>
            {/* {showdata[QuoteItem.资产负债率].html} */}
          </tr>
          <tr>
            <td>总股本: {showdata[QuoteItem.总股本].html}</td>
            <td>总值:{showdata[QuoteItem.总市值].html}</td>
          </tr>
          <tr>
            <td>流通股: {showdata[QuoteItem.流通股本].html}</td>
            <td>流值:{showdata[QuoteItem.流通市值].html}</td>
          </tr>
          {/* <tr>
            <td colSpan={2}>每股未分配利润:{showdata[QuoteItem.每股未分配利润].html}元</td>
            暂无数据
            {showdata[QuoteItem.每股未分配利润].html}元
          </tr> */}
          <tr>
            <td colSpan={2}>上市时间:{showdata[QuoteItem.上市日期].html}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}