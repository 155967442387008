/**
 * K线推送数据画图
 */

import React, { useState, useEffect } from "react"
import { KType, KFQType } from './index'
import { sendStat } from "../../../modules/bigdata_stat";


let kchart:any = null;


let kfqtype = 1
let type = 'D'

function loadCandleChartNew(quotecode: string, ktype:string, kfqtype:number, fullscreen: boolean) {
  
  let default_options = {
    code: quotecode,
    container: "#emchartk",
    ktype: ktype,
    cfq: kfqtype,
    width: 585,
    height: 385,
    padding: {
      top: 0,
      bottom: 0
    },
    scale: {
      pillar: 60,
      min: 10
    },
    // show: {
    //     fold: true
    // },
    color: {
      background: "transparent"
    },
    popWin: { type: "move" },
    maxin: {
      //show: true,
      lineWidth: 30,     // 线长
      skewx: 0,            // x偏移   
      skewy: 0,            // y偏移
    },
    // onError: function (e: any) {
    //   console.error(e)
    // }
    onClick: function(){
      if(fullscreen){
        window.open("#fullScreenChart", "_self")
      }
    }
  }

  if(kchart == null && window?.quotekchart?.k){
    kchart = new window.quotekchart.k(default_options);
    kchart.draw()
  }

}


export function KlineChart(props: {
  quotecode: string,
  ktype: KType,
  kfqtype: KFQType,
  zoom_number: number,
  fullscreen: boolean
}) {

  useEffect(() => {
    if(window?.quotekchart?.k){
      loadCandleChartNew(props.quotecode, getKtype(props.ktype), getCFQ(props.kfqtype), props.fullscreen)

      let cd = setInterval(function(){
        kchart.reDraw()
      }, 60 * 1000)

      return ()=>{
        clearInterval(cd)
        kchart = null
      }      
    }

  }, [])

  useEffect(()=>{
    let totype = getCFQ(props.kfqtype)

    if(totype != kfqtype){
      kfqtype = totype
      kchart.setCfq(totype)
    }

    
  }, [props.kfqtype])

  useEffect(()=>{
    // console.info(props.zoom_number)
    
    if(props.zoom_number > 0){
      sendStat('DIV', 'click', 'hqzx.hsjAghqdy.dtt.lcKx') //大数据统计
      kchart.elongate();
    }
    if(props.zoom_number < 0){
      sendStat('DIV', 'click', 'hqzx.hsjAghqdy.dtt.sdKx') //大数据统计
      kchart.shorten();
    }
  }, [props.zoom_number])


  useEffect(()=>{
    document.getElementsByClassName('mainquotecharts')?.[0].addEventListener('click', function(e){
      if(e.target){
        var classname = (e.target as HTMLDivElement).className
        if(classname == '__slidebar' || classname == '__sb_left' || classname == '__sb_right'){
          sendStat('DIV', 'click', 'hqzx.hsjAghqdy.dtt.tdKxfw') //大数据统计
        }
      }
      
    })
  }, [])  

  useEffect(()=>{
    let totype = getKtype(props.ktype)
    if(totype != type){
      type = totype
      kchart.setKType(totype)
    }
  }, [props.ktype])    

  return (
    <div className="kchart_d">
      <div id="emchartk" className={`${props.fullscreen?'kchart_d_fs':''}`}></div>
    </div>
  )
}

function getCFQ(kfqtype: KFQType){
  let totype = 1
  switch (kfqtype) {
    case KFQType.前复权:
      totype = 1
      break
    case KFQType.后复权:
      totype = 2
      break
    case KFQType.不复权:
      totype = 0
      break
  }  
  return totype
}


function getKtype(ktype: KType){
  let totype = 'D'
    switch (ktype) {
      case KType.日K:
        totype = 'D'
        break
      case KType.周K:
        totype = 'W'   
        break
      case KType.月K:
        totype = 'M'
        break
      case KType['5分钟']:
        totype = 'M5'
        break
      case KType['15分钟']:
        totype = 'M15'
        break
      case KType['30分钟']:
        totype = 'M30'
        break
      case KType['60分钟']:
        totype = 'M60'
        break
    }  
  return totype
}