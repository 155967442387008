import React,{useState, useEffect} from "react"
import qrcode from "../../../modules/qrcode";
import './quote_title_qr.less'


export function QuoteTitleQR(props:{
  quotecode:string
}){

  const [showqr, SetShowQR] = useState(false)

  const openqr = function(){
    SetShowQR(true)
  }

  return (
    <div className="quote_title_qr" onMouseOver={openqr}>
      <div className="qrdivc">
        <div className="qrdiv">
          <div className="qrdivl">
            <div className="qrdiv2l">
              <div className="t">东方财富APP</div>
              <img src="/newstatic/images/app_qr.png" alt="" />
            </div>
            <div className="qrdiv2r">
              下载东方财富App <br />
              一手掌握市场脉搏
            </div>
          </div>
          <div className="qrdivr">
            <div className="qrdiv2l">
              <div className="t">手机访问</div>
              {
                showqr &&
                <QRImg quotecode={props.quotecode}></QRImg>
              }
            </div>
            <div className="qrdiv2r">
              扫一扫 <br />
              二维码 <br />
              用手机打开 <br />
              页面
            </div>
          </div>
        </div>        
      </div>

    </div>
  )
}

function QRImg(props:{
  quotecode:string
}){

  useEffect(()=>{    
    //二维码
    var qrtext = "https://wap.eastmoney.com/quote/stock/" + props.quotecode + '.html';
    var qrnode = qrcode({
      text: qrtext
    });

    $("#qrcanvas").append(qrnode);
  }, [])

  return (
    <div id="qrcanvas"></div>
  )
}